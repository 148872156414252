import {
  ArrowDownIcon,
  ArrowSmRightIcon,
  ArrowUpIcon,
  DocumentTextIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { useFlag } from "@unleash/proxy-client-react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { LocalStocktakeType } from "@web/common";
import {
  Badge,
  DividerDropdownItem,
  DropdownItem,
  IRow,
  RegularDropdownItem,
  Table,
} from "@web/ui";
import { formatMoney, formatNumber } from "@web/utils";

import { SortStocktakesListBy, SortStocktakesListDir } from "src/hooks/stocktakes";
import { UiStocktakeReportListItem } from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

import { getBadgeSettingsFromStocktakeState } from "./utils";

interface Props {
  stocktakes: UiStocktakeReportListItem[];
  shouldRenderStocktakeTypes: boolean;
  shouldRenderStocktakeType: (stocktakeType: LocalStocktakeType) => boolean;
  onDeleteDraft: (draftId: string) => void;
  onResumeDraft: (draftId: string) => void;
  onViewDetails: (stocktakeId: string) => void;
  onDeleteReport: (stocktakeId: string) => void;
  onSetSortDirValue: (sortDir: SortStocktakesListDir) => void;
  onSetSortByValue: (sortBy: SortStocktakesListBy) => void;
  isLoading: boolean;
}

export const StocktakesTableUI = ({
  stocktakes,
  shouldRenderStocktakeTypes,
  shouldRenderStocktakeType,
  onDeleteDraft,
  onResumeDraft,
  onViewDetails,
  onDeleteReport,
  onSetSortDirValue,
  onSetSortByValue,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const isDeleteStocktakeReportEnabled = useFlag("delete-stocktake-report");

  const getHeaderCellDropdownOptions = useCallback(
    ({ sortBy }: { sortBy: SortStocktakesListBy }): Array<DropdownItem> => [
      {
        key: "sortAscending",
        renderComponent: () => (
          <RegularDropdownItem
            label="Sort ascending"
            variant="basic"
            LeadingIcon={ArrowUpIcon}
            onClick={() => {
              onSetSortDirValue("ASC");
              onSetSortByValue(sortBy);
            }}
          />
        ),
      },
      {
        key: "sortDescending",
        renderComponent: () => (
          <RegularDropdownItem
            label="Sort descending"
            variant="basic"
            LeadingIcon={ArrowDownIcon}
            onClick={() => {
              onSetSortDirValue("DESC");
              onSetSortByValue(sortBy);
            }}
          />
        ),
      },
    ],
    [onSetSortByValue, onSetSortDirValue]
  );

  const getRowDropdownOptions = useCallback(
    (isDraft: boolean, stocktakeId: string): DropdownItem[] => [
      ...(!isDraft
        ? [
            {
              key: "viewDetails",
              renderComponent: () => (
                <RegularDropdownItem
                  label="View"
                  variant="basic"
                  LeadingIcon={DocumentTextIcon}
                  onClick={() => {
                    onViewDetails(stocktakeId);
                  }}
                />
              ),
            },
            ...(isDeleteStocktakeReportEnabled
              ? [
                  {
                    key: "dangerZoneDividerReport",
                    renderComponent: () => <DividerDropdownItem />,
                  },
                  {
                    key: "deleteReport",
                    renderComponent: () => (
                      <RegularDropdownItem
                        label="Delete Report"
                        variant="danger"
                        LeadingIcon={TrashIcon}
                        onClick={() => {
                          onDeleteReport(stocktakeId);
                        }}
                      />
                    ),
                  },
                ]
              : []),
          ]
        : []),
      ...(isDraft
        ? [
            {
              key: "resumeDraft",
              renderComponent: () => (
                <RegularDropdownItem
                  label="Resume Draft"
                  variant="basic"
                  LeadingIcon={ArrowSmRightIcon}
                  onClick={() => {
                    onResumeDraft(stocktakeId);
                  }}
                />
              ),
            },
            {
              key: "dangerZoneDividerDraft",
              renderComponent: () => <DividerDropdownItem />,
            },
            {
              key: "deleteDraft",
              renderComponent: () => (
                <RegularDropdownItem
                  label="Delete Draft"
                  variant="danger"
                  LeadingIcon={TrashIcon}
                  onClick={() => {
                    onDeleteDraft(stocktakeId);
                  }}
                />
              ),
            },
          ]
        : []),
    ],
    [isDeleteStocktakeReportEnabled, onDeleteDraft, onDeleteReport, onResumeDraft, onViewDetails]
  );

  const onRowClick = useCallback(
    (isDraft: boolean, stocktakeId: string) => {
      if (isDraft) {
        onResumeDraft(stocktakeId);
      } else {
        onViewDetails(stocktakeId);
      }
    },
    [onViewDetails, onResumeDraft]
  );

  return (
    <Table
      testId="ordersTable"
      isLoading={isLoading}
      headings={[
        {
          text: "Date",
          dropdownOptions: getHeaderCellDropdownOptions({ sortBy: "STOCKTAKE_DATE" }),
        },
        shouldRenderStocktakeTypes
          ? {
              text: "Type",
              dropdownOptions: getHeaderCellDropdownOptions({ sortBy: "TYPE" }),
            }
          : undefined,
        {
          text: "Inventory Count",
          dropdownOptions: getHeaderCellDropdownOptions({ sortBy: "INVENTORY_COUNT" }),
        },
        {
          text: "Status",
          dropdownOptions: getHeaderCellDropdownOptions({ sortBy: "STATUS" }),
        },
        {
          text: "ROB Value",
          dropdownOptions: getHeaderCellDropdownOptions({ sortBy: "ROB_VALUE" }),
        },
      ]}
      emptyState={{
        title: "No stocktake reports yet",
        subtitle: "All your stocktake reports will end up here",
      }}
      rows={stocktakes.map(
        (stocktake: UiStocktakeReportListItem): IRow => ({
          onClick: () => onRowClick(stocktake.isDraft, stocktake.id),
          dropdownOptions: getRowDropdownOptions(stocktake.isDraft, stocktake.id),
          data: [
            {
              type: "title",
              data: LocalStocktakeService.formatStocktakeDateFromIsoString(
                stocktake.stocktakeDate,
                "justDate"
              ),
            },
            shouldRenderStocktakeTypes
              ? {
                  type: "statusBadge",
                  data: shouldRenderStocktakeType(stocktake.type.type) ? (
                    <Badge
                      {...LocalStocktakeService.getBadgeSettingsFromStocktakeType(
                        stocktake.type.type
                      )}
                      size="s"
                    />
                  ) : (
                    <></>
                  ),
                }
              : undefined,
            {
              type: "title",
              data: t("stocktaking.nrItems", {
                count: stocktake.inventoryCount,
                formattedCount: formatNumber(stocktake.inventoryCount),
              }),
            },
            {
              type: "statusBadge",
              data: (
                <Badge
                  withBullet
                  text={getBadgeSettingsFromStocktakeState(stocktake.isDraft).text}
                  color={getBadgeSettingsFromStocktakeState(stocktake.isDraft).color}
                  size="s"
                  contrast="low"
                />
              ),
            },
            {
              type: "title",
              data: formatMoney(stocktake.robValue),
            },
          ],
        })
      )}
    />
  );
};
