import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppArea } from "src/hooks/useAppArea";

export const MainPage = () => {
  const navigate = useNavigate();
  const { overviewPath } = useAppArea();

  useEffect(() => {
    navigate(overviewPath, { replace: true });
  }, [navigate, overviewPath]);

  return null;
};
