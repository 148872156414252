import { useCallback, useEffect, useState } from "react";

import { PING_INTERVAL } from "src/config/constants";
import { pingNetworkStateResource } from "src/utils";

import { useOfflineCapabilities } from "../OfflineCapabilities";
import { NetworkDetectorContext } from "./NetworkDetectorContext";
import { networkStatePinger } from "./NetworkStatePinger";
import { useNetworkDetectorReducer } from "./reducer";

// Some code adapted from https://github.com/cwise89/react-detect-offline

interface Props {
  children: React.ReactNode;
  pingUrl: string;
  pingIntervalAndTimeout?: number;
}

// TODO #5236: Add tests for the functionality
export const NetworkDetectorProvider = ({
  pingIntervalAndTimeout = PING_INTERVAL,
  pingUrl,
  children,
}: Props) => {
  const { areOfflineCapabilitiesEnabled, isManualNetworkStateEnabled } = useOfflineCapabilities();

  // Start with `false`, so the checks don't start if the tab is loading in the background
  const [isDocumentVisible, setIsDocumentVisible] = useState<boolean>(false);
  // Start with `undefined`, so `setNavigatorNetworkState` can be run during initial render and establish proper value
  const [isNavigatorOnline, setIsNavigatorOnline] = useState<boolean | undefined>(undefined);
  // Start with `false` so the polling does not start until needed
  const [isCheckingNetworkState, setIsCheckingNetworkState] = useState<boolean>(false);

  const [networkDetectorState, dispatch] = useNetworkDetectorReducer();
  const { currentNetworkState, forcedNetworkState } = networkDetectorState;

  const setOnline = useCallback(() => dispatch({ type: "setOnline" }), [dispatch]);
  const setOffline = useCallback(() => dispatch({ type: "setOffline" }), [dispatch]);
  const setShakyConnection = useCallback(
    () => dispatch({ type: "setShakyConnection" }),
    [dispatch]
  );
  const resetShakyConnection = useCallback(
    () => dispatch({ type: "resetShakyConnection" }),
    [dispatch]
  );
  const setReconnecting = useCallback(() => dispatch({ type: "setReconnecting" }), [dispatch]);

  const setDocumentVisibilityState = useCallback(() => {
    setIsDocumentVisible(document.visibilityState === "visible");
  }, [setIsDocumentVisible]);

  const setNavigatorNetworkState = useCallback(() => {
    setIsNavigatorOnline(navigator.onLine);
  }, [setIsNavigatorOnline]);

  const pingAndSetNetworkState = useCallback(async () => {
    const pingFunc = pingNetworkStateResource(pingUrl, pingIntervalAndTimeout);
    const isOnline = await pingFunc();

    if (isOnline) {
      setOnline();
    } else {
      setOffline();
    }
  }, [pingUrl, pingIntervalAndTimeout, setOnline, setOffline]);

  // Will run only for offline version with toggle
  useEffect(() => {
    if (!areOfflineCapabilitiesEnabled || !isManualNetworkStateEnabled) {
      return;
    }

    if (!isCheckingNetworkState) {
      return;
    }

    // Make the first ping right away...
    pingAndSetNetworkState().then(/* no then */);
    // ...and set interval for recurring pings
    const pingInterval = setInterval(pingAndSetNetworkState, pingIntervalAndTimeout);

    return () => {
      clearInterval(pingInterval);
    };
  }, [
    isCheckingNetworkState,
    pingIntervalAndTimeout,
    pingAndSetNetworkState,
    areOfflineCapabilitiesEnabled,
    isManualNetworkStateEnabled,
  ]);

  // Will run only for offline version without toggle
  useEffect(() => {
    if (!areOfflineCapabilitiesEnabled || isManualNetworkStateEnabled) {
      return;
    }

    if (!isCheckingNetworkState) {
      return;
    }
    const subscription = networkStatePinger.result$.subscribe(({ isOnline, isShaky }) => {
      if (isOnline) {
        setOnline();
      } else {
        setOffline();
      }

      if (isShaky) {
        setShakyConnection();
      } else {
        resetShakyConnection();
      }
    });
    networkStatePinger.startPinging();

    return () => {
      networkStatePinger.stopPinging();
      subscription.unsubscribe();
    };
  }, [
    areOfflineCapabilitiesEnabled,
    isCheckingNetworkState,
    isManualNetworkStateEnabled,
    resetShakyConnection,
    setOffline,
    setOnline,
    setShakyConnection,
  ]);

  useEffect(() => {
    if (!areOfflineCapabilitiesEnabled) {
      return;
    }

    // We can be sure that if Navigator is offline, then there is no Internet connectivity.
    // This only applies to offline version with the manual toggle.
    // Otherwise, we want to constantly check connection status.
    if (
      isManualNetworkStateEnabled &&
      (isNavigatorOnline === false || forcedNetworkState === "offline")
    ) {
      setOffline();
      setIsCheckingNetworkState(false);
      return;
    }

    // Navigator can report being online even if there is no actual Internet access,
    // so we still need to check it another way.

    // Only check for connectivity if the Document is visible
    if (isDocumentVisible) {
      setIsCheckingNetworkState(true);

      // `reconnecting` state is not needed in the version without the manual toggle
      if (currentNetworkState === "offline" && isManualNetworkStateEnabled) {
        setReconnecting();
      }
      return;
    }

    // Disable checking if the Document is not visible (e.g. minimized browser tab; other tab in focus)
    setIsCheckingNetworkState(false);
  }, [
    isDocumentVisible,
    setIsCheckingNetworkState,
    isNavigatorOnline,
    currentNetworkState,
    forcedNetworkState,
    setOffline,
    setReconnecting,
    areOfflineCapabilitiesEnabled,
    isManualNetworkStateEnabled,
  ]);

  useEffect(() => {
    if (!areOfflineCapabilitiesEnabled) {
      return;
    }

    document.addEventListener("visibilitychange", setDocumentVisibilityState);
    window.addEventListener("online", setNavigatorNetworkState);
    window.addEventListener("offline", setNavigatorNetworkState);
    return () => {
      document.removeEventListener("visibilitychange", setDocumentVisibilityState);
      window.removeEventListener("online", setNavigatorNetworkState);
      window.removeEventListener("offline", setNavigatorNetworkState);
    };
  }, [setDocumentVisibilityState, setNavigatorNetworkState, areOfflineCapabilitiesEnabled]);

  // Only during first run - set initial visibility and navigator.online states
  useEffect(() => {
    if (!areOfflineCapabilitiesEnabled) {
      return;
    }

    setDocumentVisibilityState();
    setNavigatorNetworkState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areOfflineCapabilitiesEnabled]);

  return (
    <NetworkDetectorContext.Provider value={[networkDetectorState, dispatch]}>
      {children}
    </NetworkDetectorContext.Provider>
  );
};
