import { DefaultError, useMutation } from "@tanstack/react-query";

import { createStocktakeReport } from "src/api/stocktakes";
import { useToastMessage } from "src/hooks/useToastMessage";
import { LocalStocktakeReportForm, UiStocktakeReport } from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";
import { LiteStocktakeReport } from "src/typegens";

import { useDeleteStocktakeDraftMutation } from "./useDeleteStocktakeDraftMutation";
import { useStocktakeReportQueryHelpers } from "./useStocktakeReportQuery";
import { useStocktakesListQueryHelpers } from "./useStocktakesListQuery";

type UseCreateStocktakeReportMutationArgs = {
  onSuccess?: (createdStocktakeReport: UiStocktakeReport) => void;
  onError?: (validatedStocktakeDraftData: LocalStocktakeReportForm, error: Error) => void;
  hasErrorMessage?: boolean;
  vesselId: string;
  stocktakeDraftId: string;
};

export const useCreateStocktakeReportMutation = ({
  onSuccess,
  onError,
  hasErrorMessage = false,
  vesselId,
  stocktakeDraftId,
}: UseCreateStocktakeReportMutationArgs) => {
  const { setToastMessage } = useToastMessage();
  const { addListItemToQuery } = useStocktakesListQueryHelpers({
    vesselId,
  });
  const { setQueryData } = useStocktakeReportQueryHelpers();

  const { mutateAsync: deleteStocktakeDraft } = useDeleteStocktakeDraftMutation({
    vesselId,
    draftId: stocktakeDraftId,
  });

  return useMutation<LiteStocktakeReport, DefaultError, LocalStocktakeReportForm>({
    mutationKey: ["createStocktakeReport"],
    mutationFn: (stocktakeDraftData) =>
      createStocktakeReport(
        LocalStocktakeService.convertFromValidatedReportFormToApiStocktakeReportRequest(
          stocktakeDraftData
        )
      ),
    onSuccess: async (apiStocktakeReport) => {
      try {
        // This handles removal of the draft both from the DB, and from the StocktakesListQuery
        await deleteStocktakeDraft();
      } catch {
        hasErrorMessage &&
          setToastMessage({
            type: "failure",
            message:
              "There was an error while deleting the draft that was used to finish this Stocktake Report. You will still see this draft in the Stocktake Overview table. Please try removing the draft manually from the Stocktake Overview table.",
          });
      }

      const stocktakeReport =
        LocalStocktakeService.convertFromApiToUiStocktakeReport(apiStocktakeReport);
      setQueryData(stocktakeReport);

      const stocktakeReportListItem =
        LocalStocktakeService.convertFromApiToUiStocktakeListItem(apiStocktakeReport);
      addListItemToQuery(stocktakeReportListItem);

      if (onSuccess) {
        onSuccess(stocktakeReport);
      }
    },
    onError: (error, stocktakeDraftData) => {
      console.error(error, stocktakeDraftData);

      hasErrorMessage &&
        setToastMessage({
          type: "failure",
          message: "There was an error while saving your Stocktake Report. Please try again.",
        });

      if (onError) {
        onError(stocktakeDraftData, error);
      }
    },
  });
};
