import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Heading, Paragraph, RegularButton } from "@web/ui";

import { RoutesConfig } from "../../config/routes";
import S2SLogo from "../../icons/S2SLogo.svg?react";

export const NoAccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="h-screen flex justify-center items-center"
      data-testid="applicationUnauthorized"
    >
      <S2SLogo />
      <div className="w-0.5 h-7 mx-7 bg-neutral_200" role="presentation" />
      <div className="flex flex-col">
        <Heading size="100" className="mb-4" data-testid="noAccess_title">
          {t("auth.noAccessTitle")}
        </Heading>
        <Paragraph size="200" className="w-96">
          {t("auth.noAccessDescription")}
        </Paragraph>
        <RegularButton
          className="mt-6"
          variant="primary"
          size="large"
          width="content"
          label={t("auth.retrieveVesselToken")}
          onClick={() => {
            navigate(RoutesConfig.retrieveVesselToken);
          }}
        />
      </div>
    </div>
  );
};
