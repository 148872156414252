import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import {
  LocalStocktakeReportForm,
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemQuantityFieldPath,
  StocktakeReportItemRobValueAmountFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";

import { useItemFormDetails } from "./useItemFormDetails";

type UseUpdateDependentFormFields = {
  updateProductRobValue: () => void;
};

type Params = {
  initialQuantity: number | null | undefined;
  initialSingleUnitGrossPriceAmount: number | null | undefined;
  initialMeasurementUnit: string;
  salesEntityQuantity: number;
  currencyCode: string;
  quantityFieldPath: StocktakeReportItemQuantityFieldPath;
  singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath;
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
  robValueAmountFieldPath: StocktakeReportItemRobValueAmountFieldPath;
};

// We are providing a way to update dependent values on the form model that change due to the user's
// inputs (while direct user's inputs are changed on the model automatically via connecting the input
// fields to the model)
export const useUpdateDependentFormFields = ({
  initialQuantity,
  initialMeasurementUnit,
  initialSingleUnitGrossPriceAmount,
  salesEntityQuantity,
  currencyCode,
  quantityFieldPath,
  singleUnitGrossPriceFieldPath,
  measurementUnitFieldPath,
  robValueAmountFieldPath,
}: Params): UseUpdateDependentFormFields => {
  const { setValue } = useFormContext<LocalStocktakeReportForm>();

  const { productRobValue } = useItemFormDetails({
    initialQuantity,
    initialMeasurementUnit,
    initialSingleUnitGrossPriceAmount,
    salesEntityQuantity,
    currencyCode,
    quantityFieldPath,
    singleUnitGrossPriceFieldPath,
    measurementUnitFieldPath,
  });

  const updateProductRobValue = useCallback(() => {
    setValue(robValueAmountFieldPath, productRobValue?.amount || 0);
  }, [productRobValue?.amount, robValueAmountFieldPath, setValue]);

  return {
    updateProductRobValue,
  };
};
