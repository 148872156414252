import classNames from "classnames";

import { LoadingInline, Paragraph } from "@web/ui";

type Props = {
  inline?: boolean;
  className?: string;
};

export const OfflineCatalogLoader = ({ className, inline = false }: Props) => {
  return (
    <div
      className={classNames(
        "h-full w-full flex flex-col items-center",
        inline ? "justify-start" : "justify-center",
        className
      )}
    >
      <LoadingInline size="7" baseColor="text-primaryDefault" />
      <Paragraph size="100" weight="heavy" className="mt-4">
        Fetching data
      </Paragraph>
      <Paragraph size="100" className="text-center">
        We are downloading the catalog data and this may take some time.
        <br /> Please do not refresh your browser or go to another page.
      </Paragraph>
    </div>
  );
};
