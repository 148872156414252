import { InfiniteData } from "@tanstack/react-query";

import { OrderRequisition } from "@web/common/network/model";

import { LocalOrderReqService } from "../services/LocalOrderReqService";
import { LiteOrderRequisition } from "../typegens";

export const prependToOrderRequisitionsList =
  (result: LiteOrderRequisition[]) =>
  (oldData?: InfiniteData<{ items: OrderRequisition[]; cursor?: string }> | undefined) => {
    if (oldData) {
      return {
        pages: [
          {
            items: result.map((o) => LocalOrderReqService.toOrderRequisition(o)),
          },
          ...oldData.pages,
        ],
        pageParams: [...oldData.pageParams],
      };
    }
  };
