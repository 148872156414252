import { useQuery } from "@tanstack/react-query";

import { useAppStateContext } from "src/contexts/AppStateContext";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { OfflineCatalogService } from "src/services/OfflineCatalogService";

export const useOfflineCatalogMetaData = () => {
  const [{ configuration }] = useAppStateContext();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const hasOneOrderType = configuration?.orderTypes?.length === 1;

  return useQuery<Map<string, { updatedAt: string }>>({
    queryKey: ["offlineCatalogMetaData"],
    queryFn: async () => {
      const response =
        areOfflineCapabilitiesEnabled && hasOneOrderType
          ? await OfflineCatalogService.getOfflineCatalogListMetaData()
          : new Map();
      return response;
    },
    gcTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
