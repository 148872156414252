import { ShoppingCartIcon, XIcon } from "@heroicons/react/solid";
import { useFlag } from "@unleash/proxy-client-react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { BasketEntry, getNonEmptyCategories } from "@web/common";
import { SidebarProductBox } from "@web/common/components/SidebarProductBox";
import { useSearchInput } from "@web/common/hooks/useSearchHelpers";
import { ComboButton, Heading, Label, LoadingInline, Paragraph, RegularButton } from "@web/ui";
import { formatMoney, usePrevious } from "@web/utils";

import EmptyIndicator from "src/components/EmptyIndicator";
import RequestProductInline from "src/components/RequestProductInline";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import useBasket from "src/hooks/useBasket";
import useCategories from "src/hooks/useCategories";
import {
  useCachedProductsQuery,
  useOfflineProductsSearchQuery,
} from "src/hooks/useOfflineProductsSearchQuery";
import BaseLayout from "src/layouts/Base";

import { ProductBox } from "../Category/ProductBox";

// TODO #10712: This is a PoC. May contain dead code, bugs and performance issues.

const Loading = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full pt-5 m-auto flex justify-center">
      <Paragraph size="200">
        <LoadingInline />
        {t("pages.search.loading")}
      </Paragraph>
    </div>
  );
};

export const OfflineProductsSearch = () => {
  const { t } = useTranslation();
  const hasRfqFeature = useFlag("rfq");
  const hasGatherExpNavigationFeature = useFlag("gather-exp-navigation");
  const [{ configuration }] = useAppStateContext();
  const { categoriesQuery } = useCategories();
  const { query } = useSearchInput();
  const navigate = useNavigate();
  const isEmptyQuery = query === "";

  // Simplification: database keeps products in top categories as records, so for PoC
  // we need to pass all of them, so we can retrieve this data to build the index.

  const categoriesWithProducts = useMemo(
    () => getNonEmptyCategories(categoriesQuery.data || []),
    [categoriesQuery.data]
  ).map((category) => category.id);

  const {
    data: cachedProducts,
    isPending: isCachedProductsPending,
    error: cachedProductsError,
  } = useCachedProductsQuery({
    categoryIds: categoriesWithProducts,
    enabled: !!categoriesQuery.data,
  });
  const prevCachedProductsError = usePrevious(cachedProductsError);

  const {
    data: results,
    isPending: isSearchPending,
    error: searchError,
  } = useOfflineProductsSearchQuery({
    products: cachedProducts || [],
    enabled: !!cachedProducts,
  });
  const prevSearchError = usePrevious(searchError);

  // Debugging purposes
  useEffect(() => {
    if (cachedProductsError && prevCachedProductsError !== cachedProductsError) {
      console.error("Error fetching cached products", cachedProductsError);
    }
    if (searchError && prevSearchError !== searchError) {
      console.error("Error fetching search results", searchError);
    }
  }, [cachedProductsError, prevCachedProductsError, searchError, prevSearchError]);

  const isPending = isCachedProductsPending || isSearchPending;

  const { pushSkuToBasket, getQuantity, lineItems, totalQuantity, grandTotal } = useBasket();

  const sortedLineItems = useMemo(() => [...lineItems].reverse(), [lineItems]);

  useEffect(() => {
    if (isEmptyQuery) {
      navigate(RoutesConfig.order.overview);
    }
  }, [isEmptyQuery, navigate, query]);

  if (isEmptyQuery) {
    return null;
  }

  return (
    <div className="w-[100vw] h-[100vh] bg-neutral_100">
      <BaseLayout>
        <div className="flex flex-col flex-grow">
          <div className="flex">
            <div className="container">
              <div className="grid grid-cols-1 gap-2 w-10/12 pr-8 pl-4 ml-10">
                <ComboButton
                  className="mt-5"
                  label="Close search"
                  iconPlacement="leading"
                  Icon={XIcon}
                  onClick={() =>
                    hasGatherExpNavigationFeature
                      ? navigate(-1)
                      : navigate(RoutesConfig.gatherSetup)
                  }
                />
                <Heading className="mt-2" size="200">{`Search results for "${query}"`}</Heading>
                {isPending ? (
                  <Loading />
                ) : results && results?.length > 0 ? (
                  <>
                    {results.map((product, index: number) => {
                      return (
                        <ProductBox
                          addToBasket={(sku, qty) => pushSkuToBasket(sku, qty)}
                          key={product.id}
                          product={product}
                          quantity={getQuantity(product.skuList[0])}
                          index={index}
                        />
                      );
                    })}
                    {hasRfqFeature &&
                      configuration?.permissions.includes("CREATE_REQUISITION") &&
                      configuration.allow.createRfq && (
                        <div className="w-full my-4">
                          <RequestProductInline />
                        </div>
                      )}
                  </>
                ) : (
                  <div className="mt-10">
                    {" "}
                    <EmptyIndicator
                      title={t("pages.search.noResults")}
                      subtitle={t("pages.search.couldntFind")}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="float-right fixed z-0 border-1 top-0 right-0 bg-neutral_0 w-[20%] mr-[15px] h-screen">
          <div className="flex flex-col justify-between">
            <div className="border-b-1 w-full text-center flex flex-col justify-end h-[170px] pb-2">
              <Label size="100">Your Basket</Label>
              <div className="inline-block mt-6 w-full">
                {lineItems.length > 0 && (
                  <Label size="200" className="ml-2 float-left">{`Total: ${formatMoney(
                    grandTotal
                  )}`}</Label>
                )}
                <Label size="200" color="text-textIcon-blackSecondary" className="pr-2 float-right">
                  {totalQuantity !== 1
                    ? `${lineItems.length} Line items`
                    : `${lineItems.length} Line item`}
                </Label>
              </div>
            </div>
            <div className="overflow-auto bg-neutral_100 h-[calc(100vh-170px-75px)] -mb-[1px]">
              {lineItems.length !== 0 ? (
                <div>
                  {sortedLineItems.map((product: BasketEntry, index: number) => (
                    <SidebarProductBox
                      addToBasket={(sku, qty) => pushSkuToBasket(sku, qty)}
                      key={product.sku.id}
                      product={product}
                      quantity={product.quantity}
                      index={index}
                    />
                  ))}
                </div>
              ) : (
                <div className="mt-10 text-center">
                  <Label size="200" data-testid="empty-sidebar-text">
                    Your basket is empty
                  </Label>
                </div>
              )}
            </div>
            <div className="relative px-4 border-t-1 bg-neutral_0 z-20 h-[75px] flex flex-col justify-center">
              <RegularButton
                variant="primary"
                size="large"
                width="container"
                label="View Basket"
                LeadingIcon={ShoppingCartIcon}
                onClick={() => {
                  navigate(RoutesConfig.basket);
                }}
                data-testid="viewBasketButton"
              />
            </div>
          </div>
        </div>
      </BaseLayout>
    </div>
  );
};
