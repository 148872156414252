import {
  LitePort as ApiPort,
  LiteAttentionInfoFleetsConfigEntry,
  LiteConfiguration,
  LitePortConfiguration,
} from "src/typegens";

import { AttentionInfoItems, Configuration, LitePort } from "../state/models";

const toPorts = (
  ports: Record<string, Array<ApiPort>>,
  portConfiguration: Record<string, LitePortConfiguration>,
  attentionInfoConfiguration: LiteAttentionInfoFleetsConfigEntry[]
): Record<string, Array<LitePort>> => {
  return Object.entries(ports).reduce((acc, [key, val]) => {
    return {
      ...acc,
      [key]: val.map((port) => {
        return {
          ...port,
          freeDelivery: portConfiguration?.[port.locationCode]?.freeDelivery,
          agentInformation: portConfiguration?.[port.locationCode]?.agentInformation,
          dutyFree: portConfiguration?.[port.locationCode]?.dutyFree,
          attentionInfo: getAttentionInfoItemsForPort(attentionInfoConfiguration, port),
          _search: `${port.locationCode} ${port.name}`,
        };
      }),
    };
  }, {});
};

export const getAttentionInfoItemsForPort = (
  attentionInfoConfiguration: LiteAttentionInfoFleetsConfigEntry[],
  port: ApiPort
): AttentionInfoItems => {
  return (
    attentionInfoConfiguration
      .filter((entry) => entry.portLocationCodes.includes(port?.locationCode || ""))
      .reduce((acc: AttentionInfoItems, cur: LiteAttentionInfoFleetsConfigEntry) => {
        const existingTypeEntry = acc.find((item) => item.type === cur.attentionInfoType);
        if (existingTypeEntry) {
          // Mutate element in `acc` array
          existingTypeEntry.entries.push({
            fileMetadata: cur.fileMetadata,
            imageMetadata: cur.imageMetadata,
          });
        } else {
          // Mutate `acc` array
          acc.push({
            type: cur.attentionInfoType,
            entries: [
              {
                fileMetadata: cur.fileMetadata,
                imageMetadata: cur.imageMetadata,
              },
            ],
          });
        }
        return acc;
      }, []) || []
  );
};

export const LocalConfigurationService = {
  canCreateRequisition: (configuration?: LiteConfiguration) => {
    if (!configuration) {
      return false;
    }

    return configuration.permissions.some((permission) => permission === "CREATE_REQUISITION");
  },
  canCreateOrder: (configuration?: LiteConfiguration) => {
    if (!configuration) {
      return false;
    }

    return (
      configuration.permissions.includes("CREATE_ORDER") ||
      configuration.permissions.includes("CREATE_AUTO_APPROVED_REQUISITION")
    );
  },
  isAutoApprovedRequisition: (configuration?: LiteConfiguration) => {
    if (!configuration) {
      return false;
    }

    return configuration.permissions.includes("CREATE_AUTO_APPROVED_REQUISITION");
  },
  toPorts,
  getConfiguredPort: (
    configuration: Configuration,
    portToFind: ApiPort | LitePort
  ): LitePort | undefined =>
    configuration?.ports[portToFind.country]?.find(
      (port) => port.locationCode === portToFind.locationCode
    ),
};
