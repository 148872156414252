import { useReducer } from "react";

import {
  MANUAL_NETWORK_TOGGLE_STORAGE_KEY,
  OFFLINE_CAPABILITIES_STORAGE_KEY,
} from "src/config/constants";

import { OfflineCapabilitiesState, OfflineCapabilitiesStateAction } from "./models";

const getStoredOfflineCapabilitiesValue = () => {
  const storedOfflineCapabilities = localStorage.getItem(OFFLINE_CAPABILITIES_STORAGE_KEY);

  if (storedOfflineCapabilities === "false") {
    return false;
  }

  if (storedOfflineCapabilities === "true") {
    return true;
  }

  return undefined;
};

const getStoredManualNetworkToggleValue = () => {
  const storedManualNetworkToggle = localStorage.getItem(MANUAL_NETWORK_TOGGLE_STORAGE_KEY);

  if (storedManualNetworkToggle === "false") {
    return false;
  }

  if (storedManualNetworkToggle === "true") {
    return true;
  }

  return undefined;
};

export const defaultOfflineCapabilitiesState: OfflineCapabilitiesState = {
  areOfflineCapabilitiesEnabled: getStoredOfflineCapabilitiesValue(),
  isManualNetworkStateEnabled: getStoredManualNetworkToggleValue(),
};

const OfflineCapabilitiesReducer = (
  state: OfflineCapabilitiesState,
  action: OfflineCapabilitiesStateAction
): OfflineCapabilitiesState => {
  switch (action.type) {
    case "setOfflineCapabilities": {
      return {
        ...state,
        areOfflineCapabilitiesEnabled: action.value,
      };
    }

    case "setManualNetworkState": {
      return {
        ...state,
        isManualNetworkStateEnabled: action.value,
      };
    }

    default:
      return state;
  }
};

export const useOfflineCapabilitiesReducer = () =>
  useReducer(OfflineCapabilitiesReducer, defaultOfflineCapabilitiesState);
