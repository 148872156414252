import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useModalContext } from "@web/common/contexts";

import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { NetworkToggleSwitchController } from "src/contexts/NetworkToggle";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import useBasket from "src/hooks/useBasket";

import BackToOrderOverviewConfirmationModal from "../Modal/BackToOrderOverviewConfirmationModal";
import { TopBar } from "./TopBar";

export const TopBarController = () => {
  const [{ configuration, isPunchoutSession }] = useAppStateContext();
  const { areOfflineCapabilitiesEnabled, isManualNetworkStateEnabled } = useOfflineCapabilities();
  const navigate = useNavigate();
  const { openModal } = useModalContext();
  const { draft, totalQuantity, clearOrderRequisitionFlow } = useBasket();

  const handleBackToOverview = useCallback(() => {
    if (totalQuantity && !draft) {
      openModal(
        <BackToOrderOverviewConfirmationModal
          onBackToOverview={() => {
            navigate(RoutesConfig.mainPage);
            clearOrderRequisitionFlow();
          }}
        />
      );
    } else {
      navigate(RoutesConfig.mainPage);
      clearOrderRequisitionFlow();
    }
  }, [clearOrderRequisitionFlow, draft, navigate, openModal, totalQuantity]);

  return (
    <>
      {areOfflineCapabilitiesEnabled ? (
        <NetworkToggleSwitchController
          render={({
            isSwitchOnline,
            isNetworkOnline,
            isNetworkReconnecting,
            isNetworkOffline,
            isShakyConnection,
            toggleSwitch,
          }) => (
            <TopBar
              networkToggleSwitchSettings={{
                isEnabled: isManualNetworkStateEnabled ? isSwitchOnline : isNetworkOnline,
                hasSwitch: isManualNetworkStateEnabled,
                toggleSwitch,
                isNetworkOnline,
                isNetworkReconnecting,
                isNetworkOffline,
                isShakyConnection,
              }}
              isBackButtonDisabled={isPunchoutSession}
              vesselName={configuration?.vessel.name}
              vesselId={configuration?.vessel.id}
              onLogoClick={handleBackToOverview}
            />
          )}
        />
      ) : (
        <TopBar
          vesselName={configuration?.vessel.name || ""}
          vesselId={configuration?.vessel.id || ""}
          isBackButtonDisabled={isPunchoutSession}
          onLogoClick={handleBackToOverview}
        />
      )}
    </>
  );
};
