/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { LiteShareBasketRequest } from "../models/LiteShareBasketRequest";

export class LiteBasketControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static share(
    {
      requestBody,
    }: {
      requestBody: LiteShareBasketRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/lite/basket/share",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns binary OK
   * @throws ApiError
   */
  public static exportBasketContentToExcel(
    {
      requestBody,
    }: {
      requestBody: LiteShareBasketRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/lite/basket/excel",
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
