import { DefaultError, useMutation } from "@tanstack/react-query";

import { deleteStocktakeReport } from "src/api/stocktakes";
import { useToastMessage } from "src/hooks/useToastMessage";
import { OfflineStocktakeReportsService } from "src/services/OfflineStocktakeReportsService";

import { useStocktakeReportQueryHelpers } from "./useStocktakeReportQuery";
import { useStocktakesListQueryHelpers } from "./useStocktakesListQuery";

type UseDeleteStocktakeReportMutationArgs = {
  onSuccess?: (deletedReportId: string) => void;
  onError?: (reportToDeleteId: string, error: Error) => void;
  hasSuccessMessage?: boolean;
  hasErrorMessage?: boolean;
  vesselId: string;
  stocktakeId: string;
};

export const useDeleteStocktakeReportMutation = ({
  onSuccess,
  onError,
  hasSuccessMessage = false,
  hasErrorMessage = false,
  vesselId,
  stocktakeId,
}: UseDeleteStocktakeReportMutationArgs) => {
  const { setToastMessage } = useToastMessage();
  const { removeItemFromQuery } = useStocktakesListQueryHelpers({ vesselId });
  const { invalidate: invalidateStocktakeQuery } = useStocktakeReportQueryHelpers();

  return useMutation<unknown, DefaultError>({
    mutationKey: ["deleteStocktakeReport"],
    mutationFn: async () => {
      await deleteStocktakeReport({ id: stocktakeId });
      await OfflineStocktakeReportsService.deleteReport(stocktakeId);
    },
    onSuccess: () => {
      hasSuccessMessage &&
        setToastMessage({
          type: "success",
          message: "Your stocktake was successfully deleted",
        });

      if (onSuccess) {
        onSuccess(stocktakeId);
      }

      // Remove the stocktake list item from the cache
      removeItemFromQuery(stocktakeId);

      // Invalidate the report query to refetch and get `not found` when trying to fetch the deleted report
      invalidateStocktakeQuery(stocktakeId);
    },
    onError: (error) => {
      console.error(error, stocktakeId);

      hasErrorMessage &&
        setToastMessage({
          type: "failure",
          message: "There was an error while deleting your stocktake",
        });

      if (onError) {
        onError(stocktakeId, error);
      }
    },
  });
};
