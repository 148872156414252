import { InformationCircleIcon, XIcon } from "@heroicons/react/outline";

import { Paragraph, RegularButton } from "@web/ui";

interface Props {
  closeModal: () => void;
  onConfirm: () => void;
  hasMultipleStocktakeTypes: boolean;
  stocktakeTypeFormatted: string;
}

export const StocktakeDraftsLimitExceededModal = ({
  closeModal,
  onConfirm,
  hasMultipleStocktakeTypes,
  stocktakeTypeFormatted,
}: Props) => {
  return (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-text-whiteDisabled hover:text-neutral_800"
          onClick={closeModal}
        >
          <span className="sr-only">Close modal</span>
          <XIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start py-4 pl-2 pr-4">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center p-2 rounded-full bg-primaryBackground sm:mx-0">
          <InformationCircleIcon className="w-5 text-primaryDefault" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Paragraph size="100">
            {hasMultipleStocktakeTypes
              ? `You already have a ${stocktakeTypeFormatted} draft open`
              : "You already have a draft open"}
          </Paragraph>
          <div className="mt-2">
            {hasMultipleStocktakeTypes && (
              <>
                <Paragraph size="200" color="text-textIcon-blackSecondary">
                  You can only have one draft for each stocktake type.
                </Paragraph>{" "}
                <Paragraph size="200" color="text-textIcon-blackSecondary" className="mt-2">
                  Please change the stocktake type or go back to the stocktake overview to finish
                  the draft.
                </Paragraph>
              </>
            )}
            {!hasMultipleStocktakeTypes && (
              <Paragraph size="200" color="text-textIcon-blackSecondary">
                You can only have one draft. Please go back to the stocktake overview to finish the
                draft.
              </Paragraph>
            )}
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 flex flex-col gap-3 sm:flex-row-reverse">
        <RegularButton
          variant="primary"
          size="large"
          label="Go to stocktake overview"
          onClick={onConfirm}
        />
        <RegularButton
          variant="secondary"
          size="large"
          label={hasMultipleStocktakeTypes ? "Change type" : "Close"}
          onClick={closeModal}
        />
      </div>
    </div>
  );
};
