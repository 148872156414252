export type StorageRequest = {
  readonly body?: unknown;
  readonly access: "read" | "write";
};

export type StorageResult = {
  readonly status: number;
  readonly body?: unknown;
};

export class StorageError extends Error {
  public readonly status: number;
  public readonly body: unknown;
  public readonly request: StorageRequest;

  constructor(request: StorageRequest, response: StorageResult, message: string) {
    super(message);

    this.name = "StorageError";
    this.status = response.status;
    this.body = response.body;
    this.request = request;
  }
}
