import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useMemo } from "react";

import { LitePunchoutBasket } from "src/typegens";

import { getPunchoutBasket } from "../api/getPunchoutBasket";
import { usePunchOutIntegration } from "./usePunchOutIntegration";

export const PUNCHOUT_ITEMS_QUERY_KEY_BASE = "punchoutItems";

const getQueryKey = (sessionId: string) => [PUNCHOUT_ITEMS_QUERY_KEY_BASE, sessionId];

type UsePunchoutBasketQuery = UseQueryResult<LitePunchoutBasket>;

export const usePunchoutBasketQuery = (): UsePunchoutBasketQuery => {
  const { punchoutSessionId } = usePunchOutIntegration();
  const sessionId = punchoutSessionId || "";

  const queryKey = useMemo(() => getQueryKey(sessionId), [sessionId]);
  return useQuery<LitePunchoutBasket>({
    queryKey,
    queryFn: async ({ signal }) => getPunchoutBasket({ sessionId }, { signal }),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
    enabled: !!sessionId,
  });
};
