import classnames from "classnames";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { ActionBar, containerPadding } from "@web/ui";

import { OverviewTabs } from "src/components/OverviewTabs";
import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";

import { StocktakesTableController } from "./StocktakesTableController";

export const StocktakesOverviewPage = () => {
  const [{ configuration }] = useAppStateContext();
  const vesselId = configuration?.vessel.id;
  const availableStocktakeTypes = configuration?.availableStocktakeTypes;
  const navigate = useNavigate();

  const createNewStocktake = useCallback(() => {
    navigate(RoutesConfig.stocktake.setup);
  }, [navigate]);

  return (
    <div className="bg-neutral_100 flex flex-col min-h-full">
      <TopBarController />
      <OverviewTabs initialTabId="STOCKTAKING" />
      <ActionBar
        leftTitle="Stocktake Overview"
        primaryButtonSettings={{ title: "New Stocktake", onClick: createNewStocktake }}
      />
      <div className={classnames(containerPadding, "mt-2")}>
        <StocktakesTableController
          vesselId={vesselId}
          availableStocktakeTypes={availableStocktakeTypes}
        />
        <div className="h-5" />
      </div>
    </div>
  );
};
