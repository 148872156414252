import { Loading } from "@web/ui";

import useConfigurationQuery from "../hooks/useConfigurationQuery";

interface Props {
  children: React.ReactNode;
}

const ConfigurationWrapper: React.FC<Props> = ({ children }) => {
  const configurationQuery = useConfigurationQuery();

  if (configurationQuery.isPending || configurationQuery.isFetching) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default ConfigurationWrapper;
