import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation, useNavigate } from "react-router-dom";

import { ActionBar, containerPadding } from "@web/ui";

import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";

interface Props {
  children: React.ReactNode;
}

export const RequisitionLayout: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location.pathname, location.search]);

  return (
    <div className="flex flex-col h-full">
      <TopBarController />
      <div className={`flex-grow bg-neutral_100`}>
        <ActionBar
          backButtonSettings={{
            title: "Return to Basket",
            onClick: () => {
              navigate(RoutesConfig.basket);
            },
          }}
        />
        <div className={`${containerPadding} flex pt-6`}>{children}</div>
      </div>
    </div>
  );
};
