import { useFlag } from "@unleash/proxy-client-react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { NotFoundErrorPage } from "@web/ui";

import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";

import { Basket } from "./Basket";
import { Category } from "./Category";
import { GatherSetup } from "./GatherSetup";
import { MainPage } from "./MainPage";
import { NothingToStocktakePage } from "./NothingToStocktake";
import { OfflineProductsSearch } from "./OfflineProductsSearch";
import {
  LEGACY_OrderDetails,
  OrderDetailsController,
  OrderDraftDetailsController,
} from "./OrderDetails";
import { OrdersOverview } from "./OrdersOverview";
import { PurchaseOrderSuccess } from "./PurchaseOrderSuccess";
import { PurchaseOrderSummary } from "./PurchaseOrderSummary";
import { RequisitionSuccess } from "./RequisitionSuccess";
import { RequisitionSummary } from "./RequisitionSummary";
import { Search } from "./Search";
import { StocktakeDetailsPage } from "./StocktakeDetails";
import { StocktakeEditPage } from "./StocktakeEdit";
import { StocktakeFinishSuccessPage } from "./StocktakeFinishSuccess";
import { StocktakeSetupPage } from "./StocktakeSetup";
import { StocktakesOverviewPage } from "./StocktakesOverview";

export const Pages = () => {
  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const [{ configuration, isPunchoutSession }] = useAppStateContext();
  const isStocktakingEnabled = !!configuration?.allow.stocktaking;
  const isOfflineProductsSearchEnabled =
    useFlag("offline-products-search") && areOfflineCapabilitiesEnabled;
  const navigate = useNavigate();

  return (
    <Routes>
      {!isPunchoutSession && (
        <>
          <Route path={RoutesConfig.mainPage} element={<MainPage />} />
          <Route path={RoutesConfig.order.overview} element={<OrdersOverview />} />
          <Route path={RoutesConfig.orderInfo} element={<PurchaseOrderSummary />} />
          <Route path={RoutesConfig.requisitionInfo} element={<RequisitionSummary />} />
          <Route path={RoutesConfig.requisitions.success} element={<RequisitionSuccess />} />
          <Route path={RoutesConfig.order.success} element={<PurchaseOrderSuccess />} />
          {isOfflineDraftEnabled ? (
            <>
              <Route
                path={`${RoutesConfig.order.details}/:id`}
                element={<OrderDetailsController />}
              />
              <Route
                path={`${RoutesConfig.order.draftDetails}/:id`}
                element={<OrderDraftDetailsController />}
              />
            </>
          ) : (
            <Route path={`${RoutesConfig.order.details}/:id`} element={<LEGACY_OrderDetails />} />
          )}
          {isStocktakingEnabled && (
            <>
              <Route path={RoutesConfig.stocktake.overview} element={<StocktakesOverviewPage />} />
              <Route path={RoutesConfig.stocktake.setup} element={<StocktakeSetupPage />} />
              <Route path={RoutesConfig.stocktake.edit} element={<StocktakeEditPage />} />
              <Route
                path={RoutesConfig.stocktake.finishSuccess}
                element={<StocktakeFinishSuccessPage />}
              />
              <Route path={RoutesConfig.stocktake.details} element={<StocktakeDetailsPage />} />
              <Route
                path={RoutesConfig.stocktake.nothingToStocktake}
                element={<NothingToStocktakePage />}
              />
            </>
          )}
        </>
      )}
      <Route
        path={RoutesConfig.mainPage}
        element={<Navigate to={RoutesConfig.gatherSetup} replace />}
      />
      <Route path={RoutesConfig.gatherSetup} element={<GatherSetup />} />
      <Route path={`${RoutesConfig.category}/:categoryId`} element={<Category />} />
      {isOfflineProductsSearchEnabled ? (
        <Route path={RoutesConfig.search} element={<OfflineProductsSearch />} />
      ) : (
        <Route path={RoutesConfig.search} element={<Search />} />
      )}
      <Route path={RoutesConfig.basket} element={<Basket />} />
      <Route
        path="*"
        element={
          <NotFoundErrorPage
            onButtonClick={() => navigate(RoutesConfig.mainPage)}
            showButton={!isPunchoutSession}
            buttonText="Go To Overview"
          />
        }
      />
    </Routes>
  );
};
