import { BasketEntry, ProductSku } from "@web/common";
import { ProductItem } from "@web/common/network";
import {
  getLineItemTotal,
  getSalesEntityQuantity,
  getTotalEntityQuantity,
} from "@web/common/utils";

import { LiteProductSku } from "src/typegens";

export const LocalProductService = {
  mapBasketEntryToProductItem: (basketEntry: BasketEntry): ProductItem => {
    const salesEntityQuantity = getSalesEntityQuantity(basketEntry.sku);
    const entityQuantity = getTotalEntityQuantity(salesEntityQuantity, basketEntry.quantity);

    return {
      entityQuantity,
      measurementUnit: basketEntry.sku.measurementUnit,
      name: basketEntry.sku.about?.name || "",
      singleGrossPrice: basketEntry.sku.price.costPrice,
      totalAmount: getLineItemTotal(basketEntry),
      impaCode: basketEntry.sku.about?.generalInformation?.impaCode,
      skuDetails: basketEntry.sku,
    };
  },
  mapProductItemToBasketEntry: (product: ProductItem): BasketEntry => {
    return {
      productName: product.name,
      sku: product.skuDetails as ProductSku,
      quantity: product.quantityInBasket || 1,
    };
  },
  mapBasketEntrySkuPairToProductItem: (
    basketEntry: BasketEntry,
    sku: LiteProductSku
  ): ProductItem => {
    const salesEntityQuantity = getSalesEntityQuantity(sku);
    const entityQuantity = getTotalEntityQuantity(salesEntityQuantity, basketEntry.quantity);

    return {
      entityQuantity,
      measurementUnit: sku.measurementUnit,
      name: sku.about?.name || "",
      singleGrossPrice: sku.price.costPrice,
      totalAmount: {
        ...getLineItemTotal(basketEntry),
        amount: sku.price.costPrice.amount * entityQuantity,
      },
      impaCode: sku.about?.generalInformation?.impaCode,
      skuDetails: sku,
      originalSingleGrossPrice: basketEntry.sku.price.costPrice,
      quantityInBasket: basketEntry.quantity,
    };
  },
};
