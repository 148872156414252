import { useCallback, useEffect, useMemo } from "react";

import { useNetworkDetector } from "../NetworkDetector";
import { useOfflineCapabilities } from "../OfflineCapabilities";
import { useNetworkToggle } from "./useNetworkToggle";

export interface NetworkToggleControllerRenderProps {
  isSwitchOnline: boolean;
  isNetworkOnline: boolean;
  isNetworkReconnecting: boolean;
  isNetworkOffline: boolean;
  isShakyConnection: boolean;
  toggleSwitch: () => void;
  setSwitchOnline: () => void;
  setSwitchOffline: () => void;
}

interface Props {
  render?: (renderProps: NetworkToggleControllerRenderProps) => React.ReactNode;
}

export const NetworkToggleSwitchController: React.FC<Props> = ({ render }) => {
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();

  const {
    isOnline,
    isReconnecting,
    isOffline,
    isShakyConnection,
    currentNetworkState,
    forcedNetworkState,
    forceOffline,
    clearForcedNetworkState,
  } = useNetworkDetector();
  const { isToggleOnline, networkToggleMode, setToggleOffline, setToggleOnline } =
    useNetworkToggle();

  const setOffline = useCallback(() => {
    forceOffline();
    setToggleOffline();
  }, [forceOffline, setToggleOffline]);

  const setOnline = useCallback(() => {
    clearForcedNetworkState();
    // Jump to the end of queue so all state updates happen first
    setTimeout(() => setToggleOnline(), 10);
  }, [clearForcedNetworkState, setToggleOnline]);

  const toggleSwitch = useCallback(() => {
    if (isToggleOnline) {
      setOffline();
    } else {
      setOnline();
    }
  }, [isToggleOnline, setOnline, setOffline]);

  const renderProps = useMemo(
    () => ({
      isSwitchOnline: isToggleOnline,
      isNetworkOnline: isOnline,
      isNetworkReconnecting: isReconnecting,
      isNetworkOffline: isOffline,
      isShakyConnection,
      toggleSwitch,
      setSwitchOnline: setOnline,
      setSwitchOffline: setOffline,
    }),
    [
      isToggleOnline,
      isOnline,
      isReconnecting,
      isOffline,
      isShakyConnection,
      toggleSwitch,
      setOnline,
      setOffline,
    ]
  );

  // TODO #5387 #5390: Remove console logging when development and testing are finished
  useEffect(() => {
    if (!areOfflineCapabilitiesEnabled) {
      return;
    }
    console.log("Current network state: " + currentNetworkState);
  }, [areOfflineCapabilitiesEnabled, currentNetworkState]);

  // TODO #5387 #5390: Remove console logging when development and testing are finished
  useEffect(() => {
    if (!areOfflineCapabilitiesEnabled) {
      return;
    }
    console.log("Forced network state: " + forcedNetworkState);
  }, [areOfflineCapabilitiesEnabled, forcedNetworkState]);

  // TODO #5387 #5390: Remove console logging when development and testing are finished
  useEffect(() => {
    if (!areOfflineCapabilitiesEnabled) {
      return;
    }
    console.log("Network toggle mode: " + networkToggleMode);
  }, [areOfflineCapabilitiesEnabled, networkToggleMode]);

  if (!areOfflineCapabilitiesEnabled || !render) {
    return null;
  }

  return <>{render(renderProps)}</>;
};
