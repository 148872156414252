import { useFlag } from "@unleash/proxy-client-react";
import classnames from "classnames";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { OrderRequisition } from "@web/common/network/model";
import { Loading } from "@web/ui";
import { isDefined } from "@web/utils";

import IntersectionMonitor from "src/components/IntersectionMonitor";
import { NoCachedData } from "src/components/NoCachedData";
import { RoutesConfig } from "src/config/routes";
import LEGACY_useOrderRequisitionListQuery from "src/hooks/LEGACY_useOrderRequisitionListQuery";
import { useOrderDraftsQuery } from "src/hooks/orderDrafts/useOrderDraftsQuery";
import { useNoCachedQueryGuard } from "src/hooks/useNoCachedQueryGuard";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import {
  useOrderRequisitionListQuery,
  useOrderRequisitionListQueryHelpers,
} from "src/hooks/useOrderRequisitionListQuery";
import { OrderState } from "src/models";
import { LocalOrderReqService } from "src/services/LocalOrderReqService";

import { OrdersTableUI } from "./OrdersTableUI";

// TODO #9356: Make `ordersState` prop required
type Props = {
  ordersState?: OrderState;
  onDeleteDraft: (draftId: string) => void;
};

export const OrdersTable = ({ ordersState, onDeleteDraft }: Props) => {
  const isCloseAttributeEnabled = useFlag("close-attribute");
  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();

  const LEGACY_orderRequisitionsQuery = LEGACY_useOrderRequisitionListQuery({
    enabled: !isCloseAttributeEnabled,
  });

  const orderRequisitionsQuery = useOrderRequisitionListQuery(ordersState === "closed", {
    enabled: isCloseAttributeEnabled,
  });

  const { invalidate: _invalidateOrderRequisitionsQuery } = useOrderRequisitionListQueryHelpers();
  const invalidateOrderRequisitionsQuery = useCallback(
    () => _invalidateOrderRequisitionsQuery(ordersState === "closed"),
    [ordersState, _invalidateOrderRequisitionsQuery]
  );

  const navigate = useNavigate();

  const { hasNoCachedData } = useNoCachedQueryGuard({
    isQueryPending: isCloseAttributeEnabled
      ? orderRequisitionsQuery.isPending
      : LEGACY_orderRequisitionsQuery.isPending,
    isQueryError: isCloseAttributeEnabled
      ? orderRequisitionsQuery.isError
      : LEGACY_orderRequisitionsQuery.isError,
    becameOnlineCallback: invalidateOrderRequisitionsQuery,
  });

  const draftsQuery = useOrderDraftsQuery();

  const draftsAsOrderRequisitions = useMemo(
    () => (draftsQuery.data || []).map((draft) => LocalOrderReqService.toOrderRequisition(draft)),
    [draftsQuery.data]
  );

  const orderRequisitionsList = useMemo(() => {
    const pages = isCloseAttributeEnabled
      ? orderRequisitionsQuery.data?.pages
      : LEGACY_orderRequisitionsQuery.data?.pages;
    // Drafts are not closed orders
    const drafts =
      // TODO #9356: remove `!isDefined(ordersState)` condition
      ordersState === "active" || !isDefined(ordersState) ? draftsAsOrderRequisitions : [];
    return [...drafts, ...(pages || []).flatMap((p) => p.items)];
  }, [
    isCloseAttributeEnabled,
    orderRequisitionsQuery.data?.pages,
    LEGACY_orderRequisitionsQuery.data?.pages,
    ordersState,
    draftsAsOrderRequisitions,
  ]);

  const getOrderDetailsUrl = (order: OrderRequisition) => {
    if (isOfflineDraftEnabled) {
      return order.type === "DRAFT"
        ? `${RoutesConfig.order.draftDetails}/${order.id}`
        : `${RoutesConfig.order.details}/${order.id}`;
    }

    return `${RoutesConfig.order.details}/${order.id}`;
  };

  if (hasNoCachedData) {
    return <NoCachedData onGoBack={() => navigate(0)} />;
  }

  if (
    (isCloseAttributeEnabled
      ? orderRequisitionsQuery.isPending ||
        (orderRequisitionsQuery.isFetching && !orderRequisitionsQuery.isFetchingNextPage)
      : LEGACY_orderRequisitionsQuery.isPending ||
        (LEGACY_orderRequisitionsQuery.isFetching &&
          !LEGACY_orderRequisitionsQuery.isFetchingNextPage)) ||
    draftsQuery.isPending ||
    draftsQuery.isFetching
  ) {
    return <Loading />;
  }

  return (
    <div
      className={classnames("flex flex-col items-center", { "pt-4": !isCloseAttributeEnabled })}
      data-testid="orderOverview_ordersTable"
    >
      <OrdersTableUI
        orders={orderRequisitionsList}
        getUrl={getOrderDetailsUrl}
        onDeleteDraft={onDeleteDraft}
      />
      {!isCloseAttributeEnabled && LEGACY_orderRequisitionsQuery.hasNextPage && (
        <IntersectionMonitor onEnter={LEGACY_orderRequisitionsQuery.fetchNextPage}>
          <div className="h-10">
            {LEGACY_orderRequisitionsQuery.isFetchingNextPage && <Loading />}
          </div>
        </IntersectionMonitor>
      )}
      {isCloseAttributeEnabled && orderRequisitionsQuery.hasNextPage && (
        <IntersectionMonitor onEnter={orderRequisitionsQuery.fetchNextPage}>
          <div className="h-10">{orderRequisitionsQuery.isFetchingNextPage && <Loading />}</div>
        </IntersectionMonitor>
      )}
    </div>
  );
};
