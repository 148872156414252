export enum StoreNames {
  IMAGES = "images",
  KEYVAL = "keyval",
  ORDERS = "orders",
  CONFIGURATION = "configuration",
  ORDER_DRAFTS = "orderDrafts",
  STOCKTAKE_DRAFTS = "stocktakeDrafts",
  STOCKTAKE_REPORTS = "stocktakeReports",
  STOCKTAKE_REPORTS_LIST = "stocktakeReportsList",
}

const STORE_NAME_SUFFIX = "--db";
export const getStoreName = (base: string): string => `${base}${STORE_NAME_SUFFIX}`;
