import { TrashIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { memo, useCallback } from "react";
import { UseFieldArrayRemove } from "react-hook-form";

import { IconButton, Label } from "@web/ui";

import {
  LocalStocktakeReportNewExtraItemForm,
  StocktakeReportItemArticleCodeFieldPath,
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemNameFieldPath,
  StocktakeReportItemQuantityFieldPath,
  StocktakeReportItemRobValueAmountFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";
import { UnitOfMeasure } from "src/state/models";

import {
  FormErrors,
  QuantityField,
  RobDetails,
  RobValueDetails,
} from "../StocktakeFormProductTile";
import { useFormFieldKeyboardEvents } from "../StocktakeFormProductTile/useFormFieldKeyboardEvents";
import { useUpdateDependentFormFields } from "../StocktakeFormProductTile/useUpdateDependentFormFields";
import { ArticleCodeField } from "./ArticleCodeField";
import { ProductNameField } from "./ProductNameField";
import { SingleUnitGrossPriceField } from "./SingleUnitGrossPriceField";
import { UnitOfMeasureField } from "./UnitOfMeasureField";

type Props = {
  product: LocalStocktakeReportNewExtraItemForm;
  indexInCollection: number;
  remove: UseFieldArrayRemove;
  hasLineNumberPlaceholder: boolean;
  unitOfMeasures: UnitOfMeasure[];
};

export const StocktakeFormNewExtraProductTile = memo(
  ({
    product,

    indexInCollection,
    hasLineNumberPlaceholder,
    remove,
    unitOfMeasures,
  }: Props) => {
    const quantityFieldPath: StocktakeReportItemQuantityFieldPath = `newExtraItems.${indexInCollection}.quantity`;
    const singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath = `newExtraItems.${indexInCollection}.singleUnitGrossPrice.amount`;
    const measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath = `newExtraItems.${indexInCollection}.measurementUnit`;
    const robValueAmountFieldPath: StocktakeReportItemRobValueAmountFieldPath = `newExtraItems.${indexInCollection}.robValue.amount`;
    const nameFieldPath: StocktakeReportItemNameFieldPath = `newExtraItems.${indexInCollection}.name`;
    const articleCodeFieldPath: StocktakeReportItemArticleCodeFieldPath = `newExtraItems.${indexInCollection}.articleCode`;
    const itemsFieldPath = "newExtraItems";

    // All extra items have `salesEntityQuantity` equal to `1`
    const salesEntityQuantity = 1;

    const removeItem = useCallback(() => remove(indexInCollection), [indexInCollection, remove]);

    const { updateProductRobValue } = useUpdateDependentFormFields({
      initialQuantity: product.quantity,
      initialSingleUnitGrossPriceAmount: product.singleUnitGrossPrice.amount,
      initialMeasurementUnit: product.measurementUnit,
      salesEntityQuantity,
      currencyCode: product.robValue.currencyCode,
      quantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
      robValueAmountFieldPath,
    });

    const { handleKeyDown } = useFormFieldKeyboardEvents<HTMLButtonElement>();

    return (
      <div>
        <div className="flex justify-between items-center">
          <div
            className={classnames(
              "flex-auto flex flex-col items-center p-4 gap-4 bg-neutral_0 border border-neutral_300 rounded-xl",
              { "ml-6.5": hasLineNumberPlaceholder }
            )}
          >
            <div className="self-stretch flex justify-between items-center gap-4">
              <div className="grow basis-3/5 xl:basis-8/12">
                <ProductNameField nameFieldPath={nameFieldPath} />
              </div>
              <div className="grow basis-2/5 xl:basis-4/12">
                <ArticleCodeField articleCodeFieldPath={articleCodeFieldPath} />
              </div>
              <div className="flex-none flex gap-3">
                <div
                  className="flex-none self-stretch border-r-1 border-neutral_300"
                  role="presentation"
                ></div>
                <IconButton
                  size="large"
                  variant="secondary"
                  shape="square"
                  label="Remove Item"
                  Icon={TrashIcon}
                  onClick={removeItem}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
            <div
              className="flex-none self-stretch border-t-1 border-neutral_300"
              role="presentation"
            ></div>
            <div className="self-stretch flex flex-col xl:flex-row items-center gap-4">
              <div className="grow self-stretch flex items-end gap-1">
                <div className="flex-none w-[80px] flex flex-col gap-2">
                  <Label size="100">Quantity</Label>
                  <QuantityField
                    onValueChanged={updateProductRobValue}
                    initialQuantity={product.quantity}
                    initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
                    initialMeasurementUnit={product.measurementUnit}
                    salesEntityQuantity={salesEntityQuantity}
                    currencyCode={product.robValue.currencyCode}
                    quantityFieldPath={quantityFieldPath}
                    singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
                    measurementUnitFieldPath={measurementUnitFieldPath}
                  />
                </div>
                <div className="flex-none w-[160px] lg:w-[200px] xl:w-[220px] flex flex-col gap-2">
                  <Label size="100">Unit of measure</Label>
                  <UnitOfMeasureField
                    onValueChanged={updateProductRobValue}
                    measurementUnitFieldPath={measurementUnitFieldPath}
                    unitOfMeasures={unitOfMeasures}
                  />
                </div>
                <div className="flex-none pb-2">
                  <Label size="100">&times;</Label>
                </div>
                <div className="grow flex flex-col gap-2">
                  <Label size="100">
                    Price per unit <span className="text-textIcon-blackSecondary">(optional)</span>
                  </Label>
                  <SingleUnitGrossPriceField
                    onValueChanged={updateProductRobValue}
                    initialQuantity={product.quantity}
                    initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
                    initialMeasurementUnit={product.measurementUnit}
                    salesEntityQuantity={salesEntityQuantity}
                    currencyCode={product.robValue.currencyCode}
                    quantityFieldPath={quantityFieldPath}
                    singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
                    measurementUnitFieldPath={measurementUnitFieldPath}
                  />
                </div>
              </div>
              <div
                className="flex-none self-stretch border-t-1 border-neutral_300 xl:hidden"
                role="presentation"
              ></div>
              <div className="flex-none self-end flex gap-4">
                <div
                  className="flex-none self-stretch border-r-1 border-neutral_300"
                  role="presentation"
                ></div>
                <div className="flex-none flex flex-col justify-between w-[150px] gap-2 overflow-clip">
                  <div className="flex-none flex items-center gap-1">
                    <RobDetails
                      initialQuantity={product.quantity}
                      initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
                      initialMeasurementUnit={product.measurementUnit}
                      salesEntityQuantity={salesEntityQuantity}
                      currencyCode={product.robValue.currencyCode}
                      quantityFieldPath={quantityFieldPath}
                      singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
                      measurementUnitFieldPath={measurementUnitFieldPath}
                    />
                  </div>
                  <div
                    className="flex-none self-stretch border-t-1 border-neutral_300"
                    role="presentation"
                  ></div>
                  <div className="flex-none flex items-center gap-1">
                    <RobValueDetails
                      initialQuantity={product.quantity}
                      initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
                      initialMeasurementUnit={product.measurementUnit}
                      salesEntityQuantity={salesEntityQuantity}
                      currencyCode={product.robValue.currencyCode}
                      quantityFieldPath={quantityFieldPath}
                      singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
                      measurementUnitFieldPath={measurementUnitFieldPath}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FormErrors indexInCollection={indexInCollection} itemsFieldPath={itemsFieldPath} />
      </div>
    );
  }
);
StocktakeFormNewExtraProductTile.displayName = "StocktakeFormNewExtraProductTile";
