import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { InvoiceAccountUI } from "@web/common";
import { OrderReqService } from "@web/common/services/OrderRequisition";
import { ActionBar, Heading, Label, Paragraph } from "@web/ui";

import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import trackingCategories from "src/utils/trackingCategories";
import trackingEvents from "src/utils/trackingEvents";

export const PurchaseOrderSuccess: React.FC = () => {
  const [{ lastCreatedOrderRequisitions }] = useAppStateContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!lastCreatedOrderRequisitions) {
      navigate(RoutesConfig.order.overview);
    }
  }, [lastCreatedOrderRequisitions, navigate]);

  if (!lastCreatedOrderRequisitions) {
    return null;
  }

  const createNewOrder = () => {
    ReactGA.event({
      category: trackingCategories.CREW_APP,
      action: trackingEvents.CREATING_NEW_ORDER,
    });
    navigate(RoutesConfig.gatherSetup);
  };

  return (
    <div className="flex flex-col">
      <TopBarController />
      <ActionBar
        primaryButtonSettings={{
          title: "Go to Order Overview",
          onClick: () => navigate(RoutesConfig.order.overview),
          testId: "goToOrderOverviewButton",
        }}
        helperButtons={[{ title: "Create Another Order", onClick: () => createNewOrder() }]}
      />
      <div className="max-w-3xl mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8 text-center my-8">
        <div className="max-w-xl">
          <Label size="300" className="uppercase" color="text-primaryDefault">
            {t("pages.orderConfirmation.orderConfirmation")}
          </Label>

          <Heading size="100">{t("pages.orderConfirmation.headline")}</Heading>
          <Paragraph size="200" className="pt-4" color="text-textIcon-blackSecondary">
            {t("pages.orderConfirmation.copy", {
              email: lastCreatedOrderRequisitions[0].requesterInformation?.email,
            })}{" "}
            <br /> {t("pages.orderConfirmation.copySecond")}
          </Paragraph>
        </div>

        {lastCreatedOrderRequisitions.map((order) => (
          <div className="mt-4 border-neutral_300" key={order.id}>
            {!!order.invoiceAccount && (
              <div className="flex flex-col items-start mt-6">
                <Paragraph size="300" className="mb-4" color="text-textIcon-blackSecondary">
                  {t("pages.orderConfirmation.billingInformation")}
                </Paragraph>
                <InvoiceAccountUI value={order.invoiceAccount} />
              </div>
            )}
            <div>
              <dl className="grid grid-cols-2 text-sm py-6 text-left">
                <Label size="300">
                  {order.items.length > 1
                    ? t("pages.orderConfirmation.nrItems_plural", {
                        count: order.items.length,
                      })
                    : t("pages.orderConfirmation.nrItems", {
                        count: order.items.length,
                      })}
                </Label>
                <Label size="300">
                  <div className="text-right">{OrderReqService.getTotalGrossAmount(order)}</div>
                </Label>
              </dl>

              <dl className="border-t space-y-5 mb-6 border-neutral_300 text-sm pt-6">
                <div className="flex justify-between">
                  <Label size="300" color="text-textIcon-blackSecondary">
                    {t("pages.orderConfirmation.salesTax0")}
                  </Label>
                  <Label size="300">{OrderReqService.getAmountZeroInOrderCurrency(order)}</Label>
                </div>
              </dl>

              <dl className="border-t border-neutral_300 text-sm pt-6">
                <div className="flex justify-between">
                  <Label size="300">{t("pages.orderConfirmation.total")}</Label>
                  <dd>
                    <Heading
                      size="300" /* color="text-text-700" */
                      // TODO #6444: Fixme, there is no such color in tailwind config
                    >
                      {OrderReqService.getTotalGrossAmount(order)}
                    </Heading>
                  </dd>
                </div>
              </dl>

              {!!order.storageLabel && (
                <dl className="border-t space-y-5 mb-6 border-neutral_300 text-sm pt-6">
                  <div className="flex justify-between">
                    <Label size="300" color="text-textIcon-blackSecondary">
                      {t("pages.orderConfirmation.storageLabel")}
                    </Label>
                  </div>
                  <div className="flex justify-between">{order.storageLabel}</div>
                </dl>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
