import { forwardRef, memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { LocalStocktakeReportForm, StocktakeReportItemArticleCodeFieldPath } from "src/models";

import { Input } from "../StocktakeFormProductTile/Input";
import { useFormFieldKeyboardEvents } from "../StocktakeFormProductTile/useFormFieldKeyboardEvents";

type Props = {
  articleCodeFieldPath: StocktakeReportItemArticleCodeFieldPath;
};

export const ArticleCodeField = memo(
  forwardRef<HTMLInputElement, Props>(
    (
      { articleCodeFieldPath },
      // We need to catch `ref`, so we don't get errors in runtime

      ref
    ) => {
      const { control } = useFormContext<LocalStocktakeReportForm>();
      const { handleKeyDown } = useFormFieldKeyboardEvents<HTMLInputElement>();

      return (
        <Controller
          name={articleCodeFieldPath}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              ref={ref}
              className="px-2"
              placeholder="Article code (optional)"
              maxLength={20}
              onKeyDown={handleKeyDown}
              aria-label="Article code form field (optional)"
            />
          )}
        />
      );
    }
  )
);
ArticleCodeField.displayName = "ArticleCodeField";
