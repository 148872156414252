import { useCallback } from "react";

import { usePrevious } from "@web/utils";

import { NETWORK_TOGGLE_MODE_STORAGE_KEY } from "src/config/constants";

import { useNetworkDetectorContext } from "./NetworkDetectorContext";
import { UseNetworkDetector } from "./models";

export const useNetworkDetector = (): UseNetworkDetector => {
  const [networkDetectorState, dispatch] = useNetworkDetectorContext();
  const { currentNetworkState, forcedNetworkState, isShakyConnection } = networkDetectorState;

  const isOnline = currentNetworkState === "online";
  const isOffline = currentNetworkState === "offline";
  const isUnknown = currentNetworkState === "unknown";
  const isReconnecting = currentNetworkState === "reconnecting";

  const prevIsOnline = usePrevious(isOnline);

  const becameOnline = isOnline && !prevIsOnline;
  const isNetworkConnectionDetected = !isUnknown;

  const wentOfflineFromOnline = !!prevIsOnline && isOffline;

  const forceOffline = useCallback(() => {
    dispatch({
      type: "forceOffline",
    });
    localStorage.setItem(NETWORK_TOGGLE_MODE_STORAGE_KEY, "offline");
  }, [dispatch]);

  const clearForcedNetworkState = useCallback(() => {
    dispatch({
      type: "clearForcedNetworkState",
    });
    localStorage.removeItem(NETWORK_TOGGLE_MODE_STORAGE_KEY);
  }, [dispatch]);

  return {
    currentNetworkState,
    forcedNetworkState,
    isNetworkConnectionDetected,
    isOnline,
    isOffline,
    isReconnecting,
    isShakyConnection,
    becameOnline,
    forceOffline,
    clearForcedNetworkState,
    wentOfflineFromOnline,
  };
};
