import {
  CancelablePromise,
  LiteStocktakeControllerService,
  LiteStocktakeReport,
  LiteStocktakeReportRequest,
} from "src/typegens";
import { ApiRequestOptions } from "src/typegens/core/ApiRequestOptions";

export const createStocktakeReport = (
  requestBody: LiteStocktakeReportRequest,
  options?: Pick<ApiRequestOptions, "headers" | "signal">
): CancelablePromise<LiteStocktakeReport> =>
  LiteStocktakeControllerService.saveStocktakeReport({ requestBody }, options);
