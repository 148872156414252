import { useQuery, useQueryClient } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useCallback, useMemo } from "react";

import { getStocktakeReport } from "src/api/stocktakes";
import { UiStocktakeReport } from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

export const STOCKTAKE_REPORT_QUERY_KEY_BASE = "stocktakeReport";

const getQueryKey = (stocktakeId: string) => [STOCKTAKE_REPORT_QUERY_KEY_BASE, stocktakeId];

type UseStocktakeReportQuery = UseQueryResult<UiStocktakeReport>;

export const useStocktakeReportQuery = ({
  stocktakeId,
}: {
  stocktakeId: string;
}): UseStocktakeReportQuery => {
  const queryKey = useMemo(() => getQueryKey(stocktakeId), [stocktakeId]);
  return useQuery<UiStocktakeReport>({
    queryKey,
    queryFn: async ({ signal }) => {
      const stocktakeReportResponse = await getStocktakeReport({ stocktakeId }, { signal });
      const stocktakeReport =
        LocalStocktakeService.convertFromApiToUiStocktakeReport(stocktakeReportResponse);
      return stocktakeReport;
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
    enabled: !!stocktakeId,
  });
};

type UseStocktakeReportQueryHelpers = {
  invalidate: (stocktakeId: string) => void;
  setQueryData: (data: UiStocktakeReport) => void;
};

export const useStocktakeReportQueryHelpers = (): UseStocktakeReportQueryHelpers => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    (stocktakeId: string) =>
      queryClient.invalidateQueries({
        queryKey: getQueryKey(stocktakeId),
      }),
    [queryClient]
  );

  const setQueryData = useCallback(
    (data: UiStocktakeReport) => {
      queryClient.setQueryData(getQueryKey(data.id), data);
    },
    [queryClient]
  );

  return {
    invalidate,
    setQueryData,
  };
};
