import { LiteStocktakeControllerService } from "src/typegens";

export const exportStocktakeReportToExcel = async (id: string): Promise<Blob> => {
  const result = await LiteStocktakeControllerService.exportStocktakeReportToExcel(
    {
      id,
    },
    {
      headers: {
        Accept: "*/*",
      },
    }
  );

  return result;
};
