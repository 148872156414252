import _compact from "lodash/compact";
import { useNavigate, useParams } from "react-router-dom";

import { DeleteOrderDraftModal } from "@web/common/components/modals/DeleteOrderDraftModal";
import { OrderContextMenuActions, useModalContext } from "@web/common/contexts/ModalContext";
import { OrderItem } from "@web/common/network/model";
import { getLineItemEntityQuantity } from "@web/common/utils";
import { Loading } from "@web/ui";
import { WithRequiredProperty, triggerFileDownload } from "@web/utils";

import { RoutesConfig } from "src/config/routes";
import { useDeleteOrderDraftMutation } from "src/hooks/orderDrafts/useDeleteOrderDraftMutation";
import {
  useOrderDraftsQuery,
  useOrderDraftsQueryHelpers,
} from "src/hooks/orderDrafts/useOrderDraftsQuery";
import { useExportBasketToExcelMutation } from "src/hooks/useExportBasketToExcelMutation";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { useToastMessage } from "src/hooks/useToastMessage";
import { LocalOrderReqService } from "src/services/LocalOrderReqService";

import { OrderDetails } from "./components";

export const OrderDraftDetailsController: React.FC = () => {
  const { id } = useParams() as { id: string };

  const { closeModal, openModal } = useModalContext();

  const query = useOrderDraftsQuery();
  const { getDraftById } = useOrderDraftsQueryHelpers();
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();
  const { setToastMessage } = useToastMessage();

  const navigate = useNavigate();
  const { mutate: deleteDraft, isPending: isDeletingDraft } = useDeleteOrderDraftMutation({
    hasSuccessMessage: true,
    hasErrorMessage: true,
    onSuccess: () => {
      closeModal();
      navigate(RoutesConfig.order.overview);
    },
  });

  const { mutate: downloadExcel } = useExportBasketToExcelMutation({
    onSuccess: (file: Blob) => {
      setToastMessage({
        type: "success",
        message: "The draft will be downloaded soon.",
      });
      triggerFileDownload({
        file,
        fileNameWithExtension: `S2S_${id}.xlsx`,
      });
    },
    onError: () => {
      setToastMessage({
        type: "failure",
        message: "There was an issue while downloading the draft. Please try again.",
      });
    },
  });

  const onDeleteDraftConfirm = (draftId: string) => {
    deleteDraft(draftId);
  };

  const openDeleteDraftModal = (draftId: string) => {
    openModal(
      <DeleteOrderDraftModal
        closeModal={closeModal}
        draftId={draftId}
        isLoading={isDeletingDraft}
        onConfirm={() => onDeleteDraftConfirm(draftId)}
      />
    );
  };

  if (query.isPending || query.isFetching) {
    return <Loading />;
  }

  const draft = getDraftById(query.data || [], id);

  if (!draft) {
    return <div>Draft not found</div>;
  }

  const draftAsOrder = LocalOrderReqService.toOrderRequisition(draft);

  const handleDeleteDraft = () => {
    openDeleteDraftModal(draftAsOrder.id);
  };

  const handleExcelDownload = () => {
    const isSafeDraftItem = (
      item: OrderItem
    ): item is WithRequiredProperty<OrderItem, "skuDetails"> => {
      return !!item.skuDetails;
    };

    const areAnySkuDetailsMissing = draftAsOrder.items.some((item) => !isSafeDraftItem(item));

    if (areAnySkuDetailsMissing) {
      setToastMessage({
        type: "failure",
        message:
          "There was an issue while downloading the draft due to draft data being invalid. Please contact Customer Support for assistance.",
      });
      return;
    }

    // The `_compact` and checking for `isSafeDraftItem` are here only to accommodate for minor
    // differences in the models and make the compiler happy.
    // The `areAnySkuDetailsMissing` guard above is actually preventing any problems that these differences could cause.
    const safeDraftItems = _compact(
      draftAsOrder.items.map((item) =>
        isSafeDraftItem(item)
          ? {
              variantId: item.skuDetails.id,
              entityQuantity: getLineItemEntityQuantity({
                ...item,
                sku: item.skuDetails,
              }),
            }
          : null
      )
    );

    allowOnlineOnly(() => {
      downloadExcel({
        portId: draftAsOrder.port.id,
        items: safeDraftItems,
      });
    });
  };

  const contextMenuActions: OrderContextMenuActions = {
    onDeleteDraft: handleDeleteDraft,
    onExcelDownload: handleExcelDownload,
  };

  return (
    <>
      <OrderDetails order={draftAsOrder} contextMenuActions={contextMenuActions} />
      <AllowOnlineOnlyWarningModal />
    </>
  );
};
