import { useCallback, useEffect, useMemo, useState } from "react";

import { Modal } from "@web/ui";

import { NoNetworkConnectionModal } from "src/components/NoNetworkConnectionModal";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { NetworkToggleSwitchController } from "src/contexts/NetworkToggle";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { useWasUnableToReconnect } from "src/hooks/useWasUnableToReconnect";

export const useNetworkDependentAction = () => {
  const { areOfflineCapabilitiesEnabled, isManualNetworkStateEnabled } = useOfflineCapabilities();
  const { isOnline } = useNetworkDetector();
  const [isOfflineWarningModalOpen, setIsOfflineWarningModalOpen] = useState<boolean>(false);
  const { becameOnline } = useNetworkDetector();
  const { wasUnableToReconnect } = useWasUnableToReconnect();

  useEffect(() => {
    if (becameOnline || wasUnableToReconnect) {
      setIsOfflineWarningModalOpen(false);
    }
  }, [becameOnline, setIsOfflineWarningModalOpen, wasUnableToReconnect]);

  const AllowOnlineOnlyWarningModal = useMemo(() => {
    const AllowOnlineOnlyWarningModalComponent = () => (
      <NetworkToggleSwitchController
        render={({ isNetworkReconnecting, setSwitchOnline }) => (
          <Modal
            isOpen={isOfflineWarningModalOpen}
            closeModal={() => setIsOfflineWarningModalOpen(false)}
          >
            <NoNetworkConnectionModal
              onConfirm={() => setSwitchOnline()}
              isConfirmLoading={isNetworkReconnecting}
              onClose={() => setIsOfflineWarningModalOpen(false)}
              isManualNetworkStateEnabled={isManualNetworkStateEnabled}
            />
          </Modal>
        )}
      />
    );
    return AllowOnlineOnlyWarningModalComponent;
  }, [isManualNetworkStateEnabled, isOfflineWarningModalOpen]);

  const allowOnlineOnly = useCallback(
    (callback: () => void): void => {
      if (areOfflineCapabilitiesEnabled && !isOnline) {
        setIsOfflineWarningModalOpen(true);
        return;
      }

      callback();
    },
    [areOfflineCapabilitiesEnabled, isOnline]
  );

  return {
    allowOnlineOnly,
    AllowOnlineOnlyWarningModal,
  };
};
