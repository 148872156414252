import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Money } from "@web/models";
import { isDefined, preprocessFormAmountValue } from "@web/utils";

import {
  LocalStocktakeReportForm,
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemQuantityFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

type UseItemFormDetails = {
  modifiedQuantity: string | number | undefined;
  modifiedSingleUnitGrossPriceAmount: string | number | undefined;
  modifiedMeasurementUnit: string;
  entityQuantity: number;
  productRobValue: Money | undefined;
};

type Params = {
  initialQuantity: number | null | undefined;
  initialSingleUnitGrossPriceAmount: number | null | undefined;
  initialMeasurementUnit: string;
  salesEntityQuantity: number;
  currencyCode: string;
  quantityFieldPath: StocktakeReportItemQuantityFieldPath;
  singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath;
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
};

export const useItemFormDetails = ({
  initialQuantity,
  initialMeasurementUnit,
  initialSingleUnitGrossPriceAmount,
  salesEntityQuantity,
  currencyCode,
  quantityFieldPath,
  singleUnitGrossPriceFieldPath,
  measurementUnitFieldPath,
}: Params): UseItemFormDetails => {
  const { control } = useFormContext<LocalStocktakeReportForm>();

  // Before submission, the model value can be a string, so let's reflect that knowledge in the coerced type
  const modifiedQuantity = useWatch({
    control,
    name: quantityFieldPath,
    defaultValue: initialQuantity,
  }) as string | number | undefined;

  // Before submission, the model value can be a string, so let's reflect that knowledge in the coerced type
  const modifiedSingleUnitGrossPriceAmount = useWatch({
    control,
    name: singleUnitGrossPriceFieldPath,
    defaultValue: initialSingleUnitGrossPriceAmount,
  }) as string | number | undefined;

  const modifiedMeasurementUnit = useWatch({
    control,
    name: measurementUnitFieldPath,
    defaultValue: initialMeasurementUnit,
  });

  // We are using locally calculated `entityQuantity` and `productRobValue` to avoid unnecessary re-renders
  // that would occur if we tried to take them directly from the form values
  const entityQuantity = LocalStocktakeService.getItemEntityQuantity(
    salesEntityQuantity,
    preprocessFormAmountValue(modifiedQuantity)
  );

  const productRobValue = useMemo(() => {
    const processedModifiedSingleUnitGrossPriceAmount = preprocessFormAmountValue(
      modifiedSingleUnitGrossPriceAmount
    );
    return isDefined(processedModifiedSingleUnitGrossPriceAmount)
      ? {
          currencyCode: currencyCode,
          amount: LocalStocktakeService.getItemRobValueAmount(
            entityQuantity,
            processedModifiedSingleUnitGrossPriceAmount
          ),
        }
      : undefined;
  }, [modifiedSingleUnitGrossPriceAmount, currencyCode, entityQuantity]);

  return {
    modifiedQuantity,
    modifiedSingleUnitGrossPriceAmount,
    modifiedMeasurementUnit,
    entityQuantity,
    productRobValue,
  };
};
