import { z } from "zod";

import { StocktakeReportDraftSchema } from "./StocktakeReportDraftSchema";

export const PartialStocktakeReportDraftSchema = StocktakeReportDraftSchema.pick({
  vesselId: true,
  type: true,
  inventoryCount: true,
  robValue: true,
  stocktakeDate: true,
  stocktakerInformation: true,
  subject: true,
  categories: true,
  items: true,
  oldExtraItems: true,
  newExtraItems: true,
});

export type LocalPartialStocktakeReportDraft = z.infer<typeof PartialStocktakeReportDraftSchema>;
