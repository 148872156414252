import { useNavigate } from "react-router-dom";

import { BasketEntry, OrderItem, Port } from "@web/common";

import { RoutesConfig } from "../config/routes";
import { useAppStateContext } from "../contexts/AppStateContext";
import { OrderStocktakeType } from "../models";
import { LiteDutyFreeDeclaration, LiteProductSku, LiteRfqItem } from "../typegens";
import { getItemUnits, pairProductsWithVariants } from "../utils";
import useBasket from "./useBasket";

type AddProductsToBasketProps = {
  catalogItems: OrderItem[];
  port: Port;
  rfqItems?: LiteRfqItem[];
  deliveryDate?: string;
  dutyFreeDeclaration?: LiteDutyFreeDeclaration;
  orderType?: OrderStocktakeType;
};

export const useAddProductsToBasket = ({
  catalogItems,
  port,
  deliveryDate,
  dutyFreeDeclaration,
  rfqItems,
  orderType,
}: AddProductsToBasketProps) => {
  const navigate = useNavigate();
  const [, dispatch] = useAppStateContext();
  const { addMultipleSkusToBasket, addMultipleRfqsToBasket, clearBasket } = useBasket();

  const addProductsToBasket = (liteProductList: LiteProductSku[]) => {
    const availableProducts = (
      pairProductsWithVariants(catalogItems)(liteProductList).filter((e) => e[1]) as Array<
        [OrderItem, LiteProductSku]
      >
    ).map(([product, variant]) => ({
      sku: variant,
      quantity: getItemUnits({ product, variant }),
    })) as Array<BasketEntry>;

    dispatch({
      type: "setPreconfigureOrderSetup",
      value: {
        port,
        deliveryDate,
        dutyFreeDeclaration,
        orderType,
      },
    });

    clearBasket();
    addMultipleSkusToBasket(availableProducts);
    navigate(RoutesConfig.basket);
    if (rfqItems) {
      addMultipleRfqsToBasket(rfqItems);
    }
  };

  return { addProductsToBasket };
};
