import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BasketEntry } from "@web/common";
import { ProductItem } from "@web/common/network";
import { Modal } from "@web/ui";
import { isDefined } from "@web/utils";

import { BasketVerificationModal as BasketVerificationModalUi } from "src/components/Modal/BasketVerificationModal";
import { RoutesConfig } from "src/config/routes";
import useBasket from "src/hooks/useBasket";
import { LocalProductService } from "src/services/LocalProductService";
import { LiteProductSku } from "src/typegens";
import { pairProductsWithVariants } from "src/utils";

export const useBasketVerification = () => {
  const [isBasketVerificationModalOpen, setIsBasketVerificationModalOpen] = useState(false);
  const [unavailableProducts, setUnavailableProducts] = useState<ProductItem[]>([]);
  const [areAllItemsUnavailable, setAreAllItemsUnavailable] = useState(false);
  const [productsWithChangedPrice, setProductsWithChangedPrice] = useState<ProductItem[]>([]);
  const [onSubmit, setOnSubmit] = useState<() => void>(() => {});

  const { addMultipleSkusToBasket, removeMultipleEntriesFromBasket } = useBasket();
  const navigate = useNavigate();

  const hasOnlyItemsWithChangedPrice =
    unavailableProducts.length === 0 && productsWithChangedPrice.length > 0;

  const updateBasketHandler = useCallback(() => {
    const unavailableProductsIds = new Set(unavailableProducts.map((p) => p.skuDetails?.id || ""));
    removeMultipleEntriesFromBasket(unavailableProductsIds);
    addMultipleSkusToBasket(
      productsWithChangedPrice.map((product) =>
        LocalProductService.mapProductItemToBasketEntry(product)
      )
    );
    setIsBasketVerificationModalOpen(false);
    navigate(RoutesConfig.basket);
  }, [
    addMultipleSkusToBasket,
    navigate,
    productsWithChangedPrice,
    removeMultipleEntriesFromBasket,
    unavailableProducts,
  ]);

  const submitHandler = useCallback(() => {
    // submission may fail, but we want to update basket anyway
    addMultipleSkusToBasket(
      productsWithChangedPrice.map((product) =>
        LocalProductService.mapProductItemToBasketEntry(product)
      )
    );
    setIsBasketVerificationModalOpen(false);
    onSubmit();
  }, [addMultipleSkusToBasket, onSubmit, productsWithChangedPrice]);

  const findChangedProducts = useCallback(
    ({
      lineItems,
      skus,
      submitCallback,
    }: {
      lineItems: BasketEntry[];
      skus: LiteProductSku[];
      submitCallback: () => void;
    }) => {
      const productsInBasket = lineItems.map((item) => {
        return {
          variantId: item.sku.id,
          ...item,
        };
      });

      const productVariantPairs = pairProductsWithVariants(productsInBasket)(skus);
      const unavailableProducts = productVariantPairs
        .filter(([, sku]) => !isDefined(sku))
        .map(([entry]) => LocalProductService.mapBasketEntryToProductItem(entry));

      if (unavailableProducts.length === lineItems.length) {
        setAreAllItemsUnavailable(true);
      }

      const productsWithChangedPrice = productVariantPairs
        .filter(([, sku]) => isDefined(sku))
        .filter(([product, sku]) => {
          return sku?.price.costPrice.amount !== product.sku.price.costPrice.amount;
        })
        .map(([entry, sku]) =>
          LocalProductService.mapBasketEntrySkuPairToProductItem(entry, sku as LiteProductSku)
        );

      if (unavailableProducts.length > 0) {
        setUnavailableProducts(unavailableProducts);
        setProductsWithChangedPrice(productsWithChangedPrice);
        setIsBasketVerificationModalOpen(true);
      } else if (productsWithChangedPrice.length > 0) {
        setProductsWithChangedPrice(productsWithChangedPrice);
        setOnSubmit(() => submitCallback);
        setIsBasketVerificationModalOpen(true);
      }

      return { unavailableProducts, productsWithChangedPrice };
    },
    []
  );

  const BasketVerificationModal = useMemo(() => {
    const BasketVerificationModalComponent = () => (
      <Modal
        isOpen={isBasketVerificationModalOpen}
        closeModal={() => setIsBasketVerificationModalOpen(false)}
      >
        <BasketVerificationModalUi
          areAllItemsUnavailable={areAllItemsUnavailable}
          hasOnlyItemsWithChangedPrice={hasOnlyItemsWithChangedPrice}
          closeModal={() => setIsBasketVerificationModalOpen(false)}
          onSubmit={submitHandler}
          onUpdateBasketClick={updateBasketHandler}
          productsWithChangedPrice={productsWithChangedPrice}
          unavailableProducts={unavailableProducts}
        />
      </Modal>
    );

    return BasketVerificationModalComponent;
  }, [
    areAllItemsUnavailable,
    hasOnlyItemsWithChangedPrice,
    isBasketVerificationModalOpen,
    productsWithChangedPrice,
    submitHandler,
    unavailableProducts,
    updateBasketHandler,
  ]);

  return { findChangedProducts, BasketVerificationModal };
};
