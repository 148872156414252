import { z } from "zod";

import { optionalNonEmptyString } from "./common";

export const LiteSkuQualitySchema = z.object({
  brand: optionalNonEmptyString(),
  countryOrigin: optionalNonEmptyString(),
  quality: optionalNonEmptyString(),
  countryProduction: optionalNonEmptyString(),
  material: optionalNonEmptyString(),
  property: optionalNonEmptyString(),
  specs: optionalNonEmptyString(),
  assortmentType: optionalNonEmptyString(),
  itemArea: optionalNonEmptyString(),
});
