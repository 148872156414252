import { z } from "zod";

import { preprocessFormAmountValue } from "@web/utils";

import { ApiMoneySchema } from "../ApiMoneySchema";
import { LiteStocktakeExtraItemSchema } from "./LiteStocktakeExtraItemSchema";

export const StocktakeReportDraftNewExtraItemSchema = LiteStocktakeExtraItemSchema.omit({
  entityQuantityOnStock: true,
}).extend({
  // We need separate validation setup for draft, because we want to be able to store empty values for them
  quantity: z.preprocess(preprocessFormAmountValue, z.number().optional().nullable()),
  singleUnitGrossPrice: ApiMoneySchema.extend({
    amount: z.preprocess(preprocessFormAmountValue, z.number().optional().nullable()),
  }),
  // These need to NOT have the minlength validation in this schema, so we can save a draft with empty strings
  name: z.string(),
  measurementUnit: z.string(),
});

export type LocalStocktakeReportDraftNewExtraItem = z.infer<
  typeof StocktakeReportDraftNewExtraItemSchema
>;
