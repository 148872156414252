import { OrderRequisition } from "@web/common/network/model";

import { ReorderModal } from "src/components/Modal/ReorderModal";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { useAvailableItemsQuery } from "src/hooks/catalog/useAvailableItemsQuery";
import { useOfflineCatalogQuery } from "src/hooks/useOfflineCatalogQuery";

interface Props {
  order: OrderRequisition;
}

export const ReorderModalController = ({ order }: Props) => {
  const orderVariantIds = order.items.map((item) => item.variantId);

  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const offlineCatalogQuery = useOfflineCatalogQuery({
    portId: order.port.id,
    orderType: order.orderType,
  });

  const { query: availableItemsQuery } = useAvailableItemsQuery({
    variantIds: orderVariantIds,
    portId: order.port.id,
    orderType: order.orderType,
    disabled: areOfflineCapabilitiesEnabled && offlineCatalogQuery.isFetching,
  });

  return (
    <ReorderModal
      order={order}
      availableItemsQuery={availableItemsQuery}
      isDownloadingOfflineCatalog={offlineCatalogQuery.isFetching}
    />
  );
};
