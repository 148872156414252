enum trackingEvents {
  "CREATING_NEW_ORDER" = "Creating New Request",
  "PORT_SELECTED" = "Port Selected",
  "PORT_CHANGED" = "Port Changed",
  "CATEGORY_DROPDOWN_ACTIVATED" = "Cateory dropdown activated",
  "CATEGORY_TAB_CHANGED" = "Category tab changed",
  "LEAD_TIME_FILTER_CHANGED" = "Leadtime filter changed",
  "PRICE_MIN_FILTER_CHANGED" = "Price min filter changed",
  "PRICE_MAX_FILTER_CHANGED" = "Price max filter changed",
  "CATEGORY_LIST_EXPANDED" = "Category list expanded",
  "QUANTITY_INCREASED_BASKET" = "Quantity increased (basket)",
  "QUANTITY_DECREASED_BASKET" = "Quantity decreased (basket)",
  "QUANTITY_MODIFIED_BASKET" = "Quantity modified (basket)",
  "QUANTITY_INCREASED_LINE_ITEM" = "Quantity increased (line item)",
  "QUANTITY_DECREASED_LINE_ITEM" = "Quantity decreased (line item)",
  "QUANTITY_MODIFIED_LINE_ITEM" = "Quantity modified (line item)",
  "ITEM_ADDED_TO_BASKET" = "Item added to basket",
  "ACCORDION_EXPANDED" = "Accordion expanded",
  "REQUSITION_CANCELLED" = "Requsition cancelled",
}

export default trackingEvents;
