import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

import { useAppStateContext } from "../contexts/AppStateContext";
import { LiteProduct, ProductsService } from "../typegens";

export const PRODUCT_QUERY_KEY_BASE = "product";

const useProductQuery = ({ productId, portId }: { productId: string; portId: string }) => {
  const queryClient = useQueryClient();
  const [{ products, port, orderType }] = useAppStateContext();
  const cachedProduct = products[productId];
  const queryKey = useMemo(
    () => [PRODUCT_QUERY_KEY_BASE, productId, port?.id || "", orderType],
    [productId, port?.id, orderType]
  );

  const query = useQuery<LiteProduct>({
    queryKey,
    queryFn: async ({ signal }) => {
      const response = await ProductsService.getProductSku(
        {
          id: productId,
          portId: portId,
          orderType,
        },
        { signal }
      );
      return response;
    },
    enabled: !cachedProduct || !port?.id,
    staleTime: Infinity,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
    [queryKey, queryClient]
  );

  return { query, invalidate };
};

export default useProductQuery;
