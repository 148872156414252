import { Dispatch, useReducer } from "react";

import { appStateReducer, defaultAppState } from "../state/AppState";
import AppState, { AppStateAction } from "../state/models";

const useAppState = (): [AppState, Dispatch<AppStateAction>] => {
  const [state, dispatch] = useReducer(appStateReducer, defaultAppState);

  // Reset port if chosen port is not available
  const { port, configuration } = state;

  if (
    configuration &&
    port &&
    !Object.values(configuration.ports)
      .flatMap((port) => port)
      .map((port) => port.id)
      .includes(port.id)
  ) {
    dispatch({ type: "clearPort" });
  }

  return [state, dispatch];
};

export default useAppState;
