import { DefaultError, useMutation } from "@tanstack/react-query";

import { OrderDraftsService } from "src/services/OrderDraftsService";

import { useToastMessage } from "../useToastMessage";
import { useOrderDraftsQueryHelpers } from "./useOrderDraftsQuery";

type UseDeleteOrderDraftMutationArgs = {
  onSuccess?: (deletedDraftId: string) => void;
  onError?: (draftToDeleteId: string, error: Error) => void;
  hasSuccessMessage?: boolean;
  hasErrorMessage?: boolean;
};

export const useDeleteOrderDraftMutation = ({
  onSuccess,
  onError,
  hasSuccessMessage = false,
  hasErrorMessage = false,
}: UseDeleteOrderDraftMutationArgs = {}) => {
  const { setToastMessage } = useToastMessage();
  const { removeDraftFromQuery } = useOrderDraftsQueryHelpers();

  return useMutation<unknown, DefaultError, string>({
    mutationKey: ["deleteDraft"],
    mutationFn: OrderDraftsService.deleteDraft,
    networkMode: "always",
    onSuccess: (_, deletedDraftId) => {
      hasSuccessMessage &&
        setToastMessage({
          type: "success",
          message: "Your draft was successfully deleted",
        });

      if (onSuccess) {
        onSuccess(deletedDraftId);
      }

      removeDraftFromQuery(deletedDraftId);
    },
    onError: (error, draftToDeleteId) => {
      hasErrorMessage &&
        setToastMessage({
          type: "failure",
          message: "There was an error while deleting your draft",
        });

      if (onError) {
        onError(draftToDeleteId, error);
      }
    },
  });
};
