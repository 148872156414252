import { ChangeEvent, KeyboardEvent, forwardRef, memo, useCallback } from "react";
import { NumericFormatProps } from "react-number-format/types/types";

import { AmountField as UiAmountField } from "@web/ui";
import { preprocessFormAmountValue } from "@web/utils";

import { Input } from "./Input";
import { useFormFieldKeyboardEvents } from "./useFormFieldKeyboardEvents";

const MAX_VALUE = 9999.99;

type Props = {
  className?: string;
  "aria-label": string;
  calculateNewValue: (event: KeyboardEvent<HTMLInputElement>) => number;
  setNewValue: (newValue: number | undefined) => void;
} & NumericFormatProps;

export const AmountField = memo(
  forwardRef<HTMLInputElement, Props>(
    (
      { className = "", "aria-label": ariaLabel, calculateNewValue, setNewValue, ...props },
      // We need to catch `ref`, so we don't get errors in runtime
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      ref
    ) => {
      const handleOnChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          const newValue = preprocessFormAmountValue(event.currentTarget.value);
          setNewValue(newValue);
        },
        [setNewValue]
      );

      const { handleKeyDown } = useFormFieldKeyboardEvents<HTMLInputElement>({
        calculateNewValue,
        setNewValue,
        minValue: 0,
        maxValue: MAX_VALUE,
      });

      return (
        <UiAmountField
          {...props}
          onChange={handleOnChange}
          className={className}
          isAllowed={({ floatValue }) => !floatValue || floatValue <= 9999.99}
          allowNegative={false}
          inputMode="numeric"
          pattern="[0-9]*"
          InputComponent={Input}
          onKeyDown={handleKeyDown}
          aria-label={ariaLabel}
        />
      );
    }
  )
);
AmountField.displayName = "AmountField";
