import classnames from "classnames";

import { getAttachmentDownloadUrl } from "@web/attachments";
import DownloadIcon from "@web/common/icons/Download.svg";
import { Attachment } from "@web/common/network";
import { Label } from "@web/ui";
import { formatFileSize } from "@web/utils";

interface HeaderAttachmentElementProps {
  className?: string;
  attachment: Attachment;
}

export const HeaderAttachmentElement = (props: HeaderAttachmentElementProps) => {
  const { attachment, className } = props;
  const { attachmentId, category, size, name, nameUploadedBy } = attachment;

  return (
    <div
      className={classnames(
        `flex justify-between items-center px-4 py-3 rounded-lg border bg-neutral_0 shadow
         text-textIcon-blackSecondary break-all`,
        className
      )}
      style={{ width: "49%" }}
    >
      <div className="flex grow-2 flex-col w-3/4 gap-2 ">
        <Label className="my-0 leading-4" size="200" component="p">
          {name}
        </Label>
        <Label className="leading-4" size="300" color="text-textIcon-blackSecondary" component="p">
          {[category, formatFileSize(size), nameUploadedBy].join(" • ")}
          {[...(category ? [category] : []), formatFileSize(size), nameUploadedBy].join(" • ")}
        </Label>
      </div>
      <div className="flex justify-end w-1/4">
        <a href={getAttachmentDownloadUrl(attachmentId)} target="_blank" download={attachmentId}>
          {/* TODO #5548: Unfortunately the icon ArrowTrayDown is only available in hero-icons >= 2.0.0 */}
          <img className="cursor-pointer" src={DownloadIcon} />
        </a>
      </div>
    </div>
  );
};
