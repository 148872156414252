import { useState } from "react";
import { useTranslation } from "react-i18next";

import { BasketEntry } from "@web/common";
import ErrorLabel from "@web/common/components/ErrorLabel";
import { LeadTime } from "@web/common/components/LeadTime";
import { PriceTypeFlag } from "@web/common/components/PriceTypeFlag";
import { TotalUnitOfMeasure } from "@web/common/components/TotalUnitOfMeasure";
import WarningLabel from "@web/common/components/WarningLabel";
import {
  getMinimumOrderQuantity,
  getMinimumQuantityNumber,
  getSalesEntityQuantity,
} from "@web/common/utils";
import { Label, LineItemQuantityFastGather, Paragraph } from "@web/ui";
import { formatMoney, imagor } from "@web/utils";

import useBasket from "src/hooks/useBasket";

interface Props {
  lineItem: BasketEntry;
  index: number;
}

export const LineItemFastGather: React.FC<Props> = ({ lineItem, index }) => {
  const [isErrorMessage, setIsErrorMessage] = useState<boolean>(false);
  const { pushSkuToBasket, getLineItemTotalPrice, getQuantity } = useBasket();
  const { t } = useTranslation();
  const { sku, quantity } = lineItem;
  const lineItemTotal = getLineItemTotalPrice(sku);
  const setQuantity = (newQuantity: number) => pushSkuToBasket(sku, newQuantity);
  const minimumOrderQuantity = getMinimumOrderQuantity(sku);
  const salesEntityQuantity = getSalesEntityQuantity(sku);
  const customerOrderQuantity = sku.about?.generalInformation?.customerOrderQuantity;
  const impaCode = sku.about?.generalInformation?.impaCode;
  const minimumQuantityNumber = getMinimumQuantityNumber(sku);

  return (
    <div
      data-testid="basketLineItem"
      className="flex bg-neutral_0 rounded-lg px-4 py-2 mt-2 border-neutral_300 border-1"
    >
      <div className="flex w-[40%]" tabIndex={-1}>
        <div className="flex flex-col align-top w-[70px]">
          <img
            alt="product image"
            src={imagor(sku.images[0].imageUrl)}
            className="object-scale-down w-[70px]"
          />
        </div>

        <div className="col-span-5 ml-4 flex flex-col content-between self-center w-[70%] text-left">
          <div className="flex flex-col items-start">
            {!!sku.about?.name && <Label size="200">{sku.about?.name}</Label>}
            {isErrorMessage && (
              <ErrorLabel
                errorMessage={t("pages.basket.minimumOrderQuantityError")}
                minimumOrderQuantity={minimumOrderQuantity}
                customerOrderQuantity={customerOrderQuantity}
                measurementUnit={sku.measurementUnit}
              />
            )}
            <div className="flex justify-start items-stretch">
              {!!sku.leadTime && <LeadTime leadTime={sku.leadTime} customStyling="mr-2" />}
              {!isErrorMessage && minimumOrderQuantity && minimumQuantityNumber > 1 && (
                <WarningLabel
                  warningMessage={t("pages.basket.minimum")}
                  minimumOrderQuantity={minimumOrderQuantity}
                  minimumQuantityNumber={minimumQuantityNumber}
                  customerOrderQuantity={customerOrderQuantity}
                  measurementUnit={sku.measurementUnit}
                />
              )}
            </div>
          </div>
          {!!impaCode && (
            <div className="flex pt-2">
              <>
                <Paragraph size="300" color="text-textIcon-blackSecondary">
                  IMPA:{" "}
                </Paragraph>
                <Paragraph size="300" color="text-textIcon-blackSecondary" className="pl-2 pr-6">
                  {impaCode}
                </Paragraph>
              </>
            </div>
          )}
        </div>
      </div>

      <div className="pl-4 flex ml-auto">
        <div className="flex divide-x divide-neutral_300 space-x-5">
          <div className="text-right flex flex-col content-between justify-items-center self-center">
            <div className="flex space-x-2">
              <div>
                <div>
                  <Label size="100">{!!lineItemTotal && formatMoney(lineItemTotal)}</Label>
                </div>
                <Paragraph
                  size="300"
                  weight="heavy"
                  color="text-textIcon-blackSecondary"
                >{`${formatMoney(sku.price.costPrice)}/${sku.measurementUnit}`}</Paragraph>
              </div>
              <div className="justify-items-center self-center">
                <PriceTypeFlag priceType={sku.priceType} />
              </div>
            </div>
          </div>
          <div className="flex flex-grow pl-1 w-[170px] pr-5">
            <TotalUnitOfMeasure
              className="justify-end"
              quantityInBasket={getQuantity(sku)}
              salesEntityQuantity={salesEntityQuantity}
              measurementUnit={sku.measurementUnit}
              variant="positive"
            />
          </div>
        </div>
        <div className="flex items-center">
          <LineItemQuantityFastGather
            initialQuantity={quantity}
            onQuantityChange={setQuantity}
            setIsErrorMessage={({ err }: { err: boolean }) => setIsErrorMessage(err)}
            min={minimumQuantityNumber}
            index={index}
            customerOrderQuantity={customerOrderQuantity}
            shouldTrack
          />
        </div>
      </div>
    </div>
  );
};
