import { useReducer } from "react";

import { NetworkToggleAction, NetworkToggleMode } from "./models";

export const defaultNetworkToggleState: NetworkToggleMode = "online";

const NetworkToggleReducer = (
  networkToggleMode: NetworkToggleMode,
  action: NetworkToggleAction
): NetworkToggleMode => {
  switch (action.type) {
    case "setOnline":
      return "online";
    case "setOffline":
      return "offline";
    default:
      return networkToggleMode;
  }
};

export const useNetworkToggleReducer = () =>
  useReducer(NetworkToggleReducer, defaultNetworkToggleState);
