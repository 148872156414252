import { DefaultError, useMutation } from "@tanstack/react-query";

import { OrderRequisition } from "@web/common";

import { cancelRequisition } from "../api/cancelRequisition";
import { LiteOrderRequisition } from "../typegens";

export const useCancelRequisitionMutation = (options = {}) => {
  return useMutation<LiteOrderRequisition, DefaultError, OrderRequisition>({
    mutationKey: ["cancelRequisition"],
    mutationFn: (requisition) => {
      if (!requisition.canCancel) {
        return Promise.reject("Cannot cancel this requisition");
      }
      return cancelRequisition(requisition.id);
    },
    ...options,
  });
};
