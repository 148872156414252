import { Boat, Heading, Paragraph } from "@web/ui";

export const StocktakeEditNothingToStocktakeInfo = () => {
  return (
    <div className="mt-7">
      <div className="flex flex-col items-center gap-5">
        <Boat />
        <Heading size="200" className="text-center">
          There is nothing to stocktake
        </Heading>
      </div>

      <div className="flex flex-col gap-2 mt-5">
        <Paragraph size="100">This can be due to one of the following reasons:</Paragraph>
        <Paragraph size="100">
          &bull; You have not ordered anything on S2S yet. The stocktake list is created based on
          previous orders and stocktake reports on Source2Sea.
        </Paragraph>
        <Paragraph size="100">
          &bull; You have reported that all your items have been consumed.
        </Paragraph>
      </div>

      <Paragraph size="100" className="mt-5">
        You can still add a custom product to this stocktake report by clicking the
        &quot;Create&nbsp;Item&quot; button above.
      </Paragraph>
    </div>
  );
};
