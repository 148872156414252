import Dexie from "dexie";

import { LocalLiteOrderRequisition } from "src/models";

import { StoreNames, getStoreName } from "../config";

export class LocalOrdersDatabase extends Dexie {
  orders: Dexie.Table<LocalLiteOrderRequisition, string>;

  constructor() {
    super(getStoreName(StoreNames.ORDERS));
    this.version(0.1).stores({
      orders: ",vessel.id",
    });
    this.version(0.2)
      .stores({
        orders: ",vessel.id",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalLiteOrderRequisition>("orders")
          .toCollection()
          .modify((order) => {
            if (!order.orderType) {
              order.orderType = "DEFAULT";
            }
          });
      });
  }
}

export const ordersStore = new LocalOrdersDatabase().orders;
