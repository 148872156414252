import { Toast } from "@web/ui";

import { useAppStateContext } from "../AppStateContext";

interface Props {
  children: React.ReactNode;
}

const SystemMessagesProvider = ({ children }: Props) => {
  const [{ systemMessages }, dispatch] = useAppStateContext();
  const currentMessage = systemMessages[0];

  const clearMessage = (id: string) => dispatch({ type: "clearSystemMessage", value: { id } });

  return (
    <>
      {children}
      {currentMessage && <Toast toastMessage={currentMessage} onDismissToast={clearMessage} />}
    </>
  );
};

export default SystemMessagesProvider;
