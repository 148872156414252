import React, { useEffect, useRef } from "react";

type Props = {
  children: React.ReactNode;
};

/**
 * Usage: wrap image with `LazyImageWrapper` and use `data-src` instead of `src`
 * (`src` attribute will be set by this component when img became visible):
 * <LazyImageWrapper>
 *   <img data-src={imgSrc} />
 * </LazyImageWrapper>
 */

export const LazyImageWrapper = ({ children }: Props) => {
  const intersectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observerCallback: IntersectionObserverCallback = ([entry], observer) => {
      const img = entry.target;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (img as HTMLImageElement).src = (img as HTMLImageElement).dataset.src!; // Set the real image URL from data-src
      observer.unobserve(img); // Stop observing the image once it has been loaded
    };

    if (intersectionRef.current) {
      const localRef = intersectionRef.current;
      const observer = new IntersectionObserver(observerCallback);

      const image = localRef.querySelectorAll("img")[0];

      observer.observe(image);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return <div ref={intersectionRef}>{children}</div>;
};
