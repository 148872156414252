import { z } from "zod";

export const REQUIRED_MESSAGE = "Required";

type NonEmptyStringArgs = {
  requiredMessage?: string;
  minLength?: number;
};
export const nonEmptyString = (args: NonEmptyStringArgs = {}) => {
  const { requiredMessage, minLength = 1 } = args;
  return z
    .string()
    .trim()
    .min(minLength, { ...(requiredMessage ? { message: requiredMessage } : {}) });
};
export const optionalNonEmptyString = (args: NonEmptyStringArgs = {}) =>
  z
    .union([nonEmptyString(args), z.string().length(0)])
    .optional()
    .transform((e) => (e === "" ? undefined : e));
