import ExclamationCircleIcon from "@heroicons/react/outline/ExclamationCircleIcon";
import classnames from "classnames";
import { useCallback, useState } from "react";

import { Heading, Modal } from "@web/ui";

import { AttentionInfoItems } from "src/state/models";

import { AttentionInfoItem } from "./AttentionInfoItem";
import { AttentionInfoModal } from "./AttentionInfoModal";

type Props = {
  items: AttentionInfoItems;
  className?: string;
};

export const AttentionInfo = ({ items, className = "", ...rest }: Props) => {
  const [isAttentionInfoModalOpen, setIsAttentionInfoModalOpen] = useState(false);

  const handleAttentionInfoItemClick = useCallback(() => setIsAttentionInfoModalOpen(true), []);

  const handleCloseModal = useCallback(() => setIsAttentionInfoModalOpen(false), []);

  return (
    <div className={classnames("flex flex-col gap-2", className)} {...rest}>
      <div className="flex items-center gap-2">
        <div className="p-0.5 rounded-full bg-warningBackground border border-neutral_300">
          <ExclamationCircleIcon className="w-4.5 text-warningDefault" aria-hidden="true" />
        </div>
        <Heading size="400">Attention Info</Heading>
      </div>
      {items.map((item) => (
        <AttentionInfoItem
          key={item.type}
          type={item.type}
          onClick={handleAttentionInfoItemClick}
        />
      ))}
      <Modal isOpen={isAttentionInfoModalOpen} closeModal={handleCloseModal}>
        <AttentionInfoModal items={items} onClose={handleCloseModal} />
      </Modal>
    </div>
  );
};
