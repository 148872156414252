import { LiteBasketControllerService, LiteShareBasketRequest } from "../typegens";

export const exportBasketToExcel = async (requestBody: LiteShareBasketRequest): Promise<Blob> => {
  const result = await LiteBasketControllerService.exportBasketContentToExcel(
    {
      requestBody,
    },
    {
      headers: {
        Accept: "*/*",
      },
    }
  );

  return result;
};
