import { v4 as uuidv4 } from "uuid";

import { StorageError, StorageRequest } from "@web/models";

import { LocalOrderDraft, LocalPartialOrderDraft } from "src/models";
import { orderDraftsStore } from "src/objectStorage";

export const OrderDraftsService = {
  createDraft: async (draftData: LocalPartialOrderDraft): Promise<LocalOrderDraft> => {
    const request: StorageRequest = {
      body: draftData,
      access: "write",
    };

    if (!draftData) {
      throw new StorageError(request, { status: 400 }, "No draft data");
    }

    const draftToCreate = {
      ...draftData,
      id: uuidv4(),
      type: "DRAFT" as LocalOrderDraft["type"],
      history: [],
      changes: [],
      createdByExternalSystem: false,
      comments: [],
      priceModifiers: [],
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    try {
      await orderDraftsStore.add(draftToCreate);
    } catch (error) {
      throw new StorageError(request, { status: 500, body: error }, "Error creating draft");
    }

    return draftToCreate;
  },

  updateDraft: async ({
    draftId,
    draftData,
  }: {
    draftId: string;
    draftData: LocalPartialOrderDraft;
  }): Promise<LocalOrderDraft> => {
    const request: StorageRequest = {
      body: { draftId, draftData },
      access: "write",
    };

    if (!draftId || !draftData) {
      throw new StorageError(request, { status: 400 }, "No draft id or draft data");
    }

    let draftToUpdate: LocalOrderDraft | undefined;
    try {
      draftToUpdate = await orderDraftsStore.get(draftId);
    } catch (error) {
      throw new StorageError(request, { status: 500, body: error }, "Error getting draft");
    }

    if (!draftToUpdate) {
      throw new StorageError(
        request,
        { status: 404 },
        `Cannot update - no draft with the following ID exists: ${draftId}`
      );
    }

    const updatedDraft = {
      ...draftToUpdate,
      ...draftData,
      id: draftId,
      updatedAt: new Date().toISOString(),
    };

    try {
      await orderDraftsStore.put(updatedDraft);
    } catch (error) {
      throw new StorageError(request, { status: 500, body: error }, "Error updating draft");
    }

    return updatedDraft;
  },

  deleteDraft: async (draftId: string): Promise<void> => {
    const request: StorageRequest = {
      body: draftId,
      access: "write",
    };

    if (!draftId) {
      throw new StorageError(request, { status: 400 }, "No draft id");
    }

    try {
      await orderDraftsStore.delete(draftId);
    } catch (error) {
      throw new StorageError(request, { status: 500, body: error }, "Error deleting draft");
    }
  },

  getDrafts: async (vesselId: string): Promise<LocalOrderDraft[]> => {
    const request: StorageRequest = {
      body: vesselId,
      access: "read",
    };

    if (!vesselId) {
      throw new StorageError(request, { status: 400 }, "No vessel id");
    }
    let drafts: LocalOrderDraft[];

    try {
      drafts = await orderDraftsStore.where("vessel.id").equals(vesselId).toArray();
    } catch (error) {
      throw new StorageError(request, { status: 500, body: error }, "Error getting drafts");
    }
    return drafts;
  },
};
