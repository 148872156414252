import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";

export const useErrorBoundaryPreference = () => {
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { becameOnline, isOnline } = useNetworkDetector();
  // When being offline or reconnecting, we want to show `NoCachedData` component instead of `ErrorBoundary`
  const shouldUseErrorBoundary = !(areOfflineCapabilitiesEnabled && (!isOnline || becameOnline));

  return {
    shouldUseErrorBoundary,
  };
};
