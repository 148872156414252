import { BasketEntry } from "@web/common";

import { BasketState, BasketStateAction } from "./models";

export const removeDuplicates = (fromList: Array<BasketEntry>, sourceList: Array<BasketEntry>) => {
  const idsSet = new Set(sourceList.map((s) => s.sku.id));

  return fromList.filter(({ sku }) => !idsSet.has(sku.id));
};

export const basketStateReducer = (state: BasketState, action: BasketStateAction): BasketState => {
  switch (action.type) {
    case "setBasketState": {
      return {
        ...action.value,
      };
    }
    case "pushSkuToBasket": {
      const existingIndex = state.lineItems.findIndex(
        (lineItem) => lineItem.sku?.id === action.value.sku?.id
      );
      if (existingIndex == -1) {
        return { ...state, lastUpdated: Date.now(), lineItems: [...state.lineItems, action.value] };
      }
      return {
        ...state,
        lastUpdated: Date.now(),
        lineItems: [
          ...state.lineItems.slice(0, existingIndex),
          action.value,
          ...state.lineItems.slice(existingIndex + 1),
        ],
      };
    }

    case "addMultipleSkusToBasket": {
      const list = action.value;
      const { lineItems } = state;

      return { ...state, lineItems: [...removeDuplicates(lineItems, list), ...list] };
    }

    case "removeSkuFromBasket": {
      const existingIndex = state.lineItems.findIndex((lineItem) => lineItem.sku === action.value);
      if (existingIndex == -1) {
        return state;
      }
      return {
        ...state,
        lastUpdated: Date.now(),
        lineItems: [
          ...state.lineItems.slice(0, existingIndex),
          ...state.lineItems.slice(existingIndex + 1),
        ],
      };
    }

    case "removeMultipleEntriesFromBasket": {
      return {
        ...state,
        lineItems: state.lineItems.filter((item) => !action.value.has(item.sku.id)),
      };
    }

    case "clearBasket": {
      return {
        ...state,
        basketFormAnswers: undefined,
        draft: undefined,
        lastUpdated: undefined,
        lineItems: [],
        rfqItems: {},
      };
    }
    case "addRfqToBasket": {
      return {
        ...state,
        rfqItems: {
          ...state.rfqItems,
          [action.value.id]: action.value,
        },
      };
    }
    case "removeRfqFromBasket": {
      const { [action.value]: remove, ...items } = state.rfqItems;
      return {
        ...state,
        lastUpdated: Date.now(),
        rfqItems: {
          ...items,
        },
      };
    }
    case "updateRfqInBasket": {
      return {
        ...state,
        lastUpdated: Date.now(),
        rfqItems: {
          ...state.rfqItems,
          [action.value.id]: action.value,
        },
      };
    }

    case "addMultipleRfqsToBasket": {
      return {
        ...state,
        rfqItems: {
          ...state.rfqItems,
          ...action.value.reduce((acc, curr) => {
            return { ...acc, [curr.id]: curr };
          }, {}),
        },
      };
    }

    case "setDraft": {
      return {
        ...state,
        draft: { ...action.value },
      };
    }

    // TODO #5641: Remove this case and fall back to `setDraft` everywhere when removing Online Draft logic
    case "setNewDraft": {
      if (
        action.value.status === "CREATION_STARTED" ||
        state.draft?.status === "CREATION_STARTED"
      ) {
        return {
          ...state,
          draft: { ...action.value },
        };
      }
      return state;
    }

    case "setBasketFormAnswers": {
      return {
        ...state,
        basketFormAnswers: action.value,
      };
    }
    default:
      return state;
  }
};
