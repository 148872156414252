export function onUpdate(registration) {
  const waitingServiceWorker = registration.waiting;

  if (waitingServiceWorker) {
    console.info("There is waiting service worker");
    waitingServiceWorker.addEventListener("statechange", (event) => {
      if (event.target.state === "activated") {
        window.location.reload();
      }
    });
    waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
  }
}
