import { useCallback } from "react";

import { useNetworkToggleContext } from "./NetworkToggleContext";
import { UseNetworkToggle } from "./models";

export const useNetworkToggle = (): UseNetworkToggle => {
  const [networkToggleMode, dispatch] = useNetworkToggleContext();

  const isToggleOnline = networkToggleMode === "online";
  const isToggleOffline = networkToggleMode === "offline";

  const setToggleOnline = useCallback(() => {
    dispatch({
      type: "setOnline",
    });
  }, [dispatch]);

  const setToggleOffline = useCallback(() => {
    dispatch({
      type: "setOffline",
    });
  }, [dispatch]);

  return {
    networkToggleMode,
    isToggleOnline,
    isToggleOffline,
    setToggleOnline,
    setToggleOffline,
  };
};
