import { z } from "zod";

import { preprocessFormAmountValue } from "@web/utils";

import { LiteStocktakeItemSchema } from "./LiteStocktakeItemSchema";

export const StocktakeReportItemFormSchema = LiteStocktakeItemSchema.extend({
  // We want to have quantity optional because we are filling empty values with zeros *after* form validation happens,
  // but we still want to validate that the input is nonnegative.
  quantity: z.preprocess(
    preprocessFormAmountValue,
    z.number().nonnegative({ message: "Quantity must be 0 or greater" }).optional()
  ),
  lineNumber: z.number().positive(),
});

export type LocalStocktakeReportItemForm = z.infer<typeof StocktakeReportItemFormSchema>;
