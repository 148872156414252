import { OrdersService } from "src/typegens";

export const exportOrderToMtml = ({ orderId }: { orderId: string }): Promise<Blob> =>
  OrdersService.getPurchaseOrderAsMtml(
    {
      orderId,
    },
    {
      headers: {
        Accept: "*/*",
      },
    }
  );
