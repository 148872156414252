import { s2sDatabase } from "src/objectStorage";

export const OfflineCatalogService = {
  getOfflineCatalogListMetaData: async (): Promise<Map<string, { updatedAt: string }>> => {
    const offllineCatalogMetaData = new Map<string, { updatedAt: string }>();
    try {
      const availableCatalogData = await s2sDatabase.categoriesByPortId.toArray();

      for (const catalog of availableCatalogData) {
        offllineCatalogMetaData.set(catalog.portId, { updatedAt: catalog.updatedAt });
      }

      return offllineCatalogMetaData;
    } catch (err) {
      return offllineCatalogMetaData;
    }
  },
};
