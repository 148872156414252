import { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Heading, Paragraph } from "@web/ui";
import { formatMoney, formatNumber } from "@web/utils";

import { LocalStocktakeReportForm } from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

type Props = {
  defaultValues: LocalStocktakeReportForm;
  currencyCode: string;
};

export const StocktakeTotals = ({ defaultValues, currencyCode }: Props) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<LocalStocktakeReportForm>();

  const items = useWatch({ control, name: "items", defaultValue: defaultValues.items });
  const oldExtraItems = useWatch({
    control,
    name: "oldExtraItems",
    defaultValue: defaultValues.oldExtraItems,
  });
  const newExtraItems = useWatch({
    control,
    name: "newExtraItems",
    defaultValue: defaultValues.newExtraItems,
  });
  const totalItemsCount = useMemo(
    () => items.length + oldExtraItems.length + newExtraItems.length,
    [items.length, newExtraItems.length, oldExtraItems.length]
  );

  const totalRobValue = useMemo(
    () => ({
      currencyCode,
      amount: LocalStocktakeService.getTotalRobValueAmount({ items, oldExtraItems, newExtraItems }),
    }),
    [currencyCode, items, newExtraItems, oldExtraItems]
  );

  // Update the totals on the form
  useEffect(() => {
    setValue("robValue.amount", totalRobValue.amount);
  }, [setValue, totalRobValue.amount]);

  useEffect(() => {
    setValue("inventoryCount", totalItemsCount);
  }, [setValue, totalItemsCount]);

  return (
    <>
      <div className="flex gap-4">
        <Heading size="200" color="text-textIcon-blackSecondary">
          Total:
        </Heading>
        <Heading size="200">{formatMoney(totalRobValue)}</Heading>
      </div>
      <Paragraph size="100" weight="heavy" color="text-textIcon-blackSecondary">
        {t("stocktaking.nrItems", {
          count: totalItemsCount,
          formattedCount: formatNumber(totalItemsCount),
        })}
      </Paragraph>
    </>
  );
};
