import _debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

type UseWindowDimensions = {
  width: number;
  height: number;
};

const RESIZE_DEBOUNCE_TIME = 100;

export const useWindowDimensions = (): UseWindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResizeDebounced = useCallback(
    _debounce(() => {
      setWindowDimensions(getWindowDimensions());
    }, RESIZE_DEBOUNCE_TIME),
    []
  );

  useEffect(() => {
    window.addEventListener("resize", handleResizeDebounced);
    return () => window.removeEventListener("resize", handleResizeDebounced);
  }, [handleResizeDebounced]);

  return windowDimensions;
};
