import { useCallback, useMemo } from "react";

import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import AppState from "src/state/models";

type UseAppArea = {
  overviewPath: string;
  setAppArea: (appArea: AppState["appArea"]) => void;
};

export const useAppArea = (): UseAppArea => {
  const [{ appArea, configuration }, dispatch] = useAppStateContext();
  const isStocktakingEnabled = !!configuration?.allow.stocktaking;

  const setAppArea = useCallback(
    (appAreaToSet: AppState["appArea"]) => {
      dispatch({ type: "setAppArea", value: appAreaToSet });
    },
    [dispatch]
  );

  const overviewPath = useMemo(() => {
    if (!isStocktakingEnabled) {
      return RoutesConfig.order.overview;
    }

    switch (appArea) {
      case "STOCKTAKING":
        return RoutesConfig.stocktake.overview;
      case "SHOPPING":
      default:
        return RoutesConfig.order.overview;
    }
  }, [appArea, isStocktakingEnabled]);

  return {
    setAppArea,
    overviewPath,
  };
};
