import { AnnotationIcon, TrashIcon, UserIcon } from "@heroicons/react/outline";
import { DownloadIcon } from "@heroicons/react/solid";
import { useFlag } from "@unleash/proxy-client-react";
import classnames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { DeleteStocktakeReportModal } from "@web/common";
import {
  ActionBar,
  ActionBarBackButtonSettings,
  ActionBarProps,
  Heading,
  Label,
  Modal,
  Paragraph,
  RegularDropdownItem,
  containerPadding,
} from "@web/ui";
import { formatMoney, formatNumber, useWindowDimensions } from "@web/utils";

import { CategorizedProductsList } from "src/components/CategorizedProductsList";
import { ChunkedItemsList } from "src/components/ChunkedItemsList";
import { StocktakeDetailsCatalogProductTile } from "src/components/StocktakeDetailsCatalogProductTile";
import { StocktakeDetailsExtraProductTile } from "src/components/StocktakeDetailsExtraProductTile";
import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";
import {
  useDeleteStocktakeReportMutation,
  useExportStocktakeReportToExcelMutation,
} from "src/hooks/stocktakes";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { LocalLiteStocktakeExtraItem, LocalLiteStocktakeItem, UiStocktakeReport } from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

const XL_BREAKPOINT = 1280;
// Remember to adjust `ITEMS_GAP` when changing the product tiles spacing.
const ITEMS_GAP = 8;
const CHUNK_SIZE = 10;

type Props = {
  stocktakeReport: UiStocktakeReport;
  vesselId: string | undefined;
  canRenderStocktakeType: boolean;
};

export const StocktakeDetails = ({ stocktakeReport, canRenderStocktakeType, vesselId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();
  const isDeleteStocktakeReportEnabled = useFlag("delete-stocktake-report");

  const nonEmptyCategories = useMemo(
    () => LocalStocktakeService.getNonEmptyCategories(stocktakeReport.categories),
    [stocktakeReport.categories]
  );

  const chunkedCatalogItemsGroupedByCategoryId = useMemo(
    () =>
      LocalStocktakeService.getChunkedItemsGroupedByCategoryId(stocktakeReport.items, CHUNK_SIZE),
    [stocktakeReport.items]
  );

  const chunkedExtraItems = useMemo(
    () => LocalStocktakeService.getChunkedCollection(stocktakeReport.extraItems, CHUNK_SIZE),
    [stocktakeReport.extraItems]
  );

  const StocktakeIcon = LocalStocktakeService.getStocktakeTypeIcon(stocktakeReport.type);
  const stocktakeTypeLabel = LocalStocktakeService.getStocktakeTypeLabel(stocktakeReport.type);

  // Deleting report
  // ---------------------------------------------------------------------------

  const [isDeleteStocktakeReportModalOpen, setIsDeleteStocktakeReportModalOpen] =
    useState<boolean>(false);

  const openDeleteStocktakeReportModal = useCallback(() => {
    setIsDeleteStocktakeReportModalOpen(true);
  }, []);
  const closeDeleteStocktakeReportModal = useCallback(() => {
    setIsDeleteStocktakeReportModalOpen(false);
  }, []);

  const { mutate: deleteStocktakeReport, isPending: isDeletingStocktakeReport } =
    useDeleteStocktakeReportMutation({
      hasSuccessMessage: true,
      hasErrorMessage: true,
      onSuccess: () => {
        closeDeleteStocktakeReportModal();
        navigate(RoutesConfig.stocktake.overview);
      },
      vesselId: vesselId || "",
      stocktakeId: stocktakeReport.id,
    });

  const onDeleteStocktakeReportConfirm = useCallback(() => {
    if (isDeletingStocktakeReport) {
      return;
    }

    allowOnlineOnly(() => {
      deleteStocktakeReport();
    });
  }, [allowOnlineOnly, deleteStocktakeReport, isDeletingStocktakeReport]);

  const handleDeleteStocktakeReport = useCallback(() => {
    allowOnlineOnly(() => {
      openDeleteStocktakeReportModal();
    });
  }, [allowOnlineOnly, openDeleteStocktakeReportModal]);

  // Stock count list Excel download
  // ---------------------------------------------------------------------------

  const { mutate: downloadExcel, isPending: isExcelDownloadPending } =
    useExportStocktakeReportToExcelMutation({
      fileDownloadNameWithoutExtension: LocalStocktakeService.formatExportFileName(
        "S2S_SR",
        canRenderStocktakeType,
        stocktakeReport.type,
        stocktakeReport.stocktakeDate
      ),
      hasSuccessMessage: true,
      hasErrorMessage: true,
    });

  // ActionBar settings
  // ---------------------------------------------------------------------------

  const headerBackButton = useMemo(
    (): ActionBarBackButtonSettings => ({
      title: "Back to stocktake overview",
      onClick: () => {
        navigate(RoutesConfig.stocktake.overview);
      },
    }),
    [navigate]
  );

  const headerDropdownSettings = useMemo(
    (): ActionBarProps["dropdownSettings"] => ({
      items: isDeleteStocktakeReportEnabled
        ? [
            {
              key: "deleteReport",
              renderComponent: () => (
                <RegularDropdownItem
                  label="Delete report"
                  variant="danger"
                  LeadingIcon={TrashIcon}
                  onClick={() => {
                    handleDeleteStocktakeReport();
                  }}
                />
              ),
            },
          ]
        : [],
    }),
    [handleDeleteStocktakeReport, isDeleteStocktakeReportEnabled]
  );

  const headerHelperButtons = useMemo(
    () => [
      {
        title: "Export Report",
        isLoading: isExcelDownloadPending,
        LeadingIcon: DownloadIcon,
        onClick: () => {
          allowOnlineOnly(() => {
            downloadExcel(stocktakeReport.id);
          });
        },
      },
    ],
    [allowOnlineOnly, downloadExcel, isExcelDownloadPending, stocktakeReport.id]
  );

  // ---------------------------------------------------------------------------

  const { width } = useWindowDimensions();
  // Remember to adjust `estimatedItemHeight` when changing the product tiles size.
  // `ChunkedItemsList` does not support window resizing, so the window needs to be refreshed to apply the new values after resizing.
  const estimatedCatalogItemHeight = width < XL_BREAKPOINT ? 160 : 82;
  const estimatedExtraItemHeight = width < XL_BREAKPOINT ? 120 : 79;

  // ---------------------------------------------------------------------------

  const renderExtraItem = useCallback(
    (item: LocalLiteStocktakeExtraItem & { index: number }) => (
      <StocktakeDetailsExtraProductTile key={item.id} product={item} />
    ),
    []
  );

  const renderCatalogItem = useCallback(
    (item: LocalLiteStocktakeItem & { index: number }) => (
      <StocktakeDetailsCatalogProductTile key={item.id} product={item} />
    ),
    []
  );

  const renderCategoryItems = useCallback(
    (categoryId: string) => (
      <ChunkedItemsList
        className="flex flex-col gap-2"
        chunkWrapperClassName="flex flex-col gap-2"
        chunkedItems={chunkedCatalogItemsGroupedByCategoryId[categoryId] || []}
        estimatedItemHeightInPx={estimatedCatalogItemHeight}
        itemsGapInPx={ITEMS_GAP}
        renderChunkItem={renderCatalogItem}
      />
    ),
    [chunkedCatalogItemsGroupedByCategoryId, estimatedCatalogItemHeight, renderCatalogItem]
  );

  // ---------------------------------------------------------------------------

  // Postpone rendering until width is set to avoid costly rerenders
  if (!width) {
    return null;
  }

  return (
    <div className="bg-neutral_100 flex flex-col min-h-full">
      <AllowOnlineOnlyWarningModal />
      <Modal isOpen={isDeleteStocktakeReportModalOpen} closeModal={closeDeleteStocktakeReportModal}>
        <DeleteStocktakeReportModal
          closeModal={closeDeleteStocktakeReportModal}
          isLoading={isDeletingStocktakeReport}
          onConfirm={onDeleteStocktakeReportConfirm}
        />
      </Modal>
      <TopBarController />
      <ActionBar
        backButtonSettings={headerBackButton}
        helperButtons={headerHelperButtons}
        dropdownSettings={headerDropdownSettings}
      />
      <div className={classnames(containerPadding, "my-6")}>
        <Heading size="100">Stocktake Report</Heading>
        <Heading size="400" className="mt-2">
          {LocalStocktakeService.formatStocktakeDateFromIsoString(
            stocktakeReport.stocktakeDate,
            "justDate"
          )}
        </Heading>
        <div className="grid grid-cols-3 gap-5 mt-4">
          <div className="col-span-2">
            <hr />
            <div className="flex justify-between items-center my-5">
              <div className="w-1/3 flex items-center">
                <Heading size="200">
                  {t("stocktaking.nrItems", {
                    count: stocktakeReport.inventoryCount,
                    formattedCount: formatNumber(stocktakeReport.inventoryCount),
                  })}
                </Heading>
              </div>
              <div className="w-2/3 flex justify-end items-baseline gap-2">
                <Label size="200" color="text-textIcon-blackSecondary" className="text-right">
                  Estimated ROB value:
                </Label>
                <Heading size="200" className="text-right">
                  {formatMoney(stocktakeReport.robValue)}
                </Heading>
              </div>
            </div>
            <hr />
            <div className="flex flex-col gap-2 mt-6">
              <CategorizedProductsList
                categories={nonEmptyCategories}
                activeCategoryId={undefined}
                renderCategoryItems={renderCategoryItems}
              />
              {chunkedExtraItems.length > 0 && (
                <div className="flex flex-col gap-2">
                  <Heading size="200">Created Items</Heading>
                  <ChunkedItemsList
                    className="flex flex-col gap-2"
                    chunkWrapperClassName="flex flex-col gap-2"
                    chunkedItems={chunkedExtraItems}
                    estimatedItemHeightInPx={estimatedExtraItemHeight}
                    itemsGapInPx={ITEMS_GAP}
                    renderChunkItem={renderExtraItem}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="bg-neutral_0 border rounded-lg p-5">
              <Heading size="300">Stocktake Information</Heading>
              <div className="flex flex-col gap-4 mt-5">
                {canRenderStocktakeType && (
                  <div className="flex flex-col gap-2">
                    <Paragraph
                      size="300"
                      color="text-textIcon-blackSecondary"
                      className="uppercase"
                    >
                      Type
                    </Paragraph>
                    <div className="flex gap-2 items-center">
                      <StocktakeIcon className="w-[20px] h-[20px] text-textIcon-blackSecondary" />
                      <Paragraph size="200">{stocktakeTypeLabel}</Paragraph>
                    </div>
                  </div>
                )}

                <div className="flex flex-col gap-2">
                  <Paragraph size="300" color="text-textIcon-blackSecondary" className="uppercase">
                    Stocktake Conducted By
                  </Paragraph>
                  <div className="flex gap-2 items-center">
                    <UserIcon className="w-[20px] h-[20px] text-textIcon-blackSecondary" />
                    <Paragraph size="200">
                      {stocktakeReport.stocktakerInformation.position}:{" "}
                      {stocktakeReport.stocktakerInformation.name}
                    </Paragraph>
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <Paragraph size="300" color="text-textIcon-blackSecondary" className="uppercase">
                    Subject
                  </Paragraph>
                  <div className="flex gap-2 items-center">
                    <AnnotationIcon className="w-[20px] h-[20px] text-textIcon-blackSecondary" />
                    <Paragraph size="200">
                      {stocktakeReport.subject ? stocktakeReport.subject : "-"}
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
