import { useOfflineCapabilities } from "../OfflineCapabilities";
import { NetworkToggleContext } from "./NetworkToggleContext";
import { NetworkToggleLifecycle } from "./NetworkToggleLifecycle";
import { useNetworkToggleReducer } from "./reducer";

interface Props {
  children: React.ReactNode;
}

// TODO #5310: Add tests for the functionality
export const NetworkToggleProvider = ({ children }: Props) => {
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const [networkToggleMode, dispatch] = useNetworkToggleReducer();

  return (
    <NetworkToggleContext.Provider value={[networkToggleMode, dispatch]}>
      {areOfflineCapabilitiesEnabled && <NetworkToggleLifecycle />}
      {children}
    </NetworkToggleContext.Provider>
  );
};
