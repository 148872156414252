import { InformationCircleIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";

import { Heading, Paragraph, RegularButton } from "@web/ui";

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  isConfirmLoading: boolean;
  isManualNetworkStateEnabled: boolean;
}

export const NoNetworkConnectionModal = ({
  onClose,
  onConfirm,
  isConfirmLoading,
  isManualNetworkStateEnabled,
}: Props) => {
  return (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg p-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="flex items-center justify-between gap-2">
        <div className="flex-none flex items-center justify-center p-1 rounded-full border bg-primaryBackground">
          <InformationCircleIcon className="w-5 text-primaryDefault" aria-hidden="true" />
        </div>
        <Heading size="200" className="flex-1">
          You are currently offline
        </Heading>
        <button
          type="button"
          className="flex-none self-start bg-neutral_0 rounded-md text-textIcon-blackSecondary hover:text-textIcon-blackPrimary"
          onClick={onClose}
        >
          <span className="sr-only">Close modal</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-5">
        <Paragraph size="100" color="text-textIcon-blackSecondary">
          {isManualNetworkStateEnabled
            ? "Switch to online mode to access this feature and try again."
            : "You will be able to use this feature when you are online again."}
        </Paragraph>
      </div>
      <div className="mt-5 flex flex-row-reverse">
        <RegularButton
          variant="primary"
          size="large"
          className="ml-2"
          label={isManualNetworkStateEnabled ? "Switch To Online" : "Understood"}
          loading={isManualNetworkStateEnabled ? isConfirmLoading : false}
          onClick={isManualNetworkStateEnabled ? onConfirm : onClose}
        />
        {isManualNetworkStateEnabled && (
          <RegularButton variant="secondary" size="large" label="Close" onClick={onClose} />
        )}
      </div>
    </div>
  );
};
