import { Dispatch, createContext, useContext } from "react";

import { NetworkDetectorState, NetworkDetectorStateAction } from "./models";
import { defaultNetworkState } from "./reducer";

export const NetworkDetectorContext = createContext<
  [state: NetworkDetectorState, action: Dispatch<NetworkDetectorStateAction>]
>([
  defaultNetworkState,
  () => {
    return;
  },
]);

export const useNetworkDetectorContext = () => useContext(NetworkDetectorContext);
