import { useQuery, useQueryClient } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useCallback, useMemo } from "react";

import { addToQueryCache, removeFromQueryCacheByKey, updateInQueryCacheByKey } from "@web/utils";

import { LocalStocktakeReportDraft } from "src/models";
import { StocktakeDraftsService } from "src/services/StocktakeDraftsService";

export const STOCKTAKE_DRAFTS_LIST_QUERY_KEY_BASE = "stocktakeDraftsList";

const getQueryKey = (vesselId: string) => [STOCKTAKE_DRAFTS_LIST_QUERY_KEY_BASE, vesselId];

type UseStocktakeDraftsListQuery = UseQueryResult<LocalStocktakeReportDraft[]>;

export const useStocktakeDraftsListQuery = ({
  vesselId,
}: {
  vesselId: string;
}): UseStocktakeDraftsListQuery => {
  const queryKey = useMemo(() => getQueryKey(vesselId), [vesselId]);
  return useQuery<LocalStocktakeReportDraft[]>({
    queryKey,
    queryFn: () => StocktakeDraftsService.getDrafts(vesselId),
    networkMode: "always",
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    gcTime: 0,
    refetchOnReconnect: false,
    enabled: !!vesselId,
  });
};

type UseStocktakeDraftsListQueryHelpers = {
  invalidate: () => void;
  addListItemToQuery: (item: LocalStocktakeReportDraft) => void;
  removeItemFromQuery: (itemId: string) => void;
  updateItemInQuery: (item: LocalStocktakeReportDraft) => void;
};

export const useStocktakeDraftsListQueryHelpers = ({
  vesselId,
}: {
  vesselId: string;
}): UseStocktakeDraftsListQueryHelpers => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => getQueryKey(vesselId), [vesselId]);

  const removeItemFromQuery = useCallback(
    (itemToRemoveId: string) => {
      queryClient.setQueryData(queryKey, removeFromQueryCacheByKey({ id: itemToRemoveId }, "id"));
    },
    [queryClient, queryKey]
  );

  const updateItemInQuery = useCallback(
    (itemToUpdate: LocalStocktakeReportDraft) => {
      queryClient.setQueryData(queryKey, updateInQueryCacheByKey(itemToUpdate, "id"));
    },
    [queryClient, queryKey]
  );

  const addListItemToQuery = useCallback(
    (itemToAdd: LocalStocktakeReportDraft) => {
      queryClient.setQueryData(queryKey, addToQueryCache(itemToAdd));
    },
    [queryClient, queryKey]
  );

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
    [queryClient, queryKey]
  );

  return {
    invalidate,
    addListItemToQuery,
    removeItemFromQuery,
    updateItemInQuery,
  };
};
