import { z } from "zod";

import { LocalStocktakeTypes } from "@web/common";

import { REQUIRED_MESSAGE, nonEmptyString, optionalNonEmptyString } from "../common";

export const commonValidStocktakeReportRules = {
  type: z.enum(LocalStocktakeTypes),
  stocktakeDate: nonEmptyString({ requiredMessage: REQUIRED_MESSAGE }),
  stocktakerInformation: {
    name: nonEmptyString({ requiredMessage: REQUIRED_MESSAGE }),
    position: nonEmptyString({ requiredMessage: REQUIRED_MESSAGE }),
  },
  subject: optionalNonEmptyString(),
};
