import { DEFAULT_CURRENCY_CODE, getUnchangedOrderItems } from "@web/common";
import { Vessel } from "@web/common/models/Vessel";
import { ChangeType, OrderRequisition, ProductChange } from "@web/common/network/model";

import { LocalOrderDraft } from "src/models";
import { LiteOrderRequisition, LiteProductChange, LiteVessel } from "src/typegens";

const toVessel = (vessel: LiteVessel): Vessel => ({
  ...vessel,
  supplierCustomerId: "",
  imoNumber: "",
});

export const toProductChange = (item: LiteProductChange): ProductChange => ({
  ...item,
  totalAmount: {
    ...item.singleGrossPrice,
    amount: item.singleGrossPrice.amount * item.entityQuantity,
  },
  ...(item.oldSingleGrossPrice &&
    item.oldEntityQuantity && {
      oldTotalAmount: {
        ...item.oldSingleGrossPrice,
        amount: item.oldSingleGrossPrice.amount * item.oldEntityQuantity,
      },
    }),
});

const hasStatus = (item: LiteOrderRequisition | LocalOrderDraft): item is LiteOrderRequisition =>
  !!(item as LiteOrderRequisition).status;

const hasType = (item: LiteOrderRequisition | LocalOrderDraft): item is LiteOrderRequisition =>
  !!(item as LiteOrderRequisition).type;

export const LocalOrderReqService = {
  toVessel,
  toOrderRequisition: (order: LiteOrderRequisition | LocalOrderDraft): OrderRequisition => {
    let canCancel = false;
    let canClose = false;
    let canDownloadMTML = false;
    let canDownloadExcel = false;
    let canDeleteDraft = false;

    if (hasStatus(order)) {
      const { status } = order;

      canCancel =
        (status === "SUPPLIER_CONFIRMED" && !order.createdByExternalSystem) ||
        (status === "SUPPLIER_PENDING" && !order.createdByExternalSystem) ||
        (status === "SUPPLIER_ACKNOWLEDGED" && !order.createdByExternalSystem) ||
        (status === "PENDING_PURCHASER_APPROVAL" && !order.createdByExternalSystem);

      canClose =
        (status === "SUPPLIER_CONFIRMED" ||
          status === "CANCELLED" ||
          status === "SUPPLIER_DELIVERED") &&
        order.type === "ORDER" &&
        !order.closed;

      canDownloadMTML = status === "SUPPLIER_CONFIRMED" || status === "SUPPLIER_DELIVERED";
    }

    if (hasType(order)) {
      const { type } = order;

      canDownloadExcel = type !== "QUOTATION";
      canDeleteDraft = type === "DRAFT";
    }

    const changes = order.changes.map((change) => toProductChange(change));
    const changesOrder: ChangeType[] = ["ADDED", "REMOVED", "UPDATED", "REPLACED"];
    const items = order.catalogItems.map((item) => ({
      ...item,
      totalAmount: {
        ...item.singleGrossPrice,
        amount: item.singleGrossPrice.amount * item.entityQuantity,
      },
      supplierIdentifier: item.supplierItemId,
      sku: "",
      impaCode: "",
      variantId: item.variantId || "",
    }));

    return {
      ...order,
      status: hasStatus(order) ? order.status : "DRAFT_CREATED",
      vessel: toVessel(order.vessel),
      deliveryDate: order.deliveryDate ?? "",
      createdAt: new Date(order.createdAt),
      updatedAt: new Date(order.updatedAt),
      orderDate: order.orderDate ? new Date(order.orderDate) : undefined,
      requestDate: order.requestDate ? new Date(order.requestDate) : undefined,
      quotationDate: order.quotationDate ? new Date(order.quotationDate) : undefined,
      items,
      changes,
      unchangedItems: getUnchangedOrderItems(items, changes),
      changeTypeList: Array.from(new Set(changes.map((change) => change.changeType))).sort(
        (a, b) => changesOrder.indexOf(a) - changesOrder.indexOf(b)
      ),
      canCancel,
      canClose,
      canDownloadMTML: canDownloadMTML,
      canDownloadExcel: canDownloadExcel,
      canDeleteDraft: canDeleteDraft,
      isRfqRequisition: !!(
        order.type === "REQUISITION" &&
        order.rfqItems &&
        order.rfqItems.length > 0
      ),
      totalGrossAmount: order.totalGrossAmount || {
        amount: 0,
        currencyCode: DEFAULT_CURRENCY_CODE,
      },
    };
  },
};
