import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { usePrevious } from "@web/utils";

import { RoutesConfig } from "src/config/routes";
import { useAppArea } from "src/hooks/useAppArea";

type Props = {
  children: ReactNode;
};
export const AppAreaWrapper = ({ children }: Props) => {
  const location = useLocation();
  const { pathname } = location;
  const { setAppArea } = useAppArea();

  const prevPathname = usePrevious(pathname);

  useEffect(() => {
    if (pathname === prevPathname || pathname === RoutesConfig.mainPage) {
      return;
    }

    if (pathname.includes("stocktake")) {
      setAppArea("STOCKTAKING");
    } else {
      setAppArea("SHOPPING");
    }
  }, [pathname, prevPathname, setAppArea]);

  return <>{children}</>;
};
