import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { LineItemQuantity } from "@web/common/components/LineItemQuantity";
import { TotalUnitOfMeasure } from "@web/common/components/TotalUnitOfMeasure";
import VesselIcon from "@web/common/icons/VesselIcon.svg";
import { prependHttpToUrlIfMissing } from "@web/common/utils/prependHttpToUrlIfMissing";
import { CircledImage, Label, Paragraph, RegularButton, Tooltip } from "@web/ui";

import RequestProductInline from "src/components/RequestProductInline";
import useBasket from "src/hooks/useBasket";
import { LiteRfqItem } from "src/typegens";

interface Props {
  rfqItem: LiteRfqItem;
}

export const RfqItem: React.FC<Props> = ({ rfqItem }) => {
  const { exampleUrl, name, measurementUnit, id, description, quantity } = rfqItem;
  const { updateRfqInBasket, removeRfqFromBasket } = useBasket();
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();

  const updateRfq = (newItem: LiteRfqItem) => {
    if (newItem.quantity === 0) {
      removeRfqFromBasket(newItem.id);
      return;
    }

    updateRfqInBasket({
      ...rfqItem,
      ...newItem,
    });
  };

  if (isEdit) {
    return (
      <RequestProductInline
        rfqToEdit={rfqItem}
        closeEdit={() => setIsEdit(false)}
        saveEdit={(arg) => {
          updateRfq(arg);
          setIsEdit(false);
        }}
        basket
      />
    );
  }

  return (
    <div className="bg-neutral_0 rounded-lg px-4 py-2 mt-2 shadow-sm">
      <div className="flex flex-grow justify-between content-between items-top">
        <div className="col-span-5 pl-4 flex flex-col content-between">
          <div className="flex flex-col items-start">
            <Label size="200">{name}</Label>
            <Tooltip tooltipText={t("common.rfqTooltip")}>
              <div className="py-1 px-2 mt-1 rounded-md border border-neutral_300 flex">
                <QuestionMarkCircleIcon className="h-4 w-4 mr-1 text-text-whiteDisabled" />
                <Paragraph size="300" color="text-textIcon-blackSecondary">
                  RfQ Item
                </Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="pl-4 grid grid-flow-col-dense grid-cols-3">
          <div className="flex items-center">
            <LineItemQuantity
              initialQuantity={quantity}
              onQuantityChange={(newQuantity) => {
                updateRfq({ ...rfqItem, quantity: newQuantity });
              }}
              setIsErrorMessage={({ err }: { err: boolean }) => console.log(err)}
              min={1} // 1 if minimumOrderQuantity not set on API response
              customerOrderQuantity={quantity}
              shouldTrack
            />
          </div>
          <div className="flex flex-grow">
            <TotalUnitOfMeasure
              className="justify-center"
              quantityInBasket={quantity}
              salesEntityQuantity={1}
              measurementUnit={measurementUnit}
              variant="positive"
            />
          </div>
          <div className="text-right flex items-center justify-end h-full">
            <RegularButton
              variant="secondary"
              size="large"
              width="content"
              label="Edit"
              onClick={() => setIsEdit(true)}
            />
          </div>
        </div>
      </div>
      <div className="flex ml-4 p-2 bg-neutral_100 rounded-lg mt-4">
        <div className="w-min block">
          <CircledImage
            Icon={VesselIcon}
            size={5}
            hashKey={id}
            palette={["bg-LEGACY-cyanVessel"]}
          />
        </div>
        <div className="w-auto">
          <Paragraph size="300" color="text-textIcon-blackSecondary">
            {description}{" "}
            {!!exampleUrl && (
              <a
                className="text-primaryDefault"
                href={prependHttpToUrlIfMissing(exampleUrl)}
                target="_blank"
              >
                {exampleUrl}
              </a>
            )}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
