import { useQuery, useQueryClient } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useCallback, useMemo } from "react";

import { addToQueryCache, removeFromQueryCacheByKey, updateInQueryCacheByKey } from "@web/utils";

import { getStocktakeReportsList } from "src/api/stocktakes/getStocktakeReportsList";
import { LocalStocktakeReportDraft, UiStocktakeReportListItem } from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";
import { StocktakeDraftsService } from "src/services/StocktakeDraftsService";

export const STOCKTAKES_LIST_QUERY_KEY_BASE = "stocktakesList";

const getQueryKey = (vesselId: string) => [STOCKTAKES_LIST_QUERY_KEY_BASE, vesselId];

type UseStocktakesListQuery = UseQueryResult<UiStocktakeReportListItem[]>;

const useStocktakesListQuery = <T = UiStocktakeReportListItem[]>({
  vesselId,
  select,
}: {
  vesselId: string;
  select: (
    data: UiStocktakeReportListItem[]
  ) => T extends UiStocktakeReportListItem[] ? T : UiStocktakeReportListItem[];
}): UseStocktakesListQuery => {
  const queryKey = useMemo(() => getQueryKey(vesselId), [vesselId]);
  return useQuery<UiStocktakeReportListItem[]>({
    queryKey,
    queryFn: async ({ signal }) => {
      const stocktakeReportDraftsResponse: LocalStocktakeReportDraft[] =
        await StocktakeDraftsService.getDrafts(vesselId);
      const stocktakeReportDraftsList: UiStocktakeReportListItem[] =
        stocktakeReportDraftsResponse.map((draft) =>
          LocalStocktakeService.convertFromStoredDraftToUiStocktakeListItem(draft)
        );

      const stocktakeReportsResponse = await getStocktakeReportsList({ vesselId }, { signal });
      const stocktakeReportsList = stocktakeReportsResponse.stocktakeReports.map((report) =>
        LocalStocktakeService.convertFromApiToUiStocktakeListItem(report)
      );

      return [...stocktakeReportDraftsList, ...stocktakeReportsList];
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
    select,
    enabled: !!vesselId,
  });
};

type UseStocktakesListQueryHelpers = {
  invalidate: () => void;
  addListItemToQuery: (item: UiStocktakeReportListItem) => void;
  removeItemFromQuery: (itemId: string) => void;
  updateItemInQuery: (item: UiStocktakeReportListItem) => void;
};

export const useStocktakesListQueryHelpers = ({
  vesselId,
}: {
  vesselId: string;
}): UseStocktakesListQueryHelpers => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => getQueryKey(vesselId), [vesselId]);

  const removeItemFromQuery = useCallback(
    (itemToRemoveId: string) => {
      queryClient.setQueryData(queryKey, removeFromQueryCacheByKey({ id: itemToRemoveId }, "id"));
    },
    [queryClient, queryKey]
  );

  const updateItemInQuery = useCallback(
    (itemToUpdate: UiStocktakeReportListItem) => {
      queryClient.setQueryData(queryKey, updateInQueryCacheByKey(itemToUpdate, "id"));
    },
    [queryClient, queryKey]
  );

  const addListItemToQuery = useCallback(
    (itemToAdd: UiStocktakeReportListItem) => {
      queryClient.setQueryData(queryKey, addToQueryCache(itemToAdd));
    },
    [queryClient, queryKey]
  );

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
    [queryClient, queryKey]
  );

  return {
    invalidate,
    addListItemToQuery,
    removeItemFromQuery,
    updateItemInQuery,
  };
};

export type SortStocktakesListBy =
  | "STOCKTAKE_DATE"
  | "TYPE"
  | "INVENTORY_COUNT"
  | "STATUS"
  | "ROB_VALUE";
export type SortStocktakesListDir = "ASC" | "DESC";

export const useSortedStocktakesListQuery = ({
  vesselId,
  sortBy,
  sortDir,
}: {
  vesselId: string;
  sortBy: SortStocktakesListBy;
  sortDir: SortStocktakesListDir;
}): UseStocktakesListQuery => {
  return useStocktakesListQuery({
    vesselId,
    select: (data): UiStocktakeReportListItem[] =>
      [...data].sort((a, b) => {
        const sortByStocktakeDateWithFallback = () => {
          // Since all dates are in ISO-8601 format without TZ adjustment, we can sort them lexicographically
          // and avoid memory allocation in the sorting method
          if (a.stocktakeDate === b.stocktakeDate) {
            if (sortDir === "ASC") {
              return a.updatedAt > b.updatedAt ? 1 : a.updatedAt < b.updatedAt ? -1 : 0;
            }
            return a.updatedAt > b.updatedAt ? -1 : a.updatedAt < b.updatedAt ? 1 : 0;
          }

          // Case when both stocktakeDates are equal is handled above, so here we can simplify the ternary operators
          if (sortDir === "ASC") {
            return a.stocktakeDate > b.stocktakeDate ? 1 : -1;
          }
          return a.stocktakeDate > b.stocktakeDate ? -1 : 1;
        };

        switch (sortBy) {
          case "STOCKTAKE_DATE": {
            return sortByStocktakeDateWithFallback();
          }

          case "TYPE": {
            const aType = a.type.name;
            const bType = b.type.name;

            if (aType.localeCompare(bType, "en") === 0) {
              return sortByStocktakeDateWithFallback();
            }

            return sortDir === "ASC"
              ? aType.localeCompare(bType, "en")
              : bType.localeCompare(aType, "en");
          }

          case "INVENTORY_COUNT": {
            if (a.inventoryCount === b.inventoryCount) {
              return sortByStocktakeDateWithFallback();
            }

            return sortDir === "ASC"
              ? a.inventoryCount - b.inventoryCount
              : b.inventoryCount - a.inventoryCount;
          }

          case "STATUS": {
            if (a.isDraft === b.isDraft) {
              return sortByStocktakeDateWithFallback();
            }

            return sortDir === "ASC" ? (a.isDraft ? 1 : -1) : a.isDraft ? -1 : 1;
          }

          case "ROB_VALUE": {
            if (a.robValue.amount === b.robValue.amount) {
              return sortByStocktakeDateWithFallback();
            }

            return sortDir === "ASC"
              ? a.robValue.amount - b.robValue.amount
              : b.robValue.amount - a.robValue.amount;
          }

          default: {
            return 0;
          }
        }
      }),
  });
};
