import Dexie from "dexie";

import { LocalLiteStocktakeReport } from "src/models";

import { StoreNames, getStoreName } from "../config";

export class LocalStocktakeReportsDatabase extends Dexie {
  stocktakeReports: Dexie.Table<LocalLiteStocktakeReport, string>;

  constructor() {
    super(getStoreName(StoreNames.STOCKTAKE_REPORTS));
    this.version(0.1).stores({
      stocktakeReports: ",vesselId",
    });
  }
}

export const stocktakeReportsStore = new LocalStocktakeReportsDatabase().stocktakeReports;
