import groupBy from "lodash/groupBy";
import { useCallback } from "react";

import { BasketEntry, ProductSku } from "@web/common";
import {
  getBasketGrandTotal,
  getLineItemEntityQuantity,
  getLineItemTotal,
  getMinimumQuantityNumber,
  getTotalQuantity,
} from "@web/common/utils";
import { Money } from "@web/models";

import { BASKET_FORM_ANSWERS_STORAGE_KEY } from "src/config/constants";

import { useAppStateContext } from "../contexts/AppStateContext";
import { useBasketState } from "../contexts/BasketContext/BasketContext";
import { DraftStatus } from "../contexts/BasketContext/models";
import { LiteBasketFormAnswer, LiteRfqItem } from "../typegens";

const useBasket = () => {
  const {
    state: { basketFormAnswers, draft, lineItems, rfqItems: basketRfqItems, lastUpdated },
    dispatch: basketStateDispatch,
  } = useBasketState();

  const [, appStateDispatch] = useAppStateContext();

  const lineItemsBySupplier = groupBy(lineItems, (item) => item.sku.supplier?.supplierId);

  const totalQuantity = getTotalQuantity(lineItems, Object.values(basketRfqItems));

  const getLineItem = (sku: ProductSku) => {
    return lineItems.find((lineItem) => lineItem.sku.id === sku.id);
  };

  const getQuantity = (sku: ProductSku): number => {
    const lineItem = getLineItem(sku);
    return lineItem ? lineItem.quantity : 0;
  };

  const getLineItemTotalPrice = (sku: ProductSku): Money | null => {
    const lineItem = getLineItem(sku);
    return lineItem ? getLineItemTotal(lineItem) : null;
  };

  const addMultipleSkusToBasket = (list: Array<BasketEntry>) => {
    return basketStateDispatch({
      type: "addMultipleSkusToBasket",
      value: list,
    });
  };

  const addMultipleRfqsToBasket = (list: LiteRfqItem[]) => {
    return basketStateDispatch({
      type: "addMultipleRfqsToBasket",
      value: list,
    });
  };

  const addRfqToBasket = (rfq: LiteRfqItem) => {
    return basketStateDispatch({
      type: "addRfqToBasket",
      value: rfq,
    });
  };

  const updateRfqInBasket = (rfq: LiteRfqItem) => {
    return basketStateDispatch({
      type: "updateRfqInBasket",
      value: rfq,
    });
  };

  const removeRfqFromBasket = (id: string) => {
    return basketStateDispatch({
      type: "removeRfqFromBasket",
      value: id,
    });
  };

  const removeMultipleEntriesFromBasket = (ids: Set<string>) => {
    return basketStateDispatch({
      type: "removeMultipleEntriesFromBasket",
      value: ids,
    });
  };

  const clearBasket = () => {
    // We are storing basket (budget) form answers in a separate key that needs to be removed,
    // whenever we want to reset basket state
    localStorage.removeItem(BASKET_FORM_ANSWERS_STORAGE_KEY);

    basketStateDispatch({
      type: "clearBasket",
    });
  };

  const clearOrderRequisitionFlow = () => {
    clearBasket();
    appStateDispatch({ type: "clearLastGatherExpCategoryId" });
    appStateDispatch({ type: "clearOrderType" });
    appStateDispatch({
      type: "clearPort",
    });
    appStateDispatch({
      type: "clearDeliveryDate",
    });
    appStateDispatch({
      type: "clearDutyFreeDeclaration",
    });
  };

  const pushSkuToBasket = useCallback(
    (sku: ProductSku, quantity: number) => {
      if (quantity === 0) {
        return basketStateDispatch({
          type: "removeSkuFromBasket",
          value: sku,
        });
      } else {
        return basketStateDispatch({
          type: "pushSkuToBasket",
          value: { sku, quantity },
        });
      }
    },
    [basketStateDispatch]
  );

  const increase = (sku: ProductSku) => {
    const quantity = getQuantity(sku);
    pushSkuToBasket(sku, quantity + 1);
  };

  const decrease = (sku: ProductSku) => {
    const quantity = getQuantity(sku);
    const newQuantity = canDecrease(sku) ? Math.max(quantity - 1, 0) : 0;
    pushSkuToBasket(sku, newQuantity);
  };

  const canDecrease = (sku: ProductSku) => getQuantity(sku) > getMinimumQuantityNumber(sku);

  const getOrderItems = () =>
    lineItems.map((lineItem) => ({
      variantId: getVariantId(lineItem.sku),
      entityQuantity: getLineItemEntityQuantity(lineItem),
    }));

  const getVariantId = (sku: ProductSku): string => sku.id;

  const setDraft = ({ draftId, updatedAt }: { draftId: string; updatedAt: string }) => {
    basketStateDispatch({
      type: "setDraft",
      value: { id: draftId, updatedAt },
    });
  };

  // TODO #5641: Remove this action and fall back to `setDraft` everywhere when removing Online Draft logic
  const setNewDraft = ({
    draftId,
    updatedAt,
    status,
  }: {
    draftId: string;
    updatedAt: string;
    status?: DraftStatus;
  }) => {
    basketStateDispatch({
      type: "setNewDraft",
      value: { id: draftId, updatedAt, status },
    });
  };

  const setBasketFormAnswers = (basketFormAnswers: LiteBasketFormAnswer[]) => {
    basketStateDispatch({
      type: "setBasketFormAnswers",
      value: basketFormAnswers,
    });
  };

  const grandTotal: Money = getBasketGrandTotal(lineItems);

  const rfqItems = Object.values(basketRfqItems);

  return {
    addMultipleRfqsToBasket,
    addMultipleSkusToBasket,
    addRfqToBasket,
    basketFormAnswers,
    canDecrease,
    clearBasket,
    clearOrderRequisitionFlow,
    decrease,
    draft,
    getLineItem,
    getLineItemTotalPrice,
    getOrderItems,
    getQuantity,
    getVariantId,
    grandTotal,
    increase,
    lastUpdated,
    lineItems,
    lineItemsBySupplier,
    pushSkuToBasket,
    removeRfqFromBasket,
    removeMultipleEntriesFromBasket,
    rfqItems,
    setBasketFormAnswers,
    setDraft,
    setNewDraft,
    totalQuantity,
    updateRfqInBasket,
  };
};

export default useBasket;
