import { useUnleashContext } from "@unleash/proxy-client-react";
import { useEffect } from "react";

import { useFeatureFlagsManagement } from "@web/common";

import { useAppStateContext } from "./AppStateContext";

export const UnleashContextSetup = () => {
  const { unleashContextFlagProperties } = useFeatureFlagsManagement();
  const [{ configuration }] = useAppStateContext();
  const vesselId = configuration?.vessel?.id;
  const updateContext = useUnleashContext();

  useEffect(() => {
    updateContext({ userId: vesselId, properties: unleashContextFlagProperties });
  }, [vesselId, unleashContextFlagProperties, updateContext]);

  return null;
};
