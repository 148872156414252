import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { InvoiceAccountUI } from "@web/common";
import { DEFAULT_CURRENCY_CODE } from "@web/common/config/constants";
import { Money } from "@web/models";
import { ActionBar, Heading, Label, Paragraph, RfqBadge, Tooltip } from "@web/ui";
import { formatMoney } from "@web/utils";

import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import trackingCategories from "src/utils/trackingCategories";
import trackingEvents from "src/utils/trackingEvents";

export const RequisitionSuccess: React.FC = () => {
  const [{ lastCreatedOrderRequisitions, configuration }] = useAppStateContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!lastCreatedOrderRequisitions) {
      navigate(RoutesConfig.order.overview);
    }
  }, [lastCreatedOrderRequisitions, navigate]);

  if (!lastCreatedOrderRequisitions) {
    return null;
  }

  const isAutoApprovedRequisition = !!configuration?.isAutoApprovedRequisition;
  const catalogRequisitions = lastCreatedOrderRequisitions.filter((or) => !or.isRfqRequisition);
  const requestedRequisitions = lastCreatedOrderRequisitions.filter((or) => or.isRfqRequisition);
  const grandTotal: Money = {
    amount: lastCreatedOrderRequisitions.reduce((accumulator, or) => {
      return accumulator + or?.totalGrossAmount?.amount;
    }, 0),
    currencyCode: catalogRequisitions[0]?.totalGrossAmount?.currencyCode || DEFAULT_CURRENCY_CODE,
  };

  const createNewOrder = () => {
    ReactGA.event({
      category: trackingCategories.CREW_APP,
      action: trackingEvents.CREATING_NEW_ORDER,
    });
    navigate(RoutesConfig.gatherSetup);
  };

  const canRenderTax = () => catalogRequisitions.length !== 0;
  const canRenderBillingInformation = Boolean(catalogRequisitions[0]?.invoiceAccount);

  return (
    <div className="flex flex-col">
      <TopBarController />
      <ActionBar
        primaryButtonSettings={{
          title: "Go to Order Overview",
          onClick: () => {
            navigate(RoutesConfig.order.overview);
          },
          testId: "goToOrderOverviewButton",
        }}
        helperButtons={[
          {
            title: isAutoApprovedRequisition
              ? "Create Another Order"
              : "Create Another Requisition",
            onClick: () => createNewOrder(),
          },
        ]}
      />
      <div className="max-w-3xl mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8 text-center my-8">
        <div className="max-w-xl">
          <Label size="300" className="uppercase" color="text-primaryDefault">
            {isAutoApprovedRequisition
              ? t("pages.autoApprovedRequisitionConfirmation.requisitionConfirmation")
              : t("pages.requisitionConfirmation.requisitionConfirmation")}
          </Label>

          <Heading size="100">
            {isAutoApprovedRequisition
              ? t("pages.autoApprovedRequisitionConfirmation.headline")
              : t("pages.requisitionConfirmation.headline")}
          </Heading>
          <Paragraph size="200" color="text-textIcon-blackSecondary" className="pt-4 px-8">
            {isAutoApprovedRequisition
              ? t("pages.autoApprovedRequisitionConfirmation.copy")
              : t("pages.requisitionConfirmation.copy")}
          </Paragraph>
        </div>
        {canRenderBillingInformation && (
          <div className="flex flex-col items-start mt-6">
            <Paragraph size="300" color="text-textIcon-blackSecondary" className="mb-4">
              {t("pages.requisitionConfirmation.billingInformation")}
            </Paragraph>
            <InvoiceAccountUI value={catalogRequisitions[0].invoiceAccount} />
          </div>
        )}
        {catalogRequisitions.map((requisition) => (
          <div className="my-6 border-neutral_300" key={requisition.id}>
            <div>
              <dl className="grid grid-cols-2 text-sm text-left">
                <Paragraph size="300" color="text-textIcon-blackSecondary">
                  {t(
                    // This is weird, we're mapping over `catalogRequisitions`, why there're references to RfQ?
                    // TODO: #3501 investigate that and refactor
                    requisition.isRfqRequisition
                      ? "pages.requisitionConfirmation.rfqItems"
                      : "pages.requisitionConfirmation.catalogItems"
                  )}
                </Paragraph>
              </dl>
              <dl className="grid grid-cols-2 text-sm mt-2 text-left">
                <Label size="300">
                  {t("pages.requisitionConfirmation.nrItems", {
                    count: requisition.isRfqRequisition
                      ? requisition.rfqItems?.length
                      : requisition.items.length,
                  })}
                </Label>
                {requisition.isRfqRequisition ? (
                  <div className="flex flex-row justify-end">
                    <Tooltip tooltipText={t("common.rfqTooltip")}>
                      <div className="text-right w-8  p-2 rounded-lg border">
                        <Paragraph
                          size="300"
                          color="text-textIcon-blackSecondary"
                          className="flex-row flex"
                        >
                          <QuestionMarkCircleIcon className="h-4 w-4 mr-1" />
                          {t("pages.requisitionConfirmation.rfq")}
                        </Paragraph>
                      </div>
                    </Tooltip>
                  </div>
                ) : (
                  <Label size="300">
                    <div className="text-right">{formatMoney(requisition.totalGrossAmount)}</div>
                  </Label>
                )}
              </dl>
            </div>
          </div>
        ))}

        {canRenderTax() && (
          <>
            <dl className="border-t space-y-5 mb-6 border-neutral_300 text-sm pt-6">
              <div className="flex justify-between">
                <Label size="300" color="text-textIcon-blackSecondary">
                  {t("pages.requisitionConfirmation.salesTax0")}
                </Label>
                <Label size="300" color="text-textIcon-blackSecondary">
                  {formatMoney({
                    amount: 0,
                    currencyCode: grandTotal.currencyCode,
                  })}
                </Label>
              </div>
            </dl>

            <dl className="border-t border-neutral_300 text-sm pt-6">
              <div className="flex justify-between">
                <Label size="200">{t("pages.requisitionConfirmation.total")}</Label>
                <dd>
                  <Heading
                    size="300" /* color="text-text-700" */
                    // TODO #6444: Fixme, there is no such color in tailwind config
                  >
                    {formatMoney(grandTotal)}
                  </Heading>
                </dd>
              </div>
            </dl>
          </>
        )}

        {requestedRequisitions.map((requisition) => (
          <div
            className="bg-neutral_100 p-4 border-neutral_300 rounded-md mt-6"
            key={requisition.id}
          >
            <div className="border-neutral_300">
              <div>
                <dl className="grid grid-cols-2 text-sm text-left">
                  <Paragraph size="300" color="text-textIcon-blackSecondary">
                    {t(
                      requisition.isRfqRequisition
                        ? "pages.requisitionConfirmation.rfqItems"
                        : "pages.requisitionConfirmation.catalogItems"
                    )}
                  </Paragraph>
                </dl>
                <dl className="grid grid-cols-2 text-sm mt-2 text-left items-center">
                  <Label size="300">
                    {t("pages.requisitionConfirmation.nrItems", {
                      count: requisition.isRfqRequisition
                        ? requisition.rfqItems?.length
                        : requisition.items.length,
                    })}
                  </Label>
                  {requisition.isRfqRequisition ? (
                    <div className="flex flex-row justify-end">
                      <Tooltip tooltipText={t("common.rfqTooltip")}>
                        <RfqBadge />
                      </Tooltip>
                    </div>
                  ) : (
                    <Label size="300">
                      <div className="text-right">{formatMoney(requisition.totalGrossAmount)}</div>
                    </Label>
                  )}
                </dl>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
