import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useEffect } from "react";

import { useModalContext } from "@web/common/contexts/ModalContext";
import { OrderRequisition } from "@web/common/network/model";
import { Paragraph, RegularButton } from "@web/ui";

import S2SLoader from "../../icons/S2SLoader.gif";
import { LiteProductSku } from "../../typegens";
import { AllItemsAvailableModal } from "./AllItemsAvailableModal";
import { ItemsAvailabilityModalContainer } from "./ItemsAvailabilityModalContainer";
import { ProductDiffList } from "./ProductDiffList";
import { OrderDraftModalCTAButtons } from "./components";

// in ms
const MODAL_VISIBILITY_SPAN = 1500;

type Props = {
  draft: OrderRequisition;
  availableItemsQuery: UseQueryResult<LiteProductSku[]>;
  isDownloadingOfflineCatalog?: boolean;
};

export const CheckItemsAvailabilityModal = ({
  draft,
  availableItemsQuery,
  isDownloadingOfflineCatalog = false,
}: Props) => {
  const { closeModal, openModal } = useModalContext();

  useEffect(() => {
    if (!availableItemsQuery.isSuccess) {
      return;
    }

    const ratio: [number, number] = [availableItemsQuery.data.length, draft.items.length];

    setTimeout(() => {
      if (availableItemsQuery.data.length === draft.items.length) {
        openModal(
          <AllItemsAvailableModal draft={draft} liteProductList={availableItemsQuery.data} />
        );
      } else if (availableItemsQuery.data.length < draft.items.length) {
        openModal(
          <ItemsAvailabilityModalContainer
            closeModal={closeModal}
            ctaButtons={
              <OrderDraftModalCTAButtons
                availableProducts={availableItemsQuery.data}
                draft={draft}
              />
            }
            isDraft
            loading={false}
            order={draft}
            ratio={ratio}
            title="Reviewing your draft items"
          >
            <ProductDiffList
              sourceProducts={draft.items}
              productVariants={availableItemsQuery.data}
            />
          </ItemsAvailabilityModalContainer>
        );
      }
      // Delay displaying another modal for 1.5s to avoid janky UX
    }, MODAL_VISIBILITY_SPAN);
  }, [closeModal, draft, openModal, availableItemsQuery.isSuccess, availableItemsQuery.data]);

  const error = availableItemsQuery.isError
    ? "There was a problem checking availability of your items. Please try again."
    : undefined;

  return availableItemsQuery.isError ? (
    <ItemsAvailabilityModalContainer
      closeModal={closeModal}
      ctaButtons={
        <RegularButton
          className="ml-auto mr-5"
          variant="secondary"
          size="large"
          label="Cancel"
          onClick={() => closeModal()}
        />
      }
      isDraft
      loading={false}
      order={draft}
      error={error}
      title="Reviewing your draft items"
    />
  ) : (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg py-5 overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full">
      <div className="absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="sr-only bg-neutral_0 rounded-md text-text-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={() => closeModal()}
        >
          <span className="sr-only">Close modal</span>
        </button>
      </div>
      <div className="flex flex-col items-center">
        <img className="w-8 h-8" src={S2SLoader} />
        <Paragraph size="200" className="mt-5">
          {isDownloadingOfflineCatalog ? (
            <>
              We are downloading the catalog data and this may take some time.
              <br /> Please do not refresh your browser or go to another page.
            </>
          ) : (
            "Checking if items are available..."
          )}
        </Paragraph>
      </div>
    </div>
  );
};
