import { ShoppingCartIcon, TagIcon } from "@heroicons/react/outline";
import { MinusIcon, PlusIcon, XIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";

import { TotalUnitOfMeasure } from "@web/common";
import ErrorLabel from "@web/common/components/ErrorLabel";
import { PriceTypeFlag } from "@web/common/components/PriceTypeFlag";
import WarningLabel from "@web/common/components/WarningLabel";
import {
  getMinimumOrderQuantity,
  getMinimumQuantityNumber,
  getSalesEntityPrice,
  getSalesEntityQuantity,
} from "@web/common/utils";
import { Heading, IconButton, Paragraph } from "@web/ui";
import { formatMoney } from "@web/utils";

import { LiteProductSku } from "src/typegens";

interface SidebarProps {
  closeModal: () => void;
  quantity: number;
  addToBasket: (sku: LiteProductSku, quantity: number) => void;
  maxLength?: number;
  productSku: LiteProductSku;
}

export const ProductDetailsModalSidebar = ({
  closeModal,
  quantity,
  addToBasket,
  maxLength = 4,
  productSku,
}: SidebarProps) => {
  const initialQuantity = quantity;
  const [inputValue, setInputValue] = useState(initialQuantity);
  const [minQuantityError, setMinQuantityError] = useState(false);

  useEffect(() => {
    handleUpdateBasket();
    // DO NOT change the dependency array below, unless you know what you are doing.
    // Make sure to test any change to ensure that change won't cause inifite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const unitFormatted = productSku.measurementUnit;
  const minimumOrderQuantity = getMinimumOrderQuantity(productSku);
  const salesEntityQuantity = getSalesEntityQuantity(productSku);
  const minimumQuantityNumber = getMinimumQuantityNumber(productSku);
  const isPlusButtonDisabled = (quantity + 1).toString().length > maxLength;

  const filterInt = (value: string) => {
    if (/^[-+]?\d+$/.test(value)) {
      return Number(value);
    } else {
      return NaN;
    }
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (!isNaN(filterInt(value)) || value === "") {
      setInputValue(Number(value));
    }
  };

  const handleUpdateBasket = () => {
    addToBasket(productSku, Number(inputValue));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowRight") {
      handleUpdateBasket();
      if (
        minimumOrderQuantity !== undefined &&
        minimumOrderQuantity > 1 &&
        inputValue < minimumOrderQuantity
      ) {
        setInputValue(inputValue + minimumQuantityNumber);
      } else if (inputValue === 9999) {
        null;
      } else {
        setInputValue(inputValue + 1);
      }
    }
    if (e.key === "ArrowLeft" && inputValue > 0) {
      handleUpdateBasket();
      if (
        minimumOrderQuantity !== undefined &&
        minimumOrderQuantity > 1 &&
        inputValue < minimumQuantityNumber + 1
      ) {
        setInputValue(0);
      } else {
        setInputValue(inputValue - 1);
      }
    }
  };

  const handleBlur = () => {
    if (
      minimumOrderQuantity !== undefined &&
      minimumOrderQuantity > 1 &&
      inputValue < minimumQuantityNumber
    ) {
      if (inputValue === 0) {
        setInputValue(0);
      } else {
        setInputValue(minimumQuantityNumber);
        setMinQuantityError(true);
        setTimeout(() => setMinQuantityError(false), 5000);
      }
    }
  };

  const handlePlus = () => {
    if (isPlusButtonDisabled) {
      return;
    }
    handleUpdateBasket();
    if (
      minimumOrderQuantity !== undefined &&
      minimumOrderQuantity > 1 &&
      inputValue < minimumOrderQuantity
    ) {
      setInputValue(inputValue + minimumQuantityNumber);
    } else if (inputValue === 0) {
      setInputValue(1);
    } else {
      setInputValue(inputValue + 1);
    }
  };

  const handleMinus = () => {
    handleUpdateBasket();
    if (inputValue === 0) {
      null;
    } else if (
      minimumOrderQuantity !== undefined &&
      minimumOrderQuantity > 1 &&
      inputValue < minimumQuantityNumber + 1
    ) {
      setInputValue(0);
    } else {
      setInputValue(inputValue - 1);
    }
  };
  return (
    <div className="bg-neutral_200 ml-auto w-[30%] h-[80vh] flex flex-col p-4 fixed top-0 right-0 rounded-tr-3xl rounded-br-3xl border-l-1">
      <button
        type="button"
        className="rounded-md text-text-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none ml-auto"
        onClick={closeModal}
      >
        <span className="sr-only">Close modal</span>
        <XIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
      </button>
      <div className="flex items-center">
        <TagIcon width="30px" className="text-gray-500" />
        <Paragraph size="100" className="pl-2 text-gray-500 font-medium">
          Price per item
        </Paragraph>
      </div>
      <div className="items-baseline pt-2 border-b-1 border-neutral_300 pb-6">
        <div className="flex space-x-3">
          <PriceTypeFlag priceType={productSku.priceType} />
          <div>
            <Heading size="200" className="font-bold">
              {productSku ? formatMoney(getSalesEntityPrice(productSku)) : ""}
            </Heading>
            <div>
              <Paragraph size="200" className="font-bold" color="text-textIcon-blackSecondary">
                {productSku
                  ? `${formatMoney(productSku.price.costPrice)} / ${productSku.measurementUnit}`
                  : ""}
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div className="flex items-center">
          <ShoppingCartIcon width="30px" className="text-gray-500" />
          <Paragraph size="100" className="pl-2 text-gray-500 font-medium">
            Added to your basket
          </Paragraph>
        </div>
        <Heading size="200" className="font-bold mt-3 mb-1">
          {formatMoney({
            amount: getSalesEntityPrice(productSku).amount * inputValue,
            currencyCode: getSalesEntityPrice(productSku).currencyCode,
          })}
        </Heading>
        {minimumOrderQuantity !== undefined &&
        minimumOrderQuantity > 1 &&
        inputValue < minimumQuantityNumber &&
        minimumQuantityNumber > 1 ? (
          <div className="text-center mt-6">
            <WarningLabel
              warningMessage="Minimum: "
              minimumQuantityNumber={minimumQuantityNumber}
              minimumOrderQuantity={minimumOrderQuantity}
              measurementUnit={unitFormatted}
            />
          </div>
        ) : (
          <TotalUnitOfMeasure
            quantityInBasket={inputValue}
            salesEntityQuantity={salesEntityQuantity}
            measurementUnit={unitFormatted}
            variant={inputValue > 0 ? "positive" : undefined}
          />
        )}
        <div className="border-b-1 border-neutral_300 pb-8">
          <div className="flex ml-auto bg-white rounded-3xl border-1 align-center items-center mt-3">
            <div className="m-auto flex align-center items-center space-x-2">
              <IconButton
                size="large"
                variant="primary"
                shape="circle"
                label="Decrease"
                Icon={MinusIcon}
                onClick={handleMinus}
                disabled={inputValue === 0}
                tabIndex={-1}
                data-testid="decrease-button"
              />
              <input
                value={inputValue}
                autoFocus
                maxLength={maxLength}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                className="flex-none w-8 h-7 mb-3 mx-2 mt-3 border rounded text-center"
                aria-label="Item Quantity"
                data-testid="itemQuantity"
              />
              <IconButton
                size="large"
                variant="primary"
                shape="circle"
                label="Increase"
                Icon={PlusIcon}
                onClick={handlePlus}
                disabled={isPlusButtonDisabled}
                tabIndex={-1}
                data-testid="increase-button"
              />
            </div>
          </div>
          {minQuantityError ? (
            <div className="flex flex-col">
              <ErrorLabel
                errorMessage="You're under the minimum order quantity"
                minimumOrderQuantity={minimumOrderQuantity}
                measurementUnit={unitFormatted}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
