import { UseFieldArrayRemove } from "react-hook-form";

import { StocktakeFormNewExtraProductTile } from "../../components/StocktakeFormNewExtraProductTile";
import { LocalStocktakeReportNewExtraItemForm } from "../../models";
import { UnitOfMeasure } from "../../state/models";

type Props = {
  extraItems: Array<LocalStocktakeReportNewExtraItemForm>;
  removeNewExtraItem: UseFieldArrayRemove;
  unitOfMeasures: UnitOfMeasure[];
};

export const StocktakeNewExtraItemsList = ({
  extraItems,
  removeNewExtraItem,
  unitOfMeasures,
}: Props) => {
  return (
    <>
      {extraItems.map((extraItem, index) => (
        <StocktakeFormNewExtraProductTile
          key={extraItem.id}
          product={extraItem}
          indexInCollection={index}
          remove={removeNewExtraItem}
          hasLineNumberPlaceholder={true}
          unitOfMeasures={unitOfMeasures}
        />
      ))}
    </>
  );
};
