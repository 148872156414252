import classnames from "classnames";

import { Port } from "@web/models";
import { AnchorWhite, CircledImage, Paragraph } from "@web/ui";

type Props = {
  port: Port;
  className?: string;
};

export const PortViewer = ({ port, className = "" }: Props) => {
  return (
    <div className={classnames("flex items-center gap-1", className)} key={port.id}>
      <CircledImage className="flex-none" Icon={AnchorWhite} size={5} hashKey={port.id} />
      <div className="flex items-center gap-1 truncate">
        <Paragraph size="200">{port.locationCode}</Paragraph>
        <Paragraph size="200" className="truncate">
          {port.name}
        </Paragraph>
      </div>
    </div>
  );
};
