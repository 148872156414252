import { Dispatch, createContext, useContext } from "react";

import { NetworkToggleAction, NetworkToggleMode } from "./models";
import { defaultNetworkToggleState } from "./reducer";

export const NetworkToggleContext = createContext<
  [state: NetworkToggleMode, action: Dispatch<NetworkToggleAction>]
>([
  defaultNetworkToggleState,
  () => {
    return;
  },
]);

export const useNetworkToggleContext = () => useContext(NetworkToggleContext);
