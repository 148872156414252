import Dexie from "dexie";

import { LocalLiteStocktakeReportListItem } from "src/models";

import { StoreNames, getStoreName } from "../config";

export class LocalStocktakeReportsListDatabase extends Dexie {
  stocktakeReportsList: Dexie.Table<LocalLiteStocktakeReportListItem, string>;

  constructor() {
    super(getStoreName(StoreNames.STOCKTAKE_REPORTS_LIST));
    this.version(0.1).stores({
      stocktakeReportsList: ",vesselId",
    });
  }
}

export const stocktakeReportsListStore = new LocalStocktakeReportsListDatabase()
  .stocktakeReportsList;
