import { ComponentProps, useCallback } from "react";
import { UseFieldArrayPrepend, UseFieldArrayRemove } from "react-hook-form";

import { Paragraph } from "@web/ui";

import {
  LocalStocktakeReportForm,
  LocalStocktakeReportNewExtraItemForm,
  LocalStocktakeReportOldExtraItemForm,
} from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";
import { UnitOfMeasure } from "src/state/models";

import { StocktakeExtraItemsSectionHeader } from "./StocktakeExtraItemsSectionHeader";
import { StocktakeNewExtraItemsList } from "./StocktakeNewExtraItemsList";
import { StocktakeOldExtraItemsList } from "./StocktakeOldExtraItemsList";

type Props = ComponentProps<"div"> & {
  newExtraItems: Array<LocalStocktakeReportNewExtraItemForm>;
  oldExtraItems: Array<LocalStocktakeReportOldExtraItemForm>;
  isToggleAvailable: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean | ((prev: boolean) => boolean)) => void;
  showNoNewExtraItemsInfoPrerequisite: boolean;
  prependNewExtraItem: UseFieldArrayPrepend<LocalStocktakeReportForm, "newExtraItems">;
  removeNewExtraItem: UseFieldArrayRemove;
  currencyCode: string;
  unitOfMeasures: UnitOfMeasure[];
};

export const StocktakeExtraItemsSection = ({
  newExtraItems,
  oldExtraItems,
  isToggleAvailable,
  isOpen,
  setIsOpen,
  showNoNewExtraItemsInfoPrerequisite,
  prependNewExtraItem,
  removeNewExtraItem,
  currencyCode,
  className = "",
  unitOfMeasures,
}: Props) => {
  const toggleSectionVisibility = useCallback(() => setIsOpen((prev) => !prev), [setIsOpen]);

  const createNewItem = useCallback(() => {
    prependNewExtraItem(LocalStocktakeService.getEmptyNewExtraItem(currencyCode));
    setIsOpen(true);
  }, [currencyCode, prependNewExtraItem, setIsOpen]);

  // ---------------------------------------------------------------------------

  return (
    <div className={className}>
      <StocktakeExtraItemsSectionHeader
        createNewItem={createNewItem}
        isToggleAvailable={isToggleAvailable}
        isOpen={isOpen}
        toggleSectionVisibility={toggleSectionVisibility}
      />
      {isOpen && (
        <>
          {showNoNewExtraItemsInfoPrerequisite && newExtraItems.length === 0 && (
            <Paragraph size="100" color="text-textIcon-blackSecondary" className="mt-5">
              If you haven’t found your item on the list below, you can add a custom product to this
              stocktake report by clicking the &quot;Create&nbsp;Item&quot; button.
            </Paragraph>
          )}
          {(newExtraItems.length > 0 || oldExtraItems.length > 0) && (
            <div className="flex flex-col gap-2 mt-5">
              <StocktakeNewExtraItemsList
                extraItems={newExtraItems}
                removeNewExtraItem={removeNewExtraItem}
                unitOfMeasures={unitOfMeasures}
              />
              <StocktakeOldExtraItemsList extraItems={oldExtraItems} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
