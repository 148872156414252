import { OrderRequisition } from "@web/common/network/model";

import { ReorderModal } from "src/components/Modal/ReorderModal";
import { useProductVariantsQuery } from "src/hooks/useProductVariantsQuery";

interface Props {
  order: OrderRequisition;
}

export const LEGACY_ReorderModalController = ({ order }: Props) => {
  const orderVariantIds = order.items.map((item) => item.variantId);

  const availableItemsQuery = useProductVariantsQuery({
    variantIds: orderVariantIds,
    portId: order.port.id,
    orderType: order.orderType,
  });

  return <ReorderModal order={order} availableItemsQuery={availableItemsQuery} />;
};
