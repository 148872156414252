import { ExclamationCircleIcon, ExclamationIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import classnames from "classnames";

import { ProductItemsTable } from "@web/common";
import { ProductItem } from "@web/common/network";
import { Heading, Label, Paragraph, RegularButton } from "@web/ui";

type Props = {
  areAllItemsUnavailable: boolean;
  hasOnlyItemsWithChangedPrice: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  onUpdateBasketClick: () => void;
  productsWithChangedPrice: ProductItem[];
  unavailableProducts: ProductItem[];
};

export const BasketVerificationModal = ({
  areAllItemsUnavailable,
  hasOnlyItemsWithChangedPrice,
  closeModal,
  onSubmit,
  onUpdateBasketClick,
  productsWithChangedPrice,
  unavailableProducts,
}: Props) => {
  return (
    <div className="inline-block bg-neutral_0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle">
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-text-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={closeModal}
        >
          <XIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col w-130 m-5 pr-5">
        <Heading size="200">
          {hasOnlyItemsWithChangedPrice
            ? "These items have changed their price"
            : "Please remove items that are no longer available to submit your order"}
        </Heading>
        <div className="flex flex-row mt-2">
          <div>
            <Paragraph size="100" color="text-textIcon-blackSecondary">
              Changes have occurred due to your supplier making adjustments to their catalog.
            </Paragraph>
          </div>
        </div>
      </div>
      {unavailableProducts.length > 0 && (
        <div
          className={classnames("bg-neutral_100 border-t-2 px-5 py-6", {
            "pb-2": productsWithChangedPrice.length > 0,
          })}
        >
          {areAllItemsUnavailable ? (
            <Paragraph size="100" color="text-dangerDefault" className="flex items-center">
              <ExclamationCircleIcon className="w-4 h-4 inline mr-2 text-dangerDefault" />
              All your basket items are not available
            </Paragraph>
          ) : (
            <Paragraph size="100" weight="heavy">
              <ExclamationIcon className="w-4 h-4 inline mr-2 text-warningDefault" />
              {unavailableProducts.length} item{unavailableProducts.length > 1 ? "s" : ""} will be
              removed from your basket
            </Paragraph>
          )}
          <div className="flex justify-between py-4">
            <Label size="300">Not Available</Label>
            <Label size="300">
              {unavailableProducts.length} item{unavailableProducts.length > 1 ? "s" : ""}
            </Label>
          </div>

          <ProductItemsTable lineThrough={true} productItems={unavailableProducts} />
        </div>
      )}
      {productsWithChangedPrice.length > 0 && (
        <div
          className={classnames(
            "bg-neutral_100 px-5 pb-6",
            hasOnlyItemsWithChangedPrice ? "pt-6" : "pt-2",
            {
              "border-t-2": hasOnlyItemsWithChangedPrice,
            }
          )}
        >
          <Paragraph size="100" weight="heavy">
            <ExclamationIcon className="w-4 h-4 inline mr-2 text-warningDefault" />
            {productsWithChangedPrice.length} item
            {productsWithChangedPrice.length > 1 ? "s" : ""}{" "}
            {productsWithChangedPrice.length > 1 ? "have" : "has"} changed price
          </Paragraph>
          <div className="flex justify-between py-4">
            <Label size="300">Price changed</Label>
            <Label size="300">
              {productsWithChangedPrice.length} item
              {productsWithChangedPrice.length > 1 ? "s" : ""}
            </Label>
          </div>

          <ProductItemsTable productItems={productsWithChangedPrice} />
        </div>
      )}
      <div className="m-5 flex flex-row-reverse">
        {hasOnlyItemsWithChangedPrice ? (
          <>
            <RegularButton variant="primary" size="large" label="Submit Order" onClick={onSubmit} />
            <RegularButton
              className="mr-4"
              variant="secondary"
              size="large"
              label="Back To Basket"
              onClick={onUpdateBasketClick}
            />
          </>
        ) : (
          <RegularButton
            variant="primary"
            size="large"
            label="Remove items and Go to basket"
            onClick={onUpdateBasketClick}
          />
        )}
      </div>
    </div>
  );
};
