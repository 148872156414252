import { v4 as uuidv4 } from "uuid";

import { ToastMessage } from "@web/ui";

import { useAppStateContext } from "../contexts/AppStateContext";

export const useToastMessage = () => {
  const [, dispatch] = useAppStateContext();

  const setToastMessage = (toastMessage: Omit<ToastMessage, "id">) => {
    dispatch({
      type: "setSystemMessage",
      value: { ...toastMessage, id: uuidv4() },
    });
  };

  return { setToastMessage };
};
