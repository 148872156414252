import {
  CancelablePromise,
  LiteStocktakeControllerService,
  LiteStocktakeInitialReportData,
  LiteStocktakeSetupDataRequest,
} from "src/typegens";
import { ApiRequestOptions } from "src/typegens/core/ApiRequestOptions";

export const getInitialStocktakeReportData = (
  requestBody: LiteStocktakeSetupDataRequest,
  options?: Pick<ApiRequestOptions, "headers" | "signal">
): CancelablePromise<LiteStocktakeInitialReportData> =>
  LiteStocktakeControllerService.getStocktakeInitialReportData({ requestBody }, options);
