import Dexie from "dexie";

import { LocalLiteConfiguration } from "src/models";

import { StoreNames, getStoreName } from "../config";

export class LocalConfigurationDatabase extends Dexie {
  configuration: Dexie.Table<LocalLiteConfiguration, string>;

  constructor() {
    super(getStoreName(StoreNames.CONFIGURATION));
    this.version(0.1).stores({
      configuration: "",
    });
    this.version(0.2)
      .stores({
        configuration: "",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalLiteConfiguration>("configuration")
          .toCollection()
          .modify((config) => {
            if (!config.allow.manualNetworkState) {
              config.allow.manualNetworkState = true;
            }
          });
      });
    this.version(0.3)
      .stores({
        configuration: "",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalLiteConfiguration>("configuration")
          .toCollection()
          .modify((config) => {
            if (!config.allow.sortPurchaseOrderLines) {
              config.allow.sortPurchaseOrderLines = false;
            }
          });
      });
  }
}

export const configurationStore = new LocalConfigurationDatabase().configuration;
