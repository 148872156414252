import { useParams } from "react-router-dom";

import { Loading } from "@web/ui";

import { NoCachedData } from "src/components/NoCachedData";
import { RoutesParams } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useStocktakeReportQuery, useStocktakeReportQueryHelpers } from "src/hooks/stocktakes";
import { useNoCachedQueryGuard } from "src/hooks/useNoCachedQueryGuard";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

import { StocktakeDetails } from "./StocktakeDetails";

export const StocktakeDetailsPage = () => {
  const { [RoutesParams.STOCKTAKE_ID]: stocktakeId } = useParams() as {
    stocktakeId: string;
  };
  const [{ configuration }] = useAppStateContext();
  const vesselId = configuration?.vessel.id;
  const availableStocktakeTypes = configuration?.availableStocktakeTypes;

  const {
    isPending,
    isFetching,
    isError,
    data: stocktakeReport,
  } = useStocktakeReportQuery({
    stocktakeId,
  });
  const { invalidate } = useStocktakeReportQueryHelpers();

  const { hasNoCachedData } = useNoCachedQueryGuard({
    isQueryPending: isPending,
    isQueryError: isError,
    becameOnlineCallback: () => invalidate(stocktakeId),
  });

  if (hasNoCachedData) {
    return <NoCachedData hasGoBack />;
  }

  if (isPending || isFetching || !stocktakeReport) {
    return <Loading />;
  }

  const canRenderStocktakeType = LocalStocktakeService.shouldRenderStocktakeType(
    availableStocktakeTypes,
    stocktakeReport.type
  );

  return (
    <StocktakeDetails
      stocktakeReport={stocktakeReport}
      vesselId={vesselId}
      canRenderStocktakeType={canRenderStocktakeType}
    />
  );
};
