import { WifiIcon } from "@heroicons/react/outline";
import classnames from "classnames";

import { Label, LoadingInline, Switch } from "../../atoms";

interface Props {
  isEnabled: boolean;
  hasSwitch?: boolean;
  toggleSwitch: () => void;
  isNetworkOnline: boolean;
  isNetworkReconnecting: boolean;
  isNetworkOffline: boolean;
  isShakyConnection?: boolean;
}

export const NetworkStatusIndicator = ({
  hasSwitch = true,
  isEnabled,
  toggleSwitch,
  isNetworkReconnecting,
  isNetworkOffline,
  isShakyConnection = false,
}: Props) => {
  return (
    <div className="flex items-center">
      {hasSwitch && (
        <Switch
          enabled={isEnabled}
          onToggle={toggleSwitch}
          size="small"
          dataTestId="networkToggleSwitch"
        />
      )}
      {isEnabled ? (
        <Label
          data-testid="networkToggleSwitch_onlineState"
          size="200"
          color="text-textIcon-blackSecondary"
          className="ml-3 mr-2"
        >
          Online
        </Label>
      ) : (
        <Label
          data-testid="networkToggleSwitch_offlineState"
          size="200"
          color="text-textIcon-blackSecondary"
          className="ml-3 mr-2"
        >
          Offline
        </Label>
      )}
      {isNetworkReconnecting ? (
        <div className="inline-block -mt-0.5 relative w-4 h-4">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <LoadingInline size="4" />
          </div>
        </div>
      ) : (
        <div className="inline-block -mt-0.5 relative">
          <WifiIcon
            className={classnames("w-4.5 text-textIcon-blackSecondary", {
              "[&>path]:animate-pulse": isShakyConnection,
            })}
          />
          {isNetworkOffline && (
            <div className="w-4.5 h-4.5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <div className="relative top-1/2 w-4.5 h-[2px] bg-neutral_700 rotate-45"></div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
