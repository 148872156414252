import { z } from "zod";

import { optionalNonEmptyString } from "./common";

export const LiteSkuDimensionsSchema = z.object({
  size: optionalNonEmptyString(),
  sizeMetric: optionalNonEmptyString(),
  sizeImperial: optionalNonEmptyString(),
  length: optionalNonEmptyString(),
  width: optionalNonEmptyString(),
  diameter: optionalNonEmptyString(),
  netWeight: optionalNonEmptyString(),
  tareWeight: optionalNonEmptyString(),
  grossWeight: optionalNonEmptyString(),
  sizeVariation: optionalNonEmptyString(),
  sizeType: optionalNonEmptyString(),
});
