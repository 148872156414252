import { InfiniteData } from "@tanstack/react-query";

import { OrderRequisition } from "@web/common/network/model";

import { LocalOrderReqService } from "../services/LocalOrderReqService";
import { LiteOrderRequisition } from "../typegens";

export const updateOrderRequisitionsListItem =
  (result: LiteOrderRequisition) =>
  (oldData?: InfiniteData<{ items: OrderRequisition[]; cursor?: string }> | undefined) => {
    if (oldData) {
      return {
        pages: oldData.pages.map((page) => ({
          items: page.items.map((item) => {
            if (item.id === result.id) {
              return LocalOrderReqService.toOrderRequisition(result);
            }

            return item;
          }),
          cursor: page.cursor,
        })),
        pageParams: [...oldData.pageParams],
      };
    }
  };
