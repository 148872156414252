import { DefaultError, useMutation } from "@tanstack/react-query";

import { OFFLINE_HTTP_HEADERS } from "src/config/constants";

import { LiteProductSku, LiteProductVariantsRequest, ProductsService } from "../typegens";

export const useProductVariantsMutation = () =>
  useMutation<LiteProductSku[], DefaultError, LiteProductVariantsRequest>({
    mutationFn: async ({ variantIds, portId, orderType }: LiteProductVariantsRequest) => {
      const response = await ProductsService.getProductVariants(
        {
          requestBody: {
            portId,
            variantIds,
            orderType,
          },
        },
        { headers: { [OFFLINE_HTTP_HEADERS.SKIP_SW_HANDLER]: true } }
      );
      return response.items;
    },
  });
