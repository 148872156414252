import { createContext, useContext, useEffect, useReducer } from "react";
import { useMatch } from "react-router-dom";

import { usePersistedState } from "@web/common/hooks";
import { usePrevious } from "@web/utils";

import { BASKET_STORAGE_KEY } from "../../config/constants";
import { RoutesConfig } from "../../config/routes";
import useBasket from "../../hooks/useBasket";
import { BasketDispatch, BasketState, BasketStateSchema } from "./models";
import { basketStateReducer } from "./reducer";

export const defaultBasketState: BasketState = {
  lineItems: [],
  rfqItems: {},
};

export const BasketContext = createContext<
  { state: BasketState; dispatch: BasketDispatch } | undefined
>(undefined);

type CategoriesProviderProps = { children: React.ReactNode };

export const BasketProvider: React.FC<CategoriesProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(basketStateReducer, defaultBasketState);
  const value = { state, dispatch };

  return <BasketContext.Provider value={value}>{children}</BasketContext.Provider>;
};

export const useBasketState = () => {
  const context = useContext(BasketContext);
  if (context === undefined) {
    throw new Error("useBasketState must be used within a BasketContext");
  }
  return context;
};

export const BasketStateLifecycle: React.FC = () => {
  const { state, dispatch } = useBasketState();
  const { clearOrderRequisitionFlow } = useBasket();

  const isCategoryRoute = !!useMatch(`${RoutesConfig.category}/:categoryId`);
  const isSearchRoute = !!useMatch(RoutesConfig.search);
  const isBasketRoute = !!useMatch(RoutesConfig.basket);
  const isRequisitionInfoRoute = !!useMatch(RoutesConfig.requisitionInfo);
  const isOrderInfoRoute = !!useMatch(RoutesConfig.orderInfo);
  // Do not clear on gatherSetup since there may be a port or order type preselected in the Reorder feature flow
  const isGatherSetupRoute = !!useMatch(RoutesConfig.gatherSetup);

  const isClearingRoute =
    !isCategoryRoute &&
    !isSearchRoute &&
    !isBasketRoute &&
    !isRequisitionInfoRoute &&
    !isOrderInfoRoute &&
    !isGatherSetupRoute;
  const prevIsClearingRoute = usePrevious(isClearingRoute);

  useEffect(() => {
    if (isClearingRoute && isClearingRoute !== prevIsClearingRoute) {
      clearOrderRequisitionFlow();
    }
  }, [clearOrderRequisitionFlow, isClearingRoute, prevIsClearingRoute]);

  usePersistedState(
    BASKET_STORAGE_KEY,
    state,
    (data: BasketState) => {
      dispatch({ type: "setBasketState", value: data });
    },
    BasketStateSchema
  );

  return null;
};
