import { z } from "zod";

import { optionalNonEmptyString } from "./common";

export const LiteSkuGeneralInformationSchema = z.object({
  minimumOrderQuantity: z.number().positive().optional(),
  impaCode: optionalNonEmptyString(),
  issaCode: optionalNonEmptyString(),
  customerOrderQuantity: z.number().nonnegative().optional(),
  type: optionalNonEmptyString(),
  design: optionalNonEmptyString(),
  nickname: optionalNonEmptyString(),
  dangerousGoods: optionalNonEmptyString(),
  hazardousMaterial: optionalNonEmptyString(),
  packingQuantity: z.number().nonnegative().optional(),
  salesMultiple: optionalNonEmptyString(),
  gtin: optionalNonEmptyString(),
});
