import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Heading, Input, Paragraph, RegularButton } from "@web/ui";

import { OpenAPI } from "../../typegens";
import { RetrieveVesselTokenConfirmation } from "./RetrieveVesselTokenConfirmation";

type RetrieveVesselTokenFormValues = {
  imoNumber: string;
};

export const RetrieveVesselToken = () => {
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [imoNumber, setImoNumber] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RetrieveVesselTokenFormValues>({
    mode: "onSubmit",
    shouldUnregister: true,
  });

  const onSubmit = (data: RetrieveVesselTokenFormValues) => {
    setFormSubmitted(true);
    setImoNumber(data.imoNumber);
    fetch(`${OpenAPI.BASE}/public/vessels/${data.imoNumber}/tokens`, {
      method: "POST",
    });
  };

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="flex flex-col">
        {!isFormSubmitted ? (
          <>
            <Heading size="100" className="mb-4" data-testid="retrieveVesselToken_title">
              Hello, Crew member!
            </Heading>
            <Paragraph size="200" className="w-96">
              Before we send your vessel token we need to know your vessel’s IMO Number
            </Paragraph>
            <div className="mt-5">
              <Controller
                name="imoNumber"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  pattern: {
                    value: /^\d{7}$/,
                    message: "The IMO number needs to be 7 digits",
                  },
                }}
                render={({ field }) => (
                  <>
                    <Input
                      label="IMO Number"
                      maxLength={7}
                      value={field.value}
                      errorMessage={errors.imoNumber?.message as string}
                      onChange={(e) => {
                        field.onChange(e);
                        setImoNumber(e.target.value);
                      }}
                      required
                      withBorder
                      data-testid="retrieveVesselToken_input"
                    />
                    <RegularButton
                      className="sm:py-2 sm:px-3 mt-6"
                      variant="primary"
                      size="large"
                      label="Send Token"
                      disabled={imoNumber === ""}
                      onClick={handleSubmit(onSubmit)}
                      type="submit"
                      data-testid="retrieveVesselToken_button"
                    />
                  </>
                )}
              />
            </div>
          </>
        ) : (
          <RetrieveVesselTokenConfirmation imoNumber={imoNumber} />
        )}
      </div>
    </div>
  );
};
