import { DefaultError, useMutation } from "@tanstack/react-query";

import { LocalOrderDraft, LocalPartialOrderDraft } from "src/models";
import { OrderDraftsService } from "src/services/OrderDraftsService";

import useBasket from "../useBasket";
import { useToastMessage } from "../useToastMessage";
import { useOrderDraftsQueryHelpers } from "./useOrderDraftsQuery";

type UseUpdateOrderDraftMutationArgs = {
  onSuccess?: (updatedDraft: LocalOrderDraft) => void;
  onError?: (draftToUpdateId: string, error: Error) => void;
  hasSuccessMessage?: boolean;
  hasErrorMessage?: boolean;
};

export const useUpdateOrderDraftMutation = ({
  onSuccess,
  onError,
  hasSuccessMessage = false,
  hasErrorMessage = false,
}: UseUpdateOrderDraftMutationArgs = {}) => {
  const { setToastMessage } = useToastMessage();
  const { updateDraftInQuery } = useOrderDraftsQueryHelpers();
  const { setDraft } = useBasket();

  return useMutation<
    LocalOrderDraft,
    DefaultError,
    { draftId: string; draftData: LocalPartialOrderDraft }
  >({
    mutationKey: ["updateDraft"],
    mutationFn: OrderDraftsService.updateDraft,
    networkMode: "always",
    onSuccess: (updatedDraft) => {
      hasSuccessMessage &&
        setToastMessage({
          type: "success",
          message: "Your draft was successfully saved",
        });

      if (onSuccess) {
        onSuccess(updatedDraft);
      }

      updateDraftInQuery(updatedDraft);

      setDraft({ draftId: updatedDraft.id, updatedAt: updatedDraft.updatedAt });
    },
    onError: (error, { draftId: draftToUpdateId }) => {
      hasErrorMessage &&
        setToastMessage({
          type: "failure",
          message: "There was an error while saving your draft",
        });

      if (onError) {
        onError(draftToUpdateId, error);
      }
    },
  });
};
