/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { LiteStocktakeCountListRequest } from "../models/LiteStocktakeCountListRequest";
import type { LiteStocktakeInitialReportData } from "../models/LiteStocktakeInitialReportData";
import type { LiteStocktakeReport } from "../models/LiteStocktakeReport";
import type { LiteStocktakeReportRequest } from "../models/LiteStocktakeReportRequest";
import type { LiteStocktakeReportsResponse } from "../models/LiteStocktakeReportsResponse";
import type { LiteStocktakeSetupDataRequest } from "../models/LiteStocktakeSetupDataRequest";

export class LiteStocktakeControllerService {
  /**
   * @returns binary OK
   * @throws ApiError
   */
  public static exportStocktakeReportToExcel(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/lite/stocktakes/{id}/excel-export",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns LiteStocktakeInitialReportData OK
   * @throws ApiError
   */
  public static getStocktakeInitialReportData(
    {
      requestBody,
    }: {
      requestBody: LiteStocktakeSetupDataRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteStocktakeInitialReportData> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/lite/stocktakes/initial-report",
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns binary OK
   * @throws ApiError
   */
  public static exportStockCountListToExcel(
    {
      requestBody,
    }: {
      requestBody: LiteStocktakeCountListRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/lite/stocktakes/excel-export",
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns LiteStocktakeReportsResponse OK
   * @throws ApiError
   */
  public static getStocktakeReports(
    {
      vesselId,
    }: {
      vesselId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteStocktakeReportsResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/stocktakes",
      query: {
        vesselId: vesselId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns LiteStocktakeReport OK
   * @throws ApiError
   */
  public static saveStocktakeReport(
    {
      requestBody,
    }: {
      requestBody: LiteStocktakeReportRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteStocktakeReport> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/lite/stocktakes",
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns LiteStocktakeReport OK
   * @throws ApiError
   */
  public static getStocktakeReport(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteStocktakeReport> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/stocktakes/{id}",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static deleteStocktakeReport(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/lite/stocktakes/{id}",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
