import classnames from "classnames";
import { useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { ActionBar, Loading, containerPadding } from "@web/ui";

import { TopBarController } from "src/components/TopBar";
import { RoutesConfig, RoutesParams } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import {
  useCreateStocktakeDraftMutation,
  useGetInitialStocktakeReportDataMutation,
  useStocktakeDraftsListQuery,
} from "src/hooks/stocktakes";
import { LocalStocktakeSetupForm } from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

import { StocktakeSetupForm } from "./StocktakeSetupForm";

export const StocktakeSetupPage = () => {
  const [{ configuration }] = useAppStateContext();
  const vesselId = configuration?.vessel.id;
  const availableStocktakeTypes = configuration?.availableStocktakeTypes;
  const navigate = useNavigate();
  const [submittedFormData, setSubmittedFormData] = useState<LocalStocktakeSetupForm>();
  const {
    isPending,
    isSuccess,
    data: stocktakeDraftsList,
  } = useStocktakeDraftsListQuery({
    vesselId: vesselId || "",
  });

  // When `availableStocktakeTypes` is empty, it means that we have insufficient
  // data to create a request for initial stocktake data, so we can navigate
  // the user away to save them the trouble of filling out the form.
  useEffect(() => {
    if (availableStocktakeTypes?.length === 0) {
      navigate(RoutesConfig.stocktake.nothingToStocktake);
    }
  }, [availableStocktakeTypes?.length, navigate]);

  // TODO #9594: Handle API errors - show them to the user?
  const { mutate: getInitialStocktakeReportData, isPending: isInitialStocktakeReportDataPending } =
    useGetInitialStocktakeReportDataMutation({
      hasErrorMessage: true,
      onSuccess: (initialStocktakeReportData) => {
        if (!vesselId || !submittedFormData) {
          return;
        }

        const partialDraft = LocalStocktakeService.convertFromStocktakeSetupToPartialDraft({
          vesselId,
          stocktakeSetupForm: submittedFormData,
          initialStocktakeReportData,
        });
        createStocktakeDraft(partialDraft);
      },
    });

  const { mutate: createStocktakeDraft, isPending: isCreatingDraft } =
    useCreateStocktakeDraftMutation({
      vesselId: vesselId || "",
      hasErrorMessage: true,
      onSuccess: (createdDraft) => {
        navigate(
          generatePath(RoutesConfig.stocktake.edit, {
            [RoutesParams.STOCKTAKE_ID]: createdDraft.id,
          })
        );
      },
    });

  useEffect(() => {
    if (!vesselId || !submittedFormData) {
      return;
    }

    getInitialStocktakeReportData(
      LocalStocktakeService.convertFromStocktakeSetupFormToInitialStocktakeReportDataRequest(
        vesselId,
        submittedFormData
      )
    );
  }, [getInitialStocktakeReportData, submittedFormData, vesselId]);

  const isAnythingPending = isInitialStocktakeReportDataPending || isCreatingDraft;

  if (
    isPending ||
    !isSuccess ||
    !availableStocktakeTypes ||
    availableStocktakeTypes?.length === 0
  ) {
    return <Loading />;
  }

  return (
    <div className="bg-neutral_100 flex flex-col min-h-full">
      <TopBarController />
      <ActionBar
        backButtonSettings={{
          title: "Back to stocktake overview",
          onClick: () => {
            navigate(RoutesConfig.stocktake.overview);
          },
        }}
      />
      <div className={classnames(containerPadding, "mt-7 mb-7")}>
        <div className="max-w-xl ml-auto mr-auto">
          <StocktakeSetupForm
            availableStocktakeTypes={availableStocktakeTypes}
            onValidatedFormSubmit={setSubmittedFormData}
            existingDrafts={stocktakeDraftsList}
            isPending={isAnythingPending}
          />
        </div>
      </div>
    </div>
  );
};
