import _debounce from "lodash/debounce";
import { useCallback, useEffect } from "react";
import { Control, UseFormGetValues, useWatch } from "react-hook-form";

import { useUpdateStocktakeDraftMutation } from "src/hooks/stocktakes";
import { LocalStocktakeReportForm } from "src/models";

const SAVING_DRAFT_DEBOUNCE_TIME = 500;

type Params = {
  control: Control<LocalStocktakeReportForm, unknown>;
  getValues: UseFormGetValues<LocalStocktakeReportForm>;
  vesselId: string;
  draftId: string;
  defaultValues: LocalStocktakeReportForm;
  hasErrorMessage?: boolean;
};

type UseAutosaveStocktakeDraftForm = {
  isError: boolean;
  retry: () => void;
  abortAutosave: () => void;
};

export const useAutosaveStocktakeDraftForm = ({
  control,
  getValues,
  vesselId,
  draftId,
  defaultValues,
  hasErrorMessage = false,
}: Params): UseAutosaveStocktakeDraftForm => {
  const { mutate: updateDraft, isError } = useUpdateStocktakeDraftMutation({
    hasErrorMessage,
    vesselId,
    draftId,
  });

  const changedData = useWatch({ control, defaultValue: defaultValues });

  const saveDraft = useCallback(() => {
    const formData = getValues();
    updateDraft(formData);
  }, [getValues, updateDraft]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDraftDebounced = useCallback(
    _debounce(() => {
      saveDraft();
    }, SAVING_DRAFT_DEBOUNCE_TIME),
    [saveDraft]
  );

  const abortUpdateDraftDebounced = useCallback(
    () => updateDraftDebounced.cancel(),
    [updateDraftDebounced]
  );

  useEffect(() => {
    updateDraftDebounced();
  }, [changedData, updateDraftDebounced]);

  return {
    isError,
    retry: saveDraft,
    abortAutosave: abortUpdateDraftDebounced,
  };
};
