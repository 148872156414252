import { z } from "zod";

import { preprocessFormAmountValue } from "@web/utils";

import { ApiMoneySchema } from "../ApiMoneySchema";
import { nonEmptyString } from "../common";
import { LiteStocktakeExtraItemSchema } from "./LiteStocktakeExtraItemSchema";

export const StocktakeReportNewExtraItemFormSchema = LiteStocktakeExtraItemSchema.omit({
  entityQuantityOnStock: true,
}).extend({
  // We want to have quantity optional because we are filling empty values with zeros *after* form validation happens,
  // but we still want to validate that the input is nonnegative.
  quantity: z.preprocess(
    preprocessFormAmountValue,
    z.number().nonnegative({ message: "Quantity must be 0 or greater" }).optional().nullable()
  ),
  singleUnitGrossPrice: ApiMoneySchema.extend({
    amount: z.preprocess(
      preprocessFormAmountValue,
      z
        .number()
        .nonnegative({ message: "Price per unit must be 0 or greater if given" })
        .optional()
        .nullable()
    ),
  }),
  measurementUnit: nonEmptyString({ requiredMessage: "Unit of measure is required" }),
  name: nonEmptyString({ requiredMessage: "Product name is required" }),
});

export type LocalStocktakeReportNewExtraItemForm = z.infer<
  typeof StocktakeReportNewExtraItemFormSchema
>;
