import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Loading } from "@web/ui";
import { isDefined } from "@web/utils";

import { RoutesParams } from "src/config/routes";
import { useEditableStocktakeDraftQuery } from "src/hooks/stocktakes";
import { LocalStocktakeReportDraft } from "src/models";

import { StocktakeEditForm } from "./StocktakeEditForm";

export const StocktakeEditPage = () => {
  const { [RoutesParams.STOCKTAKE_ID]: stocktakeId } = useParams() as {
    stocktakeId: string;
  };

  const { isPending, data: editableStocktakeDraftData } = useEditableStocktakeDraftQuery({
    draftId: stocktakeId,
  });

  // Cache and use this value memoized in state to prevent rerendering of the form.
  // These rerenders would come from the fact, that each autosave of the form would trigger
  // the useEditableStocktakeDraftQuery invalidation, resulting in it refetching,
  // and essentially rerendering this component.
  const [stableEditableStocktakeDraftData, setStableEditableStocktakeDraftData] = useState<
    LocalStocktakeReportDraft | undefined
  >();
  useEffect(() => {
    if (isDefined(editableStocktakeDraftData) && !isDefined(stableEditableStocktakeDraftData)) {
      setStableEditableStocktakeDraftData(editableStocktakeDraftData);
    }
  }, [editableStocktakeDraftData, stableEditableStocktakeDraftData]);

  if (isPending || !stableEditableStocktakeDraftData) {
    return <Loading />;
  }

  return <StocktakeEditForm editableStocktakeDraftData={stableEditableStocktakeDraftData} />;
};
