import { StorageError, StorageRequest } from "@web/models";

import { stocktakeReportsListStore, stocktakeReportsStore } from "src/objectStorage";

export const OfflineStocktakeReportsService = {
  deleteReport: async (reportId: string): Promise<void> => {
    const request: StorageRequest = {
      body: reportId,
      access: "write",
    };

    if (!reportId) {
      throw new StorageError(request, { status: 400 }, "No report id");
    }

    try {
      await stocktakeReportsListStore.delete(reportId);
      await stocktakeReportsStore.delete(reportId);
    } catch (error) {
      throw new StorageError(request, { status: 500, body: error }, "Error deleting report");
    }
  },
};
