import { TFunction } from "i18next";

import { OrderRequisitionStatus } from "@web/common/network";
import { IBadge } from "@web/ui";

export const getBadgeSettingsFromOrderStatus = (
  status: OrderRequisitionStatus,
  t: TFunction<"translation", undefined>
): {
  text: string;
  color: IBadge["color"];
} => {
  switch (status) {
    case "PENDING_PURCHASER_APPROVAL":
      return {
        text: t(`status.${status}`),
        color: "danger",
      };
    case "PURCHASER_REJECTED":
      return {
        text: t(`status.${status}`),
        color: "danger",
      };
    case "CANCELLED":
      return {
        text: t(`status.${status}`),
        color: "warning",
      };
    // TODO #9356: Remove CLOSED status related code, since it ceased to exist
    case "CLOSED":
      return {
        text: t(`status.${status}`),
        color: "base",
      };
    case "PENDING_CANCELLATION":
      return {
        text: t(`status.${status}`),
        color: "base",
      };
    case "SUPPLIER_ACKNOWLEDGED":
      return {
        text: t(`status.${status}`),
        color: "primary",
      };
    case "SUPPLIER_CONFIRMED":
      return {
        text: t(`status.${status}`),
        color: "success",
      };
    case "SUPPLIER_DELIVERED":
      return {
        text: t(`status.${status}`),
        color: "success",
      };
    case "SUPPLIER_PENDING":
      return {
        text: t(`status.${status}`),
        color: "primary",
      };
    case "SUPPLIER_REJECTED":
      return {
        text: t(`status.${status}`),
        color: "danger",
      };
    case "QUOTE_EXPIRED":
      return {
        text: t(`status.${status}`),
        color: "base",
      };
    case "QUOTE_REJECTED":
      return {
        text: t(`status.${status}`),
        color: "danger",
      };
    case "SUPPLIER_QUOTE_PENDING":
      return {
        text: t(`status.${status}`),
        color: "primary",
      };
    case "SUPPLIER_QUOTE_PROVIDED":
      return {
        text: t(`status.${status}`),
        color: "base",
      };
    case "SUPPLIER_QUOTE_ACKNOWLEDGED":
      return {
        text: t(`status.${status}`),
        color: "primary",
      };
    case "SUPPLIER_QUOTE_REJECTION_ACKNOWLEDGED":
      return {
        text: t(`status.${status}`),
        color: "danger",
      };
    case "DRAFT_CREATED":
      return {
        text: t(`status.${status}`),
        color: "primary",
      };
    case "DRAFT_DELETED":
      return {
        text: t(`status.${status}`),
        color: "base",
      };
    default:
      return {
        text: "",
        color: "base",
      };
  }
};
