import { DefaultError, useMutation } from "@tanstack/react-query";

import { exportBasketToExcel } from "../api/exportBasketToExcel";
import { LiteShareBasketRequest } from "../typegens";

export const useExportBasketToExcelMutation = (options = {}) => {
  return useMutation<Blob, DefaultError, LiteShareBasketRequest>({
    mutationKey: ["exportBasketToExcel"],
    mutationFn: exportBasketToExcel,
    ...options,
  });
};
