import { z } from "zod";

import { assertTypeEquality } from "@web/utils";

import { LiteStocktakeItem } from "src/typegens";

import { ApiMoneySchema } from "../ApiMoneySchema";
import { nonEmptyString } from "../common";
import { LiteStocktakePortSchema } from "./LiteStocktakePortSchema";
import { LiteStocktakeSkuDetailsSchema } from "./LiteStocktakeSkuDetailsSchemat";

export const LiteStocktakeItemSchema = z.object({
  id: nonEmptyString(),
  categoryId: nonEmptyString(),
  port: LiteStocktakePortSchema,
  orderDate: nonEmptyString(),
  singleUnitGrossPrice: ApiMoneySchema,
  robValue: ApiMoneySchema,
  // This will differ for drafts & report form - see `StocktakeReportDraftItemSchema` & `StocktakeReportItemFormSchema`
  quantity: z.number().nonnegative(),
  entityQuantityOnStock: z.number().nonnegative(),
  skuDetails: LiteStocktakeSkuDetailsSchema,
});

export type LocalLiteStocktakeItem = z.infer<typeof LiteStocktakeItemSchema>;

assertTypeEquality<LocalLiteStocktakeItem, LiteStocktakeItem, LocalLiteStocktakeItem>();
