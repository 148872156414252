import { DefaultError, useMutation } from "@tanstack/react-query";

import { triggerFileDownload } from "@web/utils";

import { exportStockCountListToExcel } from "src/api/stocktakes";
import { type LiteStocktakeCountListRequest } from "src/typegens";

import { useToastMessage } from "../useToastMessage";

type UseExportStockCountListToExcelMutationArgs = {
  fileDownloadNameWithoutExtension: string;
  onSuccess?: (file: Blob) => void;
  onError?: (error: Error) => void;
  hasSuccessMessage?: boolean;
  hasErrorMessage?: boolean;
};

export const useExportStockCountListToExcelMutation = ({
  onSuccess,
  onError,
  hasSuccessMessage,
  hasErrorMessage,
  fileDownloadNameWithoutExtension,
}: UseExportStockCountListToExcelMutationArgs) => {
  const { setToastMessage } = useToastMessage();

  return useMutation<Blob, DefaultError, LiteStocktakeCountListRequest>({
    mutationKey: ["exportStockCountListToExcel"],
    mutationFn: exportStockCountListToExcel,
    onSuccess: (file: Blob) => {
      hasSuccessMessage &&
        setToastMessage({
          type: "success",
          message: "The stock count list will be downloaded soon.",
        });

      if (onSuccess) {
        onSuccess(file);
      }

      triggerFileDownload({
        file,
        fileNameWithExtension: `${fileDownloadNameWithoutExtension}.xlsx`,
      });
    },
    onError: (error) => {
      console.error(error);

      hasErrorMessage &&
        setToastMessage({
          type: "failure",
          message: "There was an issue while downloading the stock count list. Please try again.",
        });

      if (onError) {
        onError(error);
      }
    },
  });
};
