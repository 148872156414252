import { z } from "zod";

import { commonValidStocktakeReportRules } from "./common";

export const StocktakeSetupFormSchema = z.object({
  type: commonValidStocktakeReportRules.type,
  stocktakeDate: commonValidStocktakeReportRules.stocktakeDate,
  stocktakerName: commonValidStocktakeReportRules.stocktakerInformation.name,
  stocktakerPosition: commonValidStocktakeReportRules.stocktakerInformation.position,
  subject: commonValidStocktakeReportRules.subject,
});

export type LocalStocktakeSetupForm = z.infer<typeof StocktakeSetupFormSchema>;
