import { isAfter } from "date-fns";
import debounce from "lodash/debounce";
import { useCallback, useEffect } from "react";
import { useMatch } from "react-router-dom";

import { LEGACY_useOrderDraft } from "src/hooks/LEGACY_useOrderDraft";
import { LEGACY_useUpdateOrderDraftMutation } from "src/hooks/LEGACY_useUpdateOrderDraftMutation";
import useBasket from "src/hooks/useBasket";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { LocalPartialOrderDraft } from "src/models";
import {
  LiteDraftItem,
  LiteDraftRfqItem,
  LiteDraftsRequesterInformation,
  LiteDutyFreeDeclaration,
} from "src/typegens";

import { RoutesConfig } from "../../config/routes";
import { usePrepareOrderDraft } from "../../hooks/orderDrafts/usePrepareOrderDraft";
import { useUpdateOrderDraftMutation } from "../../hooks/orderDrafts/useUpdateOrderDraftMutation";

const LEGACY_SAVING_DRAFT_ITEM_INTERVAL = 1_000;
const SAVING_DRAFT_ITEM_DEBOUNCE_TIME = 1_000;

type Props = {
  children: React.ReactNode;
};

export const OrderDraftStateLifecycle = ({ children }: Props) => {
  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();
  const { getOrderDraftData: getDraftData } = LEGACY_useOrderDraft();
  const { draft, lastUpdated: basketLastUpdated } = useBasket();

  const { mutate, isError, isPending } = LEGACY_useUpdateOrderDraftMutation();

  const { getPartialOrderDraftData } = usePrepareOrderDraft();
  const {
    mutate: updateDraft,
    isError: isUpdateDraftError,
    isPending: isUpdateDraftPending,
  } = useUpdateOrderDraftMutation({
    hasErrorMessage: true,
  });

  const isCategoryRoute = !!useMatch(`${RoutesConfig.category}/:categoryId`);
  const isSearchRoute = !!useMatch(RoutesConfig.search);
  const isBasketRoute = !!useMatch(RoutesConfig.basket);

  const isUpdateDraftRoute = isCategoryRoute || isSearchRoute || isBasketRoute;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const LEGACY_updateDraftDebounced = useCallback(
    debounce(
      ({
        dutyFreeDeclaration,
        draftId,
        catalogItems,
        rfqItems,
        requesterInformation,
        portId,
        supplierId,
        vesselId,
        deliveryDate,
      }: {
        draftId: string;
        catalogItems?: LiteDraftItem[];
        rfqItems?: LiteDraftRfqItem[];
        requesterInformation: LiteDraftsRequesterInformation;
        portId: string;
        supplierId: string;
        vesselId: string;
        dutyFreeDeclaration?: LiteDutyFreeDeclaration;
        deliveryDate?: string;
      }) => {
        mutate({
          draftId: draftId,
          requestBody: {
            dutyFreeDeclaration,
            portId,
            supplierId,
            vesselId,
            requesterInformation,
            catalogItems,
            rfqItems,
            deliveryDate,
          },
        });
      },
      LEGACY_SAVING_DRAFT_ITEM_INTERVAL
    ),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDraftDebounced = useCallback(
    debounce((draftId: string, draftData: LocalPartialOrderDraft) => {
      updateDraft({
        draftId,
        draftData,
      });
    }, SAVING_DRAFT_ITEM_DEBOUNCE_TIME),
    []
  );

  useEffect(() => {
    if (
      isUpdateDraftRoute &&
      ((draft &&
        basketLastUpdated &&
        isAfter(basketLastUpdated, new Date(draft.updatedAt)) &&
        draft.status !== "CREATION_STARTED" &&
        !isPending &&
        !isUpdateDraftPending) ||
        (draft && !isPending && !isUpdateDraftPending && (isError || isUpdateDraftError)))
    ) {
      if (isOfflineDraftEnabled) {
        const draftToUpdate = getPartialOrderDraftData();
        updateDraftDebounced(draft.id, draftToUpdate);
        return;
      }

      // This is a dirty fix for #7668. We won't need all of this once we remove
      // online draft feature from the app.
      // The consequence is that if the user removes all items from the draft,
      // the draft will not be deleted from the backend, but the draft will
      // still have some items in it.
      let draftData;
      try {
        draftData = getDraftData();
      } catch {
        // empty
      }
      if (draftData) {
        LEGACY_updateDraftDebounced({
          ...draftData,
          draftId: draft.id,
        });
      }
    } else if (!draft) {
      // Do not cancel LEGACY_updateDraftDebounced, as this may result
      // in losing basket changes that were made by the user prior
      // to clearing the basket.
      // It is safe to do with the offline draft.

      if (isOfflineDraftEnabled) {
        updateDraftDebounced.cancel();
        return;
      }
    }
    // run updates when products in basket changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    draft,
    basketLastUpdated,
    isError,
    isUpdateDraftError,
    isPending,
    isUpdateDraftPending,
    isUpdateDraftRoute,
  ]);

  return <>{children}</>;
};
