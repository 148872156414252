import { memo } from "react";

import { Paragraph } from "@web/ui";
import { formatMoney, isDefined } from "@web/utils";

import {
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemQuantityFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";

import { useItemFormDetails } from "./useItemFormDetails";

type Props = {
  initialQuantity: number | null | undefined;
  initialSingleUnitGrossPriceAmount: number | null | undefined;
  initialMeasurementUnit: string;
  salesEntityQuantity: number;
  currencyCode: string;
  quantityFieldPath: StocktakeReportItemQuantityFieldPath;
  singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath;
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
};

export const RobValueDetails = memo(
  ({
    initialQuantity,
    initialSingleUnitGrossPriceAmount,
    initialMeasurementUnit,
    salesEntityQuantity,
    currencyCode,
    quantityFieldPath,
    singleUnitGrossPriceFieldPath,
    measurementUnitFieldPath,
  }: Props) => {
    const { productRobValue } = useItemFormDetails({
      initialQuantity,
      initialSingleUnitGrossPriceAmount,
      initialMeasurementUnit,
      salesEntityQuantity,
      currencyCode,
      quantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    });

    return (
      <>
        <Paragraph size="200" weight="heavy" color="text-textIcon-blackSecondary">
          Value:
        </Paragraph>
        <Paragraph size="200" weight="heavy" className="whitespace-nowrap">
          {isDefined(productRobValue) ? formatMoney(productRobValue) : "Not Provided"}
        </Paragraph>
      </>
    );
  }
);
RobValueDetails.displayName = "RobValueDetails";
