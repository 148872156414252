import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Loading } from "@web/ui";

import {
  BASKET_FORM_ANSWERS_STORAGE_KEY,
  BASKET_STORAGE_KEY,
  NETWORK_TOGGLE_MODE_STORAGE_KEY,
  PUNCHOUT_SESSION_ID_STORAGE_KEY,
  SUPPLIER_ID_STORAGE_KEY,
  VESSEL_STORAGE_KEY,
  VESSEL_TOKEN_STORAGE_KEY,
} from "src/config/constants";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useNetworkToggle } from "src/contexts/NetworkToggle";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { NoAccess } from "src/pages/NoAccess";
import { RetrieveVesselToken } from "src/pages/RetrieveVesselToken";
import { OpenAPI } from "src/typegens";

interface Props {
  children: React.ReactNode;
}

const Authenticated: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, dispatch] = useAppStateContext();
  const { clearOfflineCapabilitiesState } = useOfflineCapabilities();
  const { clearForcedNetworkState } = useNetworkDetector();
  const { setToggleOnline } = useNetworkToggle();

  const [loading, setLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const cleanupVesselSession = () => {
    // Do not remove the vessel token here!
    localStorage.removeItem(VESSEL_STORAGE_KEY);
    localStorage.removeItem(BASKET_STORAGE_KEY);
    localStorage.removeItem(BASKET_FORM_ANSWERS_STORAGE_KEY);
    localStorage.removeItem(NETWORK_TOGGLE_MODE_STORAGE_KEY);
    // Not manually clearing the basket's & app's state since we reload the page anyway
  };

  const cleanupPunchoutSession = () => {
    localStorage.removeItem(PUNCHOUT_SESSION_ID_STORAGE_KEY);
    localStorage.removeItem(SUPPLIER_ID_STORAGE_KEY);
  };

  // Resets all values related to `offlineCapabilities` in localStorage and app's state
  const clearOfflineState = () => {
    clearForcedNetworkState();
    clearOfflineCapabilitiesState();
    setToggleOnline();
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const newPunchoutSessionId = params.get("punchoutSessionId");
    const newSupplierId = params.get("supplierId");
    const newToken = params.get("vesselToken");
    const storedToken = localStorage.getItem(VESSEL_TOKEN_STORAGE_KEY);
    const token = newToken || storedToken;
    let hasParams = false;
    let isNewSession = false;

    if (token) {
      OpenAPI.TOKEN = token;
      dispatch({ type: "setVesselToken", value: token });

      if (newToken) {
        hasParams = true;
      }

      if (newToken && newToken !== storedToken) {
        cleanupVesselSession();
        cleanupPunchoutSession();
        clearOfflineState();
        localStorage.setItem(VESSEL_TOKEN_STORAGE_KEY, newToken);
        isNewSession = true;
      }
    } else {
      setIsUnauthorized(true);
    }

    const storedPunchoutSessionId = localStorage.getItem("punchoutSessionId");
    const storedSupplierId = localStorage.getItem("supplierId");
    const punchoutSessionId = newPunchoutSessionId || storedPunchoutSessionId;
    const supplierId = newSupplierId || storedSupplierId;

    if (punchoutSessionId && supplierId) {
      dispatch({ type: "setIsPunchoutSession", value: true });
      if (newPunchoutSessionId || newSupplierId) {
        hasParams = true;
      }

      if (
        (newPunchoutSessionId && newPunchoutSessionId !== storedPunchoutSessionId) ||
        (newSupplierId && newSupplierId !== storedSupplierId)
      ) {
        cleanupVesselSession();
        cleanupPunchoutSession();
        clearOfflineState();

        if (newPunchoutSessionId) {
          localStorage.setItem(PUNCHOUT_SESSION_ID_STORAGE_KEY, newPunchoutSessionId);
        }
        if (newSupplierId) {
          localStorage.setItem(SUPPLIER_ID_STORAGE_KEY, newSupplierId);
        }
        isNewSession = true;
      }
    }

    if (hasParams) {
      // This strips search params (which we want to do only in case of new token)
      navigate(location.pathname, { replace: true });
    }

    if (isNewSession) {
      window.location.reload();
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (location.pathname === "/retrieveVesselToken") {
    return <RetrieveVesselToken />;
  }

  if (isUnauthorized) {
    return <NoAccess />;
  }

  return <>{children}</>;
};

export default Authenticated;
