import { TruckIcon } from "@heroicons/react/outline";

import { LinkButton } from "@web/ui";

import { AttentionInfoItemType } from "src/state/models";

const attentionInfoItemTypeToIcon: Readonly<{
  [key in AttentionInfoItemType]: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}> = {
  DELIVERY_CHARGES: TruckIcon,
};

const attentionInfoItemTypeToLabel: Readonly<{ [key in AttentionInfoItemType]: string }> = {
  DELIVERY_CHARGES: "See Delivery Charges",
};

export const AttentionInfoItem = ({
  type,
  onClick,
}: {
  type: AttentionInfoItemType;
  onClick: () => void;
}) => {
  const Icon = attentionInfoItemTypeToIcon[type];
  const label = attentionInfoItemTypeToLabel[type];
  return (
    <div className="flex items-center gap-2 px-4 py-2 border border-warningDefault rounded-md bg-white">
      <Icon className="w-5 text-textIcon-blackSecondary" />
      <LinkButton size="small" variant="primary" label={label} onClick={onClick} />
    </div>
  );
};
