import { z } from "zod";

export const SkuAttachmentTypeSchema = z.union([
  z.literal("TYPE_APPROVAL"),
  z.literal("DECLARATION_OF_CONFORMITY"),
  z.literal("DNV"),
  z.literal("MARINE_EQUIPMENT_DIRECTIVE"),
  z.literal("MATERIAL_SAFETY_DATA_SHEET"),
  z.literal("SAFETY_DATA_SHEETS"),
  z.literal("INVENTORY_OF_HAZARDOUS_MATERIALS"),
  z.literal("TEST_CERTIFICATE"),
  z.literal("CERTIFICATE_OF_COMPLIANCE"),
  z.literal("MANUALS"),
  z.literal("USER_INSTRUCTIONS"),
  z.literal("NOT_DEFINED"),
]);
