import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { Modal } from "@web/ui";

import { RoutesConfig } from "src/config/routes";
import { useWasUnableToReconnect } from "src/hooks/useWasUnableToReconnect";

import { NetworkConnectionLostModal } from "../../components/Modal/NetworkConnectionLostModal";
import { NetworkConnectionRestoredModal } from "../../components/Modal/NetworkConnectionRestoredModal";
import { UnableToGoOnlineModal } from "../../components/Modal/UnableToGoOnlineModal";
import { NETWORK_TOGGLE_MODE_STORAGE_KEY } from "../../config/constants";
import { useNetworkDetector } from "../NetworkDetector";
import { useOfflineCapabilities } from "../OfflineCapabilities";
import { useNetworkToggle } from "./useNetworkToggle";

export const NetworkToggleLifecycle = () => {
  const location = useLocation();
  const { isToggleOnline, networkToggleMode, setToggleOffline, setToggleOnline } =
    useNetworkToggle();
  const { becameOnline, isOffline, forceOffline, clearForcedNetworkState, wentOfflineFromOnline } =
    useNetworkDetector();
  const { wasUnableToReconnect } = useWasUnableToReconnect();
  const { isManualNetworkStateEnabled } = useOfflineCapabilities();
  const [isNoConnectionMsgShown, setIsNoConnectionMsgShown] = useState(false);
  const [isUnableToGoOnlineMsgShown, setIsUnableToGoOnlineMsgShown] = useState(false);
  const [isConnectionRestoredMsgShown, setIsConnectionRestoredMsgShown] = useState(false);

  const firstUpdate = useRef<boolean>(true);
  const isFirstUpdate = firstUpdate.current === true;

  useEffect(() => {
    if (isFirstUpdate) {
      const storedNetworkToggleMode = localStorage.getItem(NETWORK_TOGGLE_MODE_STORAGE_KEY);
      const networkToggleModeToSet = storedNetworkToggleMode || networkToggleMode;

      if (networkToggleModeToSet === "online") {
        // Clear forced state on NetworkState
        clearForcedNetworkState();
        setToggleOnline();
      } else if (networkToggleModeToSet === "offline") {
        // Set forced state on NetworkState
        forceOffline();
        setToggleOffline();
      }

      localStorage.setItem(NETWORK_TOGGLE_MODE_STORAGE_KEY, networkToggleModeToSet);
      firstUpdate.current = false;
      return;
    }

    localStorage.setItem(NETWORK_TOGGLE_MODE_STORAGE_KEY, networkToggleMode);
  }, [
    isFirstUpdate,
    networkToggleMode,
    setToggleOnline,
    setToggleOffline,
    clearForcedNetworkState,
    forceOffline,
  ]);

  useEffect(() => {
    if (isFirstUpdate) {
      return;
    }
    const isHomeRoute = location.pathname === RoutesConfig.gatherSetup;
    const wasDisconnected = isManualNetworkStateEnabled
      ? isToggleOnline && wentOfflineFromOnline
      : wentOfflineFromOnline;

    // in `/gatherSetup` route this modal will be invoked manually,
    // so we want to bail out from the generic mechanism
    if (wasUnableToReconnect && !isHomeRoute) {
      setIsUnableToGoOnlineMsgShown(true);
      setToggleOffline();
      forceOffline();
    }

    if (wasDisconnected) {
      // close modal with the opposite message first (in case it's displayed)
      setIsConnectionRestoredMsgShown(false);
      setIsNoConnectionMsgShown(true);
      setToggleOffline();
      forceOffline();
    }

    if (becameOnline && !isManualNetworkStateEnabled) {
      // close modal with the opposite message first (in case it's displayed)
      setIsNoConnectionMsgShown(false);
      setIsConnectionRestoredMsgShown(true);
    }
    // Do not include `isFirstUpdate` in the dependency array - wait for changes in `isToggleOnline` & `isOffline` flags
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToggleOnline, isOffline, setToggleOffline, forceOffline]);

  return (
    <>
      <Modal isOpen={isNoConnectionMsgShown} closeModal={() => setIsNoConnectionMsgShown(false)}>
        <NetworkConnectionLostModal
          hasAutomaticRestorationMsg={!isManualNetworkStateEnabled}
          onClose={() => setIsNoConnectionMsgShown(false)}
        />
      </Modal>
      <Modal
        isOpen={isUnableToGoOnlineMsgShown}
        closeModal={() => setIsUnableToGoOnlineMsgShown(false)}
      >
        <UnableToGoOnlineModal
          onClose={() => setIsUnableToGoOnlineMsgShown(false)}
          onContinueOffline={() => setIsUnableToGoOnlineMsgShown(false)}
        />
      </Modal>
      <Modal
        isOpen={isConnectionRestoredMsgShown}
        closeModal={() => setIsUnableToGoOnlineMsgShown(false)}
      >
        <NetworkConnectionRestoredModal onClose={() => setIsConnectionRestoredMsgShown(false)} />
      </Modal>
    </>
  );
};
