import { InfiniteData } from "@tanstack/react-query";

import { OrderRequisition } from "@web/common/network/model";

const deleteItemFromOrderRequisitionsList = (
  data: InfiniteData<{ items: OrderRequisition[]; cursor?: string }>,
  draftId: string
) => {
  return {
    ...data,
    pages: data.pages.map((page) => ({
      ...page,
      items: page.items.filter((item) => (item.id !== draftId ? true : item.type !== "DRAFT")),
    })),
  };
};

export const deleteDraftFromOrderRequisitionsList =
  (draftId: string) =>
  (oldData: InfiniteData<{ items: OrderRequisition[]; cursor?: string }> | undefined) => {
    if (oldData) {
      return deleteItemFromOrderRequisitionsList(oldData, draftId);
    }
  };
