import { LiteDraftRequest, LiteDraftsControllerService } from "../typegens";

export const updateDraft = async ({
  requestBody,
  draftId,
}: {
  draftId: string;
  requestBody: LiteDraftRequest;
}) => {
  const result = await LiteDraftsControllerService.update({
    draftId,
    requestBody,
  });

  return result;
};
