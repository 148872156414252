import { z } from "zod";

import { assertTypeEquality } from "@web/utils";

import { LiteStocktakeCategory } from "src/typegens";

import { nonEmptyString } from "../common";

// Recursive types are a bit tricky, so we need to define it like this, but we
// export and use only the end result (`StocktakeReportCategorySchema`).
// More info: https://github.com/colinhacks/zod#recursive-types

const BaseLiteStocktakeCategorySchema = z.object({
  id: nonEmptyString(),
  name: nonEmptyString(),
  productsNumber: z.number().nonnegative(),
});

export type LocalLiteStocktakeCategory = z.infer<typeof BaseLiteStocktakeCategorySchema> & {
  children: LocalLiteStocktakeCategory[];
};

export const LiteStocktakeCategorySchema: z.ZodType<LocalLiteStocktakeCategory> =
  BaseLiteStocktakeCategorySchema.extend({
    children: z.lazy(() => LiteStocktakeCategorySchema.array()),
  });

assertTypeEquality<LocalLiteStocktakeCategory, LiteStocktakeCategory, LocalLiteStocktakeCategory>();
