import { z } from "zod";

import { LiteStocktakeReportSchema } from "./LiteStocktakeReportSchema";
import { StocktakeReportItemFormSchema } from "./StocktakeReportItemFormSchema";
import { StocktakeReportNewExtraItemFormSchema } from "./StocktakeReportNewExtraItemFormSchema";
import { StocktakeReportOldExtraItemFormSchema } from "./StocktakeReportOldExtraItemFormSchema";

export const StocktakeReportFormSchema = LiteStocktakeReportSchema.omit({
  extraItems: true,
}).extend({
  items: z.array(StocktakeReportItemFormSchema),
  oldExtraItems: z.array(StocktakeReportOldExtraItemFormSchema),
  newExtraItems: z.array(StocktakeReportNewExtraItemFormSchema),
});

export type LocalStocktakeReportForm = z.infer<typeof StocktakeReportFormSchema>;
