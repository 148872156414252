import {
  DefaultError,
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

import { OrderRequisition, OrderRequisitionStatus } from "@web/common/network/model";

import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { LocalOrderReqService } from "src/services/LocalOrderReqService";
import { OrdersService } from "src/typegens";
import { ApiRequestOptions } from "src/typegens/core/ApiRequestOptions";

const getOrderRequisitionsList = async (
  {
    cursor,
    closed,
    isOfflineDraftEnabled,
    pageSize,
  }: { cursor: string; closed: boolean; isOfflineDraftEnabled: boolean; pageSize?: string },
  options?: Pick<ApiRequestOptions, "headers" | "signal">
) => {
  const statusesToGet: OrderRequisitionStatus[] = [
    "CANCELLED",
    "PENDING_CANCELLATION",
    "SUPPLIER_ACKNOWLEDGED",
    "SUPPLIER_CONFIRMED",
    "SUPPLIER_PENDING",
    "SUPPLIER_REJECTED",
    "SUPPLIER_DELIVERED",
    "PENDING_PURCHASER_APPROVAL",
    "PURCHASER_REJECTED",
    "SUPPLIER_QUOTE_PENDING",
    "SUPPLIER_QUOTE_ACKNOWLEDGED",
    "SUPPLIER_QUOTE_PROVIDED",
    "SUPPLIER_QUOTE_REJECTION_ACKNOWLEDGED",
    "QUOTE_EXPIRED",
    "QUOTE_REJECTED",
  ];
  // This is only temporary, as long as online drafts created in Main App are
  // automatically accessible by the Lite app.
  // In the end, the DRAFT_CREATED and DRAFT_DELETED statuses should be removed
  // from the backend whatsoever.
  if (!isOfflineDraftEnabled) {
    const draftStatuses: OrderRequisitionStatus[] = ["DRAFT_CREATED", "DRAFT_DELETED"];
    statusesToGet.push(...draftStatuses);
  }

  const orderRequisitionList = await OrdersService.getAllOrderRequisitions(
    { cursor, statuses: statusesToGet, closed, pageSize },
    options
  );
  return {
    ...orderRequisitionList,
    items: orderRequisitionList.items.map((o) => LocalOrderReqService.toOrderRequisition(o)),
  };
};

export const ORDER_REQUISITION_LIST_QUERY_KEY_BASE = "orderRequisitionsList";

const getQueryKey = (closed: boolean) => [ORDER_REQUISITION_LIST_QUERY_KEY_BASE, closed];

type QueryResponseData = { items: OrderRequisition[]; cursor?: string };

// TODO #9356: Remove `settings` argument as it is only needed for enabling/disabling old and new
// queries, depending on the `closedAttribute` feature flag
export const useOrderRequisitionListQuery = (closed: boolean, settings = {}) => {
  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isOnline } = useNetworkDetector();
  const queryKey = useMemo(() => getQueryKey(closed), [closed]);

  return useInfiniteQuery<
    QueryResponseData,
    DefaultError,
    InfiniteData<QueryResponseData>,
    QueryKey,
    string
  >({
    queryKey,
    queryFn: ({ pageParam, signal }) =>
      getOrderRequisitionsList(
        {
          cursor: pageParam,
          closed,
          isOfflineDraftEnabled,
          pageSize: areOfflineCapabilitiesEnabled ? "50" : "",
        },
        { signal }
      ),
    initialPageParam: "",
    getNextPageParam: (lastPage) => {
      // Disable pagination in the offline mode (as all orders will be returned).
      // This maybe be revisited after #TODO 9869
      if (lastPage.cursor !== "" && (areOfflineCapabilitiesEnabled ? isOnline : true)) {
        return lastPage.cursor;
      }
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
    ...settings,
  });
};

export const useOrderRequisitionListQueryHelpers = () => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    (closed: boolean) =>
      queryClient.invalidateQueries({
        queryKey: getQueryKey(closed),
      }),
    [queryClient]
  );

  const invalidateAll = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: [ORDER_REQUISITION_LIST_QUERY_KEY_BASE],
      }),
    [queryClient]
  );

  return { invalidate, invalidateAll };
};
