import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

import { useErrorBoundaryPreference } from "src/hooks/useErrorBoundaryPreference";
import { HomeService, LiteCatalogConfiguration, LiteCategory } from "src/typegens";

export const CATEGORIES_QUERY_KEY_BASE = "categories";

const useCategoriesQuery = (
  {
    portId,
    supplierId,
    orderType,
  }: { portId: string; supplierId: string; orderType?: LiteCatalogConfiguration["type"] },
  settings = {}
) => {
  const queryClient = useQueryClient();
  const { shouldUseErrorBoundary } = useErrorBoundaryPreference();

  const queryKey = useMemo(
    () => [CATEGORIES_QUERY_KEY_BASE, portId, supplierId, orderType],
    [orderType, portId, supplierId]
  );

  const query = useQuery<Array<LiteCategory>>({
    queryKey,
    queryFn: async ({ signal }) => {
      const response = await HomeService.getConfigurationCategories(
        { portId, supplierId, orderType },
        { signal }
      );

      return response.items;
    },
    ...settings,
    staleTime: Infinity,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    throwOnError: shouldUseErrorBoundary,
  });

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
    [queryClient, queryKey]
  );

  return { query, invalidate };
};

export default useCategoriesQuery;
