import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { Input, Label, Modal, Textarea } from "@web/ui";

import { BasketFormModal } from "src/components/Modal/BasketFormModal";
import { BASKET_FORM_ANSWERS_STORAGE_KEY } from "src/config/constants";
import { RoutesConfig } from "src/config/routes";
import useBasket from "src/hooks/useBasket";
import { LiteBasketForm } from "src/typegens";

type Props = {
  basketForm: LiteBasketForm;
  isBasketFormModalOpen: boolean;
  isFromDraft: boolean;
  onCloseModal: () => void;
};

type FormInputs = Record<string, string>;

const BasketFormAnswersCache = z.record(z.string());

const getPersistedBasketFormState = (): Record<string, string> => {
  try {
    const localBasketFormState = localStorage.getItem(BASKET_FORM_ANSWERS_STORAGE_KEY);
    const localBasketFormStateParsed = localBasketFormState ? JSON.parse(localBasketFormState) : {};

    BasketFormAnswersCache.parse(localBasketFormStateParsed);

    return localBasketFormStateParsed;
  } catch (err) {
    console.warn(err);
    localStorage.removeItem(BASKET_FORM_ANSWERS_STORAGE_KEY);

    return {};
  }
};

export const BasketFormModalController = ({
  basketForm,
  isBasketFormModalOpen,
  isFromDraft,
  onCloseModal,
}: Props) => {
  const navigate = useNavigate();
  const { setBasketFormAnswers } = useBasket();
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm<FormInputs>({
    defaultValues: getPersistedBasketFormState(),
  });
  /**
    Handlers
   */
  const onSubmit: SubmitHandler<FormInputs> = (data: Record<string, string>) => {
    const basketFormAnswers = Object.entries(data)
      .map(([key, value]) => {
        return {
          number: Number(key),
          answer: value,
          question:
            basketForm.questions.find((question) => question.number === Number(key))?.question ||
            "",
        };
      })
      .filter((answer) => {
        return Boolean(answer.question);
      });
    setBasketFormAnswers(basketFormAnswers);
    navigate(`${RoutesConfig.requisitionInfo}${isFromDraft ? "?from=draft" : ""}`);
  };
  const handleCloseModal = () => {
    onCloseModal();
  };
  const persistFormValues = () => {
    localStorage.setItem(BASKET_FORM_ANSWERS_STORAGE_KEY, JSON.stringify(getValues()));
  };

  return (
    <Modal isOpen={isBasketFormModalOpen} closeModal={handleCloseModal}>
      <BasketFormModal
        closeModal={handleCloseModal}
        title={basketForm.title}
        comments={basketForm.comments}
        onSubmit={handleSubmit(onSubmit)}
        questions={basketForm.questions.map((question) => {
          const errorMesssage =
            errors[question.number]?.type === "required" ? "Required" : undefined;
          const testId = `basketForm_question_${question.number}`;

          return (
            <div className={`mb-4 ${errorMesssage && "pb-2"}`} key={question.number}>
              <div>
                <Label size="100">
                  {question.number}. {question.question}
                </Label>
                {question.required && (
                  <Label size="100" color="text-textIcon-blackSecondary">
                    {" "}
                    (required)
                  </Label>
                )}
              </div>
              <div>
                <Controller
                  render={({ field: { onChange, ...rest } }) => {
                    return question.multiline ? (
                      <Textarea
                        testId={testId}
                        errorMessage={errorMesssage}
                        onChange={(event) => {
                          onChange(event);
                          persistFormValues();
                        }}
                        {...rest}
                      />
                    ) : (
                      <Input
                        testId={testId}
                        errorMessage={errorMesssage}
                        onChange={(event) => {
                          onChange(event);
                          persistFormValues();
                        }}
                        withBorder
                        {...rest}
                      />
                    );
                  }}
                  name={String(question.number)}
                  control={control}
                  defaultValue=""
                  rules={{ required: question.required }}
                />
              </div>
            </div>
          );
        })}
      />
    </Modal>
  );
};
