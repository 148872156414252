import { z } from "zod";

import { assertTypeEquality } from "@web/utils";

import { LiteStocktakeReport } from "src/typegens";

import { ApiMoneySchema } from "../ApiMoneySchema";
import { nonEmptyString } from "../common";
import { LiteStocktakeCategorySchema } from "./LiteStocktakeCategorySchema";
import { LiteStocktakeExtraItemSchema } from "./LiteStocktakeExtraItemSchema";
import { LiteStocktakeItemSchema } from "./LiteStocktakeItemSchema";
import { LiteStocktakerInformationSchema } from "./LiteStocktakerInformationSchema";
import { commonValidStocktakeReportRules } from "./common";

export const LiteStocktakeReportSchema = z.object({
  id: nonEmptyString(),
  vesselId: nonEmptyString(),
  type: commonValidStocktakeReportRules.type,
  inventoryCount: z.number().nonnegative(),
  robValue: ApiMoneySchema,
  stocktakeDate: commonValidStocktakeReportRules.stocktakeDate,
  stocktakerInformation: LiteStocktakerInformationSchema,
  subject: commonValidStocktakeReportRules.subject,
  categories: z.array(LiteStocktakeCategorySchema),
  items: z.array(LiteStocktakeItemSchema),
  extraItems: z.array(LiteStocktakeExtraItemSchema),
  updatedAt: nonEmptyString(),
  createdAt: nonEmptyString(),
});

export type LocalLiteStocktakeReport = z.infer<typeof LiteStocktakeReportSchema>;

assertTypeEquality<LocalLiteStocktakeReport, LiteStocktakeReport, LocalLiteStocktakeReport>();
