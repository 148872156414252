import { useNavigate } from "react-router-dom";

import { Boat, Heading, Paragraph, RegularButton } from "@web/ui";

import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";

export const NothingToStocktakePage = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-neutral_100 flex flex-col min-h-full">
      <TopBarController />
      <div className="px-4 py-6 flex-grow flex flex-col justify-center items-center gap-4">
        <Boat />
        <div className="flex flex-col gap-4 max-w-md">
          <Heading size="200" className="text-center">
            There is nothing to stocktake
          </Heading>
          <Paragraph size="100" className="text-center">
            This can be due to one of the following reasons:
          </Paragraph>
          <Paragraph size="100">
            &bull; You have not ordered anything on S2S yet. The stocktake list is created based on
            previous orders and stocktake reports on Source2Sea.
          </Paragraph>
          <Paragraph size="100">
            &bull; You have reported that all your items have been consumed.
          </Paragraph>
          <RegularButton
            variant="primary"
            size="large"
            label="Go to Order Overview"
            width="content"
            className="mx-auto mt-6"
            onClick={() => {
              navigate(RoutesConfig.order.overview);
            }}
          />
        </div>
      </div>
    </div>
  );
};
