import { XIcon } from "@heroicons/react/solid";

import { Heading, Paragraph, RegularButton } from "@web/ui";

interface Props {
  hasAutomaticRestorationMsg: boolean;
  onClose: () => void;
}

export const NetworkConnectionLostModal = ({ hasAutomaticRestorationMsg, onClose }: Props) => {
  return (
    <div
      className="inline-block align-bottom bg-neutral_0 rounded-lg p-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      data-testid="networkConnectionLostModal"
    >
      <div className="flex items-center justify-between gap-2">
        <Heading size="200" className="flex-1">
          You lost your connection and the app is now in offline mode
        </Heading>
        <button
          type="button"
          className="flex-none self-start bg-neutral_0 rounded-md text-textIcon-blackSecondary hover:text-textIcon-blackPrimary"
          onClick={onClose}
        >
          <span className="sr-only">Close modal</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-5">
        <Paragraph size="100" color="text-textIcon-blackSecondary">
          Search will not be available while offline but you can continue shopping as this is saved
          on your computer.
        </Paragraph>
        {hasAutomaticRestorationMsg && (
          <Paragraph className="mt-1" size="100" color="text-textIcon-blackSecondary">
            We will restore connection automatically when available.
          </Paragraph>
        )}
      </div>
      <div className="mt-5 flex flex-row-reverse">
        <RegularButton
          variant="primary"
          size="large"
          className="ml-2"
          label="Understood"
          onClick={onClose}
        />
      </div>
    </div>
  );
};
