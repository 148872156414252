import classnames from "classnames";
import { useCallback, useEffect, useMemo } from "react";

import { TopCategoriesTree } from "@web/common";
import { Category } from "@web/models";
import { Paragraph } from "@web/ui";
import { formatNumber } from "@web/utils";

import { LocalLiteStocktakeCategory } from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

type Props = {
  totalNumberOfItems: number;
  categories: LocalLiteStocktakeCategory[];
  activeCategoryId: string | undefined;
  onCategoryClick: (categoryId?: string) => void;
  className?: string;
};

export const StocktakeCategories = ({
  totalNumberOfItems,
  categories,
  onCategoryClick,
  className,
  activeCategoryId,
}: Props) => {
  const handleCategoryClick = useCallback(
    (category: Category) => {
      const stocktakeCategory = category
        ? LocalStocktakeService.convertCategoryToStocktakeCategory(category)
        : undefined;

      onCategoryClick(stocktakeCategory?.id);
    },
    [onCategoryClick]
  );

  const handleAllCategoriesClick = useCallback(() => {
    onCategoryClick(undefined);
  }, [onCategoryClick]);

  const nonEmptyCategories = useMemo(
    () => LocalStocktakeService.getNonEmptyCategories(categories),
    [categories]
  );
  const doesAnyCategoryExist = nonEmptyCategories.length > 0;
  const doMultipleTopCategoriesExist = nonEmptyCategories.length > 1;
  const firstNonEmptyCategoryId = nonEmptyCategories[0]?.id;

  useEffect(() => {
    if (firstNonEmptyCategoryId && !doMultipleTopCategoriesExist) {
      onCategoryClick(firstNonEmptyCategoryId);
    }
  }, [doMultipleTopCategoriesExist, firstNonEmptyCategoryId, onCategoryClick]);

  return (
    <div className={classnames(className)}>
      {doMultipleTopCategoriesExist && (
        <>
          <hr />
          <Paragraph
            className="py-3 cursor-pointer"
            size="200"
            weight={!activeCategoryId ? "heavy" : "light"}
            color={
              !activeCategoryId
                ? "text-primaryDefault"
                : ["text-textIcon-blackPrimary", "hover:text-primaryDefault"]
            }
            onClick={handleAllCategoriesClick}
          >
            Show All Items&nbsp;
            <span className="text-gray-500">({formatNumber(totalNumberOfItems)})</span>
          </Paragraph>
        </>
      )}
      {doesAnyCategoryExist && (
        <TopCategoriesTree
          categories={nonEmptyCategories}
          onCategoryClick={handleCategoryClick}
          activeCategoryId={activeCategoryId}
          isTopLevelFoldable={doMultipleTopCategoriesExist}
          showTopHorizontalRule
          showBottomHorizontalRule
        />
      )}
    </div>
  );
};
