import Dexie from "dexie";

import { LocalOrderDraft } from "src/models";

import { StoreNames, getStoreName } from "../config";

export class LocalOrderDraftsDatabase extends Dexie {
  orderDrafts: Dexie.Table<LocalOrderDraft, string>;

  constructor() {
    super(getStoreName(StoreNames.ORDER_DRAFTS));
    this.version(0.1).stores({
      orderDrafts: "id,vessel.id",
    });
  }
}

const localOrderDraftsDatabaseInstance = new LocalOrderDraftsDatabase();

export const orderDraftsStore = localOrderDraftsDatabaseInstance.orderDrafts;
