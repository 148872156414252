import Dexie from "dexie";

import { StoreNames, getStoreName } from "../config";

export class KeyvalDatabase extends Dexie {
  keyval: Dexie.Table<string | boolean, string>;

  constructor() {
    super(getStoreName(StoreNames.KEYVAL));
    this.version(0.1).stores({
      keyval: "",
    });
  }
}

export const keyvalStore = new KeyvalDatabase().keyval;
