import classNames from "classnames";
import { useEffect, useState } from "react";

import Vector from "@web/common/icons/Vector.svg";
import { Money } from "@web/models";
import { Heading, Label, Paragraph, RegularButton } from "@web/ui";
import { formatMoney, isDefined } from "@web/utils";

import { LitePunchoutItem } from "src/typegens";

interface Props {
  repunchoutProducts: string;
  availableProducts: Array<LitePunchoutItem>;
  unavailableProducts: Array<LitePunchoutItem>;
  handleRepunchoutContinue: () => void;
}

const defaultValue = {
  items: [],
  port: {
    id: "",
    name: "",
    country: "",
    locationCode: "",
  },
};

export const RepunchoutModal: React.FC<Props> = ({
  repunchoutProducts,
  availableProducts,
  unavailableProducts,
  handleRepunchoutContinue,
}) => {
  const [parsedData, setParsedData] = useState(defaultValue);

  useEffect(() => {
    try {
      const parsedJson = JSON.parse(repunchoutProducts);
      setParsedData(parsedJson);
    } catch (error) {
      console.error(error);
    }
  }, [repunchoutProducts]);

  return (
    <div className="bg-neutral_300 h-full w-full flex items-center justify-center">
      <div className="rounded-lg shadow-lg w-[50vw] h-[70vh] bg-white overflow-y-auto">
        <div className="w-[100%] bg-white p-5 rounded-t-lg">
          <Heading size="200">Availability of Items</Heading>{" "}
          <div className="flex flex-row mt-2">
            <div>
              <Label size="200">Review the availability of items</Label>
              <Paragraph size="300" color="text-textIcon-blackSecondary">
                Items that are not available will not be added to your basket
              </Paragraph>
            </div>
            <div className="ml-auto">
              <Paragraph size="300" className="mb-2" color="text-textIcon-blackSecondary">
                Original Delivery Port
              </Paragraph>
              <div className="flex">
                <img src={Vector} alt="Vector Icon" style={{ paddingLeft: "0.1em" }} />
                <Paragraph size="200" color="text-neutral_700" className="pl-3">
                  {parsedData.port.locationCode}
                  <br />
                  <span className="text-textIcon-blackPrimary">{parsedData.port.name}</span>
                </Paragraph>
              </div>
            </div>
          </div>
          <Paragraph size="100" color="text-secondaryDefault">
            {`${availableProducts.length} / ${parsedData.items.length} of your basket Items are available`}
          </Paragraph>
        </div>
        <div className="w-[100%] bg-neutral_100 p-5">
          {unavailableProducts.length > 0 && (
            <div className="w-[100%]">
              <Paragraph size="200" className="mb-3 font-medium">
                NOT AVAILABLE
              </Paragraph>
              {repunchoutProducts &&
                unavailableProducts.map((product: LitePunchoutItem) => {
                  return (
                    <div
                      className="bg-neutral_200 py-2 px-5 w-[100%] text-gray-500 border border-neutral_200 rounded-md mb-2"
                      key={product.itemId}
                    >
                      <div className="flex w-[100%] items-center justify-between">
                        <div className="w-[400px]">
                          <Label size="200">
                            {isDefined(product.details?.about?.name)
                              ? product.details?.about?.name
                              : product.name}
                          </Label>
                          <div className="text-sm">
                            {product.impaCode && `IMPA:`} {product.impaCode} Item ID:{" "}
                            {product.supplierItemId}
                          </div>
                        </div>
                        <div>
                          Total: {product.quantity}{" "}
                          {isDefined(product.details?.measurementUnit)
                            ? product.details?.measurementUnit
                            : product.displayUnit}
                        </div>
                        <div className="float-right">
                          <div className="text-md">
                            {formatMoney({
                              amount:
                                product.details?.price.costPrice.amount !== undefined
                                  ? product.details.price.costPrice.amount * product.quantity
                                  : product.unitPrice.amount * product.quantity,
                              currencyCode:
                                product.details?.price.costPrice.currencyCode !== undefined
                                  ? product.details.price.costPrice.currencyCode
                                  : product.unitPrice.currencyCode,
                            })}
                          </div>
                          <div className="text-sm">
                            {formatMoney(
                              product.details?.price.costPrice !== undefined
                                ? product.details.price.costPrice
                                : (product.unitPrice as Money)
                            )}{" "}
                            / {product.displayUnit}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {availableProducts.length > 0 && (
            <div>
              <Paragraph
                size="200"
                className={classNames("mb3 font-medium", {
                  "mt-8": unavailableProducts.length > 0,
                })}
              >
                AVAILABLE
              </Paragraph>
              {repunchoutProducts &&
                availableProducts.map((product: LitePunchoutItem) => {
                  return (
                    <div
                      className="bg-neutral_200 py-2 px-5 w-[100%] text-gray-500 border border-neutral_200 rounded-md mb-2"
                      key={product.itemId}
                    >
                      <div className="flex w-[100%] items-center justify-between">
                        <div className="w-[400px]">
                          <Label size="200">
                            {isDefined(product.details?.about?.name)
                              ? product.details?.about?.name
                              : product.name}
                          </Label>
                          <div className="text-sm">
                            {product.impaCode && `IMPA:`} {product.impaCode} Item ID:{" "}
                            {product.supplierItemId}
                          </div>
                        </div>
                        <div>
                          <div>
                            Total: {product.quantity}{" "}
                            {isDefined(product.details?.measurementUnit)
                              ? product.details?.measurementUnit
                              : product.displayUnit}
                          </div>
                        </div>
                        <div className="float-right">
                          <div className="text-md">
                            {formatMoney({
                              amount:
                                product.details?.price.costPrice.amount !== undefined
                                  ? product.details.price.costPrice.amount * product.quantity
                                  : product.unitPrice.amount * product.quantity,
                              currencyCode:
                                product.details?.price.costPrice.currencyCode !== undefined
                                  ? product.details.price.costPrice.currencyCode
                                  : product.unitPrice.currencyCode,
                            })}
                          </div>
                          <div className="text-sm">
                            {formatMoney(
                              product.details?.price.costPrice !== undefined
                                ? product.details.price.costPrice
                                : (product.unitPrice as Money)
                            )}{" "}
                            / {product.displayUnit}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        <div className="w-[100%] p-5 mb-6">
          <RegularButton
            className="float-right"
            variant="primary"
            size="large"
            width="content"
            label="Continue"
            onClick={handleRepunchoutContinue}
          />
        </div>
      </div>
    </div>
  );
};
