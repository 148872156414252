import { ShoppingCartIcon } from "@heroicons/react/outline";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSpinDelay } from "spin-delay";

import { BasketEntry, IntersectionMonitor, ProductSku } from "@web/common";
import { SidebarProductBox } from "@web/common/components/SidebarProductBox";
import { Label, Loading, RegularButton } from "@web/ui";
import { formatMoney } from "@web/utils";

import { OfflineCatalogLoader } from "src/components/OfflineCatalogLoader";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import useBasket from "src/hooks/useBasket";
import { useOfflineCatalogQueryHelpers } from "src/hooks/useOfflineCatalogQuery";

import EmptyIndicator from "../../components/EmptyIndicator";
import { NoCachedData } from "../../components/NoCachedData";
import { RoutesConfig } from "../../config/routes";
import { useNoCachedQueryGuard } from "../../hooks/useNoCachedQueryGuard";
import useProducts from "../../hooks/useProducts";
import { LiteProduct } from "../../typegens";
import { ProductBox } from "./ProductBox";

const ProductsList = () => {
  const { t } = useTranslation();
  const { categoryId } = useParams() as { categoryId: string };
  const { pushSkuToBasket, getQuantity, lineItems, totalQuantity, grandTotal } = useBasket();
  const navigate = useNavigate();
  const [{ port, orderType }] = useAppStateContext();

  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isDownloadingOfflineCatalog, hasOfflineCatalogData } = useOfflineCatalogQueryHelpers({
    portId: port?.id || "",
    orderType,
    categoryId,
  });
  const { query, invalidate } = useProducts(
    categoryId,
    isDownloadingOfflineCatalog || (areOfflineCapabilitiesEnabled && !hasOfflineCatalogData)
  );
  const shouldDisplayOfflineCatalogLoader = useSpinDelay(isDownloadingOfflineCatalog, {
    delay: 800,
    ssr: false,
  });

  const { hasNoCachedData } = useNoCachedQueryGuard({
    isQueryPending: query.isPending,
    isQueryError: query.isError,
    becameOnlineCallback: invalidate,
  });

  const sortedLineItems = useMemo(() => [...lineItems].reverse(), [lineItems]);

  const handleAddToBasket = useCallback(
    (sku: ProductSku, qty: number) => pushSkuToBasket(sku, qty),
    [pushSkuToBasket]
  );

  if (hasNoCachedData) {
    return <NoCachedData hasGoBack={true} displayMode="inline" />;
  }

  if (shouldDisplayOfflineCatalogLoader) {
    return <OfflineCatalogLoader className="mt-[175px]" inline />;
  }

  if (query.isPending) {
    return <Loading />;
  }

  if (query.isError) {
    return <div>Fetching error</div>;
  }

  const items = query.data.pages.flatMap((p) => p.items);

  if (items.length === 0) {
    return (
      <div className="flex-grow flex items-center w-full">
        <EmptyIndicator
          title={t("pages.category.noResults")}
          subtitle={t("pages.category.tryDifferentFilters")}
        />
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="grid grid-cols-1 gap-2 w-11/12 pr-8 pl-4">
          {items.map((product: LiteProduct, index: number) => (
            <ProductBox
              addToBasket={handleAddToBasket}
              key={`${product.id}-${index}`}
              product={product}
              quantity={getQuantity(product.skuList[0])}
              index={index}
            />
          ))}
        </div>
        <div className="float-right fixed z-0 border-1 top-0 right-0 h-full bg-neutral_0 w-[20%] mr-[15px] h-screen">
          <div className="flex flex-col justify-between">
            <div className="border-b-1 w-full text-center flex flex-col justify-end h-[170px] pb-2">
              <Label size="100">Your Basket</Label>
              <div className="inline-block mt-6 w-full">
                {lineItems.length > 0 && (
                  <Label size="200" className="ml-2 float-left">{`Total: ${formatMoney(
                    grandTotal
                  )}`}</Label>
                )}
                <Label size="200" color="text-textIcon-blackSecondary" className="pr-2 float-right">
                  {totalQuantity !== 1
                    ? `${lineItems.length} Line items`
                    : `${lineItems.length} Line item`}
                </Label>
              </div>
            </div>
            <div className="overflow-auto bg-neutral_100 h-[calc(100vh-170px-75px)] -mb-[1px]">
              {lineItems.length > 0 ? (
                <div>
                  {sortedLineItems.map((product: BasketEntry, index: number) => (
                    <SidebarProductBox
                      addToBasket={(sku, qty) => pushSkuToBasket(sku, qty)}
                      key={product.sku.id}
                      product={product}
                      quantity={product.quantity}
                      index={index}
                    />
                  ))}
                </div>
              ) : (
                <div className="mt-10 text-center">
                  <Label size="200" data-testid="empty-sidebar-text">
                    Your basket is empty
                  </Label>
                </div>
              )}
            </div>
            <div className="relative px-4 border-t-1 bg-neutral_0 z-20 h-[75px] flex flex-col justify-center">
              <RegularButton
                variant="primary"
                size="large"
                width="container"
                label="View Basket"
                LeadingIcon={ShoppingCartIcon}
                onClick={() => {
                  navigate(RoutesConfig.basket);
                }}
                data-testid="viewBasketButton"
              />
            </div>
          </div>
        </div>
      </div>
      {query.hasNextPage && (
        <IntersectionMonitor onEnter={query.fetchNextPage}>
          <div className="h-10">{query.isFetchingNextPage && <Loading />}</div>
        </IntersectionMonitor>
      )}
    </>
  );
};

export default ProductsList;
