import { useFlag } from "@unleash/proxy-client-react";

import { Paragraph } from "@web/ui";

import { useAppStateContext } from "../contexts/AppStateContext";
import RequestProductInline from "./RequestProductInline";

interface Props {
  title: string;
  subtitle: string;
}

const EmptyIndicator: React.FC<Props> = ({ title, subtitle }) => {
  const hasRfqFeature = useFlag("rfq");
  const [{ configuration }] = useAppStateContext();

  return (
    <div className="flex flex-grow justify-center items-center flex-col">
      <div className="text-center">
        <Paragraph size="100">{title}</Paragraph>
        <Paragraph
          size="200"
          className="pt-4"
          /* color="text-text-whiteDisabled" */
          // TODO #6444: Fixme, there is no such color in tailwind config
        >
          {subtitle}
        </Paragraph>
      </div>
      {hasRfqFeature &&
        configuration?.permissions.includes("CREATE_REQUISITION") &&
        configuration.allow.createRfq && (
          <div className="w-full my-4">
            <RequestProductInline />
          </div>
        )}
    </div>
  );
};

export default EmptyIndicator;
