// Helpers for manipulating the TanStack Query cache along the `setQueryData` method

export function updateInQueryCacheByKey<
  T extends Record<string, unknown>,
  K extends Record<string, unknown>[]
>(elementToUpdate: T, key: string) {
  return (collection?: K) =>
    collection
      ? collection.map((collectionElement) =>
          collectionElement[key] === elementToUpdate[key]
            ? { ...elementToUpdate }
            : collectionElement
        )
      : undefined;
}

export function removeFromQueryCacheByKey<
  T extends Record<string, unknown>,
  K extends Record<string, unknown>[]
>(elementToRemove: T, key: string) {
  return (collection?: K) =>
    collection
      ? collection.filter((collectionElement) => collectionElement[key] !== elementToRemove[key])
      : undefined;
}

export function addToQueryCache<
  T extends Record<string, unknown>,
  K extends Record<string, unknown>[]
>(elementToAdd: T) {
  return (collection?: K) => (collection ? [{ ...elementToAdd }, ...collection] : undefined);
}
