import { DefaultError, useMutation } from "@tanstack/react-query";

import { LiteShareBasketRequest } from "src/typegens";

import { shareBasket } from "../api/shareBasket";

export const useShareBasketMutation = (options = {}) => {
  return useMutation<unknown, DefaultError, LiteShareBasketRequest>({
    mutationKey: ["shareBasket"],
    mutationFn: shareBasket,
    ...options,
  });
};
