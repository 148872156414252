import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { memo, useMemo } from "react";
import { useFormContext, useFormState } from "react-hook-form";

import { Paragraph } from "@web/ui";

import {
  LocalLiteStocktakeExtraItem,
  LocalStocktakeReportForm,
  StocktakeReportItemsFieldPath,
} from "src/models";

type Props = {
  indexInCollection: number;
  itemsFieldPath: StocktakeReportItemsFieldPath;
};

export const FormErrors = memo(({ indexInCollection, itemsFieldPath }: Props) => {
  const { control } = useFormContext<LocalStocktakeReportForm>();
  const { errors } = useFormState({ control });
  const itemsErrors = errors[itemsFieldPath];

  const errorMessages: Array<{ fieldName: string; message: string }> = useMemo(() => {
    const itemErrors = itemsErrors?.[indexInCollection] as
      | {
          [Property in keyof LocalLiteStocktakeExtraItem]: {
            ref?: { name?: string };
            message?: string;
          };
        }
      | undefined;

    if (!itemErrors) {
      return [];
    }

    return [
      ...(itemErrors.name && itemErrors.name.message
        ? [{ fieldName: "name", message: itemErrors.name.message }]
        : []),
      ...(itemErrors.articleCode && itemErrors.articleCode.message
        ? [{ fieldName: "articleCode", message: itemErrors.articleCode.message }]
        : []),
      ...(itemErrors.quantity && itemErrors.quantity.message
        ? [{ fieldName: "quantity", message: itemErrors.quantity.message }]
        : []),
      ...(itemErrors.measurementUnit && itemErrors.measurementUnit.message
        ? [{ fieldName: "measurementUnit", message: itemErrors.measurementUnit.message }]
        : []),
      // This is poor, but making it work with proper types is just too much for the use.
      // TODO #11215: Maybe refactor in the future?
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      ...(itemErrors.singleUnitGrossPrice?.amount && itemErrors.singleUnitGrossPrice?.amount.message
        ? [
            {
              fieldName: "singleUnitGrossPrice.amount",
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              message: itemErrors.singleUnitGrossPrice?.amount.message,
            },
          ]
        : []),
    ];
  }, [itemsErrors, indexInCollection]);

  return errorMessages.length > 0 ? (
    <div className="mt-1 flex flex-col ml-6.5 gap-0.5 px-2 py-1 bg-dangerBackground rounded-md">
      {errorMessages.map((errorMessage) => (
        <div key={errorMessage.fieldName} className="flex items-center gap-1">
          <ExclamationCircleIcon className="w-4 h-4 text-dangerDefault" />
          <Paragraph size="300">{errorMessage.message}</Paragraph>
        </div>
      ))}
    </div>
  ) : null;
});
FormErrors.displayName = "FormErrors";
