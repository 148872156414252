import { DefaultError, useMutation } from "@tanstack/react-query";

import { triggerFileDownload } from "@web/utils";

import { exportStocktakeReportToExcel } from "src/api/stocktakes";

import { useToastMessage } from "../useToastMessage";

type UseExportStocktakeReportToExcelMutationArgs = {
  fileDownloadNameWithoutExtension: string;
  onSuccess?: (file: Blob) => void;
  onError?: (error: Error) => void;
  hasSuccessMessage?: boolean;
  hasErrorMessage?: boolean;
};

export const useExportStocktakeReportToExcelMutation = ({
  onSuccess,
  onError,
  hasSuccessMessage,
  hasErrorMessage,
  fileDownloadNameWithoutExtension,
}: UseExportStocktakeReportToExcelMutationArgs) => {
  const { setToastMessage } = useToastMessage();

  return useMutation<Blob, DefaultError, string>({
    mutationKey: ["exportStocktakeReportToExcel"],
    mutationFn: exportStocktakeReportToExcel,
    onSuccess: (file: Blob) => {
      hasSuccessMessage &&
        setToastMessage({
          type: "success",
          message: "The stocktake report will be downloaded soon.",
        });

      if (onSuccess) {
        onSuccess(file);
      }

      triggerFileDownload({
        file,
        fileNameWithExtension: `${fileDownloadNameWithoutExtension}.xlsx`,
      });
    },
    onError: (error) => {
      console.error(error);

      hasErrorMessage &&
        setToastMessage({
          type: "failure",
          message: "There was an issue while downloading the stocktake report. Please try again.",
        });

      if (onError) {
        onError(error);
      }
    },
  });
};
