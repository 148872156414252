import { ReactNode, memo } from "react";

import { Badge, Label, Paragraph } from "@web/ui";
import { formatMoney, formatNumber, isDefined, preprocessFormAmountValue } from "@web/utils";

import { LocalStocktakeService } from "src/services/LocalStocktakeService";
import { ApiMoney } from "src/typegens";

type Props<T> = {
  product: T;
  renderProductDetails: (product: T, className: string) => ReactNode;
  quantity: number;
  salesEntityQuantity: number;
  measurementUnit: string;
  singleUnitGrossPrice: ApiMoney | undefined;
  robValue: ApiMoney;
};

function _StocktakeDetailsProductTile<T>({
  product,
  renderProductDetails,
  salesEntityQuantity,
  measurementUnit,
  singleUnitGrossPrice,
  quantity,
  robValue,
}: Props<T>) {
  const entityQuantity = LocalStocktakeService.getItemEntityQuantity(
    salesEntityQuantity,
    preprocessFormAmountValue(quantity)
  );
  return (
    <div className="flex flex-col xl:flex-row justify-between items-center px-4 py-2 gap-2 xl:gap-4 bg-neutral_0 border border-neutral_300 rounded-xl">
      {renderProductDetails(product, "self-stretch")}
      <div
        className="flex-none self-stretch border-t-1 border-neutral_300 xl:hidden"
        role="presentation"
      ></div>
      <div className="self-stretch flex-none flex justify-end items-center gap-4">
        <div
          className="flex-none self-stretch border-r-1 border-neutral_300 hidden xl:block"
          role="presentation"
        ></div>
        <div className="flex-none flex items-center gap-1">
          <div className="w-[80px]">
            <Paragraph
              size="100"
              className="flex w-full h-6.5 bg-neutral_200 border border-neutral_300 rounded-lg justify-center items-center overflow-x-clip"
            >
              {formatNumber(quantity)}
            </Paragraph>
          </div>
          <Label size="100">&times;</Label>
          <div className="w-[70px] overflow-clip">
            <Badge
              size="m"
              color="base"
              contrast="low"
              text={`${LocalStocktakeService.formatSalesEntityQuantity(
                salesEntityQuantity
              )} ${measurementUnit}`}
            />
          </div>
        </div>
        <div
          className="flex-none self-stretch border-r-1 border-neutral_300"
          role="presentation"
        ></div>
        <div className="flex-none flex flex-col justify-between w-[150px] gap-2 overflow-clip">
          <div className="flex-none flex items-center gap-1">
            <Paragraph size="200" weight="heavy" color="text-textIcon-blackSecondary">
              ROB:
            </Paragraph>
            <Badge
              size="m"
              color="success"
              contrast="low"
              text={`${LocalStocktakeService.formatEntityQuantity(
                entityQuantity
              )} ${measurementUnit}`}
            />
          </div>
          <div
            className="flex-none self-stretch border-t-1 border-neutral_300"
            role="presentation"
          ></div>
          <div className="flex-none flex items-center gap-1">
            <Paragraph size="200" weight="heavy" color="text-textIcon-blackSecondary">
              Value:
            </Paragraph>
            <Paragraph size="200" weight="heavy" className="whitespace-nowrap">
              {isDefined(singleUnitGrossPrice) ? formatMoney(robValue) : "Not Provided"}
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
}
export const StocktakeDetailsProductTile = memo(
  _StocktakeDetailsProductTile
) as typeof _StocktakeDetailsProductTile & {
  displayName: string;
};
StocktakeDetailsProductTile.displayName = "StocktakeDetailsProductTile";
