import { z } from "zod";

import { assertTypeEquality } from "@web/utils";

import { LiteStocktakeExtraItem } from "src/typegens";

import { ApiMoneySchema } from "../ApiMoneySchema";
import { nonEmptyString, optionalNonEmptyString } from "../common";

export const LiteStocktakeExtraItemSchema = z.object({
  id: nonEmptyString(),
  robValue: ApiMoneySchema,
  entityQuantityOnStock: z.number().nonnegative(),
  articleCode: optionalNonEmptyString(),
  // This will differ for drafts & report form - see `StocktakeReportDraftExtraItemSchema` & `StocktakeReportExtraItemFormSchema`
  singleUnitGrossPrice: ApiMoneySchema.optional(),
  // This will differ for drafts & report form - see `StocktakeReportDraftExtraItemSchema` & `StocktakeReportExtraItemFormSchema`
  quantity: z.number().nonnegative(),
  // This will differ for report form - see `StocktakeReportExtraItemFormSchema`
  measurementUnit: nonEmptyString(),
  // This will differ for report form - see `StocktakeReportExtraItemFormSchema`
  name: nonEmptyString(),
});

export type LocalLiteStocktakeExtraItem = z.infer<typeof LiteStocktakeExtraItemSchema>;

assertTypeEquality<
  LocalLiteStocktakeExtraItem,
  LiteStocktakeExtraItem,
  LocalLiteStocktakeExtraItem
>();
