import { OrderItem } from "@web/common/network/model";

import { LiteProductSku } from "../typegens";

export const getItemUnits = ({
  product,
  variant,
}: {
  product: OrderItem;
  variant: LiteProductSku;
}) => Math.floor(product.entityQuantity / variant.salesEntityQuantity) || 1;
