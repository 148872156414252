import { useCallback } from "react";

import { useAppStateContext } from "src/contexts/AppStateContext";

export const useOfflineDraftEnabled = () => {
  const [{ isOfflineDraftEnabled }, dispatch] = useAppStateContext();
  const setOfflineDraftEnabled = useCallback(
    (state: boolean | undefined) => {
      dispatch({ type: "setOfflineDraftEnabled", value: state });
    },
    [dispatch]
  );

  return {
    isOfflineDraftEnabled: !!isOfflineDraftEnabled,
    setOfflineDraftEnabled,
  };
};
