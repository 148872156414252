import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

import { containerPadding } from "@web/ui";

import { GatherControlBar } from "src/components/GatherControlBar";
import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";

interface Props {
  children: React.ReactNode;
  bgColor?: string;
}

const BaseLayout: React.FC<Props> = ({ children, bgColor }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location.pathname, location.search]);

  return (
    <header className="w-full h-full flex flex-col overflow-scroll">
      {location.pathname !== "/" && (
        <>
          <TopBarController />
          <GatherControlBar searchRoute={RoutesConfig.search} />
        </>
      )}
      <div className={`bg-background-${bgColor ? bgColor : "secondary"} flex-grow flex flex-col`}>
        <div
          className={`${containerPadding} bg-background-${
            bgColor ? bgColor : "secondary"
          } flex flex-col flex-grow`}
        >
          {children}
        </div>
      </div>
    </header>
  );
};

export default BaseLayout;
