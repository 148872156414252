import { LiteStocktakeControllerService, LiteStocktakeCountListRequest } from "src/typegens";

export const exportStockCountListToExcel = async (
  requestBody: LiteStocktakeCountListRequest
): Promise<Blob> => {
  const result = await LiteStocktakeControllerService.exportStockCountListToExcel(
    {
      requestBody,
    },
    {
      headers: {
        Accept: "*/*",
      },
    }
  );

  return result;
};
