import { useMemo } from "react";
import { Link } from "react-router-dom";

import { getNonEmptyCategories } from "@web/common";
import { Category } from "@web/models";
import { Paragraph } from "@web/ui";

import { getCategoryByIdPath } from "src/config/routes";

type CategorySelectorProps = {
  categories: Category[];
};

export const CategorySelector: React.FC<CategorySelectorProps> = ({ categories }) => {
  const categoriesWithProducts = useMemo(() => getNonEmptyCategories(categories), [categories]);

  return (
    <>
      {categoriesWithProducts.map((category) => {
        return (
          <Paragraph key={category.id} size="100">
            <Link
              className="hover:text-primaryDefault active:text-primaryDefault focus:outline-none focus-visible:ring-1 ring-primaryDefault active:ring-primaryPressed"
              to={getCategoryByIdPath(category.id)}
            >
              {category.name}
            </Link>
          </Paragraph>
        );
      })}
    </>
  );
};
