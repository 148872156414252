import { DefaultError, useMutation } from "@tanstack/react-query";

import { getInitialStocktakeReportData } from "src/api/stocktakes";
import { useToastMessage } from "src/hooks/useToastMessage";
import { LiteStocktakeInitialReportData, LiteStocktakeSetupDataRequest } from "src/typegens";

type UseGetInitialStocktakeReportDataMutation = {
  onSuccess?: (initialReportData: LiteStocktakeInitialReportData) => void;
  onError?: (error: Error) => void;
  hasErrorMessage?: boolean;
};

export const useGetInitialStocktakeReportDataMutation = ({
  onSuccess,
  onError,
  hasErrorMessage = false,
}: UseGetInitialStocktakeReportDataMutation = {}) => {
  const { setToastMessage } = useToastMessage();

  return useMutation<LiteStocktakeInitialReportData, DefaultError, LiteStocktakeSetupDataRequest>({
    mutationKey: ["getInitialStocktakeReportData"],
    mutationFn: getInitialStocktakeReportData,
    onSuccess: (initialReportData) => {
      if (onSuccess) {
        onSuccess(initialReportData);
      }
    },
    onError: (error) => {
      console.error(error);

      hasErrorMessage &&
        setToastMessage({
          type: "failure",
          message: "There was an error while starting the stocktake report. Please try again.",
        });

      if (onError) {
        onError(error);
      }
    },
  });
};
