import { useQuery, useQueryClient } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useCallback, useMemo } from "react";

import { LocalStocktakeReportDraft } from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";
import { StocktakeDraftsService } from "src/services/StocktakeDraftsService";

export const STOCKTAKE_DRAFT_QUERY_KEY_BASE = "stocktakeDraft";

const getQueryKey = (draftId: string) => [STOCKTAKE_DRAFT_QUERY_KEY_BASE, draftId];

type UseStocktakeDraftQuery = UseQueryResult<LocalStocktakeReportDraft>;

const useStocktakeDraftQuery = <T = LocalStocktakeReportDraft>({
  draftId,
  select,
}: {
  draftId: string;
  select: (
    data: LocalStocktakeReportDraft
  ) => T extends LocalStocktakeReportDraft ? T : LocalStocktakeReportDraft;
}): UseStocktakeDraftQuery => {
  const queryKey = useMemo(() => getQueryKey(draftId), [draftId]);
  return useQuery<LocalStocktakeReportDraft>({
    queryKey,
    queryFn: () => StocktakeDraftsService.getDraft(draftId),
    networkMode: "always",
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    gcTime: 0,
    refetchOnReconnect: false,
    select,
    enabled: !!draftId,
  });
};

type UseStocktakeDraftQueryHelpers = {
  invalidate: () => void;
};

export const useStocktakeDraftQueryHelpers = ({
  draftId,
}: {
  draftId: string;
}): UseStocktakeDraftQueryHelpers => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => getQueryKey(draftId), [draftId]);

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
    [queryClient, queryKey]
  );

  return {
    invalidate,
  };
};

type UseEditableStocktakeDraftQuery = UseQueryResult<LocalStocktakeReportDraft>;

export const useEditableStocktakeDraftQuery = ({
  draftId,
}: {
  draftId: string;
}): UseEditableStocktakeDraftQuery => {
  return useStocktakeDraftQuery({
    draftId,
    select: (data): LocalStocktakeReportDraft =>
      LocalStocktakeService.convertFromStoredDraftToValidatedReportDraft(data),
  });
};
