import {
  CancelablePromise,
  LiteStocktakeControllerService,
  LiteStocktakeReportsResponse,
} from "src/typegens";
import { ApiRequestOptions } from "src/typegens/core/ApiRequestOptions";

export const getStocktakeReportsList = (
  { vesselId }: { vesselId: string },
  options?: Pick<ApiRequestOptions, "headers" | "signal">
): CancelablePromise<LiteStocktakeReportsResponse> =>
  LiteStocktakeControllerService.getStocktakeReports({ vesselId }, options);
