import { Heading, Paragraph } from "@web/ui";

import MailIcon from "../../icons/Mail.svg?react";

interface Props {
  imoNumber: string;
}

export const RetrieveVesselTokenConfirmation = ({ imoNumber }: Props) => {
  return (
    <div className="text-center">
      <div className="w-max m-auto mb-4">
        <MailIcon />
      </div>
      <Heading size="100" className="mb-4" data-testid="retrieveVesselToken_confirmation">
        We sent you a vessel token!
      </Heading>
      <Paragraph size="200" className="w-96 m-auto">
        We sent the vessel token for <strong>IMO{imoNumber}</strong> to the email associated with
        your IMO number. <strong>Please check your email</strong> to find the vessel token.
      </Paragraph>
      <Paragraph size="200" className="w-96 mt-4 m-auto">
        Contact support if you haven’t received an email.
      </Paragraph>
    </div>
  );
};
