import { DefaultError, useMutation } from "@tanstack/react-query";

import { OrderRequisition } from "@web/common";

import { closeOrder } from "../api/closeOrder";
import { LiteOrderRequisition } from "../typegens";

export const useCloseOrderMutation = (options = {}) => {
  return useMutation<LiteOrderRequisition, DefaultError, OrderRequisition>({
    mutationKey: ["closeOrder"],
    mutationFn: (order) => {
      if (!order.canClose) {
        return Promise.reject("Cannot close this order");
      }
      return closeOrder(order.id);
    },
    ...options,
  });
};
