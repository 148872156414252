import { memo, useCallback } from "react";

import { LocalLiteStocktakeItem } from "src/models";

import { StocktakeCatalogProductTileDetails } from "../StocktakeCatalogProductTileDetails";
import { StocktakeDetailsProductTile } from "../StocktakeDetailsProductTile";

type Props = {
  product: LocalLiteStocktakeItem;
};

export const StocktakeDetailsCatalogProductTile = memo(({ product }: Props) => {
  const renderProductDetails = useCallback(
    (product: LocalLiteStocktakeItem, className: string) => (
      <StocktakeCatalogProductTileDetails product={product} className={className} />
    ),
    []
  );

  return (
    <StocktakeDetailsProductTile
      product={product}
      renderProductDetails={renderProductDetails}
      salesEntityQuantity={product.skuDetails.salesEntityQuantity}
      measurementUnit={product.skuDetails.measurementUnit}
      singleUnitGrossPrice={product.singleUnitGrossPrice}
      quantity={product.quantity}
      robValue={product.robValue}
    />
  );
});
StocktakeDetailsCatalogProductTile.displayName = "StocktakeDetailsCatalogProductTile";
