import { Fragment, ReactNode } from "react";
import RenderIfVisible from "react-render-if-visible";

import { ChunkItem } from "src/models";

type Props<T> = {
  chunkedItems: Array<ChunkItem<T>>;
  estimatedItemHeightInPx: number;
  itemsGapInPx: number;
  renderChunkItem: (item: T) => ReactNode;
  className?: string;
  chunkWrapperClassName?: string;
};

function getEstimatedChunkHeight<T>(
  chunk: ChunkItem<T>,
  estimatedItemHeightInPx: number,
  itemsGapInPx: number
) {
  const chunkSize = chunk.chunkItems.length;
  return estimatedItemHeightInPx * chunk.chunkItems.length + itemsGapInPx * (chunkSize - 1);
}

export const ChunkedItemsList = <T extends { id: string }>({
  // Each chunk must have a unique id
  chunkedItems,
  estimatedItemHeightInPx,
  itemsGapInPx,
  renderChunkItem,
  className = "",
  chunkWrapperClassName = "",
}: Props<T>) => {
  return chunkedItems.length > 0 ? (
    <div className={className}>
      {chunkedItems.map((chunk) => (
        <RenderIfVisible
          key={chunk.chunkId}
          defaultHeight={getEstimatedChunkHeight<T>(chunk, estimatedItemHeightInPx, itemsGapInPx)}
        >
          <div className={chunkWrapperClassName}>
            {chunk.chunkItems.map((item) => (
              <Fragment key={item.id}>{renderChunkItem(item)}</Fragment>
            ))}
          </div>
        </RenderIfVisible>
      ))}
    </div>
  ) : null;
};
