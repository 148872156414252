import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";

import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { AVAILABLE_ITEMS_QUERY_KEY_BASE } from "src/hooks/catalog/useAvailableItemsQuery";
import { ORDER_DRAFTS_QUERY_KEY_BASE } from "src/hooks/orderDrafts/useOrderDraftsQuery";
import {
  STOCKTAKES_LIST_QUERY_KEY_BASE,
  STOCKTAKE_DRAFTS_LIST_QUERY_KEY_BASE,
  STOCKTAKE_DRAFT_QUERY_KEY_BASE,
  STOCKTAKE_REPORT_QUERY_KEY_BASE,
} from "src/hooks/stocktakes";
import { CATEGORIES_QUERY_KEY_BASE } from "src/hooks/useCategoriesQuery";
import { CONFIGURATION_QUERY_KEY_BASE } from "src/hooks/useConfigurationQuery";
import { OFFLINE_CATALOG_QUERY_KEY_BASE } from "src/hooks/useOfflineCatalogQuery";
import { ORDER_REQUISITION_LIST_QUERY_KEY_BASE } from "src/hooks/useOrderRequisitionListQuery";
import { ORDER_REQUISITION_QUERY_KEY_BASE } from "src/hooks/useOrderRequisitionQuery";
import { PRODUCT_QUERY_KEY_BASE } from "src/hooks/useProductQuery";
import { PRODUCT_VARIANTS_QUERY_KEY_BASE } from "src/hooks/useProductVariantsQuery";
import { PRODUCTS_LIST_QUERY_KEY_BASE } from "src/hooks/useProducts";

import { useNetworkDetector } from "../NetworkDetector";

const NETWORK_FIRST_QUERIES = [
  OFFLINE_CATALOG_QUERY_KEY_BASE,
  ORDER_REQUISITION_QUERY_KEY_BASE,
  ORDER_REQUISITION_LIST_QUERY_KEY_BASE,
  CONFIGURATION_QUERY_KEY_BASE,
  PRODUCT_VARIANTS_QUERY_KEY_BASE,
  AVAILABLE_ITEMS_QUERY_KEY_BASE,
  STOCKTAKES_LIST_QUERY_KEY_BASE,
  STOCKTAKE_REPORT_QUERY_KEY_BASE,
];
const OFFLINE_FIRST_QUERIES = [
  CATEGORIES_QUERY_KEY_BASE,
  PRODUCT_QUERY_KEY_BASE,
  PRODUCTS_LIST_QUERY_KEY_BASE,
  ORDER_DRAFTS_QUERY_KEY_BASE,
  STOCKTAKE_DRAFT_QUERY_KEY_BASE,
  STOCKTAKE_DRAFTS_LIST_QUERY_KEY_BASE,
];

export const NetworkQueryCancellation = () => {
  const queryClient = useQueryClient();
  const { wentOfflineFromOnline } = useNetworkDetector();
  const { isManualNetworkStateEnabled } = useOfflineCapabilities();
  const QUERIES_TO_INVALIDATE = useMemo(
    () => [...NETWORK_FIRST_QUERIES, ...(isManualNetworkStateEnabled ? [] : OFFLINE_FIRST_QUERIES)],
    [isManualNetworkStateEnabled]
  );

  useEffect(() => {
    if (wentOfflineFromOnline) {
      queryClient.cancelQueries({
        predicate: (query) => {
          // We don't want to cancel "catalog" queries in manual network state as they're offline first
          // and no BE API request will be made in offline mode.
          // Furthermore, cancelling them can have unintended consequences.
          return !query.queryKey.some(
            (key) =>
              typeof key === "string" &&
              (isManualNetworkStateEnabled ? OFFLINE_FIRST_QUERIES.includes(key) : false)
          );
        },
      });

      // If queries got cancelled when transitioning from `online` to `offline` state, they can get
      // stuck in `loading` state. To prevent it from happening we need to invalidate them.
      // This does not apply to "catalog" queries in manual network state as they are offline first.
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey.some(
              (key) => typeof key === "string" && QUERIES_TO_INVALIDATE.includes(key)
            ) && query.state.status === "pending"
          );
        },
      });
    }
  }, [QUERIES_TO_INVALIDATE, isManualNetworkStateEnabled, queryClient, wentOfflineFromOnline]);

  return null;
};
