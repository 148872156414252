import { v4 as uuidv4 } from "uuid";

import { StorageError, StorageRequest } from "@web/models";

import { LocalPartialStocktakeReportDraft, LocalStocktakeReportDraft } from "src/models";
import { stocktakeDraftsStore } from "src/objectStorage";

export const StocktakeDraftsService = {
  getDrafts: async (vesselId: string): Promise<LocalStocktakeReportDraft[]> => {
    const request: StorageRequest = {
      body: vesselId,
      access: "read",
    };

    if (!vesselId) {
      throw new StorageError(request, { status: 400 }, "No vessel id");
    }
    let drafts: LocalStocktakeReportDraft[];

    try {
      drafts = await stocktakeDraftsStore.where("vesselId").equals(vesselId).toArray();
    } catch (error) {
      throw new StorageError(request, { status: 500, body: error }, "Error getting drafts");
    }
    return drafts;
  },

  getDraft: async (draftId: string): Promise<LocalStocktakeReportDraft> => {
    const request: StorageRequest = {
      body: draftId,
      access: "read",
    };

    if (!draftId) {
      throw new StorageError(request, { status: 400 }, "No draft id");
    }
    let draft: LocalStocktakeReportDraft | undefined;

    try {
      draft = await stocktakeDraftsStore.get(draftId);
    } catch (error) {
      throw new StorageError(request, { status: 500, body: error }, "Error getting draft");
    }

    if (!draft) {
      throw new StorageError(
        request,
        { status: 404 },
        `No draft with the following ID exists: ${draftId}`
      );
    }
    return draft;
  },

  createDraft: async (
    stocktakeDraftData: LocalPartialStocktakeReportDraft
  ): Promise<LocalStocktakeReportDraft> => {
    const request: StorageRequest = {
      body: stocktakeDraftData,
      access: "write",
    };

    if (!stocktakeDraftData) {
      throw new StorageError(request, { status: 400 }, "No draft data");
    }

    const draftToCreate = {
      ...stocktakeDraftData,
      id: uuidv4(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    try {
      await stocktakeDraftsStore.put(draftToCreate, draftToCreate.id);
    } catch (error) {
      throw new StorageError(request, { status: 500, body: error }, "Error creating draft");
    }

    return draftToCreate;
  },

  deleteDraft: async (draftId: string): Promise<void> => {
    const request: StorageRequest = {
      body: draftId,
      access: "write",
    };

    if (!draftId) {
      throw new StorageError(request, { status: 400 }, "No draft id");
    }

    try {
      await stocktakeDraftsStore.delete(draftId);
    } catch (error) {
      throw new StorageError(request, { status: 500, body: error }, "Error deleting draft");
    }
  },

  updateDraft: async ({
    draftId,
    draftData,
  }: {
    draftId: string;
    draftData: LocalPartialStocktakeReportDraft;
  }): Promise<LocalStocktakeReportDraft> => {
    const request: StorageRequest = {
      body: { draftId, draftData },
      access: "write",
    };

    if (!draftId || !draftData) {
      throw new StorageError(request, { status: 400 }, "No draft id or draft data");
    }

    let draftToUpdate: LocalStocktakeReportDraft | undefined;

    try {
      draftToUpdate = await stocktakeDraftsStore.get(draftId);
    } catch (error) {
      throw new StorageError(request, { status: 500, body: error }, "Error getting draft");
    }

    if (!draftToUpdate) {
      throw new StorageError(
        request,
        { status: 404 },
        `Cannot update - no draft with the following ID exists: ${draftId}`
      );
    }

    const updatedDraft = {
      ...draftToUpdate,
      ...draftData,
      id: draftId,
      updatedAt: new Date().toISOString(),
    };

    try {
      await stocktakeDraftsStore.put(updatedDraft, draftId);
    } catch (error) {
      throw new StorageError(request, { status: 500, body: error }, "Error updating draft");
    }
    return updatedDraft;
  },
};
