import {
  MANUAL_NETWORK_TOGGLE_STORAGE_KEY,
  OFFLINE_CAPABILITIES_STORAGE_KEY,
} from "src/config/constants";

import { useOfflineCapabilitiesContext } from "./OfflineCapabilitiesContext";
import { UseOfflineCapabilities } from "./models";

export const useOfflineCapabilities = (): UseOfflineCapabilities => {
  const [{ areOfflineCapabilitiesEnabled, isManualNetworkStateEnabled }, dispatch] =
    useOfflineCapabilitiesContext();

  return {
    areOfflineCapabilitiesEnabled: !!areOfflineCapabilitiesEnabled,
    isManualNetworkStateEnabled: !!isManualNetworkStateEnabled,
    clearOfflineCapabilitiesState: () => {
      dispatch({ type: "setOfflineCapabilities", value: undefined });
      dispatch({ type: "setManualNetworkState", value: undefined });
      localStorage.removeItem(OFFLINE_CAPABILITIES_STORAGE_KEY);
      localStorage.removeItem(MANUAL_NETWORK_TOGGLE_STORAGE_KEY);
    },
    setOfflineCapabilitiesState: (state: boolean | undefined) => {
      dispatch({ type: "setOfflineCapabilities", value: state });
      localStorage.setItem(OFFLINE_CAPABILITIES_STORAGE_KEY, String(state));
    },
    setManualNetworkState: (state: boolean | undefined) => {
      dispatch({ type: "setManualNetworkState", value: state });
      localStorage.setItem(MANUAL_NETWORK_TOGGLE_STORAGE_KEY, String(state));
    },
  };
};
