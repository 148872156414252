import {
  DefaultError,
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useContext, useMemo } from "react";

import {
  Sorting,
  getSortingString,
  useSearchSortingHelper,
} from "@web/common/hooks/useSearchHelpers";

import AppStateContext from "../contexts/AppStateContext";
import { LiteCatalogConfiguration, LiteProduct, ProductsService } from "../typegens";
import { ApiRequestOptions } from "../typegens/core/ApiRequestOptions";
import { usePunchOutIntegration } from "./usePunchOutIntegration";

const getProductsList = async (
  {
    portId,
    categoryId,
    cursor,
    leadTime,
    priceMax,
    priceMin,
    sorting,
    supplierId,
    orderType,
  }: {
    cursor?: string;
    portId: string;
    categoryId: string;
    leadTime?: "ALL" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
    priceMax?: string;
    priceMin?: string;
    sorting?: Sorting;
    supplierId?: string;
    orderType?: LiteCatalogConfiguration["type"];
  },
  options?: Pick<ApiRequestOptions, "headers" | "signal">
) => {
  const resp = await ProductsService.getProductsByCategoryId(
    {
      portId,
      categoryId,
      sort: getSortingString(sorting),
      priceMin,
      priceMax,
      leadTime,
      cursor,
      supplierId,
      orderType,
    },
    options
  );
  return { ...resp, items: resp.items.filter((p) => p.skuList.length > 0) };
};

export const PRODUCTS_LIST_QUERY_KEY_BASE = "productList";

type QueryResponseData = { items: Array<LiteProduct>; cursor?: string };

const useProductListQuery = ({
  portId,
  categoryId,
  disabled,
  leadTime,
  priceMax,
  priceMin,
  sorting,
  supplierId,
  orderType,
}: {
  portId?: string;
  categoryId: string;
  disabled: boolean;
  leadTime?: "ALL" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
  priceMax?: string;
  priceMin?: string;
  sorting?: Sorting;
  supplierId?: string;
  orderType?: LiteCatalogConfiguration["type"];
}) => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => [
      PRODUCTS_LIST_QUERY_KEY_BASE,
      sorting,
      portId,
      categoryId,
      leadTime,
      priceMin,
      priceMax,
      orderType,
    ],
    [categoryId, leadTime, orderType, portId, priceMax, priceMin, sorting]
  );

  const query = useInfiniteQuery<
    QueryResponseData,
    DefaultError,
    InfiniteData<QueryResponseData>,
    QueryKey,
    string
  >({
    queryKey,
    queryFn: ({ pageParam, signal }) =>
      getProductsList(
        {
          cursor: pageParam,
          portId: portId || "",
          categoryId,
          leadTime,
          priceMax,
          orderType,
          priceMin,
          sorting,
          supplierId,
        },
        { signal }
      ),
    initialPageParam: "",
    getNextPageParam: (lastPage) => {
      if (lastPage.cursor !== "") {
        return lastPage.cursor;
      }
    },
    enabled: !!portId && !!orderType && !disabled,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
  });

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
    [queryKey, queryClient]
  );

  return { query, invalidate };
};

const useProducts = (categoryId: string, disabled: boolean) => {
  const [{ port, orderType }] = useContext(AppStateContext);
  const { leadTime, priceMin, priceMax, sorting } = useSearchSortingHelper();
  const { supplierId } = usePunchOutIntegration();

  return useProductListQuery({
    priceMax,
    leadTime,
    categoryId,
    disabled,
    portId: port?.id,
    priceMin,
    sorting,
    orderType,
    supplierId: supplierId || "",
  });
};

export default useProducts;
