import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

import { OrderRequisition } from "@web/common/network/model";

import { useErrorBoundaryPreference } from "src/hooks/useErrorBoundaryPreference";
import { LocalOrderReqService } from "src/services/LocalOrderReqService";
import { OrdersService } from "src/typegens";

export const ORDER_REQUISITION_QUERY_KEY_BASE = "orderRequisition";

const getQueryKey = (orderId: string) => [ORDER_REQUISITION_QUERY_KEY_BASE, orderId];

export const useOrderRequisitionQuery = ({ orderId }: { orderId: string }) => {
  const { shouldUseErrorBoundary } = useErrorBoundaryPreference();
  const queryKey = useMemo(() => getQueryKey(orderId), [orderId]);

  return useQuery<OrderRequisition>({
    queryKey,
    queryFn: async ({ signal }) => {
      const resp = await OrdersService.getOrderRequisitionById({ id: orderId }, { signal });
      return LocalOrderReqService.toOrderRequisition(resp);
    },
    refetchOnWindowFocus: false,
    throwOnError: shouldUseErrorBoundary,
  });
};

export const useOrderRequisitionQueryHelpers = () => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    (orderId: string) =>
      queryClient.invalidateQueries({
        queryKey: getQueryKey(orderId),
      }),
    [queryClient]
  );

  return { invalidate };
};
