import {
  Subject,
  Subscription,
  bufferCount,
  concat,
  concatMap,
  distinctUntilChanged,
  from,
  interval,
  map,
  tap,
} from "rxjs";

import { PING_INTERVAL, PING_URL_RESOURCE } from "src/config/constants";
import { pingNetworkStateResource } from "src/utils";

type PingResult = {
  isOnline: boolean;
  isShaky: boolean;
};

export class NetworkStatePinger {
  private url: string;
  private timeout: number;
  public result$: Subject<PingResult> = new Subject<PingResult>();
  private pingSubscription: Subscription | null = null;
  private isFirstPing = true;
  private isOnline = true;
  private ping: () => Promise<boolean>;

  constructor(url: string, timeout: number) {
    this.url = url;
    this.timeout = timeout;
    this.result$ = new Subject(); // Observable to emit results
    this.ping = pingNetworkStateResource(this.url, this.timeout);
  }

  startPinging() {
    // Make the first ping right away, subsequent pings in 5s interval
    const firstPing = from(this.ping());
    const intervalPings = interval(5000).pipe(concatMap(() => from(this.ping())));

    this.pingSubscription = concat(firstPing, intervalPings)
      .pipe(
        tap((isOnline) => {
          if (this.isFirstPing) {
            this.isFirstPing = false;
            this.isOnline = isOnline;

            // Yield the result of first ping immediately
            return this.result$.next({ isOnline: isOnline, isShaky: false });
          }
        }),
        bufferCount(5, 1), // Buffer the results in a window of 5 last pings
        map((buffer) => {
          const lastThreePings = buffer.slice(2);
          const lastFivePings = buffer;
          const wereLastThreePingsAllSame = lastThreePings.every(
            (value) => value === lastThreePings[0]
          );
          const wereLastFivePingsAllSame = lastFivePings.every(
            (value) => value === lastFivePings[0]
          );
          // Last 3 pings are used to establish network condition state,
          // if all 3 pings yielded the same result, emit that as a `isOnline` value.
          // Otherwise use previous value
          if (wereLastThreePingsAllSame) {
            this.isOnline = lastThreePings[0];
          }

          return {
            isOnline: this.isOnline,
            // Last 5 pings are used to establish "shakiness" of network conditions.
            // If at least of one of six pings yielded different result, then mark connection as shaky.
            isShaky: !wereLastFivePingsAllSame,
          };
        }),
        distinctUntilChanged(
          (prev, curr) => prev.isOnline === curr.isOnline && prev.isShaky === curr.isShaky
        ) // Emit only when the current value is different than the last
      )
      .subscribe((result) => this.result$.next(result));
  }

  stopPinging() {
    if (this.pingSubscription) {
      this.pingSubscription.unsubscribe();
      this.pingSubscription = null;
    }
    this.isFirstPing = true;
  }
}

export const networkStatePinger = new NetworkStatePinger(PING_URL_RESOURCE, PING_INTERVAL);
