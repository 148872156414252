import {
  DefaultError,
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

import { Sorting, getSortingString, useSearchInput } from "@web/common/hooks/useSearchHelpers";

import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { ApiListLiteProduct, LiteCatalogConfiguration, ProductsService } from "src/typegens";

import { usePunchOutIntegration } from "./usePunchOutIntegration";

const QUERY_KEY = "search";

const useSearchQuery = (
  {
    portId,
    query,
    sorting,
    priceMin,
    priceMax,
    leadTime,
    supplierId,
    orderType,
  }: {
    portId: string;
    query: string;
    sorting?: Sorting;
    priceMin?: string;
    priceMax?: string;
    leadTime?: "ALL" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
    supplierId?: string;
    orderType?: LiteCatalogConfiguration["type"];
  },
  settings: Record<string, unknown>
) => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => [QUERY_KEY, portId, query, sorting, priceMax, priceMin, leadTime, orderType],
    [leadTime, orderType, portId, priceMax, priceMin, query, sorting]
  );

  const infiniteQuery = useInfiniteQuery<
    ApiListLiteProduct,
    DefaultError,
    InfiniteData<ApiListLiteProduct>,
    QueryKey,
    string
  >({
    queryKey,
    queryFn: ({ pageParam, signal }) => {
      const sort = getSortingString(sorting);
      return ProductsService.searchProducts(
        {
          portId,
          query,
          sort,
          priceMin,
          priceMax,
          leadTime,
          cursor: pageParam,
          supplierId,
          orderType,
        },
        { signal }
      );
    },

    ...settings,
    initialPageParam: "",
    getNextPageParam: (lastPage) => {
      if (lastPage.cursor !== "") {
        return lastPage.cursor;
      }
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
  });

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
    [queryClient, queryKey]
  );

  return { query: infiniteQuery, invalidate };
};

export const useSearch = () => {
  const [{ port, orderType }] = useAppStateContext();
  const { query, sorting, priceMin, priceMax, leadTime } = useSearchInput();
  const { supplierId } = usePunchOutIntegration();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isOnline } = useNetworkDetector();

  return useSearchQuery(
    {
      portId: port?.id || "",
      sorting,
      priceMax,
      priceMin,
      leadTime,
      query,
      // supplierId is allowed to be empty
      supplierId: supplierId || "",
      orderType,
    },
    {
      enabled: !!port?.id && !!orderType && (areOfflineCapabilitiesEnabled ? isOnline : true),
    }
  );
};
