import { z } from "zod";

import { LiteStocktakeReportSchema } from "./LiteStocktakeReportSchema";
import { StocktakeReportDraftItemSchema } from "./StocktakeReportDraftItemSchema";
import { StocktakeReportDraftNewExtraItemSchema } from "./StocktakeReportDraftNewExtraItemSchema";
import { StocktakeReportDraftOldExtraItemSchema } from "./StocktakeReportDraftOldExtraItemSchema";

export const StocktakeReportDraftSchema = LiteStocktakeReportSchema.omit({
  extraItems: true,
}).extend({
  items: z.array(StocktakeReportDraftItemSchema),
  oldExtraItems: z.array(StocktakeReportDraftOldExtraItemSchema),
  newExtraItems: z.array(StocktakeReportDraftNewExtraItemSchema),
});

export type LocalStocktakeReportDraft = z.infer<typeof StocktakeReportDraftSchema>;
