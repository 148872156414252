import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Attachment } from "@web/common/network";
import { Heading, Label } from "@web/ui";

import { HeaderAttachmentElement } from "./HeaderAttachmentsElement";

interface HeaderAttachmentsProps {
  className: string;
  attachments: Attachment[];
}

export const HeaderAttachments = (props: HeaderAttachmentsProps) => {
  const [expanded, setExpanded] = useState(false);
  const { className = "", attachments = [] } = props;
  const { t } = useTranslation();
  const DISPLAY_MORE_LIMIT = 2;

  const onExpand = () => {
    setExpanded((expanded: boolean) => !expanded);
  };

  return (
    <div className={`relative ${className}`}>
      <span className={"flex justify-between w-full mb-4"}>
        <Heading size="300" className="capitalize">{`${attachments.length} ${t("attachment", {
          count: attachments.length,
        })}`}</Heading>
        {/*  TODO: #5219 As a user I'd like to download all attachments
             <RegularButton variant="primary" size="large" label="Download all attachments" />
         */}
      </span>
      <div className="flex flex-row flex-wrap gap-y-4 py-24 pb-6 justify-between">
        {attachments
          .filter((value, index) =>
            expanded ? index < attachments.length : index < DISPLAY_MORE_LIMIT
          )
          .map((attachment, index) => {
            return <HeaderAttachmentElement attachment={attachment} key={index} />;
          })}
      </div>
      {attachments.length > DISPLAY_MORE_LIMIT && !expanded && (
        <Label
          className="pb-6 redVessel"
          size="200"
          style={{ color: "#4f46e5", borderBottom: "1px solid #E5E7EB" }}
        >
          <span>
            <span className="cursor-pointer" onClick={onExpand}>
              See All Attachments
            </span>
          </span>
        </Label>
      )}
    </div>
  );
};
