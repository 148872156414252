import { OrderState } from "src/models";

export enum RoutesParams {
  STOCKTAKE_ID = "stocktakeId",
}

export const RoutesConfig = {
  mainPage: "/",
  order: {
    overview: "/order/overview",
    success: "/order/success",
    details: "/orderDetails",
    draftDetails: "/draftDetails",
  },
  gatherSetup: "/gatherSetup",
  category: "/categories",
  search: "/search",
  basket: "/basket",
  orderInfo: "/orderInfo",
  retrieveVesselToken: "/retrieveVesselToken",
  requisitionInfo: "/requisitionInfo",
  requisitions: {
    success: "/requisition/success",
  },
  // It's important that all stocktake routes have `stocktake` in their path
  // since `AppAreaWrapper` is relying on it to determine the app area
  stocktake: {
    overview: "/stocktake/overview",
    details: `/stocktake/:${RoutesParams.STOCKTAKE_ID}/details`,
    edit: `/stocktake/:${RoutesParams.STOCKTAKE_ID}/edit`,
    setup: "/stocktake/setup",
    finishSuccess: `/stocktake/:${RoutesParams.STOCKTAKE_ID}/finish-success`,
    nothingToStocktake: `/stocktake/nothing-to-stocktake`,
  },
};

export const getOrdersOverviewPath = (ordersState: OrderState): string =>
  `${RoutesConfig.order.overview}?ordersState=${ordersState}`;

export const getCategoryByIdPath = (id: string) => `${RoutesConfig.category}/${id}`;
