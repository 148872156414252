import { forwardRef, memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { LocalStocktakeReportForm, StocktakeReportItemNameFieldPath } from "src/models";

import { Input } from "../StocktakeFormProductTile/Input";
import { useFormFieldKeyboardEvents } from "../StocktakeFormProductTile/useFormFieldKeyboardEvents";

type Props = {
  nameFieldPath: StocktakeReportItemNameFieldPath;
};

export const ProductNameField = memo(
  forwardRef<HTMLInputElement, Props>(
    (
      { nameFieldPath },
      // We need to catch `ref`, so we don't get errors in runtime

      ref
    ) => {
      const { control } = useFormContext<LocalStocktakeReportForm>();
      const { handleKeyDown } = useFormFieldKeyboardEvents<HTMLInputElement>();

      return (
        <Controller
          name={nameFieldPath}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              ref={ref}
              className="px-2"
              placeholder="Product name..."
              onKeyDown={handleKeyDown}
              maxLength={200}
              aria-label="Product name form field (required)"
            />
          )}
        />
      );
    }
  )
);
ProductNameField.displayName = "ProductNameField";
