import { forwardRef, memo, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { DropdownItem, RegularDropdownItem } from "@web/ui";

import { LocalStocktakeReportForm, StocktakeReportItemMeasurementUnitFieldPath } from "src/models";
import { UnitOfMeasure } from "src/state/models";

import { useFormFieldKeyboardEvents } from "../StocktakeFormProductTile/useFormFieldKeyboardEvents";
import { Select } from "./Select";

type Props = {
  onValueChanged: (newValue: string) => void;
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
  unitOfMeasures: UnitOfMeasure[];
};

export const UnitOfMeasureField = memo(
  forwardRef<HTMLButtonElement, Props>(
    (
      { onValueChanged, measurementUnitFieldPath, unitOfMeasures },
      // We need to catch `ref`, so we don't get errors in runtime

      ref
    ) => {
      const { control, setValue } = useFormContext<LocalStocktakeReportForm>();

      const setFieldValue = useCallback(
        (newMeasurementUnitValue: string) => {
          setValue(measurementUnitFieldPath, newMeasurementUnitValue);
          onValueChanged(newMeasurementUnitValue);
        },
        [measurementUnitFieldPath, onValueChanged, setValue]
      );

      const formatUnitOfMeasure = useCallback(
        (unitOfMeasure: UnitOfMeasure) => `${unitOfMeasure.name} (${unitOfMeasure.code})`,
        []
      );

      const options: DropdownItem[] = unitOfMeasures.map((unitOfMeasure) => ({
        key: unitOfMeasure.code,
        renderComponent: () => (
          <RegularDropdownItem
            label={formatUnitOfMeasure(unitOfMeasure)}
            variant="basic"
            onClick={() => {
              setFieldValue(unitOfMeasure.code);
            }}
          />
        ),
      }));

      const getCurrentValueLabel = useCallback(
        (unitOfMeasureCode: string) => {
          const currentValue = unitOfMeasures.find(
            (unitOfMeasure) => unitOfMeasure.code === unitOfMeasureCode
          );
          return currentValue ? formatUnitOfMeasure(currentValue) : undefined;
        },
        [formatUnitOfMeasure, unitOfMeasures]
      );

      const { handleKeyDown } = useFormFieldKeyboardEvents<HTMLButtonElement>();

      return (
        <Controller
          name={measurementUnitFieldPath}
          control={control}
          render={({ field: { value } }) => (
            <Select
              ref={ref}
              currentValueLabel={getCurrentValueLabel(value)}
              placeholder="Select..."
              options={options}
              onKeyDown={handleKeyDown}
              aria-label="Unit of measure form select field"
            />
          )}
        />
      );
    }
  )
);
UnitOfMeasureField.displayName = "UnitOfMeasureField";
