import { z } from "zod";

import { LiteSkuNutritionSchema } from "./LiteSkuNutritionSchema";
import { optionalNonEmptyString } from "./common";

export const LiteSkuCareAndUseSchema = z.object({
  allergicIngredients: optionalNonEmptyString(),
  alcoholPercentage: optionalNonEmptyString(),
  isHalal: z.boolean().optional(),
  isKosher: z.boolean().optional(),
  isVegan: z.boolean().optional(),
  isVegetarian: z.boolean().optional(),
  ingredients: optionalNonEmptyString(),
  fatContent: optionalNonEmptyString(),
  nutrition: LiteSkuNutritionSchema,
});
