import { Dispatch } from "react";
import { z } from "zod";

import { BasketEntry, ProductSku } from "@web/common";

import { LiteBasketFormAnswer, LiteRfqItem } from "../../typegens";

export type SetBasketState = {
  type: "setBasketState";
  value: BasketState;
};

export type AddMultipleSkusToBasketAction = {
  type: "addMultipleSkusToBasket";
  value: Array<BasketEntry>;
};
export type PushSkuToBasketAction = {
  type: "pushSkuToBasket";
  value: BasketEntry;
};

export type RemoveSkuFromBasketAction = {
  type: "removeSkuFromBasket";
  value: ProductSku;
};

export type RemoveMultipleEntriesFromBasketAction = {
  type: "removeMultipleEntriesFromBasket";
  value: Set<string>;
};

export type AddRfqToBasketAction = {
  type: "addRfqToBasket";
  value: LiteRfqItem;
};

export type RemoveRfqFromBasketAction = {
  type: "removeRfqFromBasket";
  value: string;
};

export type UpdateRfqInBasketAction = {
  type: "updateRfqInBasket";
  value: LiteRfqItem;
};

export type ClearBasketAction = {
  type: "clearBasket";
};

export type SetDraftAction = {
  type: "setDraft";
  value: { id: string; updatedAt: string };
};

// TODO #5641: Remove this action and fall back to `setDraft` everywhere when removing Online Draft logic
export type SetNewDraftAction = {
  type: "setNewDraft";
  value: { id: string; updatedAt: string; status?: DraftStatus };
};

export type AddMultipleRfqsToBasketAction = {
  type: "addMultipleRfqsToBasket";
  value: LiteRfqItem[];
};

export type SetBasketFormAnswersAction = {
  type: "setBasketFormAnswers";
  value: Array<LiteBasketFormAnswer>;
};

export type BasketStateAction =
  | AddMultipleRfqsToBasketAction
  | AddMultipleSkusToBasketAction
  | AddRfqToBasketAction
  | ClearBasketAction
  | PushSkuToBasketAction
  | RemoveRfqFromBasketAction
  | RemoveSkuFromBasketAction
  | RemoveMultipleEntriesFromBasketAction
  | SetBasketFormAnswersAction
  | SetBasketState
  | SetDraftAction
  | SetNewDraftAction
  | UpdateRfqInBasketAction;

export type BasketDispatch = Dispatch<BasketStateAction>;

// TODO #5641: Remove DraftStatus along with Online Draft logic
// Related to #8117: Dirty fix to prevent overwriting the draft after it was cleared in some edge cases.
// Dirty fix because online draft is a legacy feature and Offline Draft is not susceptible to this problem.
export type DraftStatus = "CREATION_STARTED";

export interface BasketState {
  basketFormAnswers?: Array<LiteBasketFormAnswer>;
  draft?: { id: string; updatedAt: string; status?: DraftStatus };
  lastUpdated?: number;
  lineItems: Array<BasketEntry>;
  rfqItems: { [key in string]: LiteRfqItem };
}

export const BasketStateSchema = z.object({
  draft: z
    .object({ id: z.string(), updatedAt: z.string(), status: z.string().optional() })
    .nullable()
    .optional(),
  lastUpdated: z.number().nullable().optional(),
  lineItems: z.array(z.object({})),
  rfqItems: z.record(
    z.string(),
    z.object({
      id: z.string(),
      name: z.string(),
      quantity: z.number(),
      measurementUnit: z.string(),
      description: z.string().optional(),
      exampleUrl: z.string().optional(),
    })
  ),
});
