import { CACHED_CATALOG_OLD_TIME } from "../config/constants";

export const isCatalogDataOld = (updatedAt?: string) => {
  if (!updatedAt) {
    return false;
  }

  const diff = Date.now() - new Date(updatedAt).valueOf();

  return diff > CACHED_CATALOG_OLD_TIME;
};
