import { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";

import { flattenPorts } from "@web/common/utils";
import { Form, PreconfigureOrderSetup } from "@web/common/views/PreconfigureOrderSetup";
import { Port } from "@web/models";
import { ActionBar, Loading, Modal } from "@web/ui";

import { AttentionInfo } from "src/components/AttentionInfo";
import { NoCatalogDataModal } from "src/components/Modal/NoCatalogDataModal";
import { OldCatalogDataModal } from "src/components/Modal/OldCatalogDataModal";
import { UnableToGoOnlineModal } from "src/components/Modal/UnableToGoOnlineModal";
import { TopBarController } from "src/components/TopBar";
import { IDB_COMPOSITE_KEYS_SEPARATOR } from "src/config/constants";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { NetworkToggleSwitchController, useNetworkToggle } from "src/contexts/NetworkToggle";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { useOfflineCatalogMetaData } from "src/hooks/useOfflineCatalogMetaData";
import { useToastMessage } from "src/hooks/useToastMessage";
import { useWasUnableToReconnect } from "src/hooks/useWasUnableToReconnect";
import { LocalConfigurationService } from "src/services/LocalConfigurationService";
import { isCatalogDataOld } from "src/utils";
import trackingCategories from "src/utils/trackingCategories";
import trackingEvents from "src/utils/trackingEvents";

export const PreconfigureOrder: React.FC = () => {
  const [{ configuration, port, orderType, isPunchoutSession }, dispatch] = useAppStateContext();
  const { areOfflineCapabilitiesEnabled, isManualNetworkStateEnabled } = useOfflineCapabilities();
  const [isOldCatalogDataMsgShown, setIsOldCatalogDataMsgShown] = useState(false);
  const [isNoCatalogDataMsgShown, setIsNoCatalogDataMsgShown] = useState(false);
  const [isUnableToGoOnlineMsgShown, setIsUnableToGoOnlineMsgShown] = useState(false);

  const [formData, setFormData] = useState<Form | undefined>(undefined);
  const { isOffline, isOnline, forceOffline } = useNetworkDetector();
  const { setToggleOffline } = useNetworkToggle();
  const { wasUnableToReconnect } = useWasUnableToReconnect();
  const { data: offlineCatalogMetadata, isPending: isOfflineCatalogMetadataPending } =
    useOfflineCatalogMetaData();
  const navigate = useNavigate();
  const isOrderTypeRequired = !isPunchoutSession;
  const { setToastMessage } = useToastMessage();

  const commitFormData = useCallback(
    (form?: Form) => {
      if (!form) {
        throw new Error("PreconfigureOrderSetup - no form data");
      }
      if (isOrderTypeRequired && !form.orderType) {
        setToastMessage({
          type: "failure",
          message:
            "There was a problem with starting the order - missing Order Type. Please contact support.",
        });
        return;
      }
      ReactGA.event({
        category: trackingCategories.CREW_APP,
        action: trackingEvents.PORT_SELECTED,
        label: form.port?.name,
      });
      dispatch({ type: "setPreconfigureOrderSetup", value: form });
    },
    [dispatch, isOrderTypeRequired, setToastMessage]
  );

  const openOldCatalogDataModal = (formData: Form) => {
    setFormData(formData);
    setIsOldCatalogDataMsgShown(true);
  };

  const openNoCatalogDataModal = (formData: Form) => {
    setFormData(formData);
    setIsNoCatalogDataMsgShown(true);
  };

  useEffect(() => {
    // We're handling here two scenarios, after old/no catalog data warning was displayed:
    // 1) user successfully reconected:
    // submit form data and dismiss the modal, user will be taken to Gather Experience
    if ((isOldCatalogDataMsgShown || isNoCatalogDataMsgShown) && isOnline && formData) {
      commitFormData(formData);
      setIsOldCatalogDataMsgShown(false);
      setIsNoCatalogDataMsgShown(false);
    }

    // 2) user was unable to reconnect:
    // dismiss the modal, set correct state for offline,
    // finally display `UnableToGoOnlineModal`
    if (wasUnableToReconnect) {
      setIsOldCatalogDataMsgShown(false);
      setIsNoCatalogDataMsgShown(false);
      setToggleOffline();
      forceOffline();
      // delay displaying next modal for smoother transition
      setTimeout(() => {
        setIsUnableToGoOnlineMsgShown(true);
      }, 500);
    }
  }, [
    commitFormData,
    forceOffline,
    formData,
    isNoCatalogDataMsgShown,
    isOldCatalogDataMsgShown,
    isOnline,
    setToggleOffline,
    wasUnableToReconnect,
  ]);

  const renderAttentionInfo = useCallback(
    ({ selectedPort }: { selectedPort?: Port }) => {
      if (!configuration || !selectedPort) {
        return null;
      }

      const items =
        LocalConfigurationService.getConfiguredPort(configuration, selectedPort)?.attentionInfo ||
        [];

      return items.length > 0 ? <AttentionInfo items={items} /> : null;
    },
    [configuration]
  );

  if (!configuration) {
    return null;
  }

  if (isOfflineCatalogMetadataPending) {
    return <Loading />;
  }

  const ports = flattenPorts(configuration.ports).map((port) => {
    const storedPortId = `${port.id}${IDB_COMPOSITE_KEYS_SEPARATOR}${orderType || "DEFAULT"}`;
    if (offlineCatalogMetadata?.has(storedPortId)) {
      return {
        ...port,
        offlineCatalogUpdatedAt: offlineCatalogMetadata.get(storedPortId)?.updatedAt,
      };
    }

    return port;
  });

  const handleFormSubmit = async (form: Form) => {
    if (
      areOfflineCapabilitiesEnabled &&
      isOffline &&
      isCatalogDataOld(form.port?.offlineCatalogUpdatedAt)
    ) {
      openOldCatalogDataModal(form);
    } else if (areOfflineCapabilitiesEnabled && isOffline && !form.port?.offlineCatalogUpdatedAt) {
      openNoCatalogDataModal(form);
    } else {
      commitFormData(form);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOldCatalogDataMsgShown}
        closeModal={() => setIsOldCatalogDataMsgShown(false)}
      >
        <NetworkToggleSwitchController
          render={({ setSwitchOnline, isNetworkReconnecting }) => (
            <OldCatalogDataModal
              isReconnecting={isNetworkReconnecting}
              onClose={() => setIsOldCatalogDataMsgShown(false)}
              onContinueOffline={() => {
                setIsOldCatalogDataMsgShown(false);
                commitFormData(formData);
              }}
              onSwitchToOnline={() => {
                setSwitchOnline();
              }}
              isManualNetworkStateEnabled={isManualNetworkStateEnabled}
            />
          )}
        />
      </Modal>
      <Modal isOpen={isNoCatalogDataMsgShown} closeModal={() => setIsNoCatalogDataMsgShown(false)}>
        <NetworkToggleSwitchController
          render={({ setSwitchOnline, isNetworkReconnecting }) => (
            <NoCatalogDataModal
              isReconnecting={isNetworkReconnecting}
              onClose={() => setIsNoCatalogDataMsgShown(false)}
              onSwitchToOnline={() => {
                setSwitchOnline();
              }}
              isManualNetworkStateEnabled={isManualNetworkStateEnabled}
            />
          )}
        />
      </Modal>
      <Modal
        isOpen={isUnableToGoOnlineMsgShown}
        closeModal={() => setIsUnableToGoOnlineMsgShown(false)}
      >
        <UnableToGoOnlineModal
          onClose={() => {
            setIsUnableToGoOnlineMsgShown(false);
          }}
          onContinueOffline={() => {
            setIsUnableToGoOnlineMsgShown(false);
            // Commiting form data will navigate user to Gather Experience.
            // Do that only, when there is catalog data.
            if (formData && !!formData.port?.offlineCatalogUpdatedAt) {
              commitFormData(formData);
            }
          }}
        />
      </Modal>
      <div className="flex flex-col h-full bg-neutral_100">
        <TopBarController />
        <ActionBar
          backButtonSettings={
            isPunchoutSession
              ? undefined
              : {
                  title: "Return to Overview",
                  onClick: () => {
                    navigate(RoutesConfig.mainPage);
                  },
                }
          }
        />
        <PreconfigureOrderSetup
          onSubmitForm={handleFormSubmit}
          ports={ports}
          preselectedOrderType={orderType}
          preselectedPort={port}
          isPunchOutIntegration={isPunchoutSession}
          renderAttentionInfo={renderAttentionInfo}
          orderTypes={configuration.orderTypes}
        />
      </div>
    </>
  );
};
