import { z } from "zod";

// based on https://github.com/colinhacks/zod/issues/310#issuecomment-790121875
const optionalTextInput = (schema: z.ZodString, error?: z.CustomErrorParams) =>
  z
    .union([z.string(), z.undefined()])
    .refine((val) => !val || schema.safeParse(val).success, error);

// https://regexr.com/3e6m0
const urlRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

export const TitleSchema = z
  .string()
  .min(1, { message: "Please a descriptive product name to your request" });
export const QuantitySchema = z.number().min(1, { message: "Quantity is required" });
export const MeasurementUnitSchema = z
  .object({
    label: z.string(),
    value: z.string(),
  })
  .required();
export const SupplierSchema = z
  .object({
    label: z.string(),
    value: z.string(),
  })
  .optional();
export const DescriptionSchema = z
  .string()
  .min(1, { message: "Please provide a description of the item you would like to request." });
export const LinkSchema = optionalTextInput(z.string().regex(urlRegex), {
  message: "Please provide a valid URL",
});

export interface ValidateOutput {
  fieldTitle: string;
  isValid: boolean;
  errors?: string[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validate = (schema: any, value: unknown, fieldTitle: string): ValidateOutput => {
  try {
    schema.parse(value);
    return { fieldTitle, isValid: true };
  } catch (e) {
    if (e instanceof Error) {
      return {
        fieldTitle,
        isValid: false,
        errors: JSON.parse(e.message)?.map((item: { message: string }) => item.message),
      };
    }

    return {
      fieldTitle,
      isValid: false,
      errors: ["Invalid"],
    };
  }
};
