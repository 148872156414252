import { ReactNode, memo } from "react";

import { findDescendantById } from "@web/common";
import { Category } from "@web/models";
import { Heading } from "@web/ui";

type Props = {
  categories: Category[];
  activeCategoryId?: string;
  renderCategoryItems: (categoryId: string) => ReactNode;
};

export const CategorizedProductsList = memo(
  ({ categories, activeCategoryId, renderCategoryItems }: Props) => (
    <div className="flex flex-col gap-2">
      {categories.map((categoryLevel1) =>
        !activeCategoryId || findDescendantById(activeCategoryId, categoryLevel1) ? (
          <div key={categoryLevel1.id} className="flex flex-col gap-2">
            <Heading size="200">{categoryLevel1.name}</Heading>
            <div className="[&:not(:empty)]:mb-3 empty:hidden">
              {renderCategoryItems(categoryLevel1.id)}
            </div>
            {categoryLevel1.children.map((categoryLevel2) =>
              !activeCategoryId ||
              categoryLevel1.id === activeCategoryId ||
              findDescendantById(activeCategoryId, categoryLevel2) ? (
                <div key={categoryLevel2.id} className="flex flex-col gap-2">
                  <Heading size="300">{categoryLevel2.name}</Heading>
                  <div className="[&:not(:empty)]:mb-3 empty:hidden">
                    {renderCategoryItems(categoryLevel2.id)}
                  </div>
                  {categoryLevel2.children.map((categoryLevel3) =>
                    !activeCategoryId ||
                    categoryLevel1.id === activeCategoryId ||
                    categoryLevel2.id === activeCategoryId ||
                    findDescendantById(activeCategoryId, categoryLevel3) ? (
                      <div key={categoryLevel3.id} className="flex flex-col gap-2 mb-3">
                        <Heading size="400">{categoryLevel3.name}</Heading>
                        {renderCategoryItems(categoryLevel3.id)}
                      </div>
                    ) : null
                  )}
                </div>
              ) : null
            )}
          </div>
        ) : null
      )}
    </div>
  )
);
CategorizedProductsList.displayName = "CategorizedProductsList";
