import Dexie from "dexie";

import { LocalStocktakeReportDraft } from "src/models";

import { StoreNames, getStoreName } from "../config";

export class LocalStocktakeDraftsDatabase extends Dexie {
  stocktakeDrafts: Dexie.Table<LocalStocktakeReportDraft, string>;

  constructor() {
    super(getStoreName(StoreNames.STOCKTAKE_DRAFTS));
    this.version(0.1).stores({
      stocktakeDrafts: ",vesselId",
    });
    this.version(0.2)
      .stores({
        stocktakeDrafts: ",vesselId",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalStocktakeReportDraft>("stocktakeDrafts")
          .toCollection()
          .modify((stocktakeDraft) => {
            if (!stocktakeDraft.oldExtraItems) {
              stocktakeDraft.oldExtraItems = [];
            }
            if (!stocktakeDraft.newExtraItems) {
              stocktakeDraft.newExtraItems = [];
            }
          });
      });
  }
}

export const stocktakeDraftsStore = new LocalStocktakeDraftsDatabase().stocktakeDrafts;
