import { IBadge } from "@web/ui";

export const getBadgeSettingsFromStocktakeState = (
  isDraft: boolean
): {
  text: string;
  color: IBadge["color"];
} => {
  if (isDraft) {
    return {
      text: "Draft",
      color: "primary",
    };
  }
  return {
    text: "Completed",
    color: "success",
  };
};
