import { DefaultError, UseMutationResult } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useCallback } from "react";

import { OrderRequisition } from "@web/common";

import { LiteOrderRequisition } from "src/typegens";

import { useCancelOrderMutation } from "./useCancelOrderMutation";
import { useCancelRequisitionMutation } from "./useCancelRequisitionMutation";
import { useCloseOrderMutation } from "./useCloseOrderMutation";
import {
  useOrderRequisitionQuery,
  useOrderRequisitionQueryHelpers,
} from "./useOrderRequisitionQuery";

type UseOrder = {
  orderQuery: UseQueryResult<OrderRequisition>;
  invalidateOrderQuery: () => Promise<void>;
  cancelOrderRequisitionMutation: UseMutationResult<
    LiteOrderRequisition,
    DefaultError,
    OrderRequisition
  >;
  closeOrderMutation: UseMutationResult<LiteOrderRequisition, DefaultError, OrderRequisition>;
};

const useOrder = (orderId: string): UseOrder => {
  const orderQuery = useOrderRequisitionQuery({
    orderId,
  });
  const { data: queryOrderRequisition } = orderQuery;
  const { invalidate } = useOrderRequisitionQueryHelpers();

  const invalidateOrderQuery = useCallback(() => invalidate(orderId), [invalidate, orderId]);

  const _cancelRequisitionMutation = useCancelRequisitionMutation();
  const _cancelOrderMutation = useCancelOrderMutation();
  const cancelOrderRequisitionMutation =
    queryOrderRequisition?.type === "REQUISITION"
      ? _cancelRequisitionMutation
      : _cancelOrderMutation;
  const closeOrderMutation = useCloseOrderMutation();

  return {
    orderQuery,
    invalidateOrderQuery,
    cancelOrderRequisitionMutation,
    closeOrderMutation,
  };
};

export default useOrder;
