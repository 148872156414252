import classnames from "classnames";
import { memo } from "react";

import { Paragraph } from "@web/ui";
import { LEGACY_formatDate, imagor } from "@web/utils";

import ProductImageSVG from "../../icons/ProductImage.svg";
import { LocalLiteStocktakeItem, LocalStocktakeReportItemForm } from "../../models";

type Props = {
  product: LocalLiteStocktakeItem | LocalStocktakeReportItemForm;
  className: string;
};

export const StocktakeCatalogProductTileDetails = memo(({ product, className }: Props) => (
  <div className={classnames(className, "flex justify-between items-center gap-4")}>
    <img
      src={
        (product.skuDetails.images[0]?.imageUrl &&
          imagor(product.skuDetails.images[0]?.imageUrl)) ||
        ProductImageSVG
      }
      loading="lazy"
      className="flex-none w-8 h-8 object-contain"
      alt={
        product.skuDetails.images[0]?.imageUrl
          ? "Image representing the product"
          : "Fallback product image"
      }
    />
    <div className="flex-auto flex flex-col justify-around items-start">
      <Paragraph size="200" weight="heavy" className="mb-1">
        {product.skuDetails.about.name}
      </Paragraph>
      <Paragraph size="400" weight="heavy" color="text-textIcon-blackSecondary">
        Supplied by{" "}
        <span className="text-primaryDefault">{product.skuDetails.supplierInformation.name}</span>
      </Paragraph>
      <Paragraph size="400" weight="heavy" color="text-textIcon-blackSecondary">
        {product.port.locationCode} - {product.port.name}{" "}
        {LEGACY_formatDate(new Date(product.orderDate))}
      </Paragraph>
    </div>
  </div>
));
StocktakeCatalogProductTileDetails.displayName = "StocktakeCatalogProductTileDetails";
