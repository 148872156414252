import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OptionType, Select } from "@web/common/components/Select";
import { Input, Label, Paragraph, RegularButton, Textarea } from "@web/ui";

import { RoutesConfig } from "../../config/routes";
import { useAppStateContext } from "../../contexts/AppStateContext";
import useBasket from "../../hooks/useBasket";
import { LiteRfqItem } from "../../typegens";
import DividerTexts from "./DividerTexts";
import {
  DescriptionSchema,
  LinkSchema,
  MeasurementUnitSchema,
  QuantitySchema,
  SupplierSchema,
  TitleSchema,
  ValidateOutput,
  validate,
} from "./schema";

type FormState = "INITIAL" | "OPENED" | "SENT";

interface Props {
  basket?: boolean;
  closeEdit?: () => void;
  rfqToEdit?: LiteRfqItem;
  saveEdit?: (arg: LiteRfqItem) => void;
  maxLength?: number;
}

const RequestProductInline = ({ basket, rfqToEdit, closeEdit, saveEdit, maxLength = 4 }: Props) => {
  const navigate = useNavigate();
  const { addRfqToBasket } = useBasket();
  const [{ configuration }] = useAppStateContext();
  const [formState, setFormState] = useState<FormState>(rfqToEdit ? "OPENED" : "INITIAL");
  const [title, setTitle] = useState(rfqToEdit?.name || "");
  const [previousQuantity, setPreviousQuantity] = useState(rfqToEdit?.quantity || 1);
  const [quantity, setQuantity] = useState(rfqToEdit?.quantity || 1);
  const [measurementUnitList, setMeasurementUnitList] = useState<OptionType[]>([]);

  const fillMeasurementUnit = () => {
    if (!rfqToEdit) {
      return undefined;
    }

    return measurementUnitList.find((unit) => unit.value === rfqToEdit.measurementUnit);
  };

  const [measurementUnit, setMeasurementUnit] = useState<OptionType | undefined>(
    fillMeasurementUnit()
  );

  const [supplier, setSupplier] = useState<OptionType | undefined>(undefined); // Not used now. Supplier data will be added later on
  const [description, setDescription] = useState<string>(rfqToEdit?.description || "");
  const [link, setLink] = useState(rfqToEdit?.exampleUrl || "");
  const [validationResult, setValidationResult] = useState<ValidateOutput[]>([]);
  const [isValid, setIsValid] = useState(false);
  const [blurredFields, setBlurredFields] = useState<string[]>([]);
  const [isValidationError, setIsValidationError] = useState(false);

  const validateForm = useCallback<() => ValidateOutput[]>(() => {
    const validationArray = [
      validate(TitleSchema, title, "title"),
      validate(QuantitySchema, quantity, "quantity"),
      validate(MeasurementUnitSchema, measurementUnit, "measurementUnit"),
      validate(SupplierSchema, supplier, "supplier"),
      validate(DescriptionSchema, description, "description"),
      validate(LinkSchema, link, "link"),
    ];

    setValidationResult(validationArray);

    if (!validationArray.find((item) => !item.isValid)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    return validationArray;
  }, [description, link, measurementUnit, quantity, supplier, title]);

  const checkValidation = (checkedTitle: string): ValidateOutput | undefined => {
    return validationResult?.find(({ fieldTitle }) => fieldTitle === checkedTitle);
  };

  const canRenderValidationErrorMessage = (checkedTitle: string): boolean => {
    return (
      !checkValidation(checkedTitle)?.isValid &&
      isValidationError &&
      blurredFields.includes(checkedTitle)
    );
  };

  const resetState = () => {
    setFormState("INITIAL");
    setTitle("");
    setQuantity(1);
    setMeasurementUnit(undefined);
    setSupplier(undefined);
    setDescription("");
    setLink("");
    setValidationResult([]);
    setBlurredFields([]);
    setIsValidationError(false);
  };

  const onSubmitClick = () => {
    if (rfqToEdit && measurementUnit) {
      saveEdit &&
        saveEdit({
          id: rfqToEdit.id,
          name: title,
          quantity,
          measurementUnit: measurementUnit.value,
          description,
        });
      return;
    }

    if (isValid && measurementUnit) {
      addRfqToBasket({
        id: crypto.randomUUID(),
        name: title,
        quantity,
        measurementUnit: measurementUnit.value,
        description,
        exampleUrl: link,
      });
      resetState();
      if (basket) {
        setFormState("INITIAL");
      } else {
        setFormState("SENT");
      }
    } else {
      validateForm();
      setBlurredFields(validateForm().map((field) => field.fieldTitle));
    }
  };

  const isPlusButtonDisabled = (quantity + 1).toString().length > maxLength;

  const handlePlus = () => {
    if (isPlusButtonDisabled) {
      return;
    }
    setQuantity(quantity + 1);
  };

  useEffect(() => {
    if (validationResult !== undefined && validationResult?.find((result) => !result.isValid)) {
      setIsValidationError(true);
    }
  }, [validationResult]);

  useEffect(() => {
    validateForm();
  }, [measurementUnit, quantity, link, title, supplier, description, validateForm]);

  useEffect(() => {
    setMeasurementUnitList(measurementUnitList);
  }, [formState, measurementUnitList]);

  useEffect(() => {
    if (configuration) {
      setMeasurementUnitList(
        configuration.unitOfMeasures.map(
          (unit): OptionType => ({
            label: unit.name,
            value: unit.code,
          })
        )
      );
    }
  }, [configuration]);

  if (formState === "INITIAL") {
    return (
      <>
        {!basket && <DividerTexts />}
        <div className="my-4 py-2 px-4 bg-neutral_200 rounded-md flex justify-between w-full items-center">
          <span className="text-text-whiteDisabled">Untitled</span>
          <RegularButton
            variant="secondary"
            size="large"
            label="Request a Product"
            LeadingIcon={PlusIcon}
            onClick={() => setFormState("OPENED")}
            data-testid="requestProductCTA"
          />
        </div>
      </>
    );
  }

  if (formState === "OPENED") {
    return (
      <>
        {!basket && <DividerTexts />}
        <div className="my-4 py-2 px-4 rounded-md flex justify-between w-full items-center bg-neutral_0 text-left">
          <div className="flex flex-col w-full" data-testid="search-form">
            <div className="my-2 flex justify-between w-full border-b pb-3 align-end">
              <div className="flex-1 items-end justify-center px-4 pl-0 lg:justify-end flex flex-grow w-full ">
                <div className="flex flex-grow flex-col">
                  <label htmlFor="productTitle" className="sr-only">
                    Product Title*
                  </label>
                  <div className="relative flex flex-grow">
                    <input
                      id="productTitle"
                      name="productTitle"
                      className="py-2 leading-5 focus:outline-none sm:text-sm w-full flex-grow bg-neutral_0 text-2xl"
                      placeholder="Untitled*"
                      type="productTitle"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      data-testid="productTitle-input"
                      onBlur={() => {
                        setBlurredFields([...blurredFields, "title"]);
                        validateForm();
                      }}
                    />
                  </div>
                  {canRenderValidationErrorMessage("title")
                    ? checkValidation("title")?.errors?.map((err) => (
                        <span className="text-dangerDefault text-sm" key={err}>
                          {err}
                        </span>
                      ))
                    : ""}
                </div>
              </div>
              <div className="flex pb-1 gap-2">
                <RegularButton
                  variant="secondary"
                  size="large"
                  label="Cancel"
                  onClick={() => {
                    rfqToEdit && closeEdit && closeEdit();
                    resetState();
                  }}
                />
                <RegularButton
                  variant="primary"
                  size="large"
                  label={rfqToEdit ? "Save" : "Add to Basket"}
                  onClick={onSubmitClick}
                  disabled={!isValid}
                  data-testid="requestProductInlineCTA"
                />
              </div>
            </div>
            <div className="flex w-full pt-4 pb-2">
              <div className="w-1/3 pr-5">
                <Label
                  size="200"
                  color={
                    canRenderValidationErrorMessage("quantity")
                      ? `text-dangerDefault`
                      : `text-textIcon-blackSecondary`
                  }
                  className="leading-none"
                >
                  Quantity*
                </Label>
                <div className="flex w-16">
                  <button
                    onClick={() => setQuantity(quantity - 1)}
                    className="rounded-full bg-neutral_200 focus:outline-none w-6.5 flex justify-around items-center"
                    aria-label="Decrease"
                    disabled={quantity <= 1}
                  >
                    <MinusIcon className="h-3" />
                  </button>
                  <input
                    value={quantity}
                    onChange={(e) => {
                      const value = +e.target.value;

                      if (Number.isInteger(value)) {
                        setQuantity(value);
                      }
                    }}
                    onFocus={() => {
                      setPreviousQuantity(quantity);
                    }}
                    onBlur={() => {
                      if (quantity < 1) {
                        setQuantity(previousQuantity);
                      }
                    }}
                    className="w-8 h-6.5 mx-2 border rounded text-center"
                    aria-label="Item Quantity"
                    maxLength={maxLength}
                  />
                  <button
                    onClick={handlePlus}
                    className="rounded-full bg-neutral_200 focus:outline-none w-6.5 flex justify-around items-center"
                    aria-label="Increase"
                    disabled={isPlusButtonDisabled}
                  >
                    <PlusIcon className="h-3" />
                  </button>
                </div>
              </div>
              <div className="w-1/2 pr-5">
                <Label
                  size="200"
                  color={
                    canRenderValidationErrorMessage("measurementUnit")
                      ? `text-dangerDefault`
                      : `text-textIcon-blackSecondary`
                  }
                >
                  Unit*
                </Label>
                <Select
                  value={measurementUnit}
                  options={measurementUnitList}
                  onChange={(value) => {
                    setMeasurementUnit(value);
                  }}
                  placeholder="Select Unit"
                  testId="unitSelector"
                />
                {canRenderValidationErrorMessage("measurementUnit")
                  ? checkValidation("measurementUnit")?.errors?.map((err) => (
                      <span className="text-dangerDefault text-sm" key={err}>
                        {err}
                      </span>
                    ))
                  : ""}
              </div>
            </div>
            <div className="w-full mt-4">
              <Label
                size="300"
                color={
                  canRenderValidationErrorMessage("description")
                    ? `text-dangerDefault`
                    : `text-textIcon-blackSecondary`
                }
              >
                Description*
              </Label>
              <Textarea
                placeholder="Add a description..."
                value={description}
                parentClassName="pb-1 pt-2"
                onChange={(e) => setDescription(e.target.value)}
                onBlur={() => {
                  validateForm();
                  setBlurredFields([...blurredFields, "description"]);
                }}
                data-testid="productDescription"
              />
              {canRenderValidationErrorMessage("description") ? (
                checkValidation("description")?.errors?.map((err) => (
                  <span className="text-dangerDefault text-sm" key={err}>
                    {err}
                  </span>
                ))
              ) : (
                <span className="text-textIcon-blackSecondary text-sm">
                  This will help us source this product.{" "}
                </span>
              )}
            </div>
            <div className="w-full">
              <Input
                label="Link to Product"
                leftBadge="http://"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                onBlur={() => {
                  validateForm();
                  setBlurredFields([...blurredFields, "link"]);
                }}
              />
              {canRenderValidationErrorMessage("link") &&
                checkValidation("link")?.errors?.map((err) => (
                  <span className="text-dangerDefault text-sm" key={err}>
                    {err}
                  </span>
                ))}
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <DividerTexts />
      <div className="my-4 bg-neutral_200 rounded-md flex flex-col justify-between w-full items-center p-5">
        <div className="w-full">
          <span>Product added to your Basket</span>
          <Paragraph size="300" color="text-textIcon-blackSecondary" className="mt-2">
            items requested
          </Paragraph>
        </div>
        <div className="w-full text-center pt-4 border-t mt-10">
          <RegularButton
            className="mr-3"
            variant="primary"
            size="large"
            label="Continue shopping"
            onClick={() => navigate(RoutesConfig.gatherSetup)}
          />
          <RegularButton
            variant="secondary"
            size="large"
            label="Go to Basket"
            onClick={() => navigate(RoutesConfig.basket)}
          />
        </div>
      </div>
    </>
  );
};

export default RequestProductInline;
