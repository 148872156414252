import { PencilAltIcon, ShoppingCartIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { TabItem, Tabs, containerPadding } from "@web/ui";

import { RoutesConfig } from "src/config/routes";

type Props = {
  initialTabId: "ORDERS" | "STOCKTAKING";
};

export const OverviewTabs = ({ initialTabId }: Props) => {
  const navigate = useNavigate();

  const tabItems: TabItem[] = useMemo(
    () => [
      {
        id: "ORDERS",
        label: "Orders",
        LeadingIcon: ShoppingCartIcon,
        "data-testid": "ordersTabButton",
      },
      {
        id: "STOCKTAKING",
        label: "Stocktake",
        LeadingIcon: PencilAltIcon,
        "data-testid": "stocktakingTabButton",
      },
    ],
    []
  );

  const handleTabChange = useCallback(
    (tabId?: string) => {
      if (tabId === "ORDERS") {
        navigate(RoutesConfig.order.overview);
        return;
      }
      if (tabId === "STOCKTAKING") {
        navigate(RoutesConfig.stocktake.overview);
        return;
      }
    },
    [navigate]
  );

  return (
    <div className={classnames(containerPadding, "mt-6 mb-4")}>
      <Tabs
        size="large"
        variant="line"
        items={tabItems}
        initialTabId={initialTabId}
        data-testid="overviewTabs"
        onTabChange={handleTabChange}
      />
    </div>
  );
};
