import { memo } from "react";

import { Badge } from "@web/ui";

import {
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemQuantityFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";

import { useItemFormDetails } from "./useItemFormDetails";

type Props = {
  initialQuantity: number | null | undefined;
  initialSingleUnitGrossPriceAmount: number | null | undefined;
  initialMeasurementUnit: string;
  salesEntityQuantity: number;
  currencyCode: string;
  entityQuantityOnStock: number;
  quantityFieldPath: StocktakeReportItemQuantityFieldPath;
  singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath;
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
};

export const EntityQuantityWarning = memo(
  ({
    initialQuantity,
    initialSingleUnitGrossPriceAmount,
    initialMeasurementUnit,
    salesEntityQuantity,
    currencyCode,
    entityQuantityOnStock,
    quantityFieldPath,
    singleUnitGrossPriceFieldPath,
    measurementUnitFieldPath,
  }: Props) => {
    const { entityQuantity } = useItemFormDetails({
      initialQuantity,
      initialSingleUnitGrossPriceAmount,
      initialMeasurementUnit,
      salesEntityQuantity,
      currencyCode,
      quantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    });

    return entityQuantity > entityQuantityOnStock ? (
      <Badge size="s" color="warning" contrast="low" text="Above PO stock count" />
    ) : null;
  }
);
EntityQuantityWarning.displayName = "EntityQuantityWarning";
