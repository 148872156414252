export const DEBOUNCE_INTERVAL = 500;

export const BASKET_STORAGE_KEY = "s2s-basket";
export const BASKET_FORM_ANSWERS_STORAGE_KEY = "basketFormAnswers";
export const VESSEL_TOKEN_STORAGE_KEY = "s2s-vesselToken";
export const NETWORK_TOGGLE_MODE_STORAGE_KEY = "s2s-networkToggleMode";
export const OFFLINE_CAPABILITIES_STORAGE_KEY = "offlineCapabilities";
export const MANUAL_NETWORK_TOGGLE_STORAGE_KEY = "manualNetworkToggle";
export const VESSEL_STORAGE_KEY = "source2sea";
export const PUNCHOUT_SESSION_ID_STORAGE_KEY = "punchoutSessionId";
export const SUPPLIER_ID_STORAGE_KEY = "supplierId";

export const serviceWorkerMessages = {
  WAS_SERVICE_WORKER_ACTIVATED: "WAS_SERVICE_WORKER_ACTIVATED",
  IS_CATALOG_DATA_OLD: "IS_CATALOG_DATA_OLD",
  WAS_CATALOG_DATA_CACHED: "WAS_CATALOG_DATA_CACHED",
};

export const CACHED_CATALOG_STALE_TIME = 300_000; // 5 minutes
export const CACHED_CATALOG_OLD_TIME = 432_000_000; // 5 days
export const OFFLINE_HTTP_HEADERS = {
  OFFLINE_CAPABILITIES: "X-Offline-Capabilities-Enabled",
  NETWORK_STATE: "X-Offline-Network-State",
  VESSEL_ID: "X-Vessel-Id",
  SKIP_SW_HANDLER: "X-Skip-Sw-Handler",
};

export const PING_INTERVAL = 5000;
export const PING_URL_RESOURCE = "/NetworkDetectorPingResource";

export const IDB_COMPOSITE_KEYS_SEPARATOR = "__";
