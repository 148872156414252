import { z } from "zod";

import { optionalNonEmptyString } from "./common";

export const LiteSkuNutritionSchema = z.object({
  perServing: optionalNonEmptyString(),
  servingSize: optionalNonEmptyString(),
  calories: optionalNonEmptyString(),
  protein: optionalNonEmptyString(),
  fat: optionalNonEmptyString(),
  saturatedFat: optionalNonEmptyString(),
  transFat: optionalNonEmptyString(),
  carbohydrates: optionalNonEmptyString(),
  sugar: optionalNonEmptyString(),
  cholesterol: optionalNonEmptyString(),
  vitamin: optionalNonEmptyString(),
  calcium: optionalNonEmptyString(),
  iron: optionalNonEmptyString(),
  potassium: optionalNonEmptyString(),
  sodium: optionalNonEmptyString(),
  salt: optionalNonEmptyString(),
  fiber: optionalNonEmptyString(),
});
