import { Dispatch, createContext, useContext } from "react";

import { OfflineCapabilitiesState, OfflineCapabilitiesStateAction } from "./models";
import { defaultOfflineCapabilitiesState } from "./reducer";

export const OfflineCapabilitiesContext = createContext<
  [state: OfflineCapabilitiesState, action: Dispatch<OfflineCapabilitiesStateAction>]
>([
  defaultOfflineCapabilitiesState,
  () => {
    return;
  },
]);

export const useOfflineCapabilitiesContext = () => useContext(OfflineCapabilitiesContext);
