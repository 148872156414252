import { memo, useCallback } from "react";

import {
  LocalStocktakeReportItemForm,
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemQuantityFieldPath,
  StocktakeReportItemRobValueAmountFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";

import { StocktakeCatalogProductTileDetails } from "../StocktakeCatalogProductTileDetails";
import {
  EntityQuantityWarning,
  FormErrors,
  QuantityField,
  RobDetails,
  RobValueDetails,
  StocktakeFormProductTile,
} from "../StocktakeFormProductTile";
import { useUpdateDependentFormFields } from "../StocktakeFormProductTile/useUpdateDependentFormFields";

type Props = {
  product: LocalStocktakeReportItemForm;
  lineNumber: number;
  hasLineNumberPlaceholder?: boolean;
  indexInCollection: number;
};

export const StocktakeFormCatalogProductTile = memo(
  ({ product, lineNumber, indexInCollection }: Props) => {
    const quantityFieldPath: StocktakeReportItemQuantityFieldPath = `items.${indexInCollection}.quantity`;
    const singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath = `items.${indexInCollection}.singleUnitGrossPrice.amount`;
    const measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath = `items.${indexInCollection}.skuDetails.measurementUnit`;
    const robValueAmountFieldPath: StocktakeReportItemRobValueAmountFieldPath = `items.${indexInCollection}.robValue.amount`;
    const itemsFieldPath = "items";

    const { updateProductRobValue } = useUpdateDependentFormFields({
      initialQuantity: product.quantity,
      initialSingleUnitGrossPriceAmount: product.singleUnitGrossPrice.amount,
      initialMeasurementUnit: product.skuDetails.measurementUnit,
      salesEntityQuantity: product.skuDetails.salesEntityQuantity,
      currencyCode: product.robValue.currencyCode,
      quantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
      robValueAmountFieldPath,
    });

    const renderProductDetails = useCallback(
      (product: LocalStocktakeReportItemForm, className: string) => (
        <StocktakeCatalogProductTileDetails product={product} className={className} />
      ),
      []
    );

    const renderQuantityField = useCallback(
      () => (
        <QuantityField
          onValueChanged={updateProductRobValue}
          initialQuantity={product.quantity}
          initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
          initialMeasurementUnit={product.skuDetails.measurementUnit}
          salesEntityQuantity={product.skuDetails.salesEntityQuantity}
          currencyCode={product.robValue.currencyCode}
          quantityFieldPath={quantityFieldPath}
          singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
          measurementUnitFieldPath={measurementUnitFieldPath}
        />
      ),
      [
        measurementUnitFieldPath,
        product.quantity,
        product.robValue.currencyCode,
        product.singleUnitGrossPrice.amount,
        product.skuDetails.measurementUnit,
        product.skuDetails.salesEntityQuantity,
        quantityFieldPath,
        singleUnitGrossPriceFieldPath,
        updateProductRobValue,
      ]
    );

    const renderEntityQuantityWarning = useCallback(
      () => (
        <EntityQuantityWarning
          initialQuantity={product.quantity}
          initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
          initialMeasurementUnit={product.skuDetails.measurementUnit}
          salesEntityQuantity={product.skuDetails.salesEntityQuantity}
          currencyCode={product.robValue.currencyCode}
          entityQuantityOnStock={product.entityQuantityOnStock}
          quantityFieldPath={quantityFieldPath}
          singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
          measurementUnitFieldPath={measurementUnitFieldPath}
        />
      ),
      [
        measurementUnitFieldPath,
        product.entityQuantityOnStock,
        product.quantity,
        product.robValue.currencyCode,
        product.singleUnitGrossPrice.amount,
        product.skuDetails.measurementUnit,
        product.skuDetails.salesEntityQuantity,
        quantityFieldPath,
        singleUnitGrossPriceFieldPath,
      ]
    );

    const renderRobDetails = useCallback(
      () => (
        <RobDetails
          initialQuantity={product.quantity}
          initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
          initialMeasurementUnit={product.skuDetails.measurementUnit}
          salesEntityQuantity={product.skuDetails.salesEntityQuantity}
          currencyCode={product.robValue.currencyCode}
          quantityFieldPath={quantityFieldPath}
          singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
          measurementUnitFieldPath={measurementUnitFieldPath}
        />
      ),
      [
        measurementUnitFieldPath,
        product.quantity,
        product.robValue.currencyCode,
        product.singleUnitGrossPrice.amount,
        product.skuDetails.measurementUnit,
        product.skuDetails.salesEntityQuantity,
        quantityFieldPath,
        singleUnitGrossPriceFieldPath,
      ]
    );

    const renderRobValueDetails = useCallback(
      () => (
        <RobValueDetails
          initialQuantity={product.quantity}
          initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
          initialMeasurementUnit={product.skuDetails.measurementUnit}
          salesEntityQuantity={product.skuDetails.salesEntityQuantity}
          currencyCode={product.robValue.currencyCode}
          quantityFieldPath={quantityFieldPath}
          singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
          measurementUnitFieldPath={measurementUnitFieldPath}
        />
      ),
      [
        measurementUnitFieldPath,
        product.quantity,
        product.robValue.currencyCode,
        product.singleUnitGrossPrice.amount,
        product.skuDetails.measurementUnit,
        product.skuDetails.salesEntityQuantity,
        quantityFieldPath,
        singleUnitGrossPriceFieldPath,
      ]
    );

    const renderFormErrors = useCallback(
      () => <FormErrors indexInCollection={indexInCollection} itemsFieldPath={itemsFieldPath} />,
      [indexInCollection]
    );

    return (
      <StocktakeFormProductTile
        product={product}
        lineNumber={lineNumber}
        renderProductDetails={renderProductDetails}
        renderQuantityField={renderQuantityField}
        salesEntityQuantity={product.skuDetails.salesEntityQuantity}
        measurementUnit={product.skuDetails.measurementUnit}
        renderEntityQuantityWarning={renderEntityQuantityWarning}
        renderRobDetails={renderRobDetails}
        renderRobValueDetails={renderRobValueDetails}
        renderFormErrors={renderFormErrors}
      />
    );
  }
);
StocktakeFormCatalogProductTile.displayName = "StocktakeFormCatalogProductTile";
