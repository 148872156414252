import { OfflineCapabilitiesContext } from "./OfflineCapabilitiesContext";
import { useOfflineCapabilitiesReducer } from "./reducer";

interface Props {
  children: React.ReactNode;
}

export const OfflineCapabilitiesProvider = ({ children }: Props) => {
  const [offlineCapabilitiesState, dispatch] = useOfflineCapabilitiesReducer();

  return (
    <OfflineCapabilitiesContext.Provider value={[offlineCapabilitiesState, dispatch]}>
      {children}
    </OfflineCapabilitiesContext.Provider>
  );
};
