import { DefaultError, useMutation } from "@tanstack/react-query";

import { useToastMessage } from "src/hooks/useToastMessage";
import { LocalPartialStocktakeReportDraft, LocalStocktakeReportDraft } from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";
import { StocktakeDraftsService } from "src/services/StocktakeDraftsService";

import { useStocktakeDraftsListQueryHelpers } from "./useStocktakeDraftsListQuery";
import { useStocktakesListQueryHelpers } from "./useStocktakesListQuery";

type UseCreateStocktakeDraftMutationArgs = {
  onSuccess?: (createdDraft: LocalStocktakeReportDraft) => void;
  onError?: (draftToCreate: LocalPartialStocktakeReportDraft, error: Error) => void;
  hasErrorMessage?: boolean;
  vesselId: string;
};

export const useCreateStocktakeDraftMutation = ({
  onSuccess,
  onError,
  hasErrorMessage = false,
  vesselId,
}: UseCreateStocktakeDraftMutationArgs) => {
  const { setToastMessage } = useToastMessage();
  const { addListItemToQuery } = useStocktakesListQueryHelpers({ vesselId });
  const { addListItemToQuery: addListItemToDraftsQuery } = useStocktakeDraftsListQueryHelpers({
    vesselId,
  });

  return useMutation<LocalStocktakeReportDraft, DefaultError, LocalPartialStocktakeReportDraft>({
    mutationKey: ["createStocktakeDraft"],
    mutationFn: StocktakeDraftsService.createDraft,
    networkMode: "always",
    onSuccess: (createdDraft) => {
      if (onSuccess) {
        onSuccess(createdDraft);
      }

      const stocktakeReportListItem =
        LocalStocktakeService.convertFromStoredDraftToUiStocktakeListItem(createdDraft);

      // Add item to queries cache
      addListItemToQuery(stocktakeReportListItem);
      addListItemToDraftsQuery(createdDraft);
    },
    onError: (error, draftToCreate) => {
      console.error(error, draftToCreate);

      hasErrorMessage &&
        setToastMessage({
          type: "failure",
          message: "There was an error while creating your draft. Please try again.",
        });

      if (onError) {
        onError(draftToCreate, error);
      }
    },
  });
};
