import classnames from "classnames";
import { useNavigate, useParams } from "react-router-dom";

import { Heading, Loading, Paragraph, RegularButton, Strong, containerPadding } from "@web/ui";
import { formatMoney } from "@web/utils";

import { NoCachedData } from "src/components/NoCachedData";
import { TopBarController } from "src/components/TopBar";
import { RoutesConfig, RoutesParams } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useStocktakeReportQuery, useStocktakeReportQueryHelpers } from "src/hooks/stocktakes";
import { useNoCachedQueryGuard } from "src/hooks/useNoCachedQueryGuard";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

export const StocktakeFinishSuccessPage = () => {
  const { [RoutesParams.STOCKTAKE_ID]: stocktakeId } = useParams() as {
    stocktakeId: string;
  };
  const navigate = useNavigate();
  const [{ configuration }] = useAppStateContext();
  const availableStocktakeTypes = configuration?.availableStocktakeTypes;
  const vesselEmail = configuration?.vessel.contactInformation?.email;

  const {
    isPending,
    isFetching,
    isError,
    isSuccess,
    data: stocktakeReport,
  } = useStocktakeReportQuery({
    stocktakeId,
  });
  const { invalidate } = useStocktakeReportQueryHelpers();

  const { hasNoCachedData } = useNoCachedQueryGuard({
    isQueryPending: isPending,
    isQueryError: isError,
    becameOnlineCallback: () => invalidate(stocktakeId),
  });

  if (hasNoCachedData) {
    return <NoCachedData />;
  }

  if (isPending || isFetching || !isSuccess) {
    return <Loading />;
  }

  const canRenderStocktakeType = LocalStocktakeService.shouldRenderStocktakeType(
    availableStocktakeTypes,
    stocktakeReport.type
  );
  const stocktakeTypeLabel = LocalStocktakeService.getStocktakeTypeLabel(stocktakeReport.type);

  return (
    <div className="bg-neutral_100 flex flex-col min-h-full">
      <TopBarController />
      <div className={classnames(containerPadding, "my-7")}>
        <div className="max-w-xl ml-auto mr-auto flex flex-col items-center">
          <Heading size="200" className="text-center">
            Stocktake has been submitted!
          </Heading>
          <Paragraph size="200" className="text-center mt-4">
            A confirmation email has been sent to{" "}
            {!!vesselEmail && (
              <>
                <Strong>{vesselEmail}</Strong> and{" "}
              </>
            )}
            <Strong>the purchasing office</Strong>. Go to the stocktake overview page to access your
            stocktake reports.
          </Paragraph>

          <div className="grid grid-cols-2 gap-x-6 gap-y-3 mt-6.5">
            {canRenderStocktakeType && (
              <>
                <Paragraph size="100" weight="heavy">
                  Type
                </Paragraph>
                <Paragraph size="100" className="text-right">
                  {stocktakeTypeLabel}
                </Paragraph>
              </>
            )}
            <Paragraph size="100" weight="heavy">
              Date
            </Paragraph>
            <Paragraph size="100" className="text-right">
              {LocalStocktakeService.formatStocktakeDateFromIsoString(
                stocktakeReport.stocktakeDate,
                "justDate"
              )}
            </Paragraph>
            <Paragraph size="100" weight="heavy">
              Reported Inventory Value ROB
            </Paragraph>
            <Paragraph size="100" className="text-right">
              {formatMoney(stocktakeReport.robValue)}
            </Paragraph>
            <Paragraph size="100" weight="heavy">
              Subject
            </Paragraph>
            <Paragraph size="100" className="text-right">
              {stocktakeReport.subject ? stocktakeReport.subject : "-"}
            </Paragraph>
          </div>

          <RegularButton
            className="mt-7"
            size="large"
            variant="primary"
            label="Go to Stocktake Overview"
            onClick={() => {
              navigate(RoutesConfig.stocktake.overview);
            }}
          />
        </div>
      </div>
    </div>
  );
};
