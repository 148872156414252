import { DefaultError, useMutation } from "@tanstack/react-query";

import { useToastMessage } from "src/hooks/useToastMessage";
import { StocktakeDraftsService } from "src/services/StocktakeDraftsService";

import { useStocktakeDraftQueryHelpers } from "./useStocktakeDraftQuery";
import { useStocktakeDraftsListQueryHelpers } from "./useStocktakeDraftsListQuery";
import { useStocktakesListQueryHelpers } from "./useStocktakesListQuery";

type UseDeleteStocktakeDraftMutationArgs = {
  onSuccess?: (deletedDraftId: string) => void;
  onError?: (draftToDeleteId: string, error: Error) => void;
  hasSuccessMessage?: boolean;
  hasErrorMessage?: boolean;
  vesselId: string;
  draftId: string;
};

export const useDeleteStocktakeDraftMutation = ({
  onSuccess,
  onError,
  hasSuccessMessage = false,
  hasErrorMessage = false,
  vesselId,
  draftId,
}: UseDeleteStocktakeDraftMutationArgs) => {
  const { setToastMessage } = useToastMessage();
  const { removeItemFromQuery } = useStocktakesListQueryHelpers({ vesselId });
  const { removeItemFromQuery: removeItemFromDraftsQuery } = useStocktakeDraftsListQueryHelpers({
    vesselId,
  });
  const { invalidate: invalidateDraftQuery } = useStocktakeDraftQueryHelpers({ draftId });

  return useMutation<unknown, DefaultError>({
    mutationKey: ["deleteStocktakeDraft"],
    mutationFn: () => StocktakeDraftsService.deleteDraft(draftId),
    networkMode: "always",
    onSuccess: () => {
      hasSuccessMessage &&
        setToastMessage({
          type: "success",
          message: "Your draft was successfully deleted",
        });

      if (onSuccess) {
        onSuccess(draftId);
      }

      // Remove the item from query caches
      removeItemFromQuery(draftId);
      removeItemFromDraftsQuery(draftId);

      // Invalidate the draft query to refetch and get `not found` when trying to fetch the deleted draft
      invalidateDraftQuery();
    },
    onError: (error) => {
      console.error(error, draftId);

      hasErrorMessage &&
        setToastMessage({
          type: "failure",
          message: "There was an error while deleting your draft",
        });

      if (onError) {
        onError(draftId, error);
      }
    },
  });
};
