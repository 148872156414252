import { DefaultError, useMutation } from "@tanstack/react-query";

import { OrderRequisition } from "@web/common";

import { cancelOrder } from "../api/cancelOrder";
import { LiteOrderRequisition } from "../typegens";

export const useCancelOrderMutation = (options = {}) => {
  return useMutation<LiteOrderRequisition, DefaultError, OrderRequisition>({
    mutationKey: ["cancelOrder"],
    mutationFn: (order) => {
      if (!order.canCancel) {
        return Promise.reject("Cannot cancel this order");
      }
      return cancelOrder(order.id);
    },
    ...options,
  });
};
