import { LiteOfflineCategory } from "src/typegens";

export const flattenCategoryTree = (
  categoryTree: LiteOfflineCategory[],
  result: LiteOfflineCategory[] = []
) => {
  for (const category of categoryTree) {
    result.push(category);

    if (category.children) {
      flattenCategoryTree(category.children, result);
    }
  }

  return result;
};
