import { TrashIcon } from "@heroicons/react/outline";
import { emulateTab } from "emulate-tab";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconButton, Label, RegularButton } from "@web/ui";
import { formatMoney, imagor } from "@web/utils";

import ProductImageSVG from "../icons/ProductImage.svg";
import RestoreIcon from "../icons/Restore.svg?react";
import { BasketEntry, ProductSku } from "../models";
import {
  getLineItemEntityQuantity,
  getLineItemTotal,
  getMinimumOrderQuantity,
  getMinimumQuantityNumber,
  getSalesEntityQuantity,
} from "../utils";
import WarningLabel from "./WarningLabel";

interface Props {
  product: BasketEntry;
  index: number;
  quantity: number;
  addToBasket: (sku: ProductSku, quantity: number) => void;
}

export const SidebarProductBox = ({ product, quantity, addToBasket }: Props) => {
  const { t } = useTranslation();
  const initialQuantity = quantity;
  const secondsForRestore = 4;
  const [inputValue, setInputValue] = useState(initialQuantity);
  const [showRestoreButton, setRestoreButton] = useState(false);
  const [selectedInput, setSelectedInput] = useState(0);
  const [counter, setCounter] = useState(secondsForRestore);

  const productSku = product.sku;

  const minimumOrderQuantity = getMinimumOrderQuantity(productSku);
  const salesEntityQuantity = getSalesEntityQuantity(productSku);
  const minimumQuantityNumber = getMinimumQuantityNumber(productSku);
  const lineItemTotal = getLineItemTotal(product);
  const entityQuantity = getLineItemEntityQuantity(product);

  const lineItemTotalFormatted = formatMoney(lineItemTotal);
  const imageUrl = productSku.images[0]?.imageUrl;

  const timerRef = useRef(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const handleUpdateBasket = () => {
    if (productSku) {
      addToBasket(productSku, Number(inputValue));
    }
  };

  useEffect(() => {
    handleUpdateBasket();
    // DO NOT change the dependency array below, unless you know what you are doing.
    // Make sure to test any change to ensure that it won't cause inifite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const filterInt = (value: string) => {
    if (/^[-+]?\d+$/.test(value)) {
      return Number(value);
    } else {
      return NaN;
    }
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (!isNaN(filterInt(value)) || value === "") {
      if (Number(value) === 0) {
        handleRemoveProduct(timerRef);
      } else {
        setInputValue(Number(value));
      }
    }
  };

  const handleBlur = () => {
    if (
      minimumOrderQuantity !== undefined &&
      minimumOrderQuantity > 1 &&
      inputValue < minimumQuantityNumber
    ) {
      if (quantity === 0) {
        setInputValue(0);
      } else {
        setInputValue(minimumQuantityNumber);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (e.key === "Enter") {
      if (!isNaN(filterInt(value)) || value === "") {
        setInputValue(Number(value));
      }
      setSelectedInput(selectedInput + 1);

      emulateTab();
    }
    if (e.key === "ArrowUp") {
      emulateTab.backwards();
    }
    if (e.key === "ArrowDown") {
      emulateTab();
    }
    if (e.key === "ArrowRight") {
      handleUpdateBasket();
      if (
        minimumOrderQuantity !== undefined &&
        minimumOrderQuantity > 1 &&
        quantity < minimumOrderQuantity
      ) {
        setInputValue(quantity + minimumQuantityNumber);
      } else if (quantity === 9999) {
        null;
      } else {
        setInputValue(quantity + 1);
      }
    }
    if (e.key === "ArrowLeft" && quantity > 0) {
      handleUpdateBasket();
      if (
        minimumOrderQuantity !== undefined &&
        minimumOrderQuantity > 1 &&
        quantity < minimumQuantityNumber + 1
      ) {
        handleRemoveProduct(timerRef);
      } else {
        if (quantity === 1) {
          handleRemoveProduct(timerRef);
        } else {
          setInputValue(quantity - 1);
        }
      }
    }
  };

  const handleRemoveProduct = (ref: MutableRefObject<number>) => {
    setRestoreButton(true);
    startCountdown();
    ref.current = window.setTimeout(() => {
      setInputValue(0);
    }, 4000);
  };

  const handleRestoreProduct = (ref: MutableRefObject<number>) => {
    setRestoreButton(false);
    clearTimeout(ref.current);
    setCounter(secondsForRestore);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const startCountdown = () => {
    if (counter <= 0) {
      setCounter(secondsForRestore);
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => setCounter((counter) => counter - 1), 1000);
  };

  return (
    <div data-testid="sidebar-box" className="flex h-10 xl:h-9 overflow-hidden">
      {showRestoreButton ? (
        <div className="bg-neutral_0 px-2 shadow-sm flex flex-grow border-b-1 justify-center items-center">
          <div className="flex flex-col w-6 h-6">
            <img
              src={(imageUrl && imagor(imageUrl)) || ProductImageSVG}
              className="whitespace-nowrap min-h-full"
            />
          </div>
          <div className="flex flex-col max-w-[150px]">
            <div className="pb-2 pl-2 break-normal">
              <Label size="300" color="text-textIcon-blackSecondary" className="flex">
                {productSku.about?.name} {salesEntityQuantity} {productSku.measurementUnit}
              </Label>
            </div>
          </div>
          <div className="flex ml-auto justify-center">
            <RegularButton
              size="small"
              variant="secondary"
              label={`Restore (${counter} sec)`}
              LeadingIcon={RestoreIcon}
              onClick={() => handleRestoreProduct(timerRef)}
              data-testid="sidebar-box-restore-button"
            />
          </div>
        </div>
      ) : (
        <div className="bg-neutral_0 px-2 py-2 shadow-sm flex flex-grow border-b-1 items-center">
          <div className="flex flex-col justify-center w-6 h-6">
            <div data-testid="product-card" className="flex" tabIndex={-1}>
              <img
                src={(imageUrl && imagor(imageUrl)) || ProductImageSVG}
                className="whitespace-nowrap min-h-full"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="pl-2 break-normal max-w-[150px]">
              <div data-testid="product-card" className="flex" tabIndex={-1}>
                <Label size="300">
                  {productSku.about?.name} {salesEntityQuantity} {productSku.measurementUnit}
                </Label>
              </div>
            </div>
          </div>
          <div className="flex ml-auto justify-center items-center">
            <div className="flex flex-col ml-auto mr-1">
              <div className="flex flex-col pl-1">
                <div className="flex flex-col items-end text-right">
                  <div className="flex items-baseline">
                    <Label size="300">{lineItemTotalFormatted}</Label>
                  </div>
                  {minimumOrderQuantity !== undefined &&
                  minimumOrderQuantity > 1 &&
                  inputValue < minimumQuantityNumber ? (
                    <WarningLabel
                      warningMessage={t("common.views.basket.minimum")}
                      minimumOrderQuantity={minimumOrderQuantity}
                      minimumQuantityNumber={minimumQuantityNumber}
                      measurementUnit={productSku.measurementUnit}
                    />
                  ) : (
                    <Label size="300" color="text-textIcon-blackSecondary">
                      {entityQuantity} {productSku.measurementUnit}
                    </Label>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-auto">
              <input
                value={quantity}
                autoFocus={false}
                maxLength={4}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                className="flex-none w-6 xl:w-7 h-7 mr-1 xl:mx-1 border rounded-lg text-center"
                aria-label="Item Quantity"
                data-testid="sidebar-basket-input"
              />
            </div>
            <div className="flex flex-col xl:ml-1">
              <IconButton
                size="small"
                variant="secondary"
                shape="circle"
                label="Remove item"
                Icon={TrashIcon}
                onClick={() => handleRemoveProduct(timerRef)}
                data-testid="sidebar-box-delete-button"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
