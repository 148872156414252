import { DefaultError, useMutation } from "@tanstack/react-query";

import { LocalOrderDraft, LocalPartialOrderDraft } from "src/models";
import { OrderDraftsService } from "src/services/OrderDraftsService";

import { useToastMessage } from "../useToastMessage";
import { useOrderDraftsQueryHelpers } from "./useOrderDraftsQuery";

type UseCreateOrderDraftMutationArgs = {
  onSuccess?: (createdDraft: LocalOrderDraft) => void;
  onError?: (draftToCreate: LocalPartialOrderDraft, error: Error) => void;
  hasSuccessMessage?: boolean;
  hasErrorMessage?: boolean;
};

export const useCreateOrderDraftMutation = ({
  onSuccess,
  onError,
  hasSuccessMessage = false,
  hasErrorMessage = false,
}: UseCreateOrderDraftMutationArgs = {}) => {
  const { setToastMessage } = useToastMessage();
  const { addDraftToQuery } = useOrderDraftsQueryHelpers();

  return useMutation<LocalOrderDraft, DefaultError, LocalPartialOrderDraft>({
    mutationKey: ["createDraft"],
    mutationFn: OrderDraftsService.createDraft,
    networkMode: "always",
    onSuccess: (createdDraft) => {
      hasSuccessMessage &&
        setToastMessage({
          type: "success",
          message: "Your draft was successfully saved",
        });

      if (onSuccess) {
        onSuccess(createdDraft);
      }

      addDraftToQuery(createdDraft);
    },
    onError: (error, draftToCreate) => {
      hasErrorMessage &&
        setToastMessage({
          type: "failure",
          message: "There was an error while saving your draft",
        });

      if (onError) {
        onError(draftToCreate, error);
      }
    },
  });
};
