import { OrderRequisition } from "@web/common/network/model";

import { CheckItemsAvailabilityModal } from "src/components/Modal";
import { useProductVariantsQuery } from "src/hooks/useProductVariantsQuery";

interface Props {
  draft: OrderRequisition;
}

export const LEGACY_CheckItemsAvailabilityModalController = ({ draft }: Props) => {
  const draftVariantIds = draft.items.map((item) => item.variantId);

  const availableItemsQuery = useProductVariantsQuery({
    variantIds: draftVariantIds,
    portId: draft.port.id,
    orderType: draft.orderType,
  });

  return <CheckItemsAvailabilityModal draft={draft} availableItemsQuery={availableItemsQuery} />;
};
