import { useCallback } from "react";

import { useAppStateContext } from "src/contexts/AppStateContext";

import { CXmlPunchoutService, LiteRequisitionCreationCatalogItem } from "../typegens";
import { usePunchOutIntegration } from "./usePunchOutIntegration";

export const usePunchoutOrder = ({
  catalogItems,
}: {
  catalogItems: LiteRequisitionCreationCatalogItem[];
}) => {
  const [{ port, configuration }] = useAppStateContext();
  const { supplierId, punchoutSessionId } = usePunchOutIntegration();
  const sendOrderMessage = useCallback(async () => {
    const response = await CXmlPunchoutService.sendOrderMessage({
      requestBody: {
        portId: port?.id as string,
        vesselId: configuration?.vessel.id as string,
        supplierId: supplierId as string,
        sessionId: punchoutSessionId as string,
        catalogItems,
      },
    });
    return response;
  }, [catalogItems, configuration?.vessel.id, punchoutSessionId, port?.id, supplierId]);
  return sendOrderMessage;
};
