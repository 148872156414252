import { LockClosedIcon, MailIcon } from "@heroicons/react/solid";
import { useFlag } from "@unleash/proxy-client-react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  InvoiceAccountUI,
  Warehouse,
  getBadgeSettingsFromOrderType,
  shouldRenderOrderType,
} from "@web/common";
import {
  AgentInformation as AgentInformationInfo,
  ContactInformation,
  DeliveryDate,
  SummaryBox,
} from "@web/common/components";
import {
  DeliveryAddress,
  DeliveryMethod,
  OrderComment,
} from "@web/common/pages/OrderInfo/components";
import { StorageLabelInput } from "@web/common/pages/OrderInfo/components/StorageLabelInput";
import { isDateNotInPast } from "@web/common/validators";
import { InvoiceAccount } from "@web/models";
import { Badge, Combobox, FormSection, Heading, Input, Paragraph } from "@web/ui";
import { convertTimeToISODateString, extractFromISODateString, useFuzzySearch } from "@web/utils";

import { useAppStateContext } from "../../contexts/AppStateContext";
import useBasket from "../../hooks/useBasket";
import AppState from "../../state/models";
import {
  CreateLiteAgentInformation,
  LifeRfqItem,
  LiteCreateRequisitionRequest,
  LiteOrderCreationRequest,
  LiteRequesterInformation,
  LiteRequisitionCreationItem,
} from "../../typegens";

interface Props {
  loading: boolean;
  submitForm: (requisitionSummary: LiteCreateRequisitionRequest) => void;
  validationErrorMessage?: string;
  warehouses?: Warehouse[];
  renderAttentionInfo?: () => React.ReactElement | null;
}

type FormInputs = {
  agentInformation?: CreateLiteAgentInformation;
  basketId?: string;
  catalogItems?: Array<LiteRequisitionCreationItem>;
  consolidated: boolean;
  customerOrderId?: string;
  deliveryDate: {
    time: string;
    date: string;
  };
  draftId?: string;
  invoiceAccount?: InvoiceAccount;
  orderNotes?: string;
  portId: string;
  requesterInformation: LiteRequesterInformation;
  rfqItems?: Array<LifeRfqItem>;
  storageLabel?: string;
  supplierId: string;
  vesselId: string;
  warehouseId?: string;
};

type FormValues = {
  agentInformation?: CreateLiteAgentInformation;
  basketId?: string;
  catalogItems?: Array<LiteRequisitionCreationItem>;
  consolidated: boolean;
  customerOrderId?: string;
  deliveryDate: string;
  draftId?: string;
  invoiceAccount?: InvoiceAccount;
  orderNotes?: string;
  portId: string;
  requesterInformation: LiteRequesterInformation;
  rfqItems?: Array<LifeRfqItem>;
  storageLabel?: string;
  supplierId: string;
  vesselId: string;
  warehouseId?: string;
  orderType?: LiteOrderCreationRequest["orderType"];
};

const RequisitionInfo: React.FC<Props> = ({
  submitForm,
  loading,
  validationErrorMessage,
  renderAttentionInfo,
}) => {
  /* Hooks */

  const { t } = useTranslation();
  const { grandTotal, lineItems, rfqItems, getOrderItems } = useBasket();
  const [appState] = useAppStateContext();

  /* Feature flags */

  const hasInvoiceAccountsFeature = useFlag("invoice-accounts");
  const hasConsolidatedOrdersFeature = useFlag("consolidated-orders");

  /* State from App */

  const { deliveryDate, orderType } = appState;

  const hasFMSIntegration = !!appState.configuration?.allow?.fmsIntegration;
  const invoiceAccounts = hasInvoiceAccountsFeature
    ? (appState.configuration?.vessel?.invoiceAccounts as InvoiceAccount[])
    : [];
  const vessel = (appState as Required<AppState>).configuration?.vessel;
  const warehouses = appState.configuration?.warehouses ?? [];
  const isAutoApprovedRequisition = !!appState.configuration?.isAutoApprovedRequisition;
  const orderTypes = appState.configuration?.orderTypes;

  /* Others */

  const { filteredCollection: filteredAccounts, handleSearchPatternChange } = useFuzzySearch({
    collection: invoiceAccounts || [],
    keys: ["registerOwner"],
  });

  const invoiceAccountsMessages = {
    placeholder: t("components.invoiceAccountSelector.placeholder"),
    noValueLabel: t("components.invoiceAccountSelector.noValueLabel"),
    noValueSubLabel: t("components.invoiceAccountSelector.noValueSubLabel"),
  };

  /* Form */
  const defaultValues = {
    requesterInformation: {
      email: appState.configuration?.vessel.contactInformation?.email ?? "",
      name: appState.configuration?.vessel.contactInformation?.name ?? "",
    },
    agentInformation: {
      firstName: appState.port?.agentInformation?.firstName ?? "",
      lastName: appState.port?.agentInformation?.lastName ?? "",
      email: appState.port?.agentInformation?.email ?? "",
      phoneNumber: appState.port?.agentInformation?.phoneNumber ?? "",
      company: appState.port?.agentInformation?.company ?? "",
    },
    customerOrderId: "",
    consolidated: false,
    orderNotes: "",
    storageLabel: "",
    deliveryDate: {
      time: extractFromISODateString(deliveryDate, "justTime"),
      date: extractFromISODateString(deliveryDate, "yearFirst"),
    },
    invoiceAccount: undefined,
    catalogItems: getOrderItems(),
    rfqItems: Object.entries(rfqItems).map(([, value]) => value),
    supplierId: "wss",
    warehouseId: "",
  };

  const form = useForm<FormInputs>({
    defaultValues: defaultValues,
    mode: "onSubmit",
    shouldUnregister: true,
  });

  const { control, formState, handleSubmit, register, setValue, watch } = form;

  const { errors } = formState;

  /* Conditions */

  const shouldDisplayBillingInformation = invoiceAccounts && invoiceAccounts.length > 0;
  const shouldDisplayDeliveryAddress = watch("consolidated");
  const shouldDisplayDeliveryMethod =
    hasConsolidatedOrdersFeature && warehouses && warehouses.length > 0;

  const submit = async () => {
    const { invoiceAccount, ...values } = form.getValues();
    const data: FormValues = {
      ...values,
      consolidated: values.consolidated,
      orderType,
      deliveryDate: convertTimeToISODateString(
        values.deliveryDate?.time,
        values.deliveryDate?.date
      ),
      ...(shouldDisplayBillingInformation ? { invoiceAccountId: invoiceAccount?.id } : {}),
    };

    const filtered = Object.fromEntries(
      Object.entries(data).filter(([, value]) => value != undefined)
    );
    submitForm(filtered as FormValues);
  };

  const Divisor = () => <hr className="my-2" />;

  const requesterName = watch("requesterInformation.name");
  const requesterEmail = watch("requesterInformation.email");

  return (
    <>
      <div className="flex-grow">
        <div className="mb-6">
          <Heading size="300">
            {isAutoApprovedRequisition
              ? t("pages.autoApprovedRequisitionSummary.title")
              : t("pages.requisitionSummary.title")}{" "}
            {orderType && shouldRenderOrderType(orderTypes, orderType) && (
              <Badge {...getBadgeSettingsFromOrderType({ orderType, orderTypes })} size="s" />
            )}
          </Heading>
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            {isAutoApprovedRequisition
              ? t("pages.autoApprovedRequisitionSummary.detail")
              : t("pages.requisitionSummary.detail")}
          </Paragraph>
        </div>
        <form className="flex flex-col flex-grow gap-6" name="contact_info">
          <ContactInformation
            emailField={
              <Controller
                name="requesterInformation.email"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    {...register("requesterInformation.email")}
                    prefixIcon={<MailIcon className="text-textIcon-blackSecondary h-4 w-4" />}
                    type="email"
                    id="Email"
                    label={t("components.contactInfo.emailLabel")}
                    placeholder={t("components.contactInfo.emailPlaceholder")}
                    maxLength={320}
                    value={value}
                    onInputChange={onChange}
                    errorMessage={errors.requesterInformation?.email?.message}
                    testId="requesterEmail"
                  />
                )}
              />
            }
            fullNameField={
              <Controller
                name="requesterInformation.name"
                control={control}
                render={({ field: { value, onChange }, formState: { errors } }) => (
                  <Input
                    {...register("requesterInformation.name")}
                    label={t("components.contactInfo.fullNamePlaceholder")}
                    prefixIcon={<LockClosedIcon className="text-textIcon-blackSecondary h-4 w-4" />}
                    placeholder={t("components.contactInfo.internalRequesterLabel")}
                    maxLength={100}
                    value={value}
                    onInputChange={onChange}
                    errorMessage={errors.requesterInformation?.name?.message}
                    testId="requesterName"
                  />
                )}
              />
            }
          />
          <Divisor />
          <FormSection title={"Delivery date"} subtitle={t("Enter the delivery date")}>
            <DeliveryDate
              initialValue={convertTimeToISODateString(
                defaultValues?.deliveryDate.time,
                defaultValues?.deliveryDate.date
              )}
              errors={errors}
              datePickerAdditionalProps={register("deliveryDate.date", {
                required: {
                  value: true,
                  message: t("components.contactInfo.dateEmpty"),
                },
                validate: {
                  notInPast: (value) =>
                    isDateNotInPast(value, t("components.contactInfo.dateNotInTheFuture")),
                },
              })}
              timePickerAdditionalProps={register("deliveryDate.time", {
                pattern: {
                  value: /^(0?[0-9]|1[0-9]|(2[0-3])):[0-5][0-9]$/gm,
                  message: t("components.contactInfo.timeInvalid"),
                },
                required: {
                  value: true,
                  message: t("components.contactInfo.timeEmpty"),
                },
              })}
              onChange={(time: string, date: string) => {
                setValue("deliveryDate.date", date, {
                  shouldDirty: true,
                  shouldValidate: (() => date != "")(),
                });
                setValue("deliveryDate.time", time, {
                  shouldDirty: true,
                  shouldValidate: (() => date != "")(),
                });
              }}
            />
          </FormSection>
          <Divisor />
          {shouldDisplayDeliveryMethod && (
            <Controller
              name="consolidated"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <DeliveryMethod
                    isConsolidatedOrder={value}
                    onChangeIsConsolidatedOrder={(value) => {
                      onChange(value);
                    }}
                  />
                  <Divisor />
                </>
              )}
            />
          )}
          {shouldDisplayDeliveryAddress && (
            <Controller
              name="warehouseId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("components.contactInfo.deliveryAddress.validation.required"),
                },
              }}
              render={({ field: { onChange, value, ...rest }, formState: { errors } }) => (
                <>
                  <DeliveryAddress
                    preselectedWarehouse={
                      warehouses.length === 1
                        ? warehouses[0]
                        : warehouses.find((warehouse) => value === warehouse.id)
                    }
                    onSelectWarehouse={(warehouse) => onChange(warehouse?.id)}
                    warehouses={warehouses}
                    errors={errors.warehouseId?.message}
                    {...rest}
                  />
                  <Divisor />
                </>
              )}
            />
          )}

          {shouldDisplayBillingInformation && (
            <Controller
              name="invoiceAccount"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormSection
                    subtitle={t("components.contactInfo.billingInformation.subtitle")}
                    title={t("components.contactInfo.billingInformation.title")}
                  >
                    <Combobox
                      filteredOptions={filteredAccounts}
                      messages={{
                        ...invoiceAccountsMessages,
                        validationError: errors.invoiceAccount?.message,
                      }}
                      onSearchPatternChange={handleSearchPatternChange}
                      onSelectOption={onChange}
                      preselectedOption={invoiceAccounts.find(
                        (invoice) => invoice.id === value?.id
                      )}
                      optionComponent={InvoiceAccountUI}
                      options={invoiceAccounts}
                    />
                  </FormSection>
                  <Divisor />
                </>
              )}
            />
          )}
          <Controller
            name="agentInformation"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <AgentInformationInfo
                  defaultAgentInformation={value}
                  errors={errors}
                  onChange={onChange}
                  register={register}
                  required={{
                    firstName: false,
                    lastName: false,
                    company: !!isAutoApprovedRequisition,
                    email: !!isAutoApprovedRequisition,
                    phoneNumber: !!isAutoApprovedRequisition,
                  }}
                />
                <Divisor />
              </>
            )}
          />
          <Controller
            name="storageLabel"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <StorageLabelInput onChange={onChange} defaultValue={value} />
                <Divisor />
              </>
            )}
          />
          <Controller
            name="orderNotes"
            control={control}
            render={({ field: { value, onChange } }) => (
              <OrderComment comment={value} onChange={onChange} />
            )}
          />
        </form>
      </div>
      <div className="ml-8 w-1/3">
        <Controller
          name="customerOrderId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <SummaryBox
              creationMode="REQUISITION_CREATION"
              email={requesterEmail}
              grandTotal={grandTotal}
              // Auto-approved requisitions always have FMS integration
              hasFMSIntegration={hasFMSIntegration || isAutoApprovedRequisition}
              isLoading={loading}
              name={requesterName}
              nrLineItems={lineItems.length}
              nrRfqItems={rfqItems.length}
              onPoNumberChange={onChange}
              onSubmit={handleSubmit(submit)}
              poNumber={value}
              poNumberError={errors.customerOrderId?.message}
              port={appState.port}
              register={register}
              registrationName="customerOrderId"
              validationErrorMessage={validationErrorMessage}
              vessel={vessel}
              renderAttentionInfo={renderAttentionInfo}
              isAutoApprovedRequisition={isAutoApprovedRequisition}
            />
          )}
        />
      </div>
    </>
  );
};

export default RequisitionInfo;
