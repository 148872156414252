/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { LitePunchoutBasket } from "../models/LitePunchoutBasket";
import type { LitePunchoutRequest } from "../models/LitePunchoutRequest";
import type { LitePunchoutResponse } from "../models/LitePunchoutResponse";

export class CXmlPunchoutService {
  /**
   * @returns LitePunchoutResponse OK
   * @throws ApiError
   */
  public static sendOrderMessage(
    {
      requestBody,
    }: {
      requestBody: LitePunchoutRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LitePunchoutResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/lite/punchout/checkout",
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns LitePunchoutBasket OK
   * @throws ApiError
   */
  public static getItems(
    {
      sessionId,
    }: {
      sessionId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LitePunchoutBasket> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/punchout/basket/{sessionId}",
      path: {
        sessionId: sessionId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
