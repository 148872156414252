import { DefaultError, InfiniteData, QueryKey, useInfiniteQuery } from "@tanstack/react-query";

import { OrderRequisition, OrderRequisitionStatus } from "@web/common/network/model";

import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { LocalOrderReqService } from "src/services/LocalOrderReqService";
import { OrdersService } from "src/typegens";
import { ApiRequestOptions } from "src/typegens/core/ApiRequestOptions";

// TODO #9356: Remove this file and its usages when removing the `closedAttribute` feature flag
const getOrderRequisitionsList = async (
  {
    cursor,
    isOfflineDraftEnabled,
    pageSize,
  }: { cursor: string; isOfflineDraftEnabled: boolean; pageSize?: string },
  options?: Pick<ApiRequestOptions, "headers" | "signal">
) => {
  const statusesToGet: OrderRequisitionStatus[] = [
    "CANCELLED",
    "CLOSED",
    "PENDING_CANCELLATION",
    "SUPPLIER_ACKNOWLEDGED",
    "SUPPLIER_CONFIRMED",
    "SUPPLIER_PENDING",
    "SUPPLIER_REJECTED",
    "PENDING_PURCHASER_APPROVAL",
    "PURCHASER_REJECTED",
    "SUPPLIER_QUOTE_PENDING",
    "SUPPLIER_QUOTE_ACKNOWLEDGED",
    "SUPPLIER_QUOTE_PROVIDED",
    "SUPPLIER_QUOTE_REJECTION_ACKNOWLEDGED",
    "QUOTE_EXPIRED",
    "QUOTE_REJECTED",
  ];
  // This is only temporary, as long as online drafts created in Main App are
  // automatically accessible by the Lite app.
  // In the end, the DRAFT_CREATED and DRAFT_DELETED statuses should be removed
  // from the backend whatsoever.
  if (!isOfflineDraftEnabled) {
    const draftStatuses: OrderRequisitionStatus[] = ["DRAFT_CREATED", "DRAFT_DELETED"];
    statusesToGet.push(...draftStatuses);
  }

  const orderRequisitionList = await OrdersService.getAllOrderRequisitions(
    { cursor, statuses: statusesToGet, pageSize },
    options
  );
  return {
    ...orderRequisitionList,
    items: orderRequisitionList.items.map((o) => LocalOrderReqService.toOrderRequisition(o)),
  };
};

type QueryResponseData = { items: OrderRequisition[]; cursor?: string };

export const LEGACY_useOrderRequisitionListQuery = (settings = {}) => {
  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isOnline } = useNetworkDetector();

  return useInfiniteQuery<
    QueryResponseData,
    DefaultError,
    InfiniteData<QueryResponseData>,
    QueryKey,
    string
  >({
    queryKey: ["orderRequisitionsList"],
    queryFn: ({ pageParam, signal }) =>
      getOrderRequisitionsList(
        {
          cursor: pageParam,
          isOfflineDraftEnabled,
          pageSize: areOfflineCapabilitiesEnabled ? "50" : "",
        },
        { signal }
      ),
    initialPageParam: "",
    getNextPageParam: (lastPage) => {
      // Disable pagination in the offline mode (as all orders will be returned).
      // This maybe be revisited after #TODO 9869
      if (lastPage.cursor !== "" && (areOfflineCapabilitiesEnabled ? isOnline : true)) {
        return lastPage.cursor;
      }
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
    ...settings,
  });
};

export default LEGACY_useOrderRequisitionListQuery;
