import { DefaultError, useMutation, useQueryClient } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { isDefined } from "@web/utils";

import { createRequisition } from "src/api/createRequisition";
import { AttentionInfo } from "src/components/AttentionInfo";
import RequisitionInfo from "src/components/RequisitionInfo/RequisitionInfo";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { useDeleteOrderDraftMutation } from "src/hooks/orderDrafts/useDeleteOrderDraftMutation";
import useBasket from "src/hooks/useBasket";
import { useBasketVerification } from "src/hooks/useBasketVerification";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { useProductVariantsMutation } from "src/hooks/useProductVariantsMutation";
import { useToastMessage } from "src/hooks/useToastMessage";
import { RequisitionLayout } from "src/layouts";
import { ordersStore } from "src/objectStorage";
import { LiteCreateRequisitionRequest, LiteOrderRequisition } from "src/typegens";
import {
  deleteDraftFromOrderRequisitionsList,
  isApiError,
  prependToOrderRequisitionsList,
} from "src/utils";

export const RequisitionSummary = () => {
  const navigate = useNavigate();
  const {
    basketFormAnswers,
    draft,
    lineItems,
    rfqItems,
    clearOrderRequisitionFlow,
    getOrderItems,
  } = useBasket();
  const [appState, dispatch] = useAppStateContext();
  const queryClient = useQueryClient();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isFromDraft = searchParams.get("from") === "draft";
  const { setToastMessage } = useToastMessage();
  const hasRfQFeature = useFlag("rfq");
  const isBasketVerificationEnabled = useFlag("basket-verification");
  // Default to empty string in case of RfQs
  const supplierId = lineItems[0]?.sku.supplier?.supplierId || (hasRfQFeature ? "" : undefined);
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();

  const isCloseAttributeEnabled = useFlag("close-attribute");
  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();

  const { mutate: deleteDraft } = useDeleteOrderDraftMutation({
    hasErrorMessage: true,
  });
  const { mutateAsync: productVariantsAsyncMutation, isPending: isProductVariantsMutationPending } =
    useProductVariantsMutation();
  const { findChangedProducts, BasketVerificationModal } = useBasketVerification();

  const [validationErrorMessage, setValidationErrorMessage] = useState<string | undefined>(
    undefined
  );

  const handleDraftRemoval = async () => {
    if (!isFromDraft || !draft) {
      return;
    }

    if (isOfflineDraftEnabled) {
      return deleteDraft(draft.id);
    }

    queryClient.setQueryData(
      // Drafts are not closed, so this simplification is enough
      isCloseAttributeEnabled ? ["orderRequisitionsList", false] : ["orderRequisitionsList"],
      deleteDraftFromOrderRequisitionsList(draft.id)
    );
  };

  const createMutation = useMutation<
    Array<LiteOrderRequisition>,
    DefaultError,
    LiteCreateRequisitionRequest
  >({
    mutationKey: ["createRequisition"],
    mutationFn: createRequisition,
    onSuccess: async (result) => {
      // TODO: data on success screen should be taken from mutation cache.
      dispatch({
        type: "setLastCreated",
        value: result,
      });
      clearOrderRequisitionFlow();

      queryClient.setQueryData(
        // New requisitions are not closed, so this simplification is enough
        isCloseAttributeEnabled ? ["orderRequisitionsList", false] : ["orderRequisitionsList"],
        prependToOrderRequisitionsList(result)
      );

      await handleDraftRemoval();

      if (areOfflineCapabilitiesEnabled) {
        const [createdRequisition] = result;
        await ordersStore.put(createdRequisition, createdRequisition.id);
      }

      navigate(RoutesConfig.requisitions.success);
    },
    onError: (error: unknown) =>
      // error
      {
        if (isApiError(error)) {
          if (error.status === 400) {
            setValidationErrorMessage(error.body?.errors?.[0]?.defaultMessage);
          }
        }
        setToastMessage({
          type: "failure",
          // Here should be error message but there is no such thing in types nor documentation. To do in BE.
          message: "There is an error with creating a requisition.",
        });
      },
  });

  const submitRequisition = async (requisitionSummary: LiteCreateRequisitionRequest) => {
    if (!appState.configuration) {
      setToastMessage({
        type: "failure",
        message: "No configuration.",
      });

      return;
    }

    if (lineItems.length === 0 && rfqItems.length === 0) {
      setToastMessage({
        type: "failure",
        message: "No items in the basket.",
      });

      return;
    }

    if (!appState.port?.id) {
      setToastMessage({
        type: "failure",
        message: "Unknown port.",
      });

      return;
    }

    if (!appState.orderType) {
      setToastMessage({
        type: "failure",
        message: "Unknown order type.",
      });

      return;
    }

    if (!appState.configuration?.vessel.id) {
      setToastMessage({
        type: "failure",
        message: "Unknown vessel ID.",
      });

      return;
    }

    if (!isDefined(supplierId)) {
      return setToastMessage({
        type: "failure",
        message: "Unknown supplier",
      });
    }
    const draftId = isFromDraft && !isOfflineDraftEnabled ? draft?.id : undefined;
    const dutyFreeDeclaration = appState.dutyFreeDeclaration;

    const request = {
      dutyFreeDeclaration: isDefined(dutyFreeDeclaration)
        ? {
            dutyFree: dutyFreeDeclaration.dutyFree,
            name: dutyFreeDeclaration.name,
            position: dutyFreeDeclaration.position,
          }
        : undefined,
      requesterInformation: requisitionSummary.requesterInformation,
      agentInformation: requisitionSummary.agentInformation,
      deliveryDate: requisitionSummary.deliveryDate,
      portId: appState.port?.id,
      vesselId: appState.configuration?.vessel.id,
      catalogItems: getOrderItems(),
      rfqItems: requisitionSummary.rfqItems,
      supplierId: supplierId,
      customerOrderId: requisitionSummary.customerOrderId,
      storageLabel: requisitionSummary.storageLabel,
      orderNotes: requisitionSummary.orderNotes,
      draftId,
      invoiceAccountId: requisitionSummary.invoiceAccountId,
      consolidated: requisitionSummary.consolidated,
      warehouseId: requisitionSummary.consolidated ? requisitionSummary.warehouseId : undefined,
      orderType: appState.orderType,
      basketFormAnswers,
    };

    if (isBasketVerificationEnabled) {
      const skus = await productVariantsAsyncMutation({
        variantIds: lineItems.map((item) => item.sku.id),
        portId: appState.port.id,
        orderType: appState.orderType,
      });
      const { productsWithChangedPrice, unavailableProducts } = findChangedProducts({
        lineItems,
        skus,
        submitCallback: () => {
          createMutation.mutate(request);
        },
      });

      if (unavailableProducts.length > 0 || productsWithChangedPrice.length > 0) {
        return;
      }
    }

    createMutation.mutate(request);
  };
  const submitRequisitionHandler = (requisitionSummary: LiteCreateRequisitionRequest) => {
    allowOnlineOnly(() => {
      submitRequisition(requisitionSummary);
    });
  };

  const renderAttentionInfo = useCallback(() => {
    const items = appState?.port?.attentionInfo || [];
    return items.length > 0 ? <AttentionInfo items={items} className="mt-4.5 mb-3" /> : null;
  }, [appState?.port]);

  const content = (
    <>
      <RequisitionInfo
        submitForm={submitRequisitionHandler}
        loading={createMutation.isPending || isProductVariantsMutationPending}
        validationErrorMessage={validationErrorMessage}
        renderAttentionInfo={renderAttentionInfo}
      />
      <AllowOnlineOnlyWarningModal />
      <BasketVerificationModal />
    </>
  );

  return <RequisitionLayout>{content}</RequisitionLayout>;
};
