import { MinusIcon, PlusIcon, ReplyIcon, TrashIcon } from "@heroicons/react/solid";
import { emulateTab } from "emulate-tab";
import { useEffect, useState } from "react";

import { IconButton, RegularButton } from "../../atoms";

interface LineItemQuantityProps {
  initialQuantity: number;
  onQuantityChange: (updatedQuantity: number, isRemoved?: boolean) => void;
  shouldTrack?: boolean;
  isRestorationEnabled?: boolean;
  quantity?: number;
  minusButtonClickCallback?: () => void;
  plusButtonClickCallback?: () => void;
  inputChangeCallback?: (value: string) => void;
  min: number;
  index: number;
  max?: number;
  interval?: number;
  customerOrderQuantity?: number;
  setIsErrorMessage?: ({ err }: { err: boolean }) => void;
  maxLength?: number;
}

const noop = () => {
  // no operation
};

const parseInputValue = (value: string): number => {
  if (value === "") {
    return -1;
  }

  return +value.replace(/,/, ".");
};

export const LineItemQuantityFastGather: React.FC<LineItemQuantityProps> = ({
  initialQuantity,
  onQuantityChange,
  setIsErrorMessage,
  min,
  index,
  isRestorationEnabled,
  quantity: controlledQuantity,
  minusButtonClickCallback = noop,
  plusButtonClickCallback = noop,
  inputChangeCallback = noop,
  maxLength = 4,
}) => {
  const [uncontrolledQuantity, setQuantity] = useState(initialQuantity);
  const quantity = controlledQuantity ?? uncontrolledQuantity;
  const [inputValue, setInputValue] = useState(`${quantity}`);
  const [parsedInputValue, setParsedInputValue] = useState(parseInputValue(inputValue));
  const [error, setError] = useState<boolean>(false);
  const [selectedInput, setSelectedInput] = useState(0);
  const isRemoved = quantity < min;

  const setImperativeValue = (value: number) => {
    setQuantity(value);
    setInputValue(`${value}`);
    onQuantityChange(value);
  };

  useEffect(() => {
    setImperativeValue(quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  const handleItemRestoration = () => {
    setImperativeValue(initialQuantity);
  };

  useEffect(() => {
    if (error && setIsErrorMessage) {
      setIsErrorMessage({ err: error });
    }

    if (!error && setIsErrorMessage) {
      setIsErrorMessage({ err: error });
    }
  }, [setIsErrorMessage, error]);

  useEffect(() => {
    setParsedInputValue(parseInputValue(inputValue));
  }, [inputValue]);

  const manageErrors = () => {
    if (parsedInputValue < min) {
      setMinimalQuantityError();
      return;
    }

    resetError();
  };

  const setMinimalQuantityError = () => setError(true);
  const resetError = () => setError(false);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    inputChangeCallback(value);

    if (!isNaN(filterInt(value)) || value === "") {
      setInputValue(value);
    }
  };

  const filterInt = (value: string) => {
    if (/^[-+]?\d+$/.test(value)) {
      return Number(value);
    } else {
      return NaN;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (e.key === "Enter") {
      if (!isNaN(filterInt(value)) || value === "") {
        setInputValue(value);
      }
      setSelectedInput(selectedInput + 1);

      emulateTab();
    }
    if (e.key === "ArrowUp") {
      emulateTab.backwards();
    }
    if (e.key === "ArrowDown") {
      emulateTab();
    }
    if (e.key === "ArrowRight") {
      validate();
      if (min !== undefined && min > 1 && quantity < min) {
        setInputValue(`${min}`);
      } else if (quantity === 9999) {
        null;
      } else {
        handlePlus();
      }
    }
    if (e.key === "ArrowLeft" && quantity > 0) {
      validate();
      if (min !== undefined && min > 1 && quantity < min + 1) {
        setInputValue(`${0}`);
      } else {
        handleMinus();
      }
    }
  };

  const validate = () => {
    manageErrors();

    if (Number.isInteger(parsedInputValue) && (parsedInputValue >= min || parsedInputValue === 0)) {
      commit(parsedInputValue);
    } else {
      // Revert input field to last legal value
      setInputValue(`${quantity}`);
    }
  };

  const handleMinus = () => {
    minusButtonClickCallback();

    const qty = quantity - 1;

    if (qty < min) {
      setInputValue(`${0}`);
      commit(0);
      return;
    }

    setInputValue(`${qty}`);
    commit(qty);
  };

  const isPlusButtonDisabled = (quantity + 1).toString().length > maxLength;

  const handlePlus = () => {
    if (isPlusButtonDisabled) {
      return;
    }
    plusButtonClickCallback();

    const qty = quantity + 1;
    setInputValue(`${qty}`);
    commit(qty);
  };

  const commit = (qty: number) => {
    setQuantity(qty);
    resetError();
    onQuantityChange(qty, qty === 0);
  };

  if (isRestorationEnabled && isRemoved) {
    return (
      <RegularButton
        variant="secondary"
        size="large"
        label="Restore"
        LeadingIcon={ReplyIcon}
        onClick={handleItemRestoration}
      />
    );
  }

  return (
    <div className="flex justify-between gap-2">
      <IconButton
        size="small"
        variant="secondary"
        shape="circle"
        label="Decrease"
        Icon={quantity <= min ? TrashIcon : MinusIcon}
        onClick={handleMinus}
        data-testid="decrease"
      />
      <input
        value={inputValue}
        maxLength={maxLength}
        onChange={handleInputChange}
        autoFocus={index === selectedInput}
        onBlur={validate}
        onKeyDown={handleKeyDown}
        className="flex-none w-8 h-6 border rounded text-center"
        aria-label="Item Quantity"
        data-testid="itemQuantity"
      />
      <IconButton
        size="small"
        variant="secondary"
        shape="circle"
        label="Increase"
        Icon={PlusIcon}
        onClick={handlePlus}
        data-testid="increase"
        disabled={isPlusButtonDisabled}
      />
    </div>
  );
};
